import { AdminPCRsClient, PCRStatus, PCRTypeSelection } from '../EpdClient';
const client = new AdminPCRsClient(process.env.REACT_APP_API_URL);

class AdminPCRService {
  static findPcrs = async (filter?: string, statusId?: PCRStatus, typeId?: PCRTypeSelection, categoryId?: string) => {
    return await client.getAll(filter, statusId, typeId, categoryId);
  };
  static findPcr = async (id?: string) => {
    return await client.get(id as any);
  };
}

export default AdminPCRService;
