export const environdecTypeEnum = [
  { text: 'EPD', value: '1' },
  { text: 'PCR', value: '2' },
  { text: 'Contact', value: '3' },
  { text: 'EPDOwner', value: '4' },
];

export const ilcdTypeEnum = [
  { text: 'Process', value: '1' },
  { text: 'Flow', value: '2' },
  { text: 'FlowProperty', value: '3' },
  { text: 'UnitGroup', value: '4' },
  { text: 'Source', value: '5' },
  { text: 'Contact', value: '6' },
  { text: 'LciaMethod', value: '7' },
];

export type Option = {
  label?: string;
  value?: string;
};

export type IlcdEpdMappingModel = {
  id: string;
  ilcduuid: string | undefined;
  epdilcdRelationId: string | undefined;
  epdVersionId: string | undefined;
  epdVersionDate: string | undefined;
  environdecObjectName: string | undefined;
  ilcdVersion: string | undefined;
  environdecType: Option | undefined;
  ilcdPlusEPDType: Option | undefined;
  log: string | undefined;
  publishedToEcoplatform: string | undefined;
  companyName: string | undefined;
};
