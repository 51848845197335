import styled from 'styled-components';

import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';

type TProps = Partial<TSmartLabelProps> & {
  value: any;
  name: string;
  onChanged: any;
  disabled?: boolean;
  style?: any;
  placeholder?: string;
  type?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const WizardTextAreaControlledComponent: React.FunctionComponent<TProps> = ({
  label,
  tooltip,
  tooltipPosition,
  labelStyle,
  name,
  value,
  onChanged,
  disabled,
  style,
  placeholder,
  required,
  ...props
}) => {
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', ...style }}>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!value}
        />
      )}
      <StyledTextArea
        {...props}
        style={style}
        value={value}
        onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          e.currentTarget.value !== value && onChanged(name, e.currentTarget.value)
        }
        disabled={disabled}
        placeholder={disabled ? undefined : placeholder}
      />
    </div>
  );
};

const StyledTextArea = styled.textarea`
  padding: 0.75rem 1rem;
  border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
  border-radius: 2px;
  margin: 0;
  resize: vertical;
  min-height: 44px;

  &:focus {
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.primaryOrange};
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export default WizardTextAreaControlledComponent;
