import ManageCollections from 'components/v2/collections/ManageCollections';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import WelcomeToPortal from 'pages/dashboard/WelcomeToPortal';
import React from 'react';
import { Container } from 'styles/Styles.styled';

import { withGreyBackground } from '../WithGreyBackground';

const CompanyCollections: React.FunctionComponent = () => {
  const { companyId } = React.useContext(CompanyContext);

  return companyId ? (
    <Container>
      <ManageCollections companyId={companyId || ''} key={'collections'} />
    </Container>
  ) : (
    <WelcomeToPortal />
  );
};

export default withGreyBackground(CompanyCollections);
