import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import SortedTable from 'components/form/SortedTable';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { FixedLabel } from 'pages/pcrWizard/PcrWizard';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { UserListModel } from 'services/EpdClient';
import AdminUserService from 'services/admin/AdminUserService';
import styled, { useTheme } from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  ErrorMessage,
  H2,
  LabelBox,
  Message,
  MessageBox,
  ResultMessageBox,
  SearchBox,
  ValueBox,
} from 'styles/Styles.styled';
import Spinner from 'styles/spinner-left.styled';
import { Theme } from 'styles/theme';
import { PoorMansError } from 'util/utils';
import Checkbox from 'components/form/Checkbox';
import * as Yup from 'yup';

const AdminUsers: React.FunctionComponent = () => {
  const history = useHistory();
  const theme = useTheme() as Theme;
  const titles = [
    { label: 'First Name', field: 'firstName' },
    { label: 'Last Name', field: 'lastName' },
    { label: 'Email', field: 'email' },
  ];
  const [users, setUsers] = useState<UserListModel[]>();
  const [selectedUsers, setSelectedUsers] = useState<UserListModel[]>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [underSearch, setUnderSearch] = useState<boolean>(false);
  const [usersNumber, setUsersNumber] = useState<number | undefined>();

  const initialValues = {
    email: '',
    includeArchived: false
  };

  const validationSchema = Yup.object({
    email: Yup.string(),
    includeArchived: Yup.boolean().notRequired()
  });

  const onSearch = async (rowValues: any) => {
    try {
      setIsLoading(true);
      const result = await AdminUserService.getUsers(rowValues.email, rowValues.includeArchived);
      setSelectedUsers(result);
      setUsersNumber(result.length);
      setUnderSearch(true);
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const openUser = (item: any) => {
    history.push(EpdLinks.editUser(item.id));
  };

  useEffect(() => {
    const loadUsers = async () => {
      const result = await AdminUserService.getUsers();
      setUsers(result);
    };
    loadUsers();
  }, []);
  return (
    <Container>
      <EditFull>
        <H2>Users</H2>
        <Formik initialValues={initialValues} onSubmit={onSearch} validationSchema={validationSchema}>
          <AdminBox>
            <AdminSearchGridForm>
              <LabelBox>
                <FixedLabel>Filter</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput
                  style={{ boxSizing: 'border-box', width: '100%' }}
                  name="email"
                  type="text"
                  placeholder="Search by name or email..."
                />
              </ValueBox>
              <ValueArchivedUser>
                <Checkbox 
                label="Include archived" 
                name="includeArchived" 
                labelStyle={{display: 'flex', alignItems: 'center'}}
                inputStyle={{margin: '0.5rem 0.75rem 0.5rem 0'}} />
              </ValueArchivedUser>
              <SearchBox>
                <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                  Search
                </ButtonSmall>
              </SearchBox>
            </AdminSearchGridForm>
          </AdminBox>
        </Formik>
        {errorMessage && isEmpty(users) && (
          <ErrorMessage style={{ marginTop: '1rem', color: 'black' }}> {errorMessage}</ErrorMessage>
        )}
        {(underSearch || isLoading) && !selectedUsers && (
          <div>
            <Spinner />
          </div>
        )}
        {underSearch && isEmpty(selectedUsers) && selectedUsers !== null && (
          <ErrorMessage style={{ marginTop: '1rem', color: 'black' }}>No records found.</ErrorMessage>
        )}
        {underSearch && !isEmpty(selectedUsers) && (
          <MessageBox>
            <ResultMessageBox>
              {usersNumber === 1 ? (
                <Message style={{ marginLeft: 0 }}>Found {usersNumber} match</Message>
              ) : (
                <Message>Found {usersNumber} matches</Message>
              )}
            </ResultMessageBox>
            <div
              style={{
                display: 'inline-block',
                gridColumnStart: '2',
                gridColumnEnd: '3',
                minWidth: '100%',
                padding: '1px',
                borderBottomLeftRadius: '3px',
                borderBottom: `1px solid ${theme.colors.mediumGrey}`,
                alignSelf: 'end',
                justifySelf: 'end',
              }}
            ></div>
          </MessageBox>
        )}
        {underSearch && !isEmpty(selectedUsers) && (
          <div style={{ marginTop: '-1.2rem' }}>
            <SortedTable columns={titles} collection={selectedUsers ?? []} onClick={openUser} />
          </div>
        )}
      </EditFull>
    </Container>
  );
};

const ValueArchivedUser = styled.div`
  grid-column-start: 2;
  grid-column-end: 4;
  padding-left: 0;
`;

export default AdminUsers;
