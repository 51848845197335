import OverlaySpinner from 'components/spinners/OverlaySpinner';
import { ProcessStatus } from 'constants/constants';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ButtonSmall } from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';

import { ActionState, EPDStateModel } from '../../services/EpdClient';

export const EpdStateButton: React.FunctionComponent<{
  callFunction: (id: string) => any;
  title: string;
  actionState?: ActionState;
  epdId?: string;
  setState?: (state: EPDStateModel | null) => void;
  buttonStyle?: {};
  toastMessage?: string;
}> = ({ callFunction, title, actionState, epdId, setState, buttonStyle, toastMessage }) => {
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const onClick = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      if (!epdId) {
        setStatus(ProcessStatus.Error);
        toast.error('EPD identifier is undefined', {
          position: 'top-center',
        });
        return null;
      }
      const result = await callFunction(epdId);
      toast.success(toastMessage as string, {
        position: 'top-center',
      });
      if (setState) setState(result);
      setStatus(ProcessStatus.Success);
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  return (
    <>
      {status === ProcessStatus.Fetching && <OverlaySpinner />}
      {actionState !== ActionState.Hidden && (
        <ButtonSmall
          type="submit"
          disabled={actionState === ActionState.Disabled}
          onClick={onClick}
          style={{ margin: '0.5rem', ...buttonStyle }}
        >
          {title}
        </ButtonSmall>
      )}
    </>
  );
};
