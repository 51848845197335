import { Button } from 'primereact/button';
import styled from 'styled-components';
import { ErrorAlertPanel } from '../../panels/Styles.styled';

type TProps = {
  onValidate: any;
};

const ValidateErrorsContainer = ({ onValidate }: TProps) => {
  return (
    <StyledErrorAlertPanel>
      <div>
        You have several validation errors in the table.
        <br />
        To validate the errors please click the button.
      </div>
      <StyledValidateErrorsButton label="Validate errors" onClick={onValidate} />
    </StyledErrorAlertPanel>
  );
};

const StyledErrorAlertPanel = styled(ErrorAlertPanel)`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledValidateErrorsButton = styled(Button)`
  background-color: '#CC302B';
  border-color: '#CC302B';
  min-width: 90px;
  border-radius: 4px;
  font-size: 1rem;

  &:disabled {
    background-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }

  height: 1.5rem;
  min-height: 22px;
  ${(props) => props.theme.typography.buttonButtonDefault};
`;

export default ValidateErrorsContainer;
