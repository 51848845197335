import { ReactComponent as AddImageIcon } from 'assets/images/icons/plus.svg';
import React from 'react';
import styled from 'styled-components';

export const AddCustomBlockButton: React.FunctionComponent<{ onClick: () => void; caption: React.ReactNode }> = ({
  onClick,
  caption,
}) => {
  return (
    <AddButtonBox onClick={onClick}>
      <AddImageIcon style={{ marginRight: '0.625rem' }} />
      {caption}
    </AddButtonBox>
  );
};

const AddButtonBox = styled.div`
  ${(props) => props.theme.typography.buttonButtonSmall}
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  border: 1px dashed ${(props) => props.theme.colors.primaryOrange01};
  margin-top: 16px;
  justify-content: start;
  align-items: center;
  display: flex;
  max-height: 70px;
  min-height: 70px;
  background: ${(props) => props.theme.colors.primaryOrange01};
  color: ${(props) => props.theme.colors.primaryOrange};
  cursor: pointer;
`;
