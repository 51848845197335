import React from 'react';
import { FileModelPlus } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';

interface ImageViewProps {
  images?: FileModelPlus[];
}

export const PDFImagesView: React.FunctionComponent<ImageViewProps> = ({ images }) => {
  return (
    <div>
      {images &&
        images.map((img) => (
          <ImageBox key={img.id}>
            <img alt={`Flow: ${img}`} src={FileService.getImageUrl(img.id)} />
          </ImageBox>
        ))}
    </div>
  );
};

const ImageBox = styled.div`
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  margin-bottom: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
