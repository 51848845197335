import React from 'react';
import { Label, LargeRadiusInput, ErrorMessage } from 'styles/Styles.styled';
import { useField } from 'formik';

const LargeRadiusTextInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      {label && <Label htmlFor={props.id || props.name}>{label}</Label>}
      <LargeRadiusInput {...field} {...props} />
      {meta.touched && meta.error && !props.supressErrors ? (
        <ErrorMessage style={{ marginTop: 0 }}>{meta.error}</ErrorMessage>
      ) : null}
    </>
  );
};

export default LargeRadiusTextInput;
