import { FindTheme } from 'components/theme/ThemeExtension';
import { defaultThemeCode } from 'constants/constants';
import { useEffect, useState } from 'react';
import AuthService from 'services/AuthService';
import { Theme } from 'styles/theme';

export const useTheme = (licensee: any) => {
  const [isNewUI, setIsNewUI] = useState<boolean>(false);
  const [theme, setTheme] = useState<Theme>(FindTheme(localStorage.getItem('theme') || '', isNewUI));

  useEffect(() => {
    const newUIValue = localStorage.getItem('newui');
    setIsNewUI(newUIValue === 'true');
  }, []);

  const changeTheme = (theme: string) => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = (theme?.toLowerCase() ?? defaultThemeCode.toLowerCase()) === 'au' ? 'favicon-au.ico' : 'favicon.ico';
    }
    setTheme(FindTheme(theme, isNewUI));
    localStorage.setItem('theme', theme);
  };

  useEffect(() => {
    const changeThemeByLicensee = () => {
      if (!AuthService.isLoggedin()) {
        setTheme(FindTheme(defaultThemeCode, isNewUI ?? false));
        localStorage.setItem('theme', defaultThemeCode);
        return;
      }
      const licenseeCode = (licensee ? licensee?.code?.toLowerCase() : localStorage.getItem('theme')) ?? defaultThemeCode;
      changeTheme(licenseeCode);
    };

    changeThemeByLicensee();
  }, [isNewUI, licensee]);

  const onChangeUI = (newUI: boolean) => {
    localStorage.setItem('newui', newUI.toString());
    setIsNewUI(newUI);
  };

  return { theme, changeTheme, isNewUI, onChangeUI };
};
