import React from 'react';
import VersionHistorySummaryGrid, { SummaryGridRow } from '../VersionHistorySummaryGrid';
import { ScenarioItemModel } from 'types/types';

const NOT_ASSIGNED = 'N/A';

const VersionHistoryScenarioGrid: React.FC<{ scenario: ScenarioItemModel }> = ({ scenario }) => {
  const possibleRows: (SummaryGridRow | false)[] = [
    {
      name: scenario.isDefaultScenario ? 'Name of the default scenario' : 'Name of the additional scenario',
      value: scenario.name ?? NOT_ASSIGNED,
    },
    {
      name: scenario.isDefaultScenario ? 'Description of the default scenario' : 'Description of the additional scenario',
      value: scenario.description ?? NOT_ASSIGNED,
    },
  ];

  const rows = possibleRows.filter((r) => r) as SummaryGridRow[];

  return <VersionHistorySummaryGrid rows={rows} />;
};

export default VersionHistoryScenarioGrid;
