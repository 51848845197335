import React from 'react';
import { OTHER_LCA_SOFTWARE_NAME, YES_NO } from '../../constants';
import { useEpdVersionHistoryData } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySummaryGrid, { SummaryGridRow } from '../VersionHistorySummaryGrid';

const NOT_ASSIGNED = 'N/A';

const VersionHistoryLcaInformationGrid: React.FC<{}> = () => {
  const {
    epdDefinitionInformation,
    infrastructureAndCapitalGoods,
    dataQualityAssessmentAndReferenceYears,
    lcaSpecification,
    dataSources,
    dataSourceOptions,
    lcaSoftwareOptions,
    referencePackageVersions15804,
  } = useEpdVersionHistoryData();

  const possibleRows: (SummaryGridRow | false)[] = [
    {
      name: 'Are infrastructure or capital goods included in any upstream, core or downstream processes?',
      value: infrastructureAndCapitalGoods?.includedInProcesses ?? NOT_ASSIGNED,
    },
    !!infrastructureAndCapitalGoods &&
      infrastructureAndCapitalGoods.includedInProcesses === YES_NO.YES && {
        name: 'Do infrastructure and capital goods contribute more than 10% to the cradle-to-gate results for all the environmental impact indicators declared in the EPD?',
        value: infrastructureAndCapitalGoods.contributionLevel ?? NOT_ASSIGNED,
      },
    !!infrastructureAndCapitalGoods &&
      infrastructureAndCapitalGoods.includedInProcesses === YES_NO.YES &&
      infrastructureAndCapitalGoods.contributionLevel === YES_NO.YES && {
        name: 'Detailed description of infrastructure and capital goods',
        value: infrastructureAndCapitalGoods.description ?? NOT_ASSIGNED,
        isHtmlRichText: true,
      },
    {
      name: 'Description of data quality assessment and reference years',
      value: dataQualityAssessmentAndReferenceYears.dataQualityAssessmentSummary ?? NOT_ASSIGNED,
    },
    {
      name: 'Data sources used for this EPD',
      value: dataSources
        .map((ds) => {
          const dataSource = dataSourceOptions.find((o) => o.id === ds.dataSourceId);
          if (!dataSource) {
            return 'N/A';
          }
          const version = dataSource.dataSourceVersions.find((v) => v.value === ds.dataSourceVersionId);
          return `${dataSource.name}\n${version?.label}`;
        })
        .join('\n\n'),
    },
    {
      name: 'LCA Software',
      value: (() => {
        if (!lcaSpecification) {
          return NOT_ASSIGNED;
        }
        const software = lcaSoftwareOptions.find((o) => o.id === lcaSpecification.lcaSoftware);
        if (!software) {
          return NOT_ASSIGNED;
        }
        if (software.name === OTHER_LCA_SOFTWARE_NAME) {
          return lcaSpecification.softwareName ?? NOT_ASSIGNED;
        }
        return software.name ?? NOT_ASSIGNED;
      })(),
    },
    {
      name: 'Software version',
      value: (() => {
        if (!lcaSpecification) {
          return NOT_ASSIGNED;
        }
        const software = lcaSoftwareOptions.find((o) => o.id === lcaSpecification.lcaSoftware);
        if (!software) {
          return NOT_ASSIGNED;
        }
        if (software.name === OTHER_LCA_SOFTWARE_NAME) {
          return lcaSpecification.softwareVersion ?? NOT_ASSIGNED;
        }
        const version = software.lcaSoftwareVersions.find((v) => v.value === lcaSpecification.lcaSoftwareVersion);
        if (!version) {
          return NOT_ASSIGNED;
        }
        return version.label;
      })(),
    },
    {
      name: 'Additional information about the underlying LCA-based information',
      value: lcaSpecification?.lcaBasedDescription ?? NOT_ASSIGNED,
    },
    {
      name: 'Version of the EN 15804 reference package',
      value: (() => {
        if (!epdDefinitionInformation.referencePackageVersion15804) {
          return NOT_ASSIGNED;
        }
        const version = referencePackageVersions15804.versions.find(
          (v) => v.id === epdDefinitionInformation.referencePackageVersion15804
        );
        if (!version) {
          return NOT_ASSIGNED;
        }
        return version.name;
      })(),
    },
    {
      name: 'Characterisation methods',
      value: epdDefinitionInformation.characterisationMethods || '',
    },
    {
      name: 'Technology description including background system',
      value: lcaSpecification?.technologyDescription ?? NOT_ASSIGNED,
    },
  ];

  const rows = possibleRows.filter((r) => r) as SummaryGridRow[];

  return <VersionHistorySummaryGrid rows={rows} />;
};

export default VersionHistoryLcaInformationGrid;
