import { HelpBox } from 'components/v2/help-boxes';
import React from 'react';
import { FormPanel, HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

const VerifierWillCheck: React.FunctionComponent = () => {
  return (
    <FormPanel style={{ display: 'block' }}>
      <HeaderCaptionSemiBold className="white-space-nowrap">Verification report</HeaderCaptionSemiBold>
      <HelpBox style={{ marginTop: '30px' }}>
        The verifier will check that the uploaded EPD and filled information in Steps 1 and 2 correspond with the verified
        information in the LCA report and EPD document.
        <span>
          After the review, the verifier will upload and approve the EPD.
          <br />
          You will be able to see the uploaded verification report.
        </span>
      </HelpBox>
    </FormPanel>
  );
};

export default VerifierWillCheck;
