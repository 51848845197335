import { Link } from 'react-router-dom';
import linkIcon from 'images/icons/svg/link-1.svg';
import React from 'react';
import styled from 'styled-components';
import { VersionHistoryModel } from 'types/types';
import { formatDate } from 'util/utils';
import { EpdLinks } from 'routes/EpdRoutes';

interface Props {
  epdId: string;
  data: VersionHistoryModel;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

const VersionItem: React.FunctionComponent<Props> = ({ epdId, data, onClick }) => {
  return (
    <StyledLink to={EpdLinks.epdVersionHistory(epdId, data.id)} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <div>{data.fullIdentificationNumber}</div>
      <VersionDateAndDetailsContainer>
        {formatDate(data.versionDate)}

        <ShowDetailsIcon src={linkIcon} alt="link" />
      </VersionDateAndDetailsContainer>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  background-color: #b4d0b64d;
  color: inherit;
  text-align: justify;
  text-decoration: none;
  padding: 14px 16px 14px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VersionDateAndDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ShowDetailsIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 10px;
`;

export default VersionItem;
