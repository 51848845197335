import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export const SystemBoundaryGridHeader = (
  <ColumnGroup>
    <Row>
      <Column />
      <Column header="Product stage" colSpan={3} />
      <Column header="Construction process stage" colSpan={2} style={{ maxWidth: '8rem' }} />
      <Column header="Use stage" colSpan={7} />
      <Column header="End of life stage" colSpan={4} />
      <Column header="Beyond product life cycle" style={{ maxWidth: '6rem' }} />
    </Row>
    <Row>
      <Column />
      <Column className="grid-text-vertical" header="Raw material supply" />
      <Column className="grid-text-vertical" header="Transport" />
      <Column className="grid-text-vertical" header="Manufacturing" />
      <Column className="grid-text-vertical" header="Transport to site" />
      <Column className="grid-text-vertical" header="Construction installation" />
      <Column className="grid-text-vertical" header="Use" />
      <Column className="grid-text-vertical" header="Maintenance" />
      <Column className="grid-text-vertical" header="Repair" />
      <Column className="grid-text-vertical" header="Replacement" />
      <Column className="grid-text-vertical" header="Refurbishment" />
      <Column className="grid-text-vertical" header="Operational energy use" />
      <Column className="grid-text-vertical" header="Operational water use" />
      <Column className="grid-text-vertical" header="De-construction demolition" />
      <Column className="grid-text-vertical" header="Transport" />
      <Column className="grid-text-vertical" header="Waste processing" />
      <Column className="grid-text-vertical" header="Disposal" />
      <Column className="grid-text-vertical" header="Reuse-Recovery-Recycling-potential" style={{ maxWidth: '4rem' }} />
    </Row>
    <Row>
      <Column header="Module" />
      <Column header="A1" />
      <Column header="A2" />
      <Column header="A3" />
      <Column header="A4" />
      <Column header="A5" />
      <Column header="B1" />
      <Column header="B2" />
      <Column header="B3" />
      <Column header="B4" />
      <Column header="B5" />
      <Column header="B6" />
      <Column header="B7" />
      <Column header="C1" />
      <Column header="C2" />
      <Column header="C3" />
      <Column header="C4" />
      <Column header="D" style={{ maxWidth: '4rem' }} />
    </Row>
  </ColumnGroup>
);
