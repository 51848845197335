import { StyledPrimeCheckbox } from 'components/v2/form/styled';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { ErrorMessage } from 'styles/Styles.styled';

const PrimeCheckbox = ({
  id,
  name,
  value,
  error,
  label,
  tooltip,
  tooltipPosition,
  required,
  labelStyle,
  inputStyle,
  onChange,
}: any) => {
  return (
    <>
      <StyledPrimeCheckbox inputId={id} name={name} style={inputStyle} onChange={onChange} checked={value} />
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={{ textTransform: 'none', ...labelStyle }}
          htmlFor={id}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default PrimeCheckbox;
