import styled from 'styled-components';

export const Grid2Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

export const PDF_PAGE = styled.div`
  width: 100%;
  page-break-before: always;
  /* background-color: #f0f0f0; */
`;

export const PDF_COVER_PAGE = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  page-break-after: always;
`;

export const PDF_H1 = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primaryGreen};
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
  line-height: 14px;
  margin-bottom: 12px;
`;

export const PDF_H2 = styled(PDF_H1)`
  margin-top: 12px;
  margin-bottom: 12px;
  text-transform: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

export const PDF_P = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
`;

export const PDF_Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  & > th,
  td {
    border: 1px solid #c8c8c8; /* Border style */
    padding: 4px; /* Padding for cell content */
    text-align: left; /* Align text to the left */
    vertical-align: top; /* Align content to the top */
  }
`;

export const CheckBoxGroupPdf = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  & .p-checkbox {
    width: 9px;
    height: 9px;
  }
  & .p-checkbox .p-checkbox-box {
    width: 9px;
    height: 9px;
    border-radius: 2px;
    border: 1px solid ${(props) => props.theme.colors.elementsButtonDisabled};
  }
  & .p-checkbox .p-checkbox-box.p-highlight {
    background: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  & .p-checkbox .p-checkbox-box.p-highlight:hover {
    background: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  && .p-checkbox-box.p-focus {
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }

  label {
    display: flex;
    margin-left: 0.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #000000;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const TopSection = styled.div`
  flex: 1;
  display: flex;
`;

export const LeftSubsection = styled.div`
  flex: 5;
  /* border: 1px solid #ccc; */
  padding: 1cm;
  height: 100%;
`;

export const LeftSubsectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

export const RightSubsection = styled.div`
  flex: 2;
  /* border: 1px solid #ccc; */
  padding: 10px;
  background-color: #b4d0b64d;
  padding: 1cm;
`;

export const RightSubsectionCaption = styled.div`
  color: #000;
  text-align: right;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 150% */
`;

export const RightSubsectionText = styled.div`
  color: #636363;
  text-align: right;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 1rem;
`;

export const SecondSection = styled.div`
  min-height: 100px;
  max-height: 400px;
  /* border: 1px solid #d1d1d1;
  border-right: none; */
  background-color: #d1d1d1;
  padding: 0;
`;

export const ThirdSection = styled.div`
  background-color: white;
  padding: 22px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DeclarationHeader = styled.p`
  color: #1e6052;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
`;

export const DeclarationName = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const DeclarationAccordance = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ProductsIncludedHeader = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ProductsIncluded = styled.p`
  color: #646363;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const BottomText = styled.p`
  color: #646363;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 8px; /* 133.333% */
`;

export const GreenLeftLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0.4cm;
  max-width: 0.4cm;
  height: 100vh;
  border-left: 0.4cm solid #1e6052;
  box-sizing: border-box;
`;
