import { AccountsClient, CreateAccountModel, AccountModel } from 'services/EpdClient';

const client = new AccountsClient(process.env.REACT_APP_API_URL);

class AccountsService {
  static getAccounts = async (companyId: string) => {
    return await client.getAll(companyId);
  };

  static deleteAccount = async (accountId: string) => {
    return await client.delete(accountId);
  };

  static createAccount = async (values: CreateAccountModel) => {
    return client.post(values);
  };

  static updateAccount = async (account: AccountModel) => {
    if (account.id === undefined) {
      throw new Error('Missing account id');
    }

    return client.put(account.id, account);
  };
}

export default AccountsService;
