import icon from 'images/icons/svg/list.svg';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { InvoiceStatus, InvoiceSummaryModel } from 'services/EpdClient';
import InvoiceService from 'services/InvoiceService';
import styled from 'styled-components';
import { Container, EpdLink, H2, H3, MainView } from 'styles/Styles.styled';

const Invoices: React.FunctionComponent = () => {
  const { companyid, accountId } = useParams<{ companyid: string; accountId: string }>();
  const [invoices, setInvoices] = useState<InvoiceSummaryModel>();

  useEffect(() => {
    const loadInvoices = async () => {
      const result = await InvoiceService.getInvoiceSummary(accountId);
      setInvoices(result);
    };
    loadInvoices();
  }, [accountId]);

  return (
    <Container>
      <MainView>
        <H2>Invoices</H2>
        <InfoBox>
          <img src={icon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
          <div>
            Here you can find invoices for this account.
            <br />
          </div>
        </InfoBox>

        <ListHeading>New</ListHeading>
        <List>
          {invoices?.drafts?.map((i) => (
            <ListRow key={i.invoiceId} to={EpdLinks.invoice(companyid, accountId, i.invoiceId ?? '')}>
              <img alt="" src={icon} />
              <span>{i.name}</span>

              <span>{i.invoiceStatus}</span>
            </ListRow>
          ))}
        </List>
        {invoices?.invoices ? (
          <Fragment>
            <ListHeading>History</ListHeading>
            <List>
              {invoices?.invoices?.map((i) => (
                <ListRow key={i.invoiceId} to={EpdLinks.invoice(companyid, accountId, i.invoiceId ?? '')}>
                  <img alt="" src={icon} />
                  {i.invoiceStatus === InvoiceStatus.Declined ? <span>{'Quotation ' + i.name}</span> : <span>{i.name}</span>}
                  <span>{i.invoiceStatus}</span>
                </ListRow>
              ))}
            </List>
          </Fragment>
        ) : null}
      </MainView>
    </Container>
  );
};

export const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;
  margin-bottom: 3rem;

  @media print {
    display: none;
  }
`;

const ListHeading = styled(H3)`
  padding-left: 1rem;
  font-family: inherit;
`;

const List = styled.div`
  padding: 0 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
`;

const ListRow = styled(EpdLink)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-column-gap: 0.5rem;
  border-bottom: 1px solid rgba(142, 142, 142, 0.25);

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export default Invoices;
