import React from 'react';
import { useTranslation } from 'react-i18next';
import EpdRoutes from 'routes/EpdRoutes';
import { Container, MainView, StyledLink } from 'styles/Styles.styled';

import { CardsEmpty } from './styles';

const WelcomeToPortal: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <MainView>
        <CardsEmpty>
          <h2>{t('epdDashboard.userHasNoCompanies.welcomeToPortal')}</h2>
          <div>
            {t('epdDashboard.userHasNoCompanies.startEpdPortal')}
            <br />
            <p>
              <StyledLink to={EpdRoutes.account.register.company}>
                {t('epdDashboard.userHasNoCompanies.connectToOrg')}
              </StyledLink>
            </p>
            <p>
              <StyledLink to={EpdRoutes.account.register.company}>
                {t('epdDashboard.userHasNoCompanies.createOrg')}
              </StyledLink>
            </p>
          </div>
        </CardsEmpty>
      </MainView>
    </Container>
  );
};

export default WelcomeToPortal;
