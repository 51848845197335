import { Link } from 'react-router-dom';
import EpdRoutes from 'routes/EpdRoutes';
import styled from 'styled-components';
import ParentCompanyPanel from './ParentCompanyPanel';

type TProps = {
  companyName: string;
  parentCompanyName: string;
  settingsEnabled: boolean;
  companyId: any;
};

const CompanyHeader = ({ companyName, parentCompanyName, settingsEnabled, companyId }: TProps) => {
  return (
    <Container>
      {settingsEnabled ? (
        <Link to={EpdRoutes.param(EpdRoutes.manageCompany, ':companyid', companyId || '')}>
          {companyName || '[Company name is empty]'}
        </Link>
      ) : (
        companyName || '[Company name is empty]'
      )}
      {!!parentCompanyName && <ParentCompanyPanel companyName={parentCompanyName} />}
    </Container>
  );
};

const Container = styled.div`
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
  color: #000000;
  align-self: center;
  margin-right: 1rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.media.phone} {
    font-size: 12px;
    line-height: 13px;
  }
  ${(props) => props.theme.media.tablet} {
    font-size: 16px;
    line-height: 18px;
  }
  ${(props) => props.theme.media.desktop} {
    font-size: 18px;
    line-height: 21px;
  }
  > a {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    ${(props) => props.theme.media.phone} {
      font-size: 12px;
      line-height: 13px;
    }
    ${(props) => props.theme.media.tablet} {
      font-size: 16px;
      line-height: 18px;
    }
    ${(props) => props.theme.media.desktop} {
      font-size: 18px;
      line-height: 21px;
    }
  }
`;

export default CompanyHeader;
