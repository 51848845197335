import React from 'react';
import { EPDBasedOnUnitEnum, VerifierType } from 'types/types';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import getVerificationTypeLabel from 'util/getVerificationTypeLabel';
import { formatDate } from 'util/utils';
import { VerificationType } from 'services/EpdClient';
import getSubtypeLabel from 'util/getSubtypeLabel';
import {
  MULTIPLE_PRODUCTS_BASED_ON_AVG_RESULTS_ID,
  SECTOR_OF_MULTIPLE_PRODUCTS_ID,
  SINGLE_PRODUCT_ID,
} from '../../constants';
import { useEpdVersionHistoryData } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySummaryGrid, { SummaryGridRow } from '../VersionHistorySummaryGrid';

const NOT_ASSIGNED = 'N/A';

const VersionHistoryGeneralInformationGrid: React.FC<{}> = () => {
  const {
    epdDefinitionInformation,
    organizationInformation,
    organizationInformationAddress,
    pcrInformation,
    lcaSupportInformation,
    thirdPartyVerification,
    verificationReportInfo,
    additionalInformation,
    lcaSpecification,
    countries,
    dictionaries,
  } = useEpdVersionHistoryData();

  const possibleRows: (SummaryGridRow | false)[] = [
    {
      name: 'EPD name',
      value: epdDefinitionInformation.declarationName,
    },
    {
      name: 'UUID of data set',
      value: epdDefinitionInformation.id,
    },
    {
      name: 'EPD ID',
      value: epdDefinitionInformation.fullIdentificationNumber,
    },
    {
      name: 'EPD version',
      value: String(epdDefinitionInformation.versionNumber).padStart(3, '0'),
    },
    epdDefinitionInformation.versionNumber !== 1 && {
      name: 'Version description',
      value: epdDefinitionInformation.versionDescription ?? NOT_ASSIGNED,
    },
    {
      name: 'Single or multiple products',
      value: (() => {
        if (!epdDefinitionInformation.epdClassification) {
          return NOT_ASSIGNED;
        }
        return (
          dictionaries.find(
            (d) =>
              d.dictionaryCode === EPD_DICTIONARY_CODES.EPD_CLASSIFICATION &&
              d.key === epdDefinitionInformation.epdClassification
          )?.value ?? NOT_ASSIGNED
        );
      })(),
    },
    epdDefinitionInformation.epdClassification === SINGLE_PRODUCT_ID && {
      name: 'Justification for why this is representative',
      value: epdDefinitionInformation.representativeJustification ?? NOT_ASSIGNED,
    },
    epdDefinitionInformation.epdClassification === MULTIPLE_PRODUCTS_BASED_ON_AVG_RESULTS_ID && {
      name: 'Description of how the averages have been determined',
      value: epdDefinitionInformation.determinationOfAverageDescription ?? NOT_ASSIGNED,
    },
    epdDefinitionInformation.epdClassification === SECTOR_OF_MULTIPLE_PRODUCTS_ID && {
      name: 'A list of the contributing manufacturers that the Sector EPD covers',
      value: epdDefinitionInformation.listOfContributingManufacturers ?? NOT_ASSIGNED,
    },
    epdDefinitionInformation.epdClassification === SECTOR_OF_MULTIPLE_PRODUCTS_ID && {
      name: 'How the selection of the sites/products has been done and the average has been determined',
      value: epdDefinitionInformation.sitesSelectionDescription ?? NOT_ASSIGNED,
    },
    epdDefinitionInformation.epdClassification === SECTOR_OF_MULTIPLE_PRODUCTS_ID && {
      name: 'A statement that the document covers average values for an entire or partial product category',
      value: epdDefinitionInformation.documentCoverageStatement ?? NOT_ASSIGNED,
    },
    {
      name: 'Product to declare',
      value: epdDefinitionInformation.productType || NOT_ASSIGNED,
    },
    {
      name: 'Statement of conformity with ISO',
      value: (() => {
        if (!epdDefinitionInformation.declaredStandards) {
          return NOT_ASSIGNED;
        }
        const standardsValues = epdDefinitionInformation.declaredStandards.split(',').map((x) => Number(x));
        if (standardsValues.length === 0) {
          return NOT_ASSIGNED;
        }
        const standards = standardsValues.map(
          (s) =>
            dictionaries.find((d) => d.dictionaryCode === EPD_DICTIONARY_CODES.CONFORMITY_STANDARDS && d.key === s)?.value ??
            NOT_ASSIGNED
        );
        return `In accordance with ${standards.join(', ')}`;
      })(),
    },
    {
      name: 'Keywords',
      value: epdDefinitionInformation.tags?.map((t) => t.label)?.join(', ') || NOT_ASSIGNED,
    },
    {
      name: 'Use advice for the EPD',
      value: epdDefinitionInformation.useAdvice ?? NOT_ASSIGNED,
    },
    {
      name: 'Subtype',
      value: (epdDefinitionInformation.subtype && getSubtypeLabel(epdDefinitionInformation.subtype)) ?? NOT_ASSIGNED,
    },
    {
      name: 'EPD based on declared or functional unit',
      value: (() => {
        if (!lcaSpecification || !lcaSpecification.epdBasedOnUnit) {
          return NOT_ASSIGNED;
        }
        const index = Object.keys(EPDBasedOnUnitEnum).indexOf(lcaSpecification.epdBasedOnUnit);
        if (index === -1) {
          return 'Unsupported value';
        }
        const dictionaryEntry = dictionaries.find(
          (d) => d.dictionaryCode === EPD_DICTIONARY_CODES.EPD_BASED_ON_UNIT && d.key === index
        );
        if (!dictionaryEntry) {
          return NOT_ASSIGNED;
        }
        return dictionaryEntry.value;
      })(),
    },
    !!lcaSpecification &&
      lcaSpecification.epdBasedOnUnit === EPDBasedOnUnitEnum.FunctionalUnit && {
        name: 'Functional unit description',
        value: lcaSpecification.functionalUnitDescription ?? NOT_ASSIGNED,
      },
    {
      name: 'PCR',
      value: pcrInformation.pcr?.fullName ?? NOT_ASSIGNED,
    },
    !!pcrInformation.cpcr && {
      name: 'C-PCR',
      value: pcrInformation.cpcr.fullName ?? NOT_ASSIGNED,
    },
    !!pcrInformation.cpcr && {
      name: 'Product category covered in the c-PCR',
      value: pcrInformation.cpcrProductCategory?.title ?? NOT_ASSIGNED,
    },
    {
      name: 'LCA practitioner',
      value: lcaSupportInformation.epdDevelopers?.map((d) => d.contactName).join(', ') ?? NOT_ASSIGNED,
    },
    {
      name: 'Verification type',
      value: thirdPartyVerification.verificationType
        ? getVerificationTypeLabel(thirdPartyVerification.verificationType)
        : NOT_ASSIGNED,
    },
    thirdPartyVerification.verificationType === VerificationType.EPDPreverifiedTool && {
      name: 'Pre-verified tool',
      value: thirdPartyVerification.preverifiedTool?.name ?? NOT_ASSIGNED,
    },
    thirdPartyVerification.verificationType === VerificationType.EPDPreverifiedTool && {
      name: 'Tool version',
      value: thirdPartyVerification.preverifiedToolVersion?.name ?? NOT_ASSIGNED,
    },
    thirdPartyVerification.verificationType === VerificationType.EPDVerification && {
      name: 'EPD verifier',
      value: thirdPartyVerification.epdVerifier?.displayName ?? NOT_ASSIGNED,
    },
    thirdPartyVerification.verificationType === VerificationType.EPDVerification &&
      !!thirdPartyVerification.epdVerifier && {
        name: thirdPartyVerification.epdVerifier.verifierType === VerifierType.Body ? 'Accredited by' : 'Approved by',
        value: 'The International EPD® System',
      },
    {
      name: 'Procedure for follow-up of data during EPD validity involves third party verifier',
      value: (() => {
        if (!thirdPartyVerification.followUpInvolvesThirdPartyVerifier) {
          return NOT_ASSIGNED;
        }
        return thirdPartyVerification.followUpInvolvesThirdPartyVerifier;
      })(),
    },
    {
      name: 'EPD Owner',
      value: organizationInformation.companyName,
    },
    {
      name: 'Contact person e-mail',
      value: organizationInformation.contactPersonEmail ?? NOT_ASSIGNED,
    },
    {
      name: 'Contact person name',
      value: organizationInformation.contactPersonName ?? NOT_ASSIGNED,
    },
    {
      name: 'Organization address',
      value: (() => {
        const details = [
          countries.find((c) => c.id === organizationInformationAddress.countryId)?.name,
          organizationInformationAddress.city,
          organizationInformationAddress.zipCode,
          organizationInformationAddress.addressLine,
          organizationInformationAddress.addressLineSecond,
        ]
          .filter((x) => x)
          .join(', ');
        return details || NOT_ASSIGNED;
      })(),
    },
    {
      name: 'Description of the organization',
      value: organizationInformation.description ?? NOT_ASSIGNED,
    },
    {
      name: 'Additional environmental information',
      value: additionalInformation?.environmental || '',
      isHtmlRichText: true,
    },
    {
      name: 'Conversion factors',
      value: additionalInformation?.conversion || '',
      isHtmlRichText: true,
    },
    {
      name: 'Dangerous substance to indoor air, soil, and water during the use stage',
      value: additionalInformation?.dangerous || '',
      isHtmlRichText: true,
    },
    {
      name: 'Economic and social information',
      value: additionalInformation?.economic || '',
      isHtmlRichText: true,
    },
    {
      name: 'Version date',
      value: (verificationReportInfo.approvalDate && formatDate(verificationReportInfo.approvalDate)) ?? NOT_ASSIGNED,
    },
    {
      name: 'Validity date',
      value: (verificationReportInfo.validityDate && formatDate(verificationReportInfo.validityDate)) ?? NOT_ASSIGNED,
    },
    {
      name: 'Initial version date',
      value:
        (epdDefinitionInformation.registrationDate && formatDate(epdDefinitionInformation.registrationDate)) ?? NOT_ASSIGNED,
    },
  ];

  const rows = possibleRows.filter((r) => r) as SummaryGridRow[];

  return <VersionHistorySummaryGrid rows={rows} />;
};

export default VersionHistoryGeneralInformationGrid;
