import { useState } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { Theme } from 'styles/theme';
import { Option } from 'types/types';

const ProductCategorySelector = ({ label, options, handlePostChangeAction, ...props }: any) => {
  const theme = useTheme() as Theme;
  const { name, isMulti, onChange } = props;
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : '');

  const customSelectStyles = {
    menu: (styles: any) => ({ ...styles }),
    control: (styles: any, state: any) => ({
      ...styles,
      borderRadius: '2px',
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: 'normal',
      minHeight: '44px',
      backgroundColor: state.isDisabled ? theme.colors.elementsFilledInput : theme.colors.primaryWhite,
      borderColor: state.isDisabled
        ? 'transparent'
        : state.isFocused
        ? theme.colors.primaryOrange
        : theme.colors.elementsInputBorder,
      outline: state.isFocused ? `2px solid ${theme.colors.primaryOrange}` : '',
      '&:hover': {
        borderColor: theme.colors.primaryOrange,
      },
    }),
    singleValue: (styles: any, state: any) => ({
      ...styles,
      color: state.isDisabled ? theme.colors.primaryBlack70 : theme.colors.black,
      marginLeft: '0.5rem',
    }),
    placeholder: (styles: any, state: any) => ({
      ...styles,
      marginLeft: '0.5rem',
      color: state.isDisabled ? 'transparent' : theme.colors.primaryBlack50,
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: theme.colors.secondaryLightGreen30,
    }),
    multiValueLabel: (styles: any, state: any) => ({
      ...styles,
      fontSize: '100%',
      color: theme.colors.statusLabelGreenText,
      paddingRight: state.isDisabled ? '6px' : '3px',
    }),
    multiValueRemove: (styles: any, state: any) => ({
      ...styles,
      display: state.isDisabled ? 'none' : 'flex',
    }),
    indicatorsContainer: (styles: any, state: any) => ({
      ...styles,
      display: state.isDisabled ? 'none' : 'flex',
    }),
  };

  const handleChange = (selectedOption: Option) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    setSelectedValue(selectedValue);
    if (handlePostChangeAction) {
      handlePostChangeAction({ target: { name: props.name, value: selectedValue } });
    }
  };

  const handleMultiChange = (selectedOptions: Option[]) => {
    const selectedValue = selectedOptions ? selectedOptions.map((i: Option) => i.value) : [];
    onChangeSelectValue(selectedValue);
  };

  const onChangeSelectValue = (value: any) => {
    const event = { target: { name, value } };
    onChange(event);
    if (handlePostChangeAction) {
      handlePostChangeAction(event);
    }
  };

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Select
        id={name}
        options={options}
        value={options.find((option: Option) => option.value === selectedValue)}
        onChange={props.isMulti ? handleMultiChange : handleChange}
        styles={customSelectStyles}
        {...props}
      />
    </div>
  );
};

export default ProductCategorySelector;
