import Header from 'components/header/Header';
import HeaderNew from 'components/v2/Header';
import React from 'react';

type HeaderComponentProps = {
  isNewUI: boolean;
  onChangeUI: (newUI: boolean) => void;
  changeTheme: (theme: string) => void;
};

const HeaderComponent: React.FC<HeaderComponentProps> = ({ isNewUI, onChangeUI, changeTheme }) => {
  return isNewUI ? (
    <HeaderNew onChangeUI={() => onChangeUI(false)} />
  ) : (
    <Header changeTheme={changeTheme} onChangeUI={() => onChangeUI(true)} />
  );
};

export default HeaderComponent;
