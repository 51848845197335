import { StyledInput } from 'styles/v2/Styles.styled';
import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';
import styled from 'styled-components';

type TProps = Partial<TSmartLabelProps> & {
  value: any;
  name: string;
  onChanged: any;
  disabled?: boolean;
  inputStyle?: any;
  componentStyle?: any;
  placeholder?: string;
  type?: string;
  errorMessage?: string;
  isValid?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;
const WizardTextInputWithErrorMessage: React.FunctionComponent<TProps> = ({
  label,
  tooltip,
  tooltipPosition,
  labelStyle,
  name,
  value,
  onChanged,
  disabled,
  inputStyle,
  componentStyle,
  placeholder,
  type,
  required,
  error,
  errorMessage,
  isValid,
  ...props
}) => {

  return (
    <InputWrapper style={componentStyle}>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!value}
          error={error}
        />
      )}
      <StyledInput
        {...props}
        style={inputStyle}
        type={type}
        defaultValue={value}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.currentTarget.value !== value?.toString() && onChanged(name, e.currentTarget.value);
        }}
        disabled={disabled}
        placeholder={placeholder}
      />
      {(!isValid && !!value) && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputWrapper>
  );
};

const ErrorMessage = styled.span`
  margin-top: 0.5rem;
  color: red;
  display: block;
`;

const InputWrapper = styled.div`
  width: '100%';
  display: flex;
  flex-direction: column;
`;

export default WizardTextInputWithErrorMessage;
