import HTMLEditor from 'components/controls/WysiwygEditor';
import { ProcessStatus } from 'constants/constants';
import LibraryIcon from 'images/notebook-5.svg';
import React, { useEffect, useState } from 'react';
import { PCRModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import PcrService from 'services/PcrService';
import styled from 'styled-components';
import { ButtonSmall, H2 } from 'styles/Styles.styled';

const DetailsPcr: React.FunctionComponent<{ pcrId: string; onClose: () => void }> = ({ pcrId, onClose }) => {
  const [pcr, setPcr] = useState<PCRModel>();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);

  const fileUrl = (fileId: any) => {
    return FileService.getImageUrl(fileId);
  };

  useEffect(() => {
    const fetchPcr = async () => {
      try {
        setStatus(ProcessStatus.Fetching);
        const result = await PcrService.getOnePcr(pcrId);
        setPcr(result as any);
        setStatus(ProcessStatus.Success);
      } catch (error) {
        setStatus(ProcessStatus.Error);
      }
    };
    fetchPcr();
  }, [pcrId]);

  return (
    <>
      {status === ProcessStatus.Fetching && <div>Fetching PCR...</div>}
      {status === ProcessStatus.Error && (
        <CardsEmpty>
          <h3>Something went wrong</h3>
        </CardsEmpty>
      )}
      {status === ProcessStatus.Success && (
        <PopUp>
          <Properties>
            <TextContainer>
              <H2>{pcr?.name}</H2>
            </TextContainer>
            <PropertyName>
              <Title>Full name</Title>
            </PropertyName>
            <PropertyValue>{pcr?.fullName}</PropertyValue>
            <PropertyName>
              <Title>Prepared by</Title>
            </PropertyName>
            <PropertyValue>{pcr?.preparedBy}</PropertyValue>
            <PropertyName>
              <Title>CPC Name</Title>
            </PropertyName>
            <PropertyValue>{pcr?.cpcName}</PropertyValue>
            <PropertyName>
              <Title>Documents</Title>
            </PropertyName>
            <PropertyValue style={{ textDecoration: 'none' }}>
              {pcr?.documents?.map((doc) => (
                <Document key={doc.id} href={fileUrl(doc.id)}>
                  <img src={LibraryIcon} alt={''} height="20px" width="25p" />
                  <span>{doc.name}</span>
                </Document>
              ))}
            </PropertyValue>
            {pcr?.text && (
              <TextContainer>
                <HTMLEditor value={pcr?.text}></HTMLEditor>
              </TextContainer>
            )}

            <ButtonsContainer>
              <ButtonSmall>Update</ButtonSmall>
              <ButtonSmall>Become a stakeholder</ButtonSmall>
              <CloseTab>
                <InfoButton onClick={() => onClose()}>Close</InfoButton>
              </CloseTab>
            </ButtonsContainer>
          </Properties>
        </PopUp>
      )}
    </>
  );
};
export default DetailsPcr;

const PopUp = styled.div`
  width: 100%;
  height: 100%;
  top: 25rem;
  left: 0;
  right: 30%;
  bottom: 0;
  margin: auto;
  background-color: ${(props) => props.theme.colors.lightGray};
  line-height: 1.7;
`;

const Properties = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const TextContainer = styled.div`
  margin: 0.5rem 1rem;
  grid-column-start: 1;
  grid-column-end: 3;
`;

const ButtonsContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  display: grid;
  grid-column-gap: 0.5rem;
  grid-template-columns: 10% 25% 60%;
  margin: 0 1rem 1.5rem 1rem;
`;

const CloseTab = styled.div`
  align-item: end;
  text-align: end;
  grid-column-start: 3;
  grid-column-end: -1;
`;

const PropertyName = styled.div`
  margin: 0.5rem 1rem;
  font-size: 0.9rem;
`;

const PropertyValue = styled.div`
  margin: 0.5rem;
  padding-left: 1rem;
  font-weight: normal;
  font-size: 1rem;
  font-family: ${(props) => props.theme.fontFamilies.heading};
`;

const Title = styled.h4`
  font-family: ${(props) => props.theme.fontFamilies.heading};
  font-weight: bold;
  margin: 0;
`;

const Document = styled.a.attrs({ target: '_blank' })`
  padding: 0.3rem 1rem 0.3rem 0rem;
  display: flex;
  align-items: center;
  min-height: 1.7rem;
  cursor: pointer;
  text-decoration: none;

  span {
    color: ${(props) => props.theme.colors.regionColor};
  }
`;

const InfoButton = styled(ButtonSmall)`
  background-color: ${(props) => props.theme.colors.regionColor};
  &:hover {
    background-color: ${(props) => props.theme.colors.regionColorLight};
  }
`;

const CardsEmpty = styled.div`
  flex: 1 0 auto;
  text-align: center;
  margin: 2rem;
`;
