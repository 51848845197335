import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import styled from 'styled-components';

const OverlaySpinner = () => {
  return (
    <>
      <Overlay>
        <SpinnerPosition>
            <SpinnerStyle>
                <ProgressSpinner 
                style={{width: "50px", height: "50px"}} 
                strokeWidth='4' 
                className="p-progress-spinner-circle" />
            </SpinnerStyle>
        </SpinnerPosition>
      </Overlay>
    </>
  );
};

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: rgb(128, 128, 128, 0.7);
  z-index: 1001;
`;

const SpinnerPosition = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const SpinnerStyle = styled.div`
  .p-progress-spinner-circle {
    stroke: #135f51;
    animation: p-progress-spinner-dash 2s ease-in-out infinite;
  }`

export default OverlaySpinner;
