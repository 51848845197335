import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextAreaComponent from 'components/v2/epd-wizard-inputs/WizardTextArea';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { useGeographicalScopeLookup } from 'services/api/queries';
import {
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel, Option } from 'types/types';

import ToggleButton from '../../panels/toggleable/ToggleButton';

const GeographySection: React.FunctionComponent<{
  product?: EPDProductInformationModel;
  onChange: any;
  errors: any;
}> = ({ product, onChange, errors }) => {
  const geographicalScopeLookup = useGeographicalScopeLookup().data;
  const geographicalScopeValue = product?.productGeographicalScopes || [];
  const productDetailsErrors = errors?.geographyInfoBlock;

  function noErrorsAtGeographyInfoBlock(errors: any): boolean | undefined {
    const fieldNames: string[] = ['geographyInfoBlock'];

    return !errors || !fieldNames.some((field) => field in errors);
  }

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    required?: boolean,
    isFilledIn?: boolean
  ) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {required && !isFilledIn && <TooltipErrorIcon />}
          <ToggleButton options={options} withMargins />
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <ExpandablePanelModule
      headerTemplate={(options) =>
        headerModuleTemplate(options, 'Geography', undefined, 'top | right', true, noErrorsAtGeographyInfoBlock(errors))
      }
      toggleable
    >
      <FieldPanelFullWidth>
        <CompilerSelectControlledComponent
          label="Geographical scope"
          name="geographicalScope"
          tooltip="Explanation if the geographical scope refers to production or to consumption of a product/service."
          options={geographicalScopeLookup?.map((item: any) => {
            return {
              value: item?.id,
              label: item?.name,
            } as Option;
          })}
          required
          value={
            geographicalScopeValue &&
            geographicalScopeValue?.map((x: any) => {
              return { label: x.name, value: x.id } as Option;
            })
          }
          onChanged={onChange}
          isMulti
          isClearable
          placeholder="Select"
          error={productDetailsErrors?.geographicalScope}
        />
        <WizardTextAreaComponent
          label="Geographical scope description"
          placeholder="Type here"
          tooltip="Explanations about the provided geographical scope, for which the data set is representative or relevant."
          value={product?.geographicalScopeDescription}
          onChanged={onChange}
          name="geographicalScopeDescription"
        />
      </FieldPanelFullWidth>
    </ExpandablePanelModule>
  );
};

export default GeographySection;
