import React from 'react';
import { Panel } from 'primereact/panel';
import { defaultThemeColors } from 'styles/theme';
import { Button } from 'primereact/button';
import { isWithin24Hours } from 'util/utils';
import styled from 'styled-components';

interface VerifierCompanyCardProps {
  data: any;
  onSelectVerifier: any;
  onRequestCollaboration: any;
  onAddVerifier: any;
  expanded: boolean;
  buttonsAreDisabled: boolean | undefined;
}

const VerifierCompanyCard: React.FunctionComponent<VerifierCompanyCardProps> = ({
  data,
  onSelectVerifier,
  onRequestCollaboration,
  onAddVerifier,
  expanded,
  buttonsAreDisabled,
}) => {
  const { id, name, email, information, verifierType, requestSentAt, successOperationText, errorText } = data;

  const collaborationRequestDate = !!requestSentAt ? new Date(requestSentAt) : undefined;

  const requestCollaborationButtonDisabledText =
    successOperationText ??
    (isWithin24Hours(collaborationRequestDate)
      ? 'The collaboration request was sent, you can submit a new request in 24 hours.'
      : null);

  const headerTemplate = (options: any) => {
    const className = `${options.className} justify-content-space-between w-full`;
    return (
      <div className={className} onClick={options.onTogglerClick}>
        <div>
          <strong>{name}</strong>
          <StyledCompanyInfo style={{ marginBottom: 0 }}>{verifierType}</StyledCompanyInfo>
        </div>
      </div>
    );
  };

  const renderContactInformation = () => {
    if (!!information && !!email) {
      return (
        <StyledCompanyInfo className="p-component">
          {information}
          <br />
          {email}
        </StyledCompanyInfo>
      );
    }
    if (!!information && !email) {
      return <StyledCompanyInfo className="p-component">{information}</StyledCompanyInfo>;
    }
    if (!information && !!email) {
      return <StyledCompanyInfo className="p-component">{email}</StyledCompanyInfo>;
    }
    return null;
  };

  return (
    <StyledVerifierCompanyCardPanel
      headerTemplate={(options) => headerTemplate(options)}
      toggleable
      collapsed={!expanded}
      onToggle={onSelectVerifier}
    >
      <div onClick={onSelectVerifier}>
        {renderContactInformation()}
        <StyledRequestCollaborationButton
          label="Request collaboration"
          onClick={(e) => {
            e.stopPropagation();
            onRequestCollaboration(id);
          }}
          disabled={buttonsAreDisabled || requestCollaborationButtonDisabledText}
        />
        <StyledAddVerifierButton
          label="Add verifier"
          onClick={(e) => {
            e.stopPropagation();
            onAddVerifier(id);
          }}
          disabled={buttonsAreDisabled}
        />
        {requestCollaborationButtonDisabledText && (
          <StyledSuccessTextContainer>{requestCollaborationButtonDisabledText}</StyledSuccessTextContainer>
        )}
        {errorText && <StyledErrorTextContainer>{errorText}</StyledErrorTextContainer>}
      </div>
    </StyledVerifierCompanyCardPanel>
  );
};

const StyledVerifierCompanyCardPanel = styled(Panel)`
  border: 1px solid;
  border-color: ${defaultThemeColors.elementsInputBorder};
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;

  .p-panel-header {
    border: 0;
    background-color: ${defaultThemeColors.primaryWhite};
    margin-top: 3px;
  }

  .p-panel-content {
    border: 0;
    background-color: ${defaultThemeColors.primaryWhite};
    ${(props) => props.theme.typography.bodyInput};
    padding-top: 0px;
  }

  p {
    margin-top: 0.5rem;
    color: ${defaultThemeColors.black};
  }
`;

const StyledRequestCollaborationButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primaryOrange};
  border-color: ${(props) => props.theme.colors.primaryOrange};
  min-width: 170px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;

  &&:hover {
    background-color: ${(props) => props.theme.colors.lightOrange};
    border-color: ${(props) => props.theme.colors.lightOrange};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }

  height: 1.5rem;
  min-height: 22px;
  ${(props) => props.theme.typography.buttonButtonDefault};

  margin-right: 10px;
`;

const StyledAddVerifierButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primaryGreen};
  border-color: ${(props) => props.theme.colors.primaryGreen};
  min-width: 90px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;

  &&:hover {
    background-color: ${(props) => props.theme.colors.lightGreen};
    border-color: ${(props) => props.theme.colors.lightGreen};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }

  height: 1.5rem;
  min-height: 22px;
  ${(props) => props.theme.typography.buttonButtonDefault};
`;

const StyledSuccessTextContainer = styled.div`
  margin-top: 15px;
  color: green;
`;

const StyledErrorTextContainer = styled.div`
  margin-top: 15px;
  color: red;
`;

const StyledCompanyInfo = styled.p`
  color: #151515b2 !important;
`;

export default VerifierCompanyCard;
