import { TextButton } from 'components/v2/buttons';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardAddButton from 'components/v2/epd-wizard-inputs/WizardAddButton';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import { HelpBox } from 'components/v2/help-boxes';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { useEffect, useState } from 'react';
import { MaterialPropertyModel, ProductMaterialProperyModel } from 'services/EpdClient';
import {
  useCreateProductMaterialProperty,
  useDeleteProductMaterialProperty,
  useUpdateProductMaterialProperty,
} from 'services/api/mutations';
import { useMaterialPropertiesLookup } from 'services/api/queries';
import {
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanel,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel, Option } from 'types/types';

import ToggleButton from '../../panels/toggleable/ToggleButton';

const MaterialPropertiesSection: React.FunctionComponent<{
  product?: EPDProductInformationModel;
  onChange: any;
  errors: any;
}> = ({ product, errors }) => {
  const materialPropertiesLookup = useMaterialPropertiesLookup().data;

  const [rowValues, setRowValues] = useState<ProductMaterialProperyModel[] | undefined>(product?.productMaterialProperties);
  const createMutation = useCreateProductMaterialProperty();
  const deleteMutation = useDeleteProductMaterialProperty();
  const updateMutation = useUpdateProductMaterialProperty();

  function noErrorsAtProductMaterialPropertiesBlock(errors: any): boolean | undefined {
    const fieldNames: string[] = ['materialPropertiesBlock'];

    return !errors || !fieldNames.some((field) => field in errors);
  }

  const materialPropertyErrors = (errors: any, index: any) => {
    return errors?.materialPropertiesBlock?.[`productMaterialProperties[${index}]`];
  };

  const confirmDeleteMaterialProp = (materialProperty: ProductMaterialProperyModel) => {
    confirmDelete({
      caption: `Are you sure you want to delete material property?`,
      onConfirm: () => deleteMutation.mutate(materialProperty?.id as string),
    });
  };

  useEffect(() => {
    setRowValues(product?.productMaterialProperties);
  }, [product?.productMaterialProperties]);

  const createRow = () => {
    createMutation.mutate(product?.id!);
    const newRowValues = [
      ...((rowValues as ProductMaterialProperyModel[]) ?? []),
      { id: '', productId: '', materialPropertyId: '', value: undefined },
    ];
    setRowValues(newRowValues);
  };

  const onChangeInternal = (item: ProductMaterialProperyModel, name: keyof ProductMaterialProperyModel, value: any) => {
    item[name] = value;
    updateMutation.mutate(item);
  };

  const selectedMaterialProp = (item: ProductMaterialProperyModel) => {
    if (item?.materialPropertyId) {
      return {
        value: item.materialPropertyId,
        label: materialPropertiesLookup?.find((prop: any) => prop.id === item.materialPropertyId)?.name,
      } as Option;
    }
    return null;
  };

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    required?: boolean,
    isFilledIn?: boolean
  ) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {required && !isFilledIn && <TooltipErrorIcon />}
          <ToggleButton options={options} withMargins />
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  return (
    <ExpandablePanelModule
      headerTemplate={(options) =>
        headerModuleTemplate(
          options,
          'Material properties',
          'Declaration of relevant non-scaling physical or chemical product properties',
          'top | right',
          true,
          noErrorsAtProductMaterialPropertiesBlock(errors)
        )
      }
      toggleable
    >
      {rowValues?.map((item: ProductMaterialProperyModel, index: number) => (
        <FieldPanel key={index} style={{ paddingTop: '0', marginTop: '0' }}>
          <>
            <CompilerSelectControlledComponent
              label="Unit"
              name="materialPropertyId"
              required
              options={materialPropertiesLookup
                ?.map((x: MaterialPropertyModel) => {
                  return { value: x.id, label: x.name } as Option;
                })
                .filter((option) => !rowValues?.find((rowValue: any) => rowValue.id === option.value))}
              value={selectedMaterialProp(item)}
              onChanged={(name: any, value: any) => {
                onChangeInternal(item, name, value);
              }}
              placeholder="Select"
              error={materialPropertyErrors(errors, index)?.unit}
            />
            <div style={{ display: 'flex' }}>
              <WizardTextInputComponent
                label="Value"
                placeholder="Type here"
                required
                value={item?.value}
                onChanged={(name: any, value: any) => {
                  onChangeInternal(item, name, value);
                }}
                name="value"
                error={materialPropertyErrors(errors, index)?.value}
              />
              {index !== 0 && (
                <TextButton
                  style={{ alignSelf: 'flex-end', margin: '0.25rem', color: '#000000' }}
                  type="button"
                  text
                  icon="pi pi-trash"
                  onClick={() => confirmDeleteMaterialProp(item)}
                />
              )}
            </div>
          </>
        </FieldPanel>
      ))}

      <FieldPanelFullWidth style={{ gridRowGap: '5px' }}>
        <HelpBox>
          <span>You can add more material properties if the product have more than one.</span>
        </HelpBox>
        <WizardAddButton label="+ Add material property" onClick={createRow} />
      </FieldPanelFullWidth>
    </ExpandablePanelModule>
  );
};

export default MaterialPropertiesSection;
