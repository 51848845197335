import styled from 'styled-components';
import { ActionState } from 'services/EpdClient';

export const SentForVerificationLabel = ({ actionState }: any) => {
  return !actionState || actionState === ActionState.Enabled ? <LabelStyle>SENT FOR VERIFICATION</LabelStyle> : <></>;
};

export const LabelStyle = styled.div`
  width: 160px;
  height: 15px;
  display: table;
  margin: 10px 5px 5px 5px;
  padding: 0.25rem 0.75rem;
  background-color: #fbebba;
  color: #864a14;
  font-size: 12px;
  border-radius: 2px;
`;
export default SentForVerificationLabel;
