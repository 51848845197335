import React, { ChangeEvent, useRef, useState } from 'react';
import EPDService from 'services/EpdService';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container, H1, H3, MainView, TextSmall } from 'styles/Styles.styled';
import OverlaySpinner from 'components/spinners/OverlaySpinner';

const LcaValidation: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const inputFileRef = useRef(null);

  const [status, setStatus] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [log, setLog] = useState<string[]>([]);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const targetFile = target && target.files && target.files[0];
    if (targetFile instanceof File) {
      const fileName = targetFile.name;
      const fileType = targetFile.type;

      const reader = new FileReader();
      reader.onload = async () => {
        const fileBlob = reader.result as ArrayBuffer;
        if (fileBlob) {
          const blob = new Blob([new Uint8Array(fileBlob)], {
            type: fileType,
          });
          if (blob.size > 1024 * 1024 * 10) {
            alert('You can not upload file more than 10 mb');
            return;
          }
          try {
            setIsLoading(true);

            const result = await EPDService.validateLCAFile(fileName, blob);

            if (result) {
              setStatus(result.isSuccessful ? 1 : 2);
              setLog(result.logText);

              return result;
            } else {
              alert('oops! something went wrong.');
            }
          } catch {
            alert('oops! something went wrong.');
          } finally {
            setIsLoading(false);
          }
        }
      };
      reader.readAsArrayBuffer(targetFile);
    }
  };

  const selectFile = () => {
    (inputFileRef as any).current.click();
  };

  const onFileInputChange = (e: any, validExtensions: Array<string>) => {
    if (validExtensions?.length > 0) {
      for (const file of e.target.files) {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!validExtensions.includes(fileExtension)) {
          alert(`Please upload file having extensions ${validExtensions.join('/')} only.`);
          return false;
        }
      }
    }

    uploadFile(e);
  };

  return (
    <Container>
      <MainView>
        {isLoading && (
          <center>
            <div>
              <OverlaySpinner />
            </div>
          </center>
        )}
        <ValidationContainer>
          <Title>LCA validation</Title>
          <div
            style={{
              display: 'flex',
            }}
          >
            <FileBox>
              <TextSmall>You can upload max&nbsp;10&nbsp;MB</TextSmall>
              <input
                ref={inputFileRef as any}
                type="file"
                accept=".xls,.xlsx"
                value={''}
                onChange={(e) => onFileInputChange(e, ['xls', 'xlsx'])}
              />
              <UploadLabel onClick={() => selectFile()}>{t('upload')}</UploadLabel>
            </FileBox>
          </div>
        </ValidationContainer>
        <ValidationContainer>
          {status != 0 &&
            (status == 1 ? (
              <H3 style={{ color: 'green' }}>Document is valid</H3>
            ) : (
              <H3 style={{ color: 'red' }}>Document is not valid</H3>
            ))}
          {status == 2 && (
            <div>
              {log.map(function (value, index, array) {
                return <p key={index}>{value?.replace('<br/>', '\r\n')}</p>;
              })}
            </div>
          )}
        </ValidationContainer>
      </MainView>
    </Container>
  );
};

const FileBox = styled.div`
  padding: 1rem 3rem;
  flex: 1 0 auto;

  > h3 {
    margin-top: 0;
  }

  > input[type='file'] {
    visibility: hidden;
    display: none;
  }

  > p {
    font-weight: bold;
  }
`;

const UploadLabel = styled.label`
  font-size: 0.8rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background: ${(props) => props.theme.colors.orange};
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.colors.lightOrange};
  }
`;

const ValidationContainer = styled(Container)`
  flex: 1;
  justify-content: center;
  margin: 10px auto;
`;

const Title = styled(H1)`
  border-right: 1px solid rgb(142, 142, 142);
  font-size: 3rem;
  padding-right: 2rem;
`;

export default LcaValidation;
