import EpdDetails from 'components/v2/copy/epd-details/EpdDetails';
import EpdPanelHeaderTemplate from 'components/v2/copy/header/EpdPanelHeaderTemplate';
import React, { useState } from 'react';
import { EPDCopyGroupStatus, EPDModel, FileLoadingModel } from 'services/EpdClient';
import { EpdItemPanel } from 'styles/v2/Styles.styled';

const EpdItem: React.FunctionComponent<{
  epd: EPDModel;
  isConstruction: boolean;
  onChangeEpd: any;
  refetchGroupData: any;
  onConfirmUpdate: any;
  isReadOnly: boolean;
  canUpload: boolean;
  fileLoading?: FileLoadingModel[];
  isMenuEnabled: boolean;
  groupStatus: EPDCopyGroupStatus;
}> = ({
  epd,
  isConstruction,
  onChangeEpd,
  refetchGroupData,
  onConfirmUpdate,
  isReadOnly,
  canUpload,
  fileLoading,
  isMenuEnabled,
  groupStatus,
}) => {
  const [title, setTitle] = useState<string | undefined>(epd.title);

  return (
    <EpdItemPanel
      headerTemplate={(options) =>
        EpdPanelHeaderTemplate(
          options,
          epd,
          onChangeEpd,
          refetchGroupData,
          title,
          setTitle,
          onConfirmUpdate,
          isReadOnly,
          isMenuEnabled,
          groupStatus
        )
      }
      toggleable
      collapsed
    >
      <EpdDetails
        epd={epd}
        onChangeEpd={onChangeEpd}
        isConstruction={isConstruction}
        title={title}
        setTitle={setTitle}
        isReadOnly={isReadOnly}
        canUpload={canUpload}
        fileLoading={fileLoading}
      />
    </EpdItemPanel>
  );
};

export default EpdItem;
