import SelectInput from 'components/form/SelectInput';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountriesService from 'services/CountriesService';
import { AccountModel, CompanyModel, CountryModel, InvoicingDeliveryMethod, MembershipModel } from 'services/EpdClient';
import AccountsService from 'services/admin/AccountsService';
import styled from 'styled-components';
import { ButtonSmall, ErrorText, FormMode, StyledForm } from 'styles/Styles.styled';
import * as Yup from 'yup';

import { AuLicenseesCountry, ProcessStatus } from '../../constants/constants';
import { ExpandButton } from '../buttons/ExpandButton';
import TextInput from '../form/TextInput';
import { TableRow } from '../member/ManageMembers';

function useAccountRowHook(account: AccountModel, company: CompanyModel, onCompleted: () => Promise<void>) {
  const [isEditMode, setEditMode] = useState(false);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [countries, setCountries] = useState<CountryModel[]>([]);

  useEffect(() => {
    async function fetch() {
      const resultCountries = await CountriesService.getCountries();
      setCountries(resultCountries);
    }
    fetch();
  }, []);

  const deleteAccount = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await AccountsService.deleteAccount(account.id || '');
      await onCompleted();
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  const saveChanges = async (update: AccountModel) => {
    try {
      setStatus(ProcessStatus.Fetching);
      await AccountsService.updateAccount(update);
      await onCompleted();
      setEditMode(false);
      setStatus(ProcessStatus.None);
    } catch (error) {
      setStatus(ProcessStatus.Error);
    }
  };

  return { status, deleteAccount, saveChanges, isEditMode, setEditMode, countries };
}

const AccountRow: FunctionComponent<{
  account: AccountModel;
  company: CompanyModel;
  accountManagers: MembershipModel[];
  onCompleted: () => Promise<void>;
  isAdmin: boolean;
}> = ({ account, company, accountManagers, onCompleted, isAdmin }) => {
  const { status, isEditMode, setEditMode, deleteAccount, saveChanges, countries } = useAccountRowHook(
    account,
    company,
    onCompleted
  );
  const { t } = useTranslation();

  if (status === ProcessStatus.Success) {
    return null;
  }
  const hasExtraRulesForAULicensee = (selectedCountryId: string | undefined) => {
    return (
      AuLicenseesCountry.includes(countries?.find((x) => x.id === selectedCountryId)?.alpha2Code || '') && !company.licenseesExclude
    );
  };

  const accountSchema = Yup.object<AccountModel>({
    name: Yup.string().required(t('messages.required')),
    /* The model has the email defined as `string | undefined`, and that is not
    the same as being nullable. The model received from the server _does_ have
    the value set to null. I believe the model generated from nswag is
    incorrect. Long story short: we have to cast to `any` for this to compile */
    invoicingEmail: Yup.string().email(t('messages.invalidEmail')).nullable() as any,
  });

  return (
    <Row style={{ alignItems: 'flex-start' }}>
      {isEditMode ? (
        <Formik initialValues={account} validationSchema={accountSchema} onSubmit={(values) => saveChanges(values)}>
          {({ isSubmitting, values, dirty, isValid }) => (
            <StyledForm mode={FormMode.Inline} style={{ flex: 1 }}>
              <FormColumn>
                <TextInput label={t('name')} name="name" />
                <SelectInput
                  label={t('manageAccounts.editAccount.manager')}
                  name="accountManagerUserId"
                  style={{ margin: '0.5rem 0 1rem 0' }}
                  supressErrors={true}
                >
                  {accountManagers?.map((option) => (
                    <option value={option.userId} key={option.userId}>
                      {option.userName}
                    </option>
                  ))}
                </SelectInput>
                <TextInput label={t('manageAccounts.editAccount.reference')} name="reference" />
                {!hasExtraRulesForAULicensee(values.invoicingAddress?.countryId) && (
                  <SelectInput label={t('manageAccounts.editAccount.invoicingMethod')} name="invoicingDeliveryMethod">
                    <option value="0">{InvoicingDeliveryMethod.Electronic}</option>
                    <option value="1">{InvoicingDeliveryMethod.Letter}</option>
                  </SelectInput>
                )}

                <TextInput
                  placeholder="email@example.com"
                  label={t('manageAccounts.editAccount.invoicingEmail')}
                  name="invoicingEmail"
                />

                <TextInput label={t('manageAccounts.editAccount.invoicingNotes')} name="invoicingNotes" />
              </FormColumn>
              <FormColumn>
                <TextInput
                  placeholder={t('placeholder.InvoiceAddress.line1')}
                  label={t('manageAccounts.editAccount.invoicingAddress')}
                  name="invoicingAddress.line1"
                />
                <TextInput
                  placeholder={t('placeholder.InvoiceAddress.line2')}
                  label="&nbsp;"
                  name="invoicingAddress.line2"
                />
                <TextInput
                  placeholder={t('placeholder.InvoiceAddress.line3')}
                  label="&nbsp;"
                  name="invoicingAddress.line3"
                />
                <TextInput
                  placeholder={t('placeholder.InvoiceAddress.line4')}
                  label="&nbsp;"
                  name="invoicingAddress.line4"
                />
                <SelectInput label={t('country')} name="invoicingAddress.countryId">
                  {countries.map((c) => (
                    <option value={c.id} key={c.id}>
                      {c.name}
                    </option>
                  ))}
                </SelectInput>
              </FormColumn>
              <FormColumn>
                <ButtonContainer>
                  <ButtonSmall disabled={isSubmitting || !isValid || !dirty}>
                    {isSubmitting ? t('messages.saving') : t('messages.saveSettings')}
                  </ButtonSmall>
                  {isAdmin && (
                    <ButtonSmall
                      style={{ marginLeft: '1rem' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.confirm(t('manageAccounts.messages.confirmDeletion')) && deleteAccount();
                      }}
                    >
                      {t('delete')}
                    </ButtonSmall>
                  )}
                </ButtonContainer>
              </FormColumn>
            </StyledForm>
          )}
        </Formik>
      ) : (
        <>
          <div style={{ flexGrow: 1 }}>
            {account.name}
            {status === ProcessStatus.Error && <ErrorText> Error!</ErrorText>}
          </div>
          <div>{account.accountManagerName}</div>
        </>
      )}
      <ExpandButton isOpen={isEditMode} onClick={() => setEditMode(!isEditMode)} />
    </Row>
  );
};

export default AccountRow;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 48%;
  box-sizing: border-box;
  padding-right: 2rem;
`;

const Row = styled(TableRow)`
  button:last-child {
    margin-left: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  padding-right: 2rem;
`;
