import { ActionState } from 'services/EpdClient';

export const withActionState = (WrappedComponent: any) => {
  type TProps = {
    actionState?: ActionState;
  };

  const WithActionState = ({ actionState, ...props }: any) => {
    return actionState && actionState !== ActionState.Hidden ? (
      <WrappedComponent {...props} disabled={actionState === ActionState.Disabled} />
    ) : (
      <></>
    );
  };

  return WithActionState;
};
