import { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import styled from 'styled-components';
import arrowUpBoldIcon from 'images/icons/svg/arrow-up-bold.svg';

type ParentCompanyPanelProps = {
  companyName: string;
};

const ParentCompanyPanel = ({ companyName }: ParentCompanyPanelProps) => {
  const [arrowRotation, setArrowRotation] = useState<number>(180);
  const parentCompanyOverlayRef = useRef<OverlayPanel>(null);

  const rotateArrow = () => setArrowRotation((prevRotation) => (prevRotation === 0 ? 180 : 0));
  const handleArrowButtonClick = (event: any) => {
    parentCompanyOverlayRef!.current!.toggle(event);
    rotateArrow();
  };
  const handleOverlayHide = () => {
    if (arrowRotation === 180) return;
    rotateArrow();
  };

  return (
    <>
      <StyledRotatingBlock onClick={handleArrowButtonClick} style={{ transform: `rotate(${arrowRotation}deg)` }}>
        <StyledImage src={arrowUpBoldIcon} alt="arrow-icon" />
      </StyledRotatingBlock>
      <StyledOverlayPanel ref={parentCompanyOverlayRef} onHide={handleOverlayHide}>
        <p style={{ fontWeight: 'bold' }}>{companyName}</p>
        <p>Holding organisation</p>
      </StyledOverlayPanel>
    </>
  );
};

const StyledRotatingBlock = styled.div`
  margin-left: 0.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
`;

const StyledImage = styled.img`
  width: 1rem;
  height: 1rem;
`;

const StyledOverlayPanel = styled(OverlayPanel)`
  min-width: 19rem;
  margin-top: 0.7rem;
  :after,
  :before {
    content: none;
  }
  p {
    margin: 0 0 0.5rem 0;
  }
`;

export default ParentCompanyPanel;
