import useIsReadOnlyMode from 'components/v2/compiler/hooks/useIsReadOnlyMode';
import MaximizedDialog from 'components/v2/compiler/panels/expandable/MaximizedDialog';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useState } from 'react';
import {
  useCreateLcaCustomResultGridDataRow,
  useDeleteLcaCustomResultGridDataRows,
  useUpdateLcaCustomResultGridDataRow,
} from 'services/api/mutationsLcaResults';
import { useEpdLcaCustomResultGridData } from 'services/api/queries';
import { LcaCustomResultGridDataModel } from 'types/types';

import DynamicGridHeader from '../../DynamicGridHeader';
import { ColumnFooterInputText, DataTableStyled } from '../../StyledGrid';
import useGridRowSelectionViaCheckbox from '../../useGridRowSelectionViaCheckbox';
import { LcaResultsAdditionalGridColumns } from '../LcaResultsGridColumns';
import useDynamicGridWithEmptyFirstRow from '../../useDynamicGridWithEmptyFirstRow';

type TProps = {
  epdVersionId: string;
  customResultId: string;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaCustomResultsGrid = ({
  epdVersionId,
  customResultId,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const [visible, setVisible] = useState(false);

  const notes = [
    {
      note: 'Acronyms',
      description: '',
      placeholder: 'Acronym1 description; Acronym2  - description; etc.',
    },
  ];

  const isReadOnly = useIsReadOnlyMode() || !!disabled;

  const columns = LcaResultsAdditionalGridColumns;
  const rows = useEpdLcaCustomResultGridData(customResultId).data;
  const createMutation = useCreateLcaCustomResultGridDataRow(epdVersionId, customResultId);
  const updateMutation = useUpdateLcaCustomResultGridDataRow(epdVersionId, customResultId);
  const deleteMutation = useDeleteLcaCustomResultGridDataRows(epdVersionId, customResultId);

  const { dataTableProps, checkboxColumnNode, handleDeleteSelected, isDeleteDisabled } =
    useGridRowSelectionViaCheckbox<LcaCustomResultGridDataModel>(isReadOnly, deleteMutation);

  const rowClassName = () => ({ 'p-disabled': isReadOnly && !preferEnabledAppearanceEvenWhenDisabled });

  const { gridData, handleAddRow, handleCellEdit } = useDynamicGridWithEmptyFirstRow<LcaCustomResultGridDataModel>({
    rows,
    createMutation,
    updateMutation,
    emptyEntity: { lcaCustomResultId: customResultId },
  });

  const header = (
    <DynamicGridHeader
      caption="Indicators data"
      onAddRow={handleAddRow}
      onMaximize={visible ? undefined : () => setVisible(true)}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={isDeleteDisabled}
      hideMutationButtons={isReadOnly && preferEnabledAppearanceEvenWhenDisabled}
    />
  );

  const footerGroup = notes ? (
    <ColumnGroup>
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} colSpan={2} className="p-row-note" />
          <Column
            style={{ padding: '2px' }}
            footer={
              <ColumnFooterInputText
                type="text"
                defaultValue={x.description as string}
                onBlur={(e) => {
                  // TODO save the value
                }}
                disabled={isReadOnly}
              />
            }
            colSpan={columns?.length}
            className={isReadOnly ? 'p-row-note' : undefined}
          />
        </Row>
      ))}
    </ColumnGroup>
  ) : null;

  return (
    <>
      <MaximizedDialog title="" visible={visible} onHide={() => setVisible(false)}>
        <DataTableStyled
          value={gridData}
          dataKey="ord"
          tableStyle={{ minWidth: '49rem' }}
          showGridlines
          footerColumnGroup={footerGroup}
          header={header}
          editMode="cell"
          rowClassName={rowClassName}
          {...dataTableProps}
        >
          {!(isReadOnly && preferEnabledAppearanceEvenWhenDisabled) && checkboxColumnNode}

          {columns?.map((x) => (
            <Column
              key={x.field}
              {...x}
              onCellEditComplete={x.editor ? handleCellEdit : undefined}
              {...(isReadOnly && { editor: undefined })}
            />
          ))}
        </DataTableStyled>
      </MaximizedDialog>

      <DataTableStyled
        value={gridData}
        dataKey="ord"
        tableStyle={{ minWidth: '49rem' }}
        showGridlines
        footerColumnGroup={footerGroup}
        header={header}
        editMode="cell"
        rowClassName={rowClassName}
        {...dataTableProps}
      >
        {!(isReadOnly && preferEnabledAppearanceEvenWhenDisabled) && checkboxColumnNode}

        {columns?.map((x) => (
          <Column
            key={x.field}
            {...x}
            onCellEditComplete={x.editor ? handleCellEdit : undefined}
            {...(isReadOnly && { editor: undefined })}
          />
        ))}
      </DataTableStyled>
    </>
  );
};

export default LcaCustomResultsGrid;
