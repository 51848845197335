import { customSelectStyles } from 'components/form/Select';
import { ProcessStatus, Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import filterIcon from 'images/icons/svg/funnel.svg';
import { ListBox, ListBoxNoItems } from 'pages/wizard/EpdWizard';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CompanyService from 'services/CompanyService';
import { CountryModel, DeveloperCompanyModel, EPDModel, MemberModel } from 'services/EpdClient';
import EpdService from 'services/EpdService';
import MembershipService from 'services/MembershipService';
import styled, { css } from 'styled-components';
import { ButtonSmall, ErrorText, InputSmallIcon, SuccessText } from 'styles/Styles.styled';

import UserIcon from '../../images/icons/svg/user.svg';

const EpdDeveloperSelect: React.FunctionComponent<{
  epd: EPDModel;
  onSelected: (updatedEpd: EPDModel) => void;
}> = ({ epd, onSelected }) => {
  const { t } = useTranslation();
  const { companyId } = useContext(CompanyContext);
  const [epdDeveloperCompanies, setEpdDeveloperCompanies] = useState<DeveloperCompanyModel[]>([]);
  const [countries, setCountries] = useState<{ value: string | undefined; label: string | undefined }[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [expandedCompanyId, setExpandedCompanyId] = useState<string>();
  const [connectedStatus, setConnectedStatus] = useState<string>();
  const [filter, setFilter] = React.useState<string>();

  React.useEffect(() => {
    const fetchEpdCompanies = async () => {
      const res = await CompanyService.getDevelopers(undefined, selectedCountry);
      setEpdDeveloperCompanies([...res].sort((a, b) => ((a.name ?? '') > (b.name ?? '') ? 1 : -1)));
    };

    fetchEpdCompanies();
  }, [selectedCountry]);

  React.useEffect(() => {
    const fetchCountries = async () => {
      const res = await CompanyService.getCountries(Role.EPDDeveloper);
      const countryOptions = res.map((country: CountryModel) => {
        return { value: country.id, label: country.name };
      });
      setCountries(countryOptions);
    };

    fetchCountries();
  }, [companyId]);

  const toggleEpdCompany = (e: React.MouseEvent<HTMLElement, MouseEvent>, id?: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (expandedCompanyId === id) {
      setExpandedCompanyId(undefined);
    } else {
      setExpandedCompanyId(id);
    }
  };

  const handleCountrySelect = (e: any) => {
    setSelectedCountry(e?.value);
  };

  const onConnect = async (e: React.MouseEvent<HTMLElement, MouseEvent>, member: MemberModel) => {
    e.preventDefault();
    e.stopPropagation();

    setStatus(ProcessStatus.Fetching);
    setConnectedStatus(undefined);

    try {
      const res = await EpdService.connectDeveloper(epd.id ?? '', member.membershipId ?? '');
      setConnectedStatus(t('epdWizard.wizardStep1.epdDeveloperMenu.messages.addEpdSuccessfully'));
      setStatus(ProcessStatus.Success);
      onSelected(res);
    } catch (error) {
      setConnectedStatus(error as string);
      setStatus(ProcessStatus.Error);
    }
  };

  const inquireCollaboration = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      setConnectedStatus(undefined);

      await MembershipService.inquireCollaboration({
        companyId: expandedCompanyId,
        roleId: Role.EPDDeveloper,
      });
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  const handleFilter = (e: any) => {
    setFilter(e.target.value);
  };

  return (
    <>
      <Select
        options={countries}
        styles={customSelectStyles}
        placeholder={t('epdWizard.wizardStep1.epdDeveloperMenu.placeholder.filterByCountry')}
        isLoading={!countries || countries.length === 0}
        isClearable={false}
        onChange={handleCountrySelect}
      />
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1 0 auto' }}>
          <InputSmallIcon
            style={{ backgroundImage: `url(${filterIcon})`, width: '-webkit-fill-available' }}
            onChange={handleFilter}
            value={filter || ''}
            placeholder={t('epdWizard.wizardStep1.epdDeveloperMenu.placeholder.filterOnNameOrCode')}
          />
        </div>
      </div>
      <ListBox style={{ marginTop: '0.5rem', height: '400px' }}>
        {epdDeveloperCompanies
          .filter((x) => !filter || x.name?.toLowerCase().includes(filter.toLowerCase()))
          .map((comp) => (
            <SelectorCompanyOption
              key={comp.id}
              data-id={comp.id}
              onClick={(e) => toggleEpdCompany(e, comp.id)}
              selected={comp.id === expandedCompanyId}
            >
              <CompanyName>
                <span>{comp.name}</span>
                {expandedCompanyId === comp.id && (
                  <InquireButton
                    disabled={!expandedCompanyId || status === ProcessStatus.Fetching}
                    onClick={() => inquireCollaboration()}
                  >
                    {t('epdWizard.wizardStep1.epdDeveloperMenu.inquireCollaboration')}
                  </InquireButton>
                )}
              </CompanyName>
              {expandedCompanyId === comp.id && (
                <MemberList>
                  {comp.members?.map((member) => (
                    <Member key={member.membershipId}>
                      <MemberName>
                        <img src={UserIcon} alt="" />
                        {member.name}
                      </MemberName>
                      <AddButton onClick={(e) => onConnect(e, member)} disabled={status === ProcessStatus.Fetching}>
                        {t('epdWizard.wizardStep1.epdDeveloperMenu.add')}
                      </AddButton>
                    </Member>
                  ))}
                </MemberList>
              )}
            </SelectorCompanyOption>
          ))}
        {epdDeveloperCompanies && epdDeveloperCompanies.length === 0 && (
          <ListBoxNoItems>{t('epdWizard.wizardStep1.epdDeveloperMenu.messages.noCompanies')}</ListBoxNoItems>
        )}
      </ListBox>

      {status === ProcessStatus.Success && (
        <SuccessText>{connectedStatus ?? t('epdWizard.wizardStep1.epdDeveloperMenu.messages.successInquiry')}</SuccessText>
      )}
      {status === ProcessStatus.Error && <ErrorText>{t('epdWizard.messages.error')}</ErrorText>}
    </>
  );
};

const SelectorCompanyOption = styled.div<{ selected: boolean }>`
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #f4f4f4;
  }

  ${(props) =>
    props.selected &&
    css`
      border-left: 3px solid ${(props) => props.theme.colors.orange};
      background-color: #f4f4f4;

      ${CompanyName} {
        font-weight: bold;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0.5rem;
      }
    `}
`;

const CompanyName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
  }
`;

const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  padding-right: 0;
`;

const Member = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.25rem;
`;

const AddButton = styled(ButtonSmall)`
  padding: 0.25rem 0.5rem;
`;

const InquireButton = styled(ButtonSmall)`
  padding: 0.5rem;
  align-self: flex-end;
`;

const MemberName = styled.span`
  display: flex;
  flex: auto;
  align-items: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.5rem;
  }
`;

export default EpdDeveloperSelect;
