import React, { useState } from 'react';
import { ExpandablePanel, PanelContainer } from 'styles/v2/Styles.styled';

import GridPanelHeader from './GridPanelHeader';
import MaximizedDialog from './MaximizedDialog';
import { PanelTitle } from '../Styles.styled';

type TProps = React.PropsWithChildren<{
  title: string;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  error?: any;
}>;

const GridExpandablePanel = ({ title, children, tooltip, tooltipPosition, error }: TProps) => {
  const [visible, setVisible] = useState(false);
  const onMaximize = () => {
    setVisible(true);
  };

  return (
    <PanelContainer>
      <MaximizedDialog title={title} onHide={() => setVisible(false)} visible={visible}>
        {children}
      </MaximizedDialog>

      <ExpandablePanel
        headerTemplate={(options) => (
          <GridPanelHeader
            options={options}
            title={<PanelTitle>{title}</PanelTitle>}
            onMaximize={onMaximize}
            tooltip={tooltip}
            tooltipPosition={tooltipPosition}
            error={error}
          />
        )}
        toggleable
        collapsed
      >
        <div style={{ overflowX: 'hidden' }}>{children}</div>
      </ExpandablePanel>
    </PanelContainer>
  );
};

export default GridExpandablePanel;
