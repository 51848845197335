import logo from 'images/epd-portal-logo.svg';
import { css } from 'styled-components';

import { Theme, defaultThemeColors, defaultTypography } from '.';

const colors = {
  ...defaultThemeColors,
};

const sizes = {
  small: 320,
  medium: 720,
  large: 1024,
  xlarge: 1200,
};

const breakpoints = {
  xsmall: 0,
  small: `${sizes.small}px`,
  medium: `${sizes.medium}px`,
  large: `${sizes.large}px`,
  xlarge: `${sizes.xlarge}px`,
};

const customMediaQuery = (minWidth: number) => `@media (min-width: ${minWidth}px)`;
const customMatchMedia = (minWidth: number) => `(min-width: ${minWidth}px)`;
const media = {
  custom: customMediaQuery,
  phone: customMediaQuery(sizes.small),
  tablet: customMediaQuery(sizes.medium),
  desktop: customMediaQuery(sizes.xlarge),
  largeDesktop: customMediaQuery(sizes.xlarge),
};

const matchMedia = {
  custom: customMatchMedia,
  phone: customMatchMedia(sizes.small),
  tablet: customMatchMedia(sizes.medium),
  desktop: customMatchMedia(sizes.xlarge),
};

const fontFamilies = {
  heading: "'Roboto Slab', sans-serif",
  body: "'Roboto', serif",
};

const fontSizes = {
  xlarge: 4,
  large: 2,
  medium: 1.5,
  mediumish: 1.25,
  mediumSmall: 1.125,
  standard: 1,
  small: 0.875,
  smallDescription: 0.75,
};

const fonts = {
  headingHero: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.xlarge}rem;
  `,
  heading1: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.large}rem;
  `,
  heading2: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.medium}rem;
  `,
  heading3: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.mediumish}rem;
  `,
  heading4: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.standard}rem;
  `,
  headingDialog: css`
    font-family: ${fontFamilies.heading};
    font-size: ${fontSizes.mediumSmall}rem;
  `,
  text: css`
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.standard}rem;
    text-transform: none;
  `,
  textSmall: css`
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.small}rem;
  `,
  textSmallDescription: css`
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.smallDescription}rem;
  `,
  textLarge: css`
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.mediumish}rem;
  `,
};

const variables = {
  headerHeight: '90px',
};

const verifiersUrl = 'https://www.environdec.com/resources/verifiers';
const serviceAgreementUrl = '/Service-Agreement-EPD-May2024.pdf';

const theme: Theme = {
  fontSizes: fontSizes,
  fonts: fonts,
  fontFamilies: fontFamilies,
  colors: colors,
  typography: { ...defaultTypography },
  breakpoints,
  variables,
  media,
  matchMedia,
  logo,
  verifiersUrl,
  serviceAgreementUrl,
};

export default theme;
