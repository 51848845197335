// Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>

import ReactSelect from 'components/form/ReactSelect';
import { Form } from 'formik';
import tick from 'images/tick.svg';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.main`
  margin: ${(props) => props.theme.variables.headerHeight} auto;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;

  ${(props) => props.theme.media.desktop} {
    width: ${(props) => props.theme.breakpoints.xlarge};
  }
`;

export const CenterColumn = styled.div`
  flex: 0 0 100%;
  margin: auto;

  ${(props) => props.theme.media.desktop} {
    flex-basis: 50%;
  }
`;

export const Half = styled.div`
  flex: 0 0 100%;
  ${(props) => props.theme.media.desktop} {
    flex: 0 0 48%;
  }
`;

export const EditFull = styled.div`
  flex: 0 0 90%;
  margin: auto;
`;

export const AdminBox = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #f0f0f0;
  border: 1px solid ${(props) => props.theme.colors.mediumGrey};
  padding: 24px !important;
`;

export const Full = styled.div`
  flex: 0 0 100%;
`;

export const H1 = styled.h1`
  ${(props) => props.theme.fonts.heading1}
`;

export const H2 = styled.h2`
  ${(props) => props.theme.fonts.heading2}
`;

export const H3 = styled.h3`
  ${(props) => props.theme.fonts.heading3}
`;

export const HeaderDialog = styled.h3`
  ${(props) => props.theme.fonts.headingDialog}
  margin-top: 30px;
`;

export const H1Hero = styled.h1`
  ${(props) => props.theme.fonts.headingHero}
`;

export const Text = styled.div`
  text-align: justify;
  ${(props) => props.theme.fonts.text}
`;

export const TextSmall = styled.p`
  ${(props) => props.theme.fonts.textSmall}
`;

export const TextSmallDescription = styled.p`
  ${(props) => props.theme.fonts.textSmallDescription}
`;

export const StyledLink = styled(Link)`
  color: black;
  &:hover {
    color: #333;
  }
`;

export const EpdLink = styled(Link)`
  text-decoration: none;
  color: black;
  padding: 0.5rem;
  display: inline-block;
  &:hover {
    background: ${(props) => props.theme.colors.gray};
  }
`;

export const CheckBulletList = styled.ul`
  font-weight: bold;
  padding: 0;
  list-style: none;

  > li {
    margin: 0.75rem 0;
    &:before {
      content: '';
      display: inline-block;
      height: 1rem;
      width: 1rem;
      background-image: url(${tick});
      margin-right: 0.5rem;
    }
  }
`;
export enum FormMode {
  Inline,
  Default,
}

export enum ProcessMode {
  Submitting,
  Success,
  Error,
  None,
}

export const StyledForm = styled(Form)<{ mode?: FormMode; process?: ProcessMode }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.mode === FormMode.Inline ? 'row' : 'column')};
  opacity: ${(props) => (props.process === ProcessMode.Submitting ? 0.5 : 1)};
`;

export const ErrorMessage = styled.div`
  color: red;
  margin: -0.75rem 0 1rem;
  ${(props) => props.theme.fonts.textSmall};
`;

export const Message = styled.h4`
  margin: 0;
  text-align: center;
  display: inline-block;
`;

export const Label = styled.label<{ required?: boolean }>`
  font-weight: normal;
  text-transform: uppercase;
  ${(props) => props.theme.fonts.textSmall};
  ${(props) =>
    props.required &&
    css`
      &:after {
        content: ' *';
      }
    `}
`;

export const Input = styled.input`
  padding: 1rem;
  border: solid 1px gray;
  margin: 0.5rem 0 1rem 0;
`;

export const InputRadio = styled.input`
  padding: 1rem;
  border: solid 1px gray;
`;

export const LargeInput = styled(Input)`
  width: 80%;
  ${(props) => props.theme.media.tablet} {
    width: 93%;
  }
  ${(props) => props.theme.media.desktop} {
    width: 94%;
  }
`;

export const AdminSearchGridForm = styled(Form)`
  width: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 3;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: start;
  align-items: center;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 22% 70%;
  }
  ${(props) => props.theme.media.desktop} {
    max-width: 60%;
    grid-template-columns: 25% 15% 15% 2% 10% 15% 15%;
  }
`;

export const LabelBox = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
`;

export const ValueBox = styled.div`
  min-height: 38px;
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 8;
  }
`;

export const SearchBox = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: start;
`;

export const SaveBox = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: start;
`;

export const StyledReactSelect = styled(ReactSelect)`
  box-sizing: border-box;
  width: 100%;
`;

export const LargeRadiusInput = styled(Input)`
  border: solid 1px gray;
  background-color: hsl(0, 0%, 100%);
  height: 8px;
  border-color: gray;
  border-radius: 3px;
  border-width: 1px;
  box-shadow: none !important;
  margin: 0;
  padding: 1rem;
  width: calc(100% - 2rem);
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
`;

export const LargeRadiusTextArea = styled.textarea`
  background-color: hsl(0, 0%, 100%);
  height: 8px;
  border-color: gray;
  border-radius: 3px;
  border-width: 1px;
  box-shadow: none !important;
  margin: 0;
  padding: 1rem;
  width: calc(100% - 2rem);
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
`;

export const SmallRadiusInput = styled(Input)`
  background-color: hsl(0, 0%, 100%);
  height: 38px;
  border: solid gray 1px;
  box-shadow: none !important;
  padding: 1rem;
  width: calc(100% - 2rem);
  border-radius: 3px;
  margin: 0;
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
`;

export const InputSmall = styled(Input)`
  padding: 0.75rem 1rem;
  border-color: gainsboro;
  border-width: 0 0 1px 0;
  margin: 0;
`;

export const InputSmallIcon = styled(InputSmall)`
  background-size: 20px;
  background-position: right 0.5rem bottom 9px;
  background-repeat: no-repeat;
`;

export const Select = styled.select`
  padding: 0.94rem;
  border: solid 1px gray;
  margin: 0.5rem 0 1rem 0;

  // Normalize select styles for all browsers:
  height: calc(3rem + 1px);
  padding-right: 1.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23666'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 0.75rem;
  background-position: calc(100% - 0.4rem) calc(50% + 0.2rem);
  background-repeat: no-repeat;
  border-radius: 0;
`;

export const ErrorText = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: red;
`;

export const SuccessText = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: green;
`;

export const InfoText = styled.div`
  margin-top: 1rem;
`;

export const SpinnerContainer = styled.div`
  margin-top: 1rem;
`;

const buttonStyles = css`
  ${(props) => props.theme.fonts.heading2};
  border: none;
  border-radius: 5px;
  padding: 1rem 2.5rem;
  background-color: ${(props) => props.theme.colors.button};
  color: white;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.buttonHover};
  }
`;

const buttonStylesSmall = css`
  font-size: 1rem;
  padding: 0.75rem 1rem;
  margin: 0;
`;

const buttonStylesSecondary = css`
  background-color: ${(props) => props.theme.colors.regionColor};
  &:hover {
    background-color: ${(props) => props.theme.colors.regionColorMedium};
  }
`;

const buttonStylesTertiary = css`
  font-size: 1rem;
  padding: 2px 0.5rem;
  margin: 0;
  border-radius: 0;
  background-color: ${(props) => props.theme.colors.darkGray};
  &:hover {
    background-color: ${(props) => props.theme.colors.mediumGrey};
  }
`;

export const ButtonLink = styled(Link)`
  ${buttonStyles}
`;

export const ButtonLinkSmall = styled(ButtonLink)`
  ${buttonStylesSmall}
`;

export const Button = styled.button`
  ${buttonStyles}
`;

export const ButtonSecondary = styled.button`
  ${buttonStyles}
  ${buttonStylesSecondary}
`;

export const ButtonSmall = styled(Button)`
  ${buttonStylesSmall}
`;

export const ButtonTertiary = styled(Button)`
  ${buttonStylesTertiary}
`;

export const ButtonSmallSecondary = styled(ButtonSmall)`
  ${buttonStylesSmall}
  ${buttonStylesSecondary}
`;

export const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;
  font-size: 14px;
`;

export const EditInfoBox = styled(InfoBox)`
  border-radius: 6px;
  background-color: #f0f0f0;
  border: 1px solid ${(props) => props.theme.colors.mediumGrey};
`;

export const ErrorBox = styled(InfoBox)`
  border-left: solid 3px red;
`;

export const A = styled.a`
  color: black;
  &::hover {
    text-decoration: underline;
  }
`;

export const HyperLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.primaryOrange};
  &:hover {
    color: ${(props) => props.theme.colors.elementsButtonHover};
  }
`;

export const MainView = styled.div`
  flex: 0 0 77%;
`;

export const Aside = styled.div`
  flex: 0 0 22%;
  background-color: ${(props) => props.theme.colors.gray};
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
`;

export const ButtonAnchorLink = styled.a`
  ${buttonStyles}
`;

export const ButtonAnchorLinkSmall = styled(ButtonAnchorLink)`
  ${buttonStylesSmall}
`;

export const GridFormAdmin = styled(Form)`
  display: grid;
  grid-template-columns: 2fr 4fr 1fr 2fr;
  grid-template-rows: 3;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const MessageBox = styled.div`
  display: grid;
  grid-template-columns: fit-content(25%) auto;
  align-items: start;
  justify-items: start;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
`;

export const ResultMessageBox = styled.div`
  align-self: start;
  padding: 0.8rem 5px 5px 5px;
  border-top-right-radius: 3px;
  border-top: 1px solid ${(props) => props.theme.colors.mediumGrey};
  border-right: 1px solid ${(props) => props.theme.colors.mediumGrey};
  margin-left: 0;
`;

export const StartColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  ${(props) => props.theme.media.phone} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  ${(props) => props.theme.media.tablet} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  padding: 50px 50px 26px 60px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 100%;
`;

export const EndColumn = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.phone} {
    display: none;
  }
  ${(props) => props.theme.media.tablet} {
    display: none;
  }
  ${(props) => props.theme.media.desktop} {
    display: flex;
  }
  align-items: flex-end;
  flex-direction: column;
`;

export const LoginFlowA = styled(A)`
  display: inline-block;
  color: ${(props) => props.theme.colors.primaryOrange};
  ${(props) => props.theme.fonts.textSmall};
`;

export const HeaderLogo = styled.img`
  align-self: flex-start;
  min-width: 92px;
  min-height: 40px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: 4rem;
`;

export const ApplicationModalRoot = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .focus-lock {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const ApplicationContentBody = styled.div``;
