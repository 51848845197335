import SelectInput from 'components/form/SelectInput';
import TextInput from 'components/form/TextInput';
import { ProcessStatus, Role } from 'constants/constants';
import { Formik } from 'formik';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateAccountModel, MembershipModel } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';
import AccountsService from 'services/admin/AccountsService';
import { ButtonSmall, ErrorText, FormMode, ProcessMode, StyledForm, SuccessText } from 'styles/Styles.styled';
import * as Yup from 'yup';

const AddNewAccountRow: React.FunctionComponent<{ companyId: string; onCompleted: () => Promise<void> }> = ({
  companyId,
  onCompleted,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [accountManagers, setAccountManagers] = React.useState<MembershipModel[]>([]);
  const initialValues: CreateAccountModel = {
    accountMangerUserId: '',
    companyId: companyId,
    name: '',
  };

  const inviteSchema = Yup.object({
    name: Yup.string().required(t('manageAccounts.messages.invalidAccName')),
    accountMangerUserId: Yup.string().strict(true).required(t('manageAccounts.messages.invalidAccMng')),
  });

  React.useEffect(() => {
    const getAccountManagers = async () => {
      try {
        const result = await MembershipService.getMembershipsByCompanyAndRole(companyId, Role.EPDOwner);
        setAccountManagers(uniqBy(result, 'userId'));
      } catch {}
    };

    getAccountManagers();
  }, [companyId]);

  const createAccount = async (values: CreateAccountModel) => {
    setStatus(ProcessStatus.Fetching);
    try {
      await AccountsService.createAccount(values);
      await onCompleted();
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  return (
    <>
      <h3>{t('manageAccounts.title')}</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={inviteSchema}
        onSubmit={(values) => createAccount(values)}
        validateOnChange={false}
      >
        {({ isSubmitting, errors }) => (
          <>
            <StyledForm mode={FormMode.Inline} process={isSubmitting ? ProcessMode.Submitting : ProcessMode.None}>
              <TextInput
                name="name"
                placeholder={t('manageAccounts.placeholder.nameOfAccount')}
                style={{ margin: 0, flexGrow: 1 }}
                supressErrors={true}
              />
              <SelectInput name="accountMangerUserId" style={{ margin: '0 1rem' }} supressErrors={true}>
                <option value="">{t('manageAccounts.selectAccountMng')}</option>
                {accountManagers?.map((option) => (
                  <option value={option.userId} key={option.userId}>
                    {option.userName}
                  </option>
                ))}
              </SelectInput>
              <ButtonSmall style={{ margin: '0 1rem' }} disabled={accountManagers?.length === 0}>
                {t('manageAccounts.createAccount')}
              </ButtonSmall>
            </StyledForm>
            <ErrorText>
              {errors.name} {errors.accountMangerUserId}
            </ErrorText>
            {status === ProcessStatus.Error && <ErrorText>{t('manageAccounts.errors.isNotCreated')}</ErrorText>}
            {status === ProcessStatus.Success && <SuccessText>{t('manageAccounts.messages.isCreated')}</SuccessText>}
          </>
        )}
      </Formik>
    </>
  );
};

export default AddNewAccountRow;
