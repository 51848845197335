import React from 'react';
import { EpdLinks } from 'routes/EpdRoutes';
import { EPDModel } from 'services/EpdClient';
import styled from 'styled-components';

import { Card, CardHeading, CardName, CardStatus } from './styles';

type TProps = {
  companyid: string;
  epd: EPDModel;
  background: any;
};

const EpdCard = ({ companyid, epd, background }: TProps) => {
  return (
    <Card to={EpdLinks.epd(epd.id)} background={background}>
      <CardHeading>{epd.fullIdentificationNumber}</CardHeading>
      <CardName>{epd.title}</CardName>
      {epd?.isCompilerFormat && (
        <CompilerBadge>
          <i className="pi pi-box" />
        </CompilerBadge>
      )}
      <CardStatus type={epd.status}>
        <span>{epd.status}</span>
      </CardStatus>
    </Card>
  );
};

const CompilerBadge = styled.div`
  ${(props) => props.theme.fonts.textSmall};
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export default EpdCard;
