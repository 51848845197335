import SelectInput from 'components/form/SelectInput';
import TextInput from 'components/form/TextInput';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateInvitationModel } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';
import { ButtonSmall, ErrorText, FormMode, ProcessMode, StyledForm, SuccessText } from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import { ProcessStatus, Role } from '../../constants/constants';

const AddNewMemberRow: React.FunctionComponent<{
  companyId: string;
  heading?: string;
  roleId?: string;
  hideGuestOptions?: boolean;
  buttonText?: string;
  handlerInvitedMembers?: Function;
}> = ({ companyId, heading, roleId, hideGuestOptions, buttonText, handlerInvitedMembers }) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [serverError, setServerError] = React.useState<string>();
  const initialValues: CreateInvitationModel = {
    invitedUserEmail: '',
    companyId: companyId,
    roleId: Role.EPDDeveloper,
    message: '',
    asGuest: true,
  };

  const inviteSchema = Yup.object({
    invitedUserEmail: Yup.string().email(t('messages.invalidEmail')).required(t('messages.required')),
  });

  const guestOptions = [
    <option value={'false'}>{t('manageMembers.internalGuest')}</option>,
    <option value={'true'}>{t('manageMembers.externalGuest')}</option>,
  ];

  const inviteUser = async (values: CreateInvitationModel) => {
    values.companyId = companyId;
    setServerError('');
    setStatus(ProcessStatus.None);
    try {
      await MembershipService.createInvitation(values);
      setStatus(ProcessStatus.Success);
      if (handlerInvitedMembers) {
        handlerInvitedMembers();
      }
    } catch (ex) {
      setServerError(PoorMansError(ex));
      setStatus(ProcessStatus.Error);
    }
  };

  return (
    <>
      {heading && <h3>{heading}</h3>}
      <Formik
        initialValues={initialValues}
        validationSchema={inviteSchema}
        onSubmit={(values) => inviteUser(values)}
        validateOnChange={false}
      >
        {({ isSubmitting, values, errors }) => (
          <>
            <StyledForm mode={FormMode.Inline} process={isSubmitting ? ProcessMode.Submitting : ProcessMode.None}>
              <TextInput
                name="invitedUserEmail"
                type="email"
                placeholder={t('placeholder.email')}
                supressErrors={true}
                style={{ margin: 0, flexGrow: 1 }}
              />

              {hideGuestOptions !== true && values.roleId === Role.EPDDeveloper ? (
                <SelectInput name="asGuest" style={{ margin: '0 1rem' }}>
                  {guestOptions.map((option) => option)}
                </SelectInput>
              ) : null}

              <ButtonSmall>{buttonText ?? t('manageMembers.inviteMember')}</ButtonSmall>
            </StyledForm>
            <ErrorText>{errors.invitedUserEmail}</ErrorText>
            {status === ProcessStatus.Success && <SuccessText>{t('manageMembers.messages.successInvitation')}</SuccessText>}
          </>
        )}
      </Formik>
      {status === ProcessStatus.Error && (
        <ErrorText>{serverError ? serverError : t('manageMembers.errors.wrong')}</ErrorText>
      )}
    </>
  );
};

export default AddNewMemberRow;
