import { customSelectStyles } from 'components/form/Select';
import documentIcon from 'images/icons/svg/document.svg';
import helpIcon from 'images/icons/svg/info.svg';
import warningIcon from 'images/icons/svg/warning.svg';
import jsPDF from 'jspdf';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { EpdLinks } from 'routes/EpdRoutes';
import CompanyService from 'services/CompanyService';
import { AddressModel, CountryModel, InvoiceModel, InvoiceStatus } from 'services/EpdClient';
import PdfService from 'services/PdfService';
import styled, { useTheme } from 'styled-components';
import { ButtonSmall, ButtonSmallSecondary, H1, H2, Input, Select, StyledLink } from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';
import { Theme } from 'styles/theme';
import { PoorMansError } from 'util/utils';

import InvoiceService from '../../services/InvoiceService';

const domtoimage = require('dom-to-image');

const useInvoice = (invoiceId: string) => {
  const [invoice, setInvoice] = useState<InvoiceModel>();
  const [countries, setCountries] = useState<{ value: string | undefined; label: string | undefined }[]>();
  const [isBusy, setIsBusy] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [invoiceUpdates, setInvoiceUpdates] = useState<Partial<InvoiceModel>>({});
  const [addressUpdates, setAddressUpdates] = useState<Partial<AddressModel>>({});
  const [declineMessage, setDeclineMessage] = useState('');
  const [currentCountry, setCurrentCountry] = useState<{ value: string | undefined; label: string | undefined }>();

  useEffect(() => {
    const fetchCountries = async () => {
      const result = await CompanyService.getCountries();
      const countryOptions = result.map((country: CountryModel) => {
        return { value: country.id, label: country.name };
      });
      setCountries(countryOptions);
      return countryOptions;
    };

    const fetchInvoice = async () => {
      const data = await InvoiceService.getInvoice(invoiceId);
      setInvoice(data);
      return data;
    };

    const fetchData = async () => {
      const countryData = await fetchCountries();
      const invoiceData = await fetchInvoice();
      setCurrentCountry({
        value: invoiceData.invoicingAddress?.countryId,
        label:
          invoiceData.invoicingAddress?.country ||
          countryData.find((country) => country.value === invoiceData.invoicingAddress?.countryId)?.label,
      });
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onApprove = useCallback(async () => {
    if (invoice != null) {
      setIsBusy(true);
      setErrorMessage('');
      try {
        await InvoiceService.approve(invoice);
        setInvoice(await InvoiceService.getInvoice(invoiceId));
      } catch (error) {
        setErrorMessage(PoorMansError(error));
        setIsBusy(false);
        return;
      }
      setIsBusy(false);
      setIsApproved(true);
    }
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps

  const onBeginDecline = useCallback(() => setIsDeclining(true), []);
  const onCancelDecline = useCallback(() => setIsDeclining(false), []);

  const onDecline = useCallback(async () => {
    if (invoice != null) {
      setIsBusy(true);
      setErrorMessage('');
      try {
        await InvoiceService.decline(invoice, declineMessage);
        setInvoice(await InvoiceService.getInvoice(invoiceId));
      } catch (error) {
        setIsDeclining(false);
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
        setIsBusy(false);
        return;
      }
      setIsDeclining(false);
      setIsBusy(false);
      setIsDeclined(true);
    }
  }, [invoice, declineMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectCurrency = useCallback(
    async (value: any) => {
      if (invoice != null) {
        setIsBusy(true);

        try {
          // This will issue an update to change currency on the invoice item.
          // We do not want to affect any outstanding edits that the user
          // already has, so we will not clear the *Updates states.
          await InvoiceService.update({ ...invoice, currency: value });
          setInvoice(await InvoiceService.getInvoice(invoiceId));
        } catch (error) {
          console.error(error);
        }

        setIsBusy(false);
      }
    },
    [invoice] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSaveChanges = useCallback(async () => {
    if (invoice != null) {
      setIsBusy(true);

      try {
        const updated = applyUpdates(invoice, invoiceUpdates, addressUpdates);
        await InvoiceService.update(updated);
        setInvoiceUpdates({});
        setAddressUpdates({});
        setInvoice(await InvoiceService.getInvoice(invoiceId));
      } catch (error) {
        console.error(error);
      }

      setIsBusy(false);
      setEditMode(false);
    }
  }, [invoice, invoiceUpdates, addressUpdates]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateInvoiceField = useCallback(
    (field: keyof InvoiceModel, value: any) => {
      if (invoice != null) {
        const updates = { ...invoiceUpdates };
        updates[field] = value;

        if (invoice[field] === updates[field]) {
          delete updates[field];
        }

        setInvoiceUpdates(updates);
      }
    },
    [invoice, invoiceUpdates]
  );

  const updateAddressField = useCallback(
    (field: keyof AddressModel, value: any) => {
      if (invoice != null) {
        const updates = { ...addressUpdates };
        updates[field] = value;

        if (invoice.invoicingAddress?.[field] === updates[field]) {
          delete updates[field];
        }

        setAddressUpdates(updates);
      }
    },
    [invoice, addressUpdates]
  );

  const updateCountry = (selectedValue: any) => {
    const { value, label } = selectedValue;
    setAddressUpdates({
      ...invoice?.invoicingAddress,
      countryId: value,
      country: label,
    });
    setCurrentCountry(selectedValue);
  };

  return {
    invoice: applyUpdates(invoice, invoiceUpdates, addressUpdates),
    hasChanges: !isEmpty(Object.keys(invoiceUpdates)) || !isEmpty(Object.keys(addressUpdates)),
    onApprove,
    onDecline,
    onBeginDecline,
    onCancelDecline,
    onSaveChanges,
    onSelectCurrency,
    isBusy,
    isDeclining,
    isEditMode,
    isApproved,
    isDeclined,
    errorMessage,
    setEditMode,
    declineMessage,
    setDeclineMessage,
    updateInvoiceField,
    updateAddressField,
    updateCountry,
    countries,
    currentCountry,
  };
};

function applyUpdates(
  invoice: InvoiceModel | undefined,
  invoiceUpdates: Partial<InvoiceModel>,
  addressUpdates: Partial<AddressModel>
): InvoiceModel {
  return { ...invoice, ...invoiceUpdates, invoicingAddress: { ...invoice?.invoicingAddress, ...addressUpdates } };
}

const Invoice: React.FunctionComponent<unknown> = () => {
  const theme = useTheme() as Theme;
  let { invoiceId } = useParams<{ invoiceId: string }>();
  const swedenId = '6f65eb76-cb39-ea11-a248-dcfb48d05344';
  const onPrintInvoice = () => {
    const element = document.getElementById('invoice');
    if (element) {
      domtoimage
        .toPng(element, { style: { background: 'white', width: '210', height: '297', padding: 40 } })
        .then((imageData: any) => {
          var img = new Image();
          img.src = imageData;
          img.style.display = 'none';
          document.body.appendChild(img);
          img.onload = () => {
            const pdf = new jsPDF('p', 'mm');
            pdf.addImage(imageData, 'PNG', 0, 0, 210, 297);
            pdf.save('invoice.pdf');
          };
        });
    }
  };

  const {
    invoice,
    updateInvoiceField,
    updateAddressField,
    onApprove,
    onDecline,
    onBeginDecline,
    onCancelDecline,
    onSaveChanges,
    onSelectCurrency,
    hasChanges,
    isBusy,
    isDeclining,
    isEditMode,
    isApproved,
    isDeclined,
    errorMessage,
    setEditMode,
    setDeclineMessage,
    declineMessage,
    updateCountry,
    countries,
    currentCountry,
  } = useInvoice(invoiceId);

  if (!invoice) {
    return <Spinner />;
  }

  const isReadOnly = invoice.invoiceStatus !== InvoiceStatus.Draft;
  const hidePdfButton =
    invoice.invoiceStatus === InvoiceStatus.Draft ||
    invoice.invoiceStatus === InvoiceStatus.Sent ||
    invoice.invoiceStatus === InvoiceStatus.Declined;
  const lastIndex = (invoice.invoiceItems?.length ?? 0) - 1;
  const formatAmount = (number: number) => {
    return new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  };

  const downloadImage = () => {
    PdfService.downloadImage(invoice?.id || '');
  };

  return (
    <InvoiceContainer>
      {!isReadOnly ? (
        <InfoBox>
          <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
          <div>
            On this page you find the information for the invoice for EPD services from the last quarter. Please review the
            information and add if needed any additional information to be included on the invoice (e.g. purchase order,
            reference, comments). The invoice address can be edited directly in the overview below and you can also change
            this at any time in the organisational settings menu. The final invoice will be created after clicking on the
            “Approve” button. If anything needs to be corrected and the invoice cannot be approved, please add a note in the
            text box, which is shown after pressing the “Decline” button The Secretariat will then look into this before
            contacting you.
            <br />
          </div>
        </InfoBox>
      ) : (
        <InfoBox>
          <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
          <div>
            Download the invoice by using the PDF button on the right or use the print function in your web browser to print
            and save the invoice as a PDF-file.
            <br />
          </div>
        </InfoBox>
      )}
      {!hidePdfButton && (
        <PdfButtonContainer onClick={downloadImage}>
          <div style={{ justifySelf: 'center', alignSelf: 'center' }}>
            <img alt="Download PDF" src={documentIcon} style={{ width: '16px' }} />
          </div>
          <div style={{ alignSelf: 'start', justifySelf: 'center' }}>PDF</div>
        </PdfButtonContainer>
      )}
      <InvoicePage id="invoice">
        <Row style={{ marginBottom: '0.5rem' }}>
          <Column style={{ width: '10vw' }}>
            <LogoBox>
              <img src={theme.logo} alt="EPD International" />
            </LogoBox>
          </Column>
          {invoice.invoiceStatus === InvoiceStatus.Draft || invoice.invoiceStatus === InvoiceStatus.Declined ? (
            <Column style={{ color: 'red', fontSize: '0.9rem', width: '15vw' }}>
              <b>This is not a real invoice!</b>
              <br />
              Please approve the quotation to generate the invoice.
            </Column>
          ) : null}
          <Column>
            {invoice.invoiceStatus === InvoiceStatus.Draft || invoice.invoiceStatus === InvoiceStatus.Declined ? (
              <H1 style={{ marginBottom: 0, fontSize: '1.3rem' }}>Quotation</H1>
            ) : (
              <H1 style={{ marginBottom: 0, fontSize: '1.3rem' }}>Invoice</H1>
            )}
            <H2 style={{ marginBottom: '1rem', marginTop: 0 }}>{invoice.period}</H2>
            <H1 style={{ fontSize: '1rem' }}>{invoice.companyName}</H1>
            <H1 style={{ fontSize: '0.75rem' }}>{invoice.accountName}</H1>
            <H1 style={{ fontSize: '0.75rem' }}>Please enter invoice number when paying</H1>
          </Column>
        </Row>
        <Row>
          <Box>
            <Column>
              {!isReadOnly ? (
                <Fragment>
                  <PropertyWithTopPadding>Our reference</PropertyWithTopPadding>
                  <ReadOnlyProperty>{invoice.ourReference}</ReadOnlyProperty>
                  <PropertyWithTopPadding>Terms of payment</PropertyWithTopPadding>
                  <ReadOnlyProperty>{invoice.termsOfPayment}</ReadOnlyProperty>
                </Fragment>
              ) : (
                <Fragment>
                  <PropertyWithTopPadding>Invoice number</PropertyWithTopPadding>
                  <ReadOnlyProperty>{invoice.vismaId}</ReadOnlyProperty>
                  <PropertyWithTopPadding>Our reference</PropertyWithTopPadding>
                  <ReadOnlyProperty>{invoice.ourReference}</ReadOnlyProperty>
                  <PropertyWithTopPadding>Invoice date</PropertyWithTopPadding>
                  <ReadOnlyProperty>
                    {invoice.invoiceDate ? new Date(invoice.invoiceDate).toLocaleDateString() : 'Missing'}
                  </ReadOnlyProperty>
                  <PropertyWithTopPadding>Due date</PropertyWithTopPadding>
                  <ReadOnlyProperty>
                    {invoice.dueDate ? new Date(invoice.dueDate).toLocaleDateString() : 'Missing'}
                  </ReadOnlyProperty>
                </Fragment>
              )}
            </Column>
          </Box>
          <InvoiceDetails style={{ width: '50vw' }}>
            <Column style={{ width: '50%' }}>
              {!isReadOnly ? (
                <Fragment>
                  {/* TODO: this should be purchase number when it's added */}
                  <Conditional show={!isReadOnly || !isEmpty(invoice.reference)}>
                    <PropertyWithTopPadding className={isEmpty(invoice.reference) ? 'no-print' : ''}>
                      Your reference
                    </PropertyWithTopPadding>
                    <Editor
                      className={isEmpty(invoice.reference) ? 'no-print' : ''}
                      placeholder={invoice.reference}
                      value={invoice.reference}
                      onChange={(e) => updateInvoiceField('reference', e.target.value)}
                      disabled={!isEditMode}
                    />
                  </Conditional>
                  <Conditional show={!isReadOnly || !isEmpty(invoice.customerNumber)}>
                    <PropertyWithTopPadding className={isEmpty(invoice.customerNumber) ? 'no-print' : ''}>
                      Purchase number
                    </PropertyWithTopPadding>
                    <Editor
                      className={isEmpty(invoice.customerNumber) ? 'no-print' : ''}
                      placeholder={invoice.customerNumber}
                      value={invoice.customerNumber}
                      onChange={(e) => updateInvoiceField('customerNumber', e.target.value)}
                      disabled={!isEditMode}
                    />
                  </Conditional>
                  <Conditional show={!isReadOnly || !isEmpty(invoice.vat)}>
                    <PropertyWithTopPadding className={isEmpty(invoice.vat) ? 'no-print' : ''}>
                      VAT number
                    </PropertyWithTopPadding>
                    <Editor
                      className={isEmpty(invoice.vat) ? 'no-print' : ''}
                      value={invoice.vat}
                      onChange={(e) => updateInvoiceField('vat', e.target.value)}
                      disabled={!isEditMode}
                    />
                  </Conditional>
                </Fragment>
              ) : (
                <Fragment>
                  <PropertyWithTopPadding>Your reference</PropertyWithTopPadding>
                  <ReadOnlyProperty>{invoice.reference}</ReadOnlyProperty>

                  {/* TODO: this should be purchase number when it's added */}
                  <PropertyWithTopPadding className={isEmpty(invoice.customerNumber) ? 'no-print' : ''}>
                    Purchase number
                  </PropertyWithTopPadding>
                  <ReadOnlyProperty>{invoice.customerNumber?.toLocaleString()}</ReadOnlyProperty>
                  {invoice.vat ? (
                    <Fragment>
                      <PropertyWithTopPadding className={isEmpty(invoice.vat) ? 'no-print' : ''}>
                        VAT No.
                      </PropertyWithTopPadding>
                      <ReadOnlyProperty>{invoice.vat?.toLocaleString()}</ReadOnlyProperty>
                    </Fragment>
                  ) : null}
                </Fragment>
              )}
            </Column>
            <Column style={{ width: '50%' }}>
              {!isReadOnly ? (
                <Fragment>
                  <PropertyWithTopPadding>Invoice address</PropertyWithTopPadding>
                  <AddressLine>
                    <Editor
                      className={isEmpty(invoice.customerNumber) ? 'no-print' : ''}
                      placeholder="Addr. line 1"
                      value={invoice.invoicingAddress?.line1}
                      onChange={(e) => updateAddressField('line1', e.target.value)}
                      disabled={!isEditMode}
                    />
                  </AddressLine>
                  <AddressLine>
                    <Editor
                      className={isEmpty(invoice.customerNumber) ? 'no-print' : ''}
                      placeholder="Addr. line 2"
                      value={invoice.invoicingAddress?.line2}
                      onChange={(e) => updateAddressField('line2', e.target.value)}
                      disabled={!isEditMode}
                    />
                  </AddressLine>
                  <AddressLine>
                    <Editor
                      className={isEmpty(invoice.customerNumber) ? 'no-print' : ''}
                      placeholder="Addr. line 3"
                      value={invoice.invoicingAddress?.line3}
                      onChange={(e) => updateAddressField('line3', e.target.value)}
                      disabled={!isEditMode}
                    />
                  </AddressLine>
                  <AddressLine>
                    <Editor
                      className={isEmpty(invoice.customerNumber) ? 'no-print' : ''}
                      placeholder="Addr. line 4"
                      value={invoice.invoicingAddress?.line4}
                      onChange={(e) => updateAddressField('line4', e.target.value)}
                      disabled={!isEditMode}
                    />
                  </AddressLine>
                  <CountryLine>
                    <ReactSelect
                      isDisabled={!isEditMode}
                      value={currentCountry}
                      options={countries}
                      onChange={updateCountry}
                      styles={customSelectStyles}
                      placeholder={'Select country'}
                      isLoading={!countries || countries.length === 0}
                    />
                  </CountryLine>
                </Fragment>
              ) : (
                <Fragment>
                  <PropertyWithoutTopPadding>Invoice address</PropertyWithoutTopPadding>
                  <AddressBox>
                    <AddressLineReadOnly>{invoice.invoicingAddress?.line1}</AddressLineReadOnly>
                    <AddressLineReadOnly>{invoice.invoicingAddress?.line2}</AddressLineReadOnly>
                    <AddressLineReadOnly>{invoice.invoicingAddress?.line3}</AddressLineReadOnly>
                    <AddressLineReadOnly>{invoice.invoicingAddress?.line4}</AddressLineReadOnly>
                    {invoice.invoicingAddress ? (
                      <AddressLineReadOnly>{invoice.invoicingAddress?.country}</AddressLineReadOnly>
                    ) : null}
                  </AddressBox>
                </Fragment>
              )}
              <Conditional show={!isReadOnly}>
                <EditButtonContainer>
                  {isEditMode ? (
                    <Fragment>
                      <EditButton disabled={!hasChanges || isBusy} onClick={onSaveChanges}>
                        Save
                      </EditButton>
                      <EditButton disabled={isBusy} onClick={() => setEditMode(false)}>
                        Cancel
                      </EditButton>
                    </Fragment>
                  ) : (
                    <EditButton disabled={isBusy} onClick={() => setEditMode(true)}>
                      Edit
                    </EditButton>
                  )}
                </EditButtonContainer>
              </Conditional>
            </Column>
          </InvoiceDetails>
        </Row>
        {invoice?.information && (
          <Row style={{ border: '1px solid rgb(142,142,142)', padding: '1rem' }}>{invoice?.information}</Row>
        )}

        <InvoiceItems>
          <ItemHeader>Item(s)</ItemHeader>
          <ItemHeader>Description</ItemHeader>
          {invoice.invoicingAddress?.countryId === swedenId ? (
            <ItemHeader style={{ textAlign: 'right' }}>Amount ( {invoice.currency} )</ItemHeader>
          ) : (
            <ItemHeader style={{ textAlign: 'right' }}>Amount ( EUR )</ItemHeader>
          )}

          <GridLine />
          {invoice?.invoiceItems?.map((item, index) => (
            <React.Fragment key={index}>
              <ItemName>{item.code}</ItemName>
              <span>{item.description}</span>
              {invoice.totalVAT && item.amount ? (
                <Amount>{formatAmount(item.amount)}</Amount>
              ) : (
                <Amount>{item.amount?.toLocaleString()}</Amount>
              )}
              <GridLine style={{ opacity: index === lastIndex ? 1 : 0.25 }} />
            </React.Fragment>
          ))}

          <Surcharge>Subtotal</Surcharge>
          {invoice.totalVAT && invoice.subTotal ? (
            <Amount>{formatAmount(invoice.subTotal)}</Amount>
          ) : (
            <Amount>{invoice.subTotal?.toLocaleString()}</Amount>
          )}

          {invoice.totalVAT ? (
            <Fragment>
              <Surcharge>VAT</Surcharge>
              <Amount>{formatAmount(invoice.totalVAT)}</Amount>

              {invoice.roundOff ? (
                <Fragment>
                  <Surcharge>Round off</Surcharge>
                  <Amount>{formatAmount(invoice.roundOff)}</Amount>
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}

          <GridLine />

          <TotalLabel>
            Total (<span>{invoice.currency}</span>)
          </TotalLabel>
          {invoice.totalVAT && invoice.totalAmount ? (
            <TotalSum>{formatAmount(invoice.totalAmount)}</TotalSum>
          ) : (
            <TotalSum>{invoice.totalAmount?.toLocaleString()}</TotalSum>
          )}
        </InvoiceItems>
        <Footer>
          <Column>
            <FooterTitle>Address:</FooterTitle>
            <span>EPD International AB</span>
            <span>Box 21060</span>
            <span>10031 Stockholm</span>
          </Column>
          <Column>
            <FooterTitle>Organisation number:</FooterTitle>
            <span>556975-8286</span>
            <FooterTitle>Our VAT number:</FooterTitle>
            <span>SE 556975828601</span>
          </Column>
          <Column>
            <FooterTitle>Bank:</FooterTitle>
            <span>Svenska Handelsbanken</span>
            <FooterTitle>Swift:</FooterTitle>
            <span>HANDSESS</span>
            <FooterTitle>Bankgiro-nummer:</FooterTitle>
            <span>566-7308</span>
          </Column>
          <Column>
            <FooterTitle>IBAN (for payments in Euro) :</FooterTitle>
            <span>EUR SE63 6000 0000 0000 5051 1769/</span>
            <FooterTitle>IBAN (for payments in Swedish Crowns):</FooterTitle>
            <span>SEK SE32 6000 0000 0008 3623 7838</span>
          </Column>
        </Footer>
      </InvoicePage>

      <Conditional show={!isDeclining && !isReadOnly}>
        <Actions>
          <ActionButton disabled={isBusy} onClick={onApprove}>
            Approve
          </ActionButton>
          <ActionButton disabled={isBusy} onClick={onBeginDecline}>
            Decline
          </ActionButton>
        </Actions>
      </Conditional>

      <Conditional show={isDeclining}>
        <NoteContainer>
          <NoteHeader>Note</NoteHeader>
          <textarea
            rows={5}
            placeholder="Please let us know why the invoice is declined"
            onChange={(e) => setDeclineMessage(e.target.value)}
          >
            {declineMessage}
          </textarea>
        </NoteContainer>
        <Actions>
          <ActionButton disabled={!declineMessage || isBusy} onClick={onDecline}>
            Send to secretariat
          </ActionButton>
          <ActionButton disabled={isBusy} onClick={onCancelDecline}>
            Cancel
          </ActionButton>
        </Actions>
      </Conditional>

      <Conditional show={errorMessage !== ''}>
        <InfoBox style={{ borderLeft: 'solid 3px red', display: 'flex', alignItems: 'center' }}>
          <img src={warningIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
          <div style={{ color: 'red' }}>{errorMessage}</div>
        </InfoBox>
        <StyledLink to={EpdLinks.dashboard()}>Go to Dashboard</StyledLink>
      </Conditional>

      <Conditional show={isApproved}>
        <SuccessMessage>
          Thank you for approving the invoice information! Your invoice has now been created and can be found under the
          invoices section in the side bar.
          <br />
          <br />
          <StyledLink to={EpdLinks.dashboard()}>Go to Dashboard</StyledLink>
        </SuccessMessage>
      </Conditional>

      <Conditional show={isDeclined}>
        <SuccessMessage>
          Thank you for providing your feedback! We will look into this as soon as possible and get back to you again.
          <br />
          <br />
          <StyledLink to={EpdLinks.dashboard()}>Go to Dashboard</StyledLink>
        </SuccessMessage>
      </Conditional>
      {false ? <ButtonSmallSecondary onClick={onPrintInvoice}>Download PDF</ButtonSmallSecondary> : null}
    </InvoiceContainer>
  );
};

const Conditional = (props: React.PropsWithChildren<{ show: boolean }>) => {
  if (!props.show || !props.children) {
    return null;
  }
  return <>{props.children}</>;
};

export const SuccessMessage = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: green;
`;

export const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;

  @media print {
    display: none;
  }
`;

const LogoBox = styled.div`
  > img {
    height: 40px;
  }
`;

const PdfButtonContainer = styled.div`
  align-self: flex-end;
  margin: 0.5rem 0;
  border: 1px solid ${(props) => props.theme.colors.darkGray};
  border-radius: 3px;
  padding: 0.2rem 0.6rem 0 0.2rem;
  cursor: pointer;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-decoration: none;
  color: ${(props) => props.theme.colors.darkGray};
  &:hover,
  &:active {
    color: black;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  padding: 1rem;
`;

const InvoiceDetails = styled(Box)`
  border: 1px solid #cbcbcb;
  padding: 1rem;

  @media print {
    border: none;
  }
`;

const AddressBox = styled.div`
  background: #f4f4f4;
`;

const AddressLine = styled.div`
  flex: 100%;
  font-family: monospace;
  font-size: 0.9rem;
  margin: 0.2rem;

  @media print {
    input:placeholder-shown {
      display: none;
    }
  }
`;
const AddressLineReadOnly = styled(AddressLine)`
  padding: 0.5rem 0.5rem 0rem;
`;

const CountryLine = styled(AddressLine)`
  min-width: 12.8rem;

  // Hack to style the select component for print
  @media print {
    & > div > div {
      margin: 0;
      border: none;
      min-height: 0;
    }

    // Hide the dropdown arrow
    & > div > div > div + div {
      display: none;
    }
    & > div > div > div {
      padding: 0.5rem 1rem;
      height: 2rem;
    }

    & div {
      margin: 0;
    }
  }
`;

const Property = styled.span`
  min-width: 80%;
  padding: 0.25rem 0;
  font-variant-numeric: proportional-nums;
`;

const ReadOnlyProperty = styled(Property)`
  align-self: flex-start;
  font-family: monospace;
  font-size: 0.9rem;
  background: #f4f4f4;
  padding: 0.5rem 1rem;
  width: 20ch;
  margin: 0;

  color-adjust: exact;
  -webkit-print-color-adjust: exact;
`;

const PropertyWithTopPadding = styled(Property)`
  font-size: 0.75rem;
`;

const PropertyWithoutTopPadding = styled(Property)`
  font-size: 0.75rem;
  padding-top: 0;
`;

const EditButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InvoicePage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin-bottom: 40px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);

  @media print {
    box-shadow: none;
    margin-bottom: 0;
    padding: 0;
    /* Account for 90px top-margin from parent element */
    min-height: calc(100vh - 90px);
  }
`;

const InvoiceContainer = styled.div`
  margin: 90px auto;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-width: 50vw;
  max-width: 900px;
  @media print {
    max-width: 100vw;
  }
`;

const InvoiceItems = styled.div`
  flex: 0;
  display: grid;
  grid-gap: 0.5rem 2rem;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-auto-rows: min-content;

  @media print {
    flex-grow: 1;
  }
`;

const GridLine = styled.div`
  grid-column: 1 / span 3;
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGray};
`;

const ItemName = styled.span`
  font-family: monospace;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  background: #f4f4f4;

  color-adjust: exact;
  -webkit-print-color-adjust: exact;
`;

const ItemHeader = styled.span`
  ${(props) => props.theme.fonts.heading3};
`;

const Amount = styled.div`
  text-align: right;
`;

const TotalSum = styled(Amount)`
  font-size: 1.25rem;
`;

const TotalLabel = styled(TotalSum)`
  grid-column: 1 / span 2;
  ${(props) => props.theme.fonts.heading3};

  span {
    padding: 0 0.5rem;
  }
`;

const Surcharge = styled(Amount)`
  grid-column: 1 / span 2;
  padding: 0.1rem 0;
  font-family: ${(props) => props.theme.fontFamilies.heading};
`;

const NoteHeader = styled.div`
  ${(props) => props.theme.fonts.heading3};
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media print {
    display: none;
  }

  textarea {
    font-size: 1rem;
    border: 1px solid ${(props) => props.theme.colors.darkGray};
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const EditButton = styled(ButtonSmall)`
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.25rem;
  align-self: flex-end;

  @media print {
    display: none;
  }
`;

const Editor = styled(Input)`
  font-family: monospace;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  width: 20ch;
  margin: 0;
  display: inline-block;
  width: 80%;

  color-adjust: exact;
  -webkit-print-color-adjust: exact;

  :last-child {
    margin-bottom: 1rem;
  }

  :disabled {
    background: #f4f4f4;
    border-width: 0;
    color: initial;
  }

  @media print {
    background: #f4f4f4;
    border-width: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  align-self: flex-end;

  @media print {
    display: none;
  }
`;

const ActionButton = styled(ButtonSmall)`
  text-transform: uppercase;
  margin: 0 0.25rem;
`;

const CurrencySelect = styled(Select)`
  ${(props) => props.theme.fonts.heading3};
  height: initial;
  border: none;
  font-size: 1.25rem;
  margin: 0;
  padding: 0 1.5rem 0 0.5rem;

  @media print {
    background: none;
    padding-right: 0.5rem;
  }
`;
const FooterTitle = styled.span`
  color: #bbb;
  margin-top: 0.5rem;
  font-size: 0.5rem;
`;

const Footer = styled.footer`
  margin-top: 3rem;
  font-size: 0.6rem;
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

export default Invoice;
