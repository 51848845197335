import { CountriesClient } from 'services/EpdClient';

const contriesClient = new CountriesClient(process.env.REACT_APP_API_URL);

class CountriesService {
  static getCountries = async () => {
    return await contriesClient.getAll(null, null, null, null);
  };
  static getLicenseeCountries = async (companyId: string) => {
    return await contriesClient.getAll(null, null, true, companyId);
  };
}

export default CountriesService;
