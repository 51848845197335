import DropDown from 'components/company/DropDown';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import accountIcon from 'images/icons/svg/users-1.svg';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountModel, CompanyModel } from 'services/EpdClient';
import styled from 'styled-components';

import { AccountDropDownItem } from './styles';

type TProps = {
  company: CompanyModel;
  account: AccountModel | undefined;
};

export const AccountSwitcher = ({ company, account }: TProps) => {
  const { t } = useTranslation();
  const { changeCompanyAccount } = useContext(CompanyContext);
  return (
    <Wrapper>
      <DropDown icon={accountIcon} heading={'Accounts'} content={t('epdDashboard.tooltips.switchAccount')}>
        {company?.accounts?.map((a) => (
          <AccountDropDownItem key={a.id} onClick={() => changeCompanyAccount?.(a.id)}>
            {a.name}
          </AccountDropDownItem>
        ))}
      </DropDown>
      <h3 style={{ margin: 0 }}> {account?.name} </h3>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  align-items: center;
`;
