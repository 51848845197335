import ErrorFallback from 'components/error-fallback/ErrorFallback';
import { Column, ColumnProps } from 'primereact/column';
import { DataTableValueArray } from 'primereact/datatable';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import { DataTableStyled, Placeholder } from '../StyledGrid';

interface ColumnPropsExtended extends ColumnProps {
  isDataReadonly?: boolean;
  isTitleReadonly?: boolean;
  placeholder?: string;
}

type TProps = {
  value?: DataTableValueArray | undefined;
  columns?: ColumnPropsExtended[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
};

const FixedGridPdf = ({ value, columns, notes }: TProps) => {
  const renderCellBody = (row: any, options: any) => {
    return (
      (row as any)?.[options?.field] || (
        <Placeholder>{(row as any)?.[`${options?.field}Placeholder`] || 'Type here'}</Placeholder>
      )
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div>
        <DataTableStyledHeader value={value} dataKey="id" tableStyle={{ minWidth: '49rem' }} showGridlines editMode="cell">
          {columns?.map((x) => (
            <Column key={x.field} body={(row, options) => renderCellBody(row, options)} {...x} />
          ))}
        </DataTableStyledHeader>
      </div>
    </ErrorBoundary>
  );
};

const DataTableStyledHeader = styled(DataTableStyled)`
  &.p-datatable .p-column-header-content {
    display: block;
  }
`;

export default FixedGridPdf;
