import Footer from 'components/footer/Footer';
import FooterNew from 'components/v2/Footer';
import React from 'react';
import AuthService from 'services/AuthService';

type FooterComponentProps = {
  isNewUI: boolean;
};

const FooterComponent: React.FC<FooterComponentProps> = ({ isNewUI }) => {
  if (isNewUI) {
    return AuthService.isAuthenticated() ? <FooterNew /> : null;
  }
  return <Footer />;
};

export default FooterComponent;
