import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { EPDVerifierModel, VerifierType } from 'types/types';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Option } from 'types/types';
import _ from 'lodash';
import styled from 'styled-components';
import MembershipService from 'services/MembershipService';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import CertificationBodySelectorDialog from './CertificationBodySelectorDialog';
import useIsReadOnlyMode from 'components/v2/compiler/hooks/useIsReadOnlyMode';

interface CertificationBodySelectorProps {
  epdId?: string | undefined;
  epdVersionId?: string | undefined;
  processCertificateIssuedBy?: EPDVerifierModel | undefined;
  onChangeBody: any;
  error: any;
}

const CertificationBodySelector: React.FunctionComponent<CertificationBodySelectorProps> = ({
  epdId,
  epdVersionId,
  processCertificateIssuedBy,
  onChangeBody,
  error,
}) => {
  const { companyId } = useContext(CompanyContext);
  const [bodies, setBodies] = useState<EPDVerifierModel[]>([]);
  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);
  const isReadOnly = useIsReadOnlyMode();

  const selectedBody = !!processCertificateIssuedBy
    ? ({
        label: processCertificateIssuedBy.displayName,
        value: processCertificateIssuedBy.id,
      } as Option)
    : undefined;

  useEffect(() => {
    const fetchVerifierUsers = async () => {
      if (!companyId) return;
      const result = await MembershipService.getVerifiers(companyId);
      const bodies = result.filter((value) => value.verifierType === VerifierType.Body);
      setBodies(bodies);
    };
    fetchVerifierUsers();
  }, []);

  return (
    <>
      <CompilerSelectControlledComponent
        label="EPD process certificate issued by"
        tooltip="The accredited certification body that issued the EPD process certificate."
        name="processCertificateIssuedBy"
        options={bodies.map(
          (body) =>
            ({
              value: body.id,
              label: body.displayName,
            } as Option)
        )}
        required
        error={error}
        value={selectedBody}
        onChanged={onChangeBody}
        placeholder="Select"
        isClearable={true}
      />
      {!isReadOnly && (
        <FindButtonBox>
          <Button
            style={{ fontSize: 15, fontWeight: 500 }}
            type="button"
            label="+ Find Certification Body"
            text
            onClick={() => setShowSelectDialog(true)}
          />
        </FindButtonBox>
      )}
      <CertificationBodySelectorDialog
        epdId={epdId}
        epdVersionId={epdVersionId}
        processCertificateIssuedBy={processCertificateIssuedBy}
        onHide={() => setShowSelectDialog(false)}
        isOpened={showSelectDialog}
      />
    </>
  );
};

const FindButtonBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  justify-content: start;
  display: flex;
  max-height: 70px;

  .p-button.p-button-text {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-top: 0.25rem;
    ${(props) => props.theme.fonts.standard};
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;

export default CertificationBodySelector;
