import { Formik } from 'formik';
import removeIcon from 'images/icons/svg/error.svg';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import { CompanyModel, CreateMembershipModel, MembershipModel, RoleModel } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';
import AdminService from 'services/admin/AdminService';
import { ButtonSmall, InfoText, LabelBox, StyledReactSelect } from 'styles/Styles.styled';
import { Option } from 'types/types';

import { AdminGridForm, CompaniesListBox, CompaniesListItem, FixedLabel, ImageRemove } from './style';

interface Props {
  userid: string;
}

const EditUserMembership: React.FunctionComponent<Props> = ({ userid }) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<any>(null);
  // const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [companiesLookup, setCompaniesLookup] = useState<CompanyModel[]>([]);
  const [memberships, setMemberships] = useState<MembershipModel[]>([]);
  const [roles, setRoles] = useState<RoleModel[]>();

  const fetchUserMemberships = async () => {
    const result = await MembershipService.getMembershipsByUser(userid);
    setMemberships(result);
    setSelectedValues(result);
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const companiesResult = await CompanyService.getCompanies();
      setCompaniesLookup(companiesResult);
    };

    const loadRoles = async () => {
      const result = await AdminService.getRoles();
      setRoles(result);
    };

    fetchUserMemberships();
    fetchCompanies();
    loadRoles();
  }, [userid]); // eslint-disable-line react-hooks/exhaustive-deps

  const initialValues = {
    ...selectedValues,
    newCompany: null,
  };

  if (!initialValues /*&& !errorMessage*/) {
    return <InfoText>{t('messages.loading')}</InfoText>;
  }

  const addCompany = async (company: Option) => {
    const defaultRole = roles?.find((x) => x.name === 'EPD owner');
    const createModel = { companyId: company.value, userId: userid, roleId: defaultRole?.id } as CreateMembershipModel;
    await MembershipService.create(createModel);
    fetchUserMemberships();
  };

  const removeCompanyMemberships = async (m: MembershipModel[]) => {
    for (const element of m) {
      await MembershipService.deleteMembership(element.id);
    }

    fetchUserMemberships();
  };

  const handleRolesChange = async (companyId: string, m: Option[]) => {
    const initialRoles = _.filter(memberships, (x) => x.companyId === companyId);
    const addMemberships = _.filter(m, (x) => !_.some(initialRoles, (y) => y.roleId === x.value));
    const deleteMemberships = _.filter(initialRoles, (x) => !_.some(m, (y) => y.value === x.roleId));

    for (const element of addMemberships) {
      await MembershipService.create({ companyId: companyId, userId: userid, roleId: element.value });
    }

    for (const element of deleteMemberships) {
      await MembershipService.deleteMembership(element.id);
    }

    fetchUserMemberships();
  };

  return (
    <Formik id={'formik2'} initialValues={initialValues as any} onSubmit={() => {}} enableReinitialize>
      {({ values, setFieldValue }) => (
        <AdminGridForm>
          <LabelBox>
            <FixedLabel>{t('account.companies')}</FixedLabel>
          </LabelBox>

          <FixedLabel style={{ marginLeft: '24px' }}>{t('account.roles')}</FixedLabel>

          <CompaniesListBox>
            {_.values(_.groupBy(memberships, (i) => i.companyId)).map((c) => {
              return (
                <CompaniesListItem key={c[0].companyId}>
                  <LabelBox>
                    <FixedLabel>{c[0].companyName}</FixedLabel>
                  </LabelBox>
                  <StyledReactSelect
                    isDisabled={!AuthService.isAdmin()}
                    name={c[0].companyId}
                    defaultValue={c.map((t) => {
                      return { value: t.roleId, label: t.roleName } as Option;
                    })}
                    isMulti
                    options={roles?.map((r) => {
                      return { value: r.id, label: r.name } as Option;
                    })}
                    onBlur={() => handleRolesChange(c[0].companyId, (values as any)[c[0].companyId])}
                  />
                  {AuthService.isAdmin() && (
                    <ImageRemove>
                      <img
                        src={removeIcon}
                        style={{ height: '1rem' }}
                        alt="Remove"
                        onClick={() => {
                          removeCompanyMemberships(c);
                        }}
                      />
                    </ImageRemove>
                  )}
                </CompaniesListItem>
              );
            })}
          </CompaniesListBox>
          {AuthService.isAdmin() && (
            <>
              <LabelBox>
                <FixedLabel>{t('account.connectToOrganization')}</FixedLabel>
              </LabelBox>
              <StyledReactSelect
                defaultValue={initialValues.newCompany}
                name="newCompany"
                options={companiesLookup?.map((company) => {
                  return { value: company.id, label: company.name } as Option;
                })}
                isClearable
              />
              <ButtonSmall
                style={{ padding: '0.5rem 1rem', borderRadius: '3px' }}
                type="button"
                disabled={values.newCompany == null}
                onClick={() => {
                  addCompany(values.newCompany as any);
                  setFieldValue('newCompany', initialValues.newCompany);
                }}
              >
                +
              </ButtonSmall>
            </>
          )}
        </AdminGridForm>
      )}
    </Formik>
  );
};

export default EditUserMembership;
