import React from 'react';
import Select from 'react-select';
import styled, { useTheme } from 'styled-components';
import { Theme } from 'styles/theme';
import { Option } from 'types/types';

import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';

type TProps = Partial<TSmartLabelProps> & {
  name: string;
  value: any;
  options: any[];
  isMulti?: boolean;
  style?: any;
  onChanged: any;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
};

const WizardSelectComponent: React.FunctionComponent<TProps> = ({
  label,
  options,
  tooltip,
  tooltipPosition,
  labelStyle,
  name,
  value,
  onChanged,
  disabled,
  style,
  placeholder,
  required,
  isMulti,
  isClearable = true,
  ...props
}) => {
  const theme = useTheme() as Theme;
  const customSelectStyles = {
    menu: (styles: any) => ({ ...styles }),
    control: (styles: any, state: any) => ({
      ...styles,
      borderRadius: '2px',
      color: 'black',
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: 'normal',
      minHeight: '44px',
      borderColor: state.isFocused ? theme.colors.primaryOrange : theme.colors.elementsInputBorder,
      outline: state.isFocused ? `2px solid ${theme.colors.primaryOrange}` : '',
      '&:hover': {
        borderColor: theme.colors.primaryOrange,
      },
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      fontSize: '100%',
    }),
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={isMulti ? value && value.length > 0 : !!value?.value}
        />
      )}
      <StyledSelect
        options={options}
        name={name}
        defaultValue={value}
        onChange={(e: any) => {
          e !== value && onChanged(name, isMulti ? e?.map((x: Option) => x.value) : e?.value);
        }}
        isClearable={isClearable}
        isMulti={isMulti}
        isDisabled={disabled}
        styles={customSelectStyles}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

const StyledSelect = styled(Select)``;

export default WizardSelectComponent;
