import { CreateRequestModel, RequestsClient } from './EpdClient';

const client = new RequestsClient(process.env.REACT_APP_API_URL);

class RequestService {
  static createRequest = async (values: CreateRequestModel) => {
    return await client.createRequest(values);
  };

  static getRequests = async (companyId: string) => {
    return await client.getAll(companyId);
  };

  static rejectRequest = async (requestId: string) => {
    return await client.reject(requestId);
  };

  static acceptRequest = async (requestId: string) => {
    return await client.accept(requestId);
  };
}

export default RequestService;
