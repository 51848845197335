import { Formik } from 'formik';
import helpIcon from 'images/icons/svg/info.svg';
import React from 'react';
import { A, ButtonSmall, InfoBox, Label } from 'styles/Styles.styled';
import { FixedLabel, FixedTextAreaComponent, FixedTextInput, RegBox, Row } from './PcrWizard';

const WizardStep3: React.FunctionComponent<{
  pcr?: any;
  onChangePcr?: any;
  validation: any;
  goToStep: any;
}> = ({ pcr, onChangePcr, validation, goToStep }) => {
  return (
    <div>
      <Row>
        <FixedLabel style={{ alignSelf: 'start' }}>Comments received during open consultation</FixedLabel>
        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start' }}>
          <Formik initialValues={{}} onSubmit={(values, actions) => {}}>
            <RegBox>
              <Label>Sender</Label>
              <FixedTextInput
                value={pcr?.sender}
                type={'text'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.currentTarget.value !== pcr?.sender && onChangePcr('sender', new Date(e.currentTarget.value))
                }
              />
              <Label>Comment</Label>
              <FixedTextAreaComponent
                value={pcr?.emailComment}
                propertyName={'emailComment'}
                onChangePcr={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('emailComment', e.currentTarget.value)}
                disabled={false}
              />
              <div style={{ alignSelf: 'flex-end' }}>
                <ButtonSmall type="submit">+ Add</ButtonSmall>
              </div>
            </RegBox>
          </Formik>
        </div>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Download summary template</FixedLabel>
        <InfoBox>
          <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
          <div>
            Download the open consultation summary template.
            <br />
            <A href={''}>Download template</A>
          </div>
        </InfoBox>
      </Row>
    </div>
  );
};

export default WizardStep3;
