import { Column, ColumnEvent, ColumnProps } from 'primereact/column';
import React from 'react';
import { useUpdateEpdTransportationPerformance } from 'services/api/mutations';
import { useEpdLcaTransportation } from 'services/api/queries';
import styled from 'styled-components';

import { cellTextEditor } from '../GridCellEditors';
import { DataTableStyled } from '../StyledGrid';
import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import { TransportationPerformanceModel } from 'types/types';

const TransportationPerformanceGrid: React.FunctionComponent<{ epdVersionId: string; errors?: any }> = ({
  epdVersionId,
}) => {
  const data = useEpdLcaTransportation(epdVersionId!).data;
  const updateTransportationPerformance = useUpdateEpdTransportationPerformance(epdVersionId);
  const isReadOnly = useIsReadOnlyMode();

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  const rows = [
    {
      ord: 0,
      epdVersionId: epdVersionId,
      parameter: 'Transportation performance (TP)',
      rule: (
        <>
          Average car load (Q<sub>av</sub>) multiplied by the distance travelled by the lift during the service life (SRSL)
        </>
      ),
      fieldName: 'transportationPerformance',
      value: data?.transportationPerformance,
    },
    {
      ord: 1,
      epdVersionId: epdVersionId,
      parameter: (
        <>
          Average car load (Q<sub>av</sub>)
        </>
      ),
      rule: <>Rated load multiplied by the corresponding percentage from Table 3 in ISO 25745-2</>,
      fieldName: 'transportationAverageCarLoad',
      value: data?.transportationAverageCarLoad,
    },
    {
      ord: 2,
      epdVersionId: epdVersionId,
      parameter: (
        <>
          Distance travelled by the lift during the service life (S<sub>RSL</sub>)
        </>
      ),
      rule: (
        <>
          One-way average travel distance (s<sub>AV</sub>) multiplied by the number of trips per day (n<sub>d</sub>), number
          of operating days per year (d<sub>op</sub>), and reference service life (RSL).
        </>
      ),
      fieldName: 'transportationServiceLifeDistance',
      value: data?.transportationServiceLifeDistance,
    },
  ];

  const onCellEditComplete = (e: ColumnEvent) => {
    const { rowData, newValue } = e;
    const newData: TransportationPerformanceModel = {
      ...data,
      epdVersionId: epdVersionId,
      [rowData.fieldName]: newValue,
    };
    updateTransportationPerformance.mutate(newData);
  };

  const TransportationPerformanceGridColumns: ColumnProps[] = [
    {
      field: 'parameter',
      header: 'Parameter',
      style: { minWidth: '3rem' },
    },
    {
      field: 'rule',
      header: 'How to calculate',
      style: { minWidth: '3rem' },
    },
    {
      field: 'value',
      header: 'Value',
      style: { minWidth: '3rem' },
      editor: isReadOnly ? undefined : cellTextEditor,
      onCellEditComplete: onCellEditComplete,
    },
  ];

  return (
    <TransportationPerformanceGridDataTableStyled
      value={rows}
      dataKey="ord"
      tableStyle={{ minWidth: '40rem' }}
      showGridlines
      editMode="cell"
      rowClassName={rowClassName}
    >
      {TransportationPerformanceGridColumns?.map((x) => (
        <Column key={x.field} {...x} />
      ))}
    </TransportationPerformanceGridDataTableStyled>
  );
};

const TransportationPerformanceGridDataTableStyled = styled(DataTableStyled)`
  .p-datatable-thead > tr > th:not(:last-child),
  .p-datatable-tbody > tr > td:not(:last-child),
  .p-datatable-tfoot > tr > td:not(:last-child) {
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
  }

  .p-datatable-thead > tr:first-child > th {
    ${(props) => props.theme.typography.contentTableTitleXs}
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    padding: 0.5rem 1rem;
  }

  .p-datatable-thead > tr > th {
    ${(props) => props.theme.typography.contentTableBodyXs}
    background-color: ${(props) => props.theme.colors.primaryWhite};
    padding: 0.5rem 1rem;
  }
`;

export default TransportationPerformanceGrid;
