import useIsReadOnlyMode from 'components/v2/compiler/hooks/useIsReadOnlyMode';
import { StyledCrossIcon, StyledDownloadIcon } from 'components/v2/epd-compiler-inputs/common-icons';
import WizardAddButton from 'components/v2/epd-wizard-inputs/WizardAddButton';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { ProcessStatus } from 'constants/constants';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PCRModel, ProductCategoryModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import PcrService from 'services/PcrService';
import { Option } from 'types/types';

import {
  InputTextStyled,
  PcrInputGroup,
  PcrListFileDownloadIcon,
  PcrListFileItem,
  PcrListFileTitle,
} from '../../../../pcr-selector/styled';
import PcrSelectorDetails from './PcrSelectorDetails';
import PcrSelectorDialog from './PcrSelectorDialog';
import PcrSelectorFilesDialog from './PcrSelectorFilesDialog';

interface PcrProps {
  label?: string | undefined;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  pcr: PCRModel | undefined;
  disabled: boolean | undefined;
  required: boolean | undefined;
  onChangePcr: (pcr: PCRModel | undefined) => void;
  onRemovePcr: () => void;
  error: any;
}

const PcrSelector: React.FunctionComponent<PcrProps> = ({
  label,
  tooltip,
  tooltipPosition,
  pcr,
  disabled,
  required,
  onChangePcr,
  onRemovePcr,
  error,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [pcrList, setPcrList] = useState<PCRModel[]>([]);
  const [productCategory, setProductCategory] = useState<Option | undefined>();
  const [selectedPcr, setSelectedPcr] = useState<PCRModel | undefined>(pcr);
  const [productCategoryList, setProductCategoryList] = useState<Option[]>([]);
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [isEditOpened, setIsEditOpened] = useState<boolean>(false);
  const isReadOnly = useIsReadOnlyMode() || disabled;

  const findPcr = async (val: string, catId?: string, isDisabled?: boolean) => {
    setStatus(ProcessStatus.Fetching);
    const res = await PcrService.findPcr(val || '', 'c-pcr', catId, isDisabled);
    setPcrList(res);
    setStatus(ProcessStatus.Success);
  };

  const debounceLoadData = useCallback(_.debounce(findPcr, 500), []);

  React.useEffect(() => {
    const fetchPcr = async () => {
      if (!productCategory) {
        setPcrList([]);
        return;
      }

      debounceLoadData('', productCategory?.value, false);
    };
    fetchPcr();
  }, [productCategory, debounceLoadData]);

  React.useEffect(() => {
    const fetchCats = async () => {
      const res = await PcrService.getAllCategories();
      setProductCategoryList(
        res.map((item) => {
          return { value: item.id!, label: item.title! };
        })
      );
    };

    fetchCats();
  }, []);

  const selectPcr = (selectedPcr: PCRModel | undefined) => {
    onChangePcr(selectedPcr);
  };

  const handleCloseDialog = () => {
    setIsEditOpened(false);
    setStatus(ProcessStatus.None);
    setPcrList([]);
    setProductCategory(undefined);
    setSelectedPcr(undefined);
  };

  const handleSelectPcr = () => {
    selectPcr(selectedPcr);
    handleCloseDialog();
  };

  const handleDownloadSelectedPcrFiles = (pcr: PCRModel | undefined) => {
    if (pcr?.documents && pcr.documents?.length > 1) {
      setShowFiles(true);
    } else if (pcr?.documents && pcr.documents?.length === 1) {
      window.open(FileService.getImageUrl(pcr.documents[0].id || ''), '_blank', 'noreferrer');
    }
  };

  const handleRemovePcr = () => {
    setSelectedPcr(undefined);
    onRemovePcr();
  };

  return (
    <>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!pcr}
          error={error}
        />
      )}
      <div style={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
        <PcrInputGroup className="p-inputgroup" disabled={isReadOnly}>
          <InputTextStyled
            name="pcr"
            value={pcr ? pcr.fullName : ''}
            placeholder="Add product category rule"
            readOnly
            disabled={isReadOnly}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 11 }}>{pcr && !isReadOnly && <StyledCrossIcon onClick={handleRemovePcr} />}</div>
            <div style={{ marginRight: 12 }}>
              {pcr && !isReadOnly && (
                <StyledDownloadIcon
                  onClick={() => {
                    handleDownloadSelectedPcrFiles(pcr);
                  }}
                />
              )}
            </div>
          </div>
        </PcrInputGroup>
        <WizardAddButton label="+ Add PCR" onClick={() => setIsEditOpened(true)} disabled={!!pcr} />

        <PcrSelectorDialog
          header="Add Product Category Rule (PCR)"
          isOpened={isEditOpened}
          onHide={handleCloseDialog}
          onChange={handleSelectPcr}
          contentStyle={pcrList?.length > 0 ? {} : { overflowY: 'initial' }}
        >
          <PcrSelectorDetails
            productCategoryList={productCategoryList}
            productCategory={productCategory}
            pcrList={pcrList}
            pcr={selectedPcr}
            onProductCategorySelect={(value: Option | undefined) => setProductCategory(value)}
            onPcrSelect={setSelectedPcr}
            status={status}
          />
        </PcrSelectorDialog>

        <PcrSelectorFilesDialog
          header={pcr?.fullName ?? ''}
          isOpened={showFiles}
          onHide={() => {
            setShowFiles(false);
          }}
        >
          <PcrListFileTitle>Here you can download PCR related documents.</PcrListFileTitle>
          {pcr?.documents?.map(
            (file, index) =>
              file && (
                <PcrListFileItem key={index}>
                  <span>{file.name || t('epdWizard.wizardStep1.pcrMenu.placeholderImage')}</span>
                  <PcrListFileDownloadIcon
                    className="pi pi-download"
                    onClick={() => {
                      window.open(FileService.getImageUrl(file.id || ''), '_blank', 'noreferrer');
                    }}
                  />
                </PcrListFileItem>
              )
          )}
        </PcrSelectorFilesDialog>
      </div>
    </>
  );
};

export default PcrSelector;
