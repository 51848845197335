import { PreverifiedToolsClient } from './EpdClient';

const client = new PreverifiedToolsClient(process.env.REACT_APP_API_URL);

class PreverifiedToolService {
  static getPreVerifiedTool = async () => {
    return await client.get();
  };
  static getPreVerifiedToolVersions = async () => {
    return await client.getVersions();
  };
}

export default PreverifiedToolService;
