import { Panel } from 'primereact/panel';
import { useTranslation } from 'react-i18next';
import { EPDStatus, VersionStateType } from 'services/EpdClient';
import styled from 'styled-components';
import { PanelContainer } from 'styles/v2/Styles.styled';

import ToggleButton from './panels/toggleable/ToggleButton';

type TProps = {
  status?: EPDStatus;
  statusMessage?: string;
  requiredActionMessage?: string;
};

const StatusBox = ({ status, statusMessage, requiredActionMessage }: TProps) => {
  const { t } = useTranslation();

  const headerTemplate = (options: any, status: EPDStatus | undefined) => {
    const className = `${options.className} justify-content-space-between w-full`;

    return (
      <div
        className={className}
        style={{
          borderRadius: options.collapsed ? '4px' : undefined,
          paddingRight: 0,
          height: '3rem',
        }}
      >
        <StatusLabel style={options.collapsed ? { whiteSpace: 'nowrap' } : {}}>EPD status: {status}</StatusLabel>
        <ToggleButton options={options} withMargins />
      </div>
    );
  };

  return (
    <PanelContainer style={{ boxShadow: 'none' }}>
      <StatusBoxPanel headerTemplate={(options) => headerTemplate(options, status)} toggleable collapsed>
        <div style={{ marginTop: '-2rem' }}>
          <p>{t(`epdWizard.epdState.statusMessage.${statusMessage || 'status0'}`)}</p>
          <StatusLabel>{t('epdWizard.epdState.action.title')}</StatusLabel>
          <p>{t(`epdWizard.epdState.actionMessage.${requiredActionMessage || 'requiredAction0'}`)}</p>
        </div>
      </StatusBoxPanel>
    </PanelContainer>
  );
};

const StatusLabel = styled.label`
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 185px;
  padding: 8px 0;
`;

const StatusBoxPanel = styled(Panel)`
  border-radius: 4px;
  border: 0;
  color: ${(props) => props.theme.colors.primaryBlack70};
  ${StatusLabel} {
    ${(props) => props.theme.typography.headerCaptionSemiBold};
    color: ${(props) => props.theme.colors.primaryGreen};
  }

  .p-panel-header {
    border: 0;
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  }
  .p-panel-content {
    border: 0;
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    ${(props) => props.theme.typography.bodyInput};
  }

  p {
    margin-top: 4px;
  }
`;

export default StatusBox;
