import { ProcessStatus, REMOVE_FILE } from 'constants/constants';
import Tick from 'images/tick.svg';
import { WizardTabModel } from 'models/tabModels';
import WizardStep1 from 'pages/pcrWizard/WizardStep1';
import WizardStep2 from 'pages/pcrWizard/WizardStep2';
import WizardStep3 from 'pages/pcrWizard/WizardStep3';
import WizardStep4 from 'pages/pcrWizard/WizardStep4';
import WizardStep5 from 'pages/pcrWizard/WizardStep5';
import { StepStatus } from 'pages/wizard/types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ActionState, PCRModel } from 'services/EpdClient';
import PcrService from 'services/PcrService';
import styled, { css } from 'styled-components';
import { Container, ErrorText, H2, Input, Label, TextSmall } from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';
import * as Yup from 'yup';

const step1ValidationSchema = Yup.object<Partial<PCRModel>>({});

const step2ValidationSchema = Yup.object<Partial<PCRModel>>({});

const step3ValidationSchema = Yup.object<Partial<PCRModel>>({});

const step4ValidationSchema = Yup.object<Partial<PCRModel>>({});

const step5ValidationSchema = Yup.object<Partial<PCRModel>>({});

const PcrWizard: React.FunctionComponent = () => {
  const { id } = useParams<any>();
  const [pcr, setPcr] = React.useState<PCRModel>();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [updatingPcr, setUpdatingPcr] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    const fetchPcr = async () => {
      try {
        setStatus(ProcessStatus.Fetching);
        const result = await PcrService.getOnePcr(id);
        setPcr(result as any);
        setStatus(ProcessStatus.Success);
      } catch (error) {
        setStatus(ProcessStatus.Error);
      }
    };
    fetchPcr();
  }, [id]);

  const onChangePcr = (propertyName: string, val: any) => {
    if (!pcr || !pcr.id) {
      return;
    }

    if (val instanceof File) {
      addPcrFile(pcr.id, propertyName, val);
    } else {
      addPcrProperty(pcr.id, propertyName, val);
    }
  };

  const addPcrProperty = async (pcrId: string, propertyName: string, val: any) => {
    try {
      setUpdatingPcr(ProcessStatus.Fetching);
      const result =
        propertyName === REMOVE_FILE
          ? await PcrService.removePcrFile(pcrId, val)
          : await PcrService.updateProperty(id, propertyName, val === undefined ? null : val);
      setPcr(result);
      setUpdatingPcr(ProcessStatus.Success);
    } catch {
      setUpdatingPcr(ProcessStatus.Error);
    }
  };

  const addPcrFile = async (pcrId: string, propertyName: string, file: File) => {
    const fileName = file.name;
    const fileType = file.type;
    const reader = new FileReader();
    reader.onload = async () => {
      const fileBlob = reader.result as ArrayBuffer;
      if (fileBlob) {
        const blob = new Blob([new Uint8Array(fileBlob)], { type: fileType });
        try {
          setUpdatingPcr(ProcessStatus.Fetching);
          const result = await PcrService.addPcrFile(pcrId, propertyName, fileName, blob);
          setPcr(result);
          setUpdatingPcr(ProcessStatus.Success);
        } catch {
          setUpdatingPcr(ProcessStatus.Error);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const goToStep = (stepNumber: number) => setActiveStep(stepNumber);
  const wizardTabs: WizardTabModel<PCRModel>[] = [
    {
      name: (
        <>
          1. Initiation<TextSmall>Define your PCR and find support.</TextSmall>
        </>
      ),
      component: (
        <WizardStep1
          key={'step1'}
          validation={step1ValidationSchema}
          pcr={pcr}
          onChangePcr={onChangePcr}
          goToStep={goToStep}
        />
      ),
      isValid: (e) => step1ValidationSchema.isValidSync(e),
    },
    {
      name: (
        <>
          2. Preparation<TextSmall>Compile all data for your PCR.</TextSmall>
        </>
      ),
      component: (
        <WizardStep2
          key={'step2'}
          validation={step2ValidationSchema}
          pcr={pcr}
          onChangePcr={onChangePcr}
          goToStep={goToStep}
        />
      ),
      isValid: (e) => step2ValidationSchema.isValidSync(e),
    },
    {
      name: (
        <>
          3. Open consultation<TextSmall>Consultation remarks.</TextSmall>
        </>
      ),
      component: (
        <WizardStep3
          key={'step3'}
          validation={step3ValidationSchema}
          pcr={pcr}
          onChangePcr={onChangePcr}
          goToStep={goToStep}
        />
      ),
      isValid: (e) => step3ValidationSchema.isValidSync(e),
    },
    {
      name: (
        <>
          4. Review <TextSmall>Summary and review.</TextSmall>
        </>
      ),
      component: (
        <WizardStep4
          key={'step4'}
          validation={step4ValidationSchema}
          pcr={pcr}
          onChangePcr={onChangePcr}
          goToStep={goToStep}
        />
      ),
      isValid: (e) => step4ValidationSchema.isValidSync(e),
    },
    {
      name: (
        <>
          5. Publication<TextSmall>Submit PCR for publication.</TextSmall>
        </>
      ),
      component: (
        <WizardStep5
          key={'step5'}
          validation={step5ValidationSchema}
          pcr={pcr}
          onChangePcr={onChangePcr}
          goToStep={goToStep}
        />
      ),
      isValid: (e) => step5ValidationSchema.isValidSync(e),
    },
  ];

  if (status === ProcessStatus.Fetching) {
    return (
      <Container>
        <div>Loading...</div>
      </Container>
    );
  }

  if (status === ProcessStatus.Error) {
    return (
      <Container>
        <div>An error occurred. Try again later.</div>
      </Container>
    );
  }

  return (
    <Container>
      {updatingPcr === ProcessStatus.Fetching && <Spinner />}
      {updatingPcr === ProcessStatus.Error && <ErrorUpdating>An error occurred when trying to save the PCR.</ErrorUpdating>}
      {pcr?.name === 'Unnamed' ? (
        <H2 style={{ margin: '2rem 1rem' }}>{pcr?.name}</H2>
      ) : (
        <H2 style={{ margin: '2rem 1rem' }}>{pcr?.name}</H2>
      )}
      <Wizard>
        <WizardSteps>
          {wizardTabs.map((tab, index) => (
            <WizardStep
              key={index}
              status={index === activeStep ? StepStatus.Active : StepStatus.Todo}
              onClick={() => setActiveStep(index)}
            >
              <div>{tab.name}</div>
              {!tab.isValid(pcr as PCRModel) && (
                <div>
                  <img src={Tick} width="16" height="16" alt="Step completed" />
                </div>
              )}
            </WizardStep>
          ))}
        </WizardSteps>
        <WizardContent>
          {wizardTabs.map(
            (tab, index) =>
              index === activeStep &&
              React.cloneElement(tab.component, {
                pcr: pcr,
                onChangePcr,
              })
          )}
        </WizardContent>
      </Wizard>
    </Container>
  );
};

const Wizard = styled.section`
  display: flex;
  width: 100%;
`;

const WizardSteps = styled.div`
  flex: 0 0 calc(100% / 4);
`;

const WizardStep = styled.div<{ status?: StepStatus }>`
  ${(props) => props.theme.fonts.heading3}
  display: flex;
  padding: 1rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray};
  border-right: solid 3px ${(props) => props.theme.colors.gray};
  justify-content: space-between;

  ${(props) =>
    props.status === StepStatus.Active
      ? css`
          border-right-color: ${(props) => props.theme.colors.orange};
          background-color: ${(props) => props.theme.colors.lightGray};
        `
      : null};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightGray};
  }
`;

const WizardContent = styled.div`
  flex: 0 0 calc(100% / 4 * 3);
  padding: 1rem 2rem;
  box-sizing: border-box;
`;

const ErrorUpdating = styled(ErrorText)`
  position: fixed;
  right: 0;
  margin: 1rem;
  padding: 1rem;
  border: solid 1px red;
  background: white;
  z-index: 1;
`;

export const Row = styled.div<{ state?: ActionState }>`
  display: ${(props) => (props?.state === ActionState.Hidden ? 'none' : 'grid')};
  grid-template-rows: 3;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  justify-content: left;
  align-items: center;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 30% 70% auto;
  }

  ${(props) =>
    props.state === ActionState.Disabled &&
    css`
      label {
        color: gray;
      }
    `}
`;

export const FixedLabel = styled(Label)`
  flex: 0 0 200px;
  align-self: center;
  margin: 0.5rem 1rem 0.5rem 0; /*risky change? */

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

export const FixedTextInput = styled(Input)`
  flex: 1 0 auto;
`;

export const FixedTextArea = styled.textarea`
  min-width: 19.5rem;
  height: 100px;
  margin: 0.5rem 0 1rem 0;
`;

export const FixedTextAreaComponent: React.FunctionComponent<{
  value: any;
  propertyName: string;
  onChangePcr: any;
  disabled: boolean;
}> = ({ value, propertyName, onChangePcr, disabled }) => {
  return (
    <FixedTextArea
      defaultValue={value}
      disabled={disabled === true}
      onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
        e.currentTarget.value !== value && onChangePcr(propertyName, e.currentTarget.value)
      }
    />
  );
};

export const FileBox = styled.div`
  background-color: ${(props) => props.theme.colors.regionColorLight};
  padding: 2rem;
  flex: 1 0 auto;

  > h3 {
    margin-top: 0;
  }
`;

export const FileBoxRow = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;

  > em {
    flex: 0 1 auto;
    max-width: fit-content;
  }
  > span {
    flex: 0 0 auto;
  }
`;

export const RegBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;

  @media print {
    display: none;
  }
`;

export default PcrWizard;
