import companyLogo from 'assets/images/dashboard.png';
import ErrorFallback from 'components/error-fallback/ErrorFallback';
import useCompilerPdfLogic from 'components/v2/compiler/useCompilerPdfLogic';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import AuthService from 'services/AuthService';
import { CountryModel } from 'services/EpdClient';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import {
  useAreQueriesComplete,
  useCountriesLookup,
  useDictionaryValues,
  useEpdAdditionalInformation,
  useEpdContentDeclarationProducts,
  useEpdCoverPageInformation,
  useEpdDataSources,
  useEPDDefinitionInformation,
  useEpdGeneralInformation,
  useEpdLcaCustomResults,
  useEpdLcaResults,
  useEpdOrganizationInformation,
  useEpdOrganizationInformationAddress,
  useEpdProductInformation,
  useEpdScenarios,
  useEpdSystemBoundaryData,
  useEpdVerificationReportInfo,
  useGetEpdLcaSupport,
  useGetEpdPCRInformation,
  useGetEpdQRCode,
  useGetEpdQRCodePDF,
  useGetInfrastructureAndCapitalGoods,
  useGetLCASpecification,
} from 'services/api/queries';
import { EPDDeclareContentProductModel, EPDPDFModel, Option, PCRInformationModel, QRCodeTypeEnum } from 'types/types';
import DebugOnly from 'util/dev-tools/DebugOnly';
import RenderCounter from 'util/dev-tools/RenderCounter';
import { getOptionsFromDictionary } from 'util/utils';

import Page_AdditionalInfo from './AdditionalInfoPage';
import BackCoverPage from './BackCoverPage';
import Page6_ContentDeclaration from './ContentDeclarationPage';
import CoverPage from './CoverPage';
import LCACustomResultsPage from './LCACustomResultsPage';
import Page7_9_LCAInformation from './LCAInformationPage';
import Page17_19_LCAResults from './LCAResultsPage';
import Page4_ManufacturerInformation from './ManufacturerInformationPage';
import Page3_Ownership from './OwnershipPage';
import Page11_ProcessFlowDescription from './ProcessFlowDescriptionPage';
import Page5_ProductInformation from './ProductInformationPage';
import Page2_ProgrammeInformation from './ProgrammeInformationPage';
import Page_References from './ReferencePage';
import Page12_17_Scenarios from './ScenariosPage';
import Page10_SystemBoundary from './SystemBoundaryPage';
import TechPerformancePage from './TechPerformancePage';

const EpdCompilerPdfPreviewAuth: React.FunctionComponent = () => {
  const { epdVersionId } = useCompilerPdfLogic();
  const epdGeneralInformationQ = useEpdGeneralInformation(epdVersionId);
  const verificationInfoQ = useEpdVerificationReportInfo(epdVersionId);
  const coverPageInformationQ = useEpdCoverPageInformation(epdVersionId);
  const additionalInformationQ = useEpdAdditionalInformation(epdVersionId);
  const productsInformationQ = useEpdProductInformation(epdVersionId);
  const countriesListQ = useCountriesLookup();
  const dictionaryValuesQ = useDictionaryValues();
  const organizationInformationQ = useEpdOrganizationInformation(epdVersionId);
  const organizationInformationAddressQ = useEpdOrganizationInformationAddress(epdVersionId);
  const pcrInformationQ = useGetEpdPCRInformation(epdVersionId);
  const contentDeclarationProductsQ = useEpdContentDeclarationProducts(epdVersionId);
  const infrastructureAndCapitalGoodsQ = useGetInfrastructureAndCapitalGoods(epdVersionId);
  const lcaSpecificationQ = useGetLCASpecification(epdVersionId);
  const dataSourcesQ = useEpdDataSources(epdVersionId);
  const referenceFlowUnits = getOptionsFromDictionary(dictionaryValuesQ.data, EPD_DICTIONARY_CODES.REFERENCE_FLOW_UNIT);
  const systemBoundaryDataQ = useEpdSystemBoundaryData(epdVersionId);
  const scenariosQ = useEpdScenarios(epdVersionId);
  const lcaSupportInformationQ = useGetEpdLcaSupport(epdVersionId);
  const {
    data: qrImageBlob
  } = useGetEpdQRCodePDF(epdGeneralInformationQ.data ? `epd${epdGeneralInformationQ.data.registrationNumber}` : null);


  const qrImageUrl = qrImageBlob ? URL.createObjectURL(qrImageBlob) : null;
  useEffect(() => {
    return () => {
      if (qrImageUrl) {
        URL.revokeObjectURL(qrImageUrl);
      }
    };
  }, [qrImageUrl]);

  const lcaResultsRowsQ = useEpdLcaResults(epdVersionId);
  const lcaCustomResultsQ = useEpdLcaCustomResults(epdVersionId);
  const additionalPerformanceResultsTypeLookup = getOptionsFromDictionary(
    useDictionaryValues().data,
    EPD_DICTIONARY_CODES.ADDITIONAL_LCA_RESULTS_TYPE
  );
  const epdDefinitionInformationQ = useEPDDefinitionInformation(epdVersionId);

  const allComplete = useAreQueriesComplete([
    epdGeneralInformationQ,
    additionalInformationQ,
    productsInformationQ,
    coverPageInformationQ,
    countriesListQ,
    dictionaryValuesQ,
    organizationInformationQ,
    organizationInformationAddressQ,
    infrastructureAndCapitalGoodsQ,
    lcaSupportInformationQ
  ]);

  const getOptions = (code: string) => getOptionsFromDictionary(dictionaryValuesQ.data, code);

  const epdPDFModel: EPDPDFModel | any = {
    products: productsInformationQ.data,
    organizationInformation: { ...(organizationInformationQ?.data as any), address: organizationInformationAddressQ },
    companyInformation: {
      name: 'Company name',
      logo: companyLogo,
      address: 'Company address',
      description: 'Company description',
      contact: 'Contact person information',
    },
    additionalInformation: additionalInformationQ.data as any,
    pcrInformation: pcrInformationQ.data as PCRInformationModel,
    countries: countriesListQ.data,
    infrastructureAndCapitalGoods: infrastructureAndCapitalGoodsQ.data,
    lcaSpecification: lcaSpecificationQ.data,
    contentDeclarationProducts: contentDeclarationProductsQ.data as EPDDeclareContentProductModel[],
    scenarios: scenariosQ.data,
    lcaSupportInformation: lcaSupportInformationQ.data
  };

  if (!AuthService.isLoggedin()) {
    return null;
  }

  if (!allComplete) {
    return <div>Loading data...</div>;
  }

  return (
    <>
      <DebugOnly>
        <RenderCounter componentName="PdfPreviewDocument" threshold={3} />
      </DebugOnly>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CoverPage
          generalInformation={epdGeneralInformationQ.data!}
          coverPageInformation={coverPageInformationQ.data!}
          products={productsInformationQ.data!}
          companyLogo={companyLogo}
          getOptionsFromDictionary={getOptions}
          organizationInformation={organizationInformationQ.data}
          verificationInfo={verificationInfoQ.data}
          qrImageUrl={qrImageUrl}
          epdDefinitionInformation={epdDefinitionInformationQ.data!}
        />
      </ErrorBoundary>

      <Page2_ProgrammeInformation epdVersionId={epdVersionId} e={epdPDFModel} />
      <Page3_Ownership cpcrNumber={pcrInformationQ.data?.cpcr?.registrationNumber} />
      <Page4_ManufacturerInformation
        organizationInformation={organizationInformationQ?.data}
        organizationInformationAddress={organizationInformationAddressQ?.data}
        countries={countriesListQ.data}
      />
      {productsInformationQ.data?.map((product, index) => (
        <React.Fragment key={index}>
          <Page5_ProductInformation
            productInformation={product}
            countries={countriesListQ.data as CountryModel[]}
            getOptionsFromDictionary={getOptions}
            hasDangerMaterials={contentDeclarationProductsQ.data?.some((x) => x.hasDangerMaterials)}
          />
        </React.Fragment>
      ))}

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TechPerformancePage epdVersionId={epdVersionId} />
      </ErrorBoundary>

      <Page6_ContentDeclaration
        generalInformation={epdGeneralInformationQ.data!}
        contentDeclarationProducts={contentDeclarationProductsQ.data ?? []}
        pcrInformation={pcrInformationQ.data}
        epdDefinitionInformation={epdDefinitionInformationQ.data!}
      />
      <Page7_9_LCAInformation
        epdVersionId={epdVersionId}
        e={epdPDFModel}
        dataSources={dataSourcesQ.data}
        getOptionsFromDictionary={getOptions}
        referenceFlowUnits={referenceFlowUnits}
      />
      <Page10_SystemBoundary
        epdVersionId={epdVersionId}
        generalInformation={epdGeneralInformationQ.data}
        systemBoundaryData={systemBoundaryDataQ.data}
        systemBoundaryDescriptionLookup={getOptions(EPD_DICTIONARY_CODES.SYSTEM_BOUNDARY_DESCRIPTION)}
      />
      <Page11_ProcessFlowDescription systemBoundaryData={systemBoundaryDataQ.data} />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Page12_17_Scenarios epdVersionId={epdVersionId} epd={epdPDFModel} />
      </ErrorBoundary>

      <Page17_19_LCAResults
        epdVersionId={epdVersionId}
        infrastructureAndCapitalGoods={infrastructureAndCapitalGoodsQ.data}
        lcaResults={lcaResultsRowsQ.data?.lcaResults}
      />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <LCACustomResultsPage
          lcaCustomResults={lcaCustomResultsQ.data || []}
          additionalPerformanceResultsTypeLookup={additionalPerformanceResultsTypeLookup}
          scenariosLookup={(scenariosQ.data || []).map((s) => {
            return {
              value: s.id,
              label: s.name,
            } as Option;
          })}
        />
      </ErrorBoundary>

      <Page_AdditionalInfo
        versionDescription={epdGeneralInformationQ.data?.versionDescription}
        additionalInformation={additionalInformationQ.data}
      />

      <Page_References references={additionalInformationQ?.data?.references} />
      <BackCoverPage />
    </>
  );
};

export default EpdCompilerPdfPreviewAuth;
