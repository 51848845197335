import { AdminDataSourceVersionsClient } from 'services/EpdClient';

const client = new AdminDataSourceVersionsClient(process.env.REACT_APP_API_URL);

class AdminDataSourceVersionsService {
  static getByDataSource = async (dataSourceId: string) => {
    return await client.getByDataSource(dataSourceId);
  };
}

export default AdminDataSourceVersionsService;
