import React from 'react';
import DynamicGridPdf from 'components/v2/compiler/grids/lca-results/DynamicGridPdf';
import FixedGridPdf from 'components/v2/compiler/grids/lca-results/FixedGridPdf';
import { useEpdCpcrSpecificData } from 'services/api/queries';
import { CpcrPerformanceTableModel } from 'types/types';

import { PDF_H1, PDF_H2, PDF_PAGE } from './Styles.styled';

const TechPerformancePage: React.FunctionComponent<{
  epdVersionId: string;
}> = ({ epdVersionId }) => {
  const cpcrSpecificData = useEpdCpcrSpecificData(epdVersionId!).data;
  const isThereAnythingToDisplay = !!cpcrSpecificData && cpcrSpecificData.data.length > 0;

  if (!isThereAnythingToDisplay) {
    return null;
  }

  return (
    <PDF_PAGE className="landscape">
      <PDF_H1>Technical characteristics and performance</PDF_H1>
      {cpcrSpecificData?.tables?.map((t: CpcrPerformanceTableModel) => (
        <React.Fragment key={t.tableCode}>
          {t.isFixed ? (
            <>
              <PDF_H2>{t.tableTitle}</PDF_H2>
              <FixedGridPdf
                columns={cpcrSpecificData.columns.filter((x) => x.tableCode == t.tableCode)}
                value={cpcrSpecificData.data.filter((x) => x.tableCode == t.tableCode) || []}
              />
              <br />
            </>
          ) : (
            (cpcrSpecificData.data?.filter((x) => x.tableCode == t.tableCode) || []).length > 0 && (
              <>
                <PDF_H2>{t.tableTitle}</PDF_H2>
                <DynamicGridPdf
                  columns={cpcrSpecificData.columns.filter((x) => x.tableCode == t.tableCode)}
                  value={cpcrSpecificData.data.filter((x) => x.tableCode == t.tableCode) || []}
                />
                <br />
              </>
            )
          )}
        </React.Fragment>
      ))}
    </PDF_PAGE>
  );
};

export default TechPerformancePage;
