import HelpIcon from 'components/icons/HelpIcon';
import { useField } from 'formik';
import styled from 'styled-components';
import { ErrorMessage, Input, Label } from 'styles/Styles.styled';

const CheckBox = ({ label, tooltip, handlePostChangeAction, ...props }: any) => {
  const [field, meta, helpers] = useField(props);

  const { name, value, onBlur, onChange } = field;

  const onChangeInner = (event: any) => {
    onChange(event);
    if (handlePostChangeAction) {
      handlePostChangeAction(event);
    }
  };

  return (
    <>
      <CheckboxLabel htmlFor={props.id || name} style={props.labelStyle}>
        <Input
          {...field}
          id={props.id || name}
          style={props.inputStyle || inputStyle}
          {...props}
          type={'checkbox'}
          checked={value}
          onBlur={(event) => {
            helpers.setTouched(true);
            onBlur(event);
          }}
          onChange={onChangeInner}
        />
        {label ?? null}
        {tooltip && <HelpIcon content={tooltip} />}
      </CheckboxLabel>
      {meta.touched && meta.error && !props.supressErrors ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  );
};

const inputStyle = {
  marginRight: '0.75rem',
};

const CheckboxLabel = styled(Label)`
  ${(props) => props.theme.fonts.text};
  display: flex;
  align-items: center;
`;

export default CheckBox;
