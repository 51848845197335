import ErrorFallback from 'components/error-fallback/ErrorFallback';
import DictionaryDetailsGrid from 'components/v2/admin/DictionaryDetailsGrid';
import { ActionPrimaryButton } from 'components/v2/buttons';
import { cellCheckboxEditor, cellNumberEditor, cellTextEditor } from 'components/v2/compiler/grids/GridCellEditors';
import { checkboxTemplate } from 'components/v2/compiler/grids/lca-results/LcaResultsGridColumns';
import WizardTextAreaControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextAreaControlled';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import _ from 'lodash';
import { withGreyBackground } from 'pages/v2/WithGreyBackground';
import { ColumnProps } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ListBox } from 'primereact/listbox';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ActionState } from 'services/EpdClient';
import { useCreateDictionary, useUpdateDictionary } from 'services/api/mutations';
import { useDictionaryCatalog } from 'services/api/queries';
import styled from 'styled-components';
import { Container } from 'styles/Styles.styled';
import theme from 'styles/theme/au';
import {
  ErrorTextBox,
  FieldPanel,
  FieldPanelFullWidth,
  WizardHeaderButtonBox,
  WizardHeaderGrid,
} from 'styles/v2/Styles.styled';
import { DictionaryCatalogItem } from 'types/types';

const AdminDictionaries: React.FunctionComponent = () => {
  const [selectedDictionary, setSelectedDictionary] = useState<any>(null);

  const dictionaryCatalogQuery = useDictionaryCatalog();
  const catalog = dictionaryCatalogQuery.data;

  const createMutation = useCreateDictionary();
  const updateMutation = useUpdateDictionary();

  const handleAddDictionaryCatalogItem = () => {
    createMutation.mutate({ id: undefined, name: ' New dictionary', code: _.uniqueId(), description: '' });
  };

  const onChangeDictionary = (e: DictionaryCatalogItem) => {
    updateMutation.mutate(selectedDictionary);
  };

  const DictionaryGridColumns: ColumnProps[] = [
    { field: 'id', header: 'Id', hidden: true },
    { field: 'key', header: 'Key', style: { minWidth: '2rem' }, editor: (options) => cellTextEditor(options) },
    { field: 'value', header: 'Value', style: { minWidth: '10rem' }, editor: (options) => cellTextEditor(options) },
    { field: 'code', header: 'Code', style: { minWidth: '8rem' }, editor: (options) => cellTextEditor(options) },
    {
      field: 'description',
      header: 'Description',
      style: { minWidth: '8rem' },
      editor: (options) => cellTextEditor(options),
    },
    { field: 'ord', header: 'Order', style: { minWidth: '1rem' }, editor: (options) => cellNumberEditor(options) },
    {
      field: 'isDisabled',
      header: 'Disabled',
      style: { minWidth: '4rem', textAlign: 'center' },
      editor: (options) => cellCheckboxEditor(options),
      body: (row, options) => checkboxTemplate(row, options),

      // bodyStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    },
  ];

  return (
    <Container>
      <ConfirmDialog />
      <WizardHeaderGrid style={{ marginBottom: '30px' }}>
        <TitleText>Dictionary</TitleText>
        <WizardHeaderButtonBox>
          <ActionPrimaryButton
            style={{ marginLeft: '16px' }}
            label="Add dictionary"
            onClick={handleAddDictionaryCatalogItem}
            actionState={ActionState.Enabled}
          />
        </WizardHeaderButtonBox>
      </WizardHeaderGrid>

      <WizardContentsGrid>
        <LeftPanel>
          <ListBox
            value={selectedDictionary}
            onChange={(e) => {
              setSelectedDictionary(e.value);
            }}
            options={catalog}
            optionLabel="name"
            className="w-full md:w-14rem"
            listStyle={{ maxHeight: '40vh', border: 0 }}
          />
        </LeftPanel>
        <MainViewFull>
          <FieldPanel style={{ marginTop: 0 }}>
            <WizardTextInputControlledComponent
              label="Dictionary name"
              tooltipPosition={'right'}
              required
              placeholder="Type here"
              value={selectedDictionary?.name ?? ''}
              onChange={(e: any) => setSelectedDictionary({ ...selectedDictionary, name: e.target.value })}
              onChanged={onChangeDictionary}
              name="dictionaryName"
              disabled={!selectedDictionary}
            />
            <WizardTextInputControlledComponent
              label="Abbreviation/Code"
              required
              placeholder="Type here"
              value={selectedDictionary?.code ?? ''}
              onChange={(e: any) => setSelectedDictionary({ ...selectedDictionary, code: e.target.value })}
              onChanged={onChangeDictionary}
              name="dictionaryCode"
              disabled={!selectedDictionary}
            />
            <WizardTextAreaControlledComponent
              style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
              label="Description"
              required
              placeholder="Type here"
              value={selectedDictionary?.description ?? ''}
              onChange={(e) => setSelectedDictionary({ ...selectedDictionary, description: e.target.value })}
              onChanged={onChangeDictionary}
              name="dictionaryDescription"
              disabled={!selectedDictionary}
            />
          </FieldPanel>
          <FieldPanelFullWidth>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <DictionaryDetailsGrid dictionaryCode={selectedDictionary?.code ?? ''} columns={DictionaryGridColumns} />
            </ErrorBoundary>
          </FieldPanelFullWidth>
        </MainViewFull>
      </WizardContentsGrid>
    </Container>
  );
};

const WizardContentsGrid = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 30px;
  align-items: start;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  border: 2 solid green;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 3fr 10fr;
  }
`;

const MainViewFull = styled.div`
  flex: 0 0 100%;

  ${ErrorTextBox} {
    word-break: break-word;
  }
`;

const TitleText = styled.div`
  ${(props) => props.theme.typography.headerH2}
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const LeftPanel = styled.div`
  && > .p-listbox {
    border: 0;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    color: #000000;
  }

  && .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #000000;
    font-weight: 600;
    background-color: ${(props) => theme.colors.lightGreen};
  }

  && .p-listbox .p-listbox-list .p-listbox-item:focus {
    box-shadow: none;
  }
`;

export default withGreyBackground(AdminDictionaries);
