import React from 'react';
import { useEpdScenarioModules } from 'services/api/queries';
import VersionHistorySummaryGrid, { SummaryGridRow } from '../VersionHistorySummaryGrid';

interface ScenarioModuleGridRow {
  moduleId: string;
  descriptionLabel: string;
}

interface Props {
  epdVersionId: string;
  scenarioId: string;
  modules: ScenarioModuleGridRow[];
}

const VersionHistoryScenarioModulesDescriptionsGrid: React.FC<Props> = ({ epdVersionId, scenarioId, modules }) => {
  const modulesIds = modules.map((m) => m.moduleId);
  const moduleDataQueries = useEpdScenarioModules({ epdVersionId, scenarioId, modules: modulesIds });
  if (moduleDataQueries.some((q) => q.isPending)) {
    return null;
  }

  const rows: SummaryGridRow[] = modules.map((m, i) => ({
    name: m.descriptionLabel,
    value: moduleDataQueries[i].data?.defaultScenarioModuleDescription ?? '',
  }));

  return <VersionHistorySummaryGrid rows={rows} />;
};

export default VersionHistoryScenarioModulesDescriptionsGrid;
