import React from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionEPDModel, CollectionModel } from 'services/EpdClient';
import { ButtonTertiary, Label } from 'styles/Styles.styled';
import { Col, EpdLine } from './styles/EpdLine.styled';

type TProps = {
  collection?: CollectionModel | undefined;
  collectionId?: string | undefined;
  onPress: (target: any, collectionId: string, epdId: string) => void;
};

const EpdGrid = ({ collection, collectionId, onPress }: TProps) => {
  const { t } = useTranslation();
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {collection?.epDs &&
        collection.epDs.map((epd: CollectionEPDModel, index: number) => {
          return (
            <EpdLine isOdd={index % 2 === 1}>
              <Col width="20%">
                <Label>{epd.fullNumber}</Label>
              </Col>
              <Col width="60%">
                <Label>{epd.name}</Label>
              </Col>
              <Col width="20%">
                <Label>{epd.epdStatus}</Label>
              </Col>
              <ButtonTertiary key={epd.id} onClick={(e) => onPress(e.target, collectionId || '', epd.id || '')}>
                {t('remove')}
              </ButtonTertiary>
            </EpdLine>
          );
        })}
    </div>
  );
};

export default EpdGrid;
