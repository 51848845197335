import SelectInput from 'components/form/SelectInput';
import { Formik } from 'formik';
import editIcon from 'images/icons/svg/edit-1.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyService from 'services/CompanyService';
import CountriesService from 'services/CountriesService';
import { CompanyModel, CountryModel } from 'services/EpdClient';
import styled from 'styled-components';
import { Button, ErrorText, FormMode, InfoText, StyledForm, SuccessText } from 'styles/Styles.styled';
import * as Yup from 'yup';
import { ActionDeclineButton, ActionPrimaryButton } from 'components/v2/buttons';
import AuthService from 'services/AuthService';
import { ActionState } from 'services/EpdClient';
import { ProcessStatus } from '../../constants/constants';
import { toaster } from 'components/v2/toast';
import TextInput from '../form/TextInput';
import { useGetRegexVATPattern } from './useGetRegexVATPattern';

const ManageInvoiceInfo: React.FunctionComponent<{ companyId: string }> = ({ companyId }) => {
  const [company, setCompany] = useState<CompanyModel>({} as CompanyModel);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const { t } = useTranslation();
  const allCountriesRef = useRef<CountryModel[]>([]);
  let vatValidationRegex = useGetRegexVATPattern(selectedCountry as string);
  const glnValidationRegex = /^\d{13}$/;

  useEffect(() => {
    fetchCompany();
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCompany = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const result = await CompanyService.getCompany(companyId);
      setCountries(allCountriesRef.current); //fetchCountries(result?.address?.countryId);
      setSelectedCountry(result.invoiceCountryName);
      setCompany(result);
    } catch (error) {}
    setStatus(ProcessStatus.None);
  };

  useEffect(() => {
    async function fetch() {
      if (!allCountriesRef.current || allCountriesRef.current.length == 0) {
        var countries = await CountriesService.getCountries();
        allCountriesRef.current = countries; //setAllCountries(countries);
        setCountries(countries);
      }
    }
    fetch();
  }, []);

  const handleCountryChange = (event: any, formCountryId: string | undefined, setFieldValue: any) => {
    const { value: countryId } = event.target;
    setSelectedCountry(countries.find((c) => c.id === event.target.value)?.name);
    setFieldValue('invoiceCountryId', countryId);
  };

  const updateCompany = async (values: CompanyModel) => {
    try {
      await CompanyService.updateCompany(values);
      await fetchCompany();
      setStatus(ProcessStatus.Success);
    } catch (error) {
      setStatus(ProcessStatus.Error);
    }
  };

  if (status === ProcessStatus.Fetching) {
    return <InfoText>{t('manageSettings.messages.fetching')}</InfoText>;
  }

  if (!company?.id) {
    return <ErrorText>{t('manageSettings.errors.technical')}</ErrorText>;
  }
  const sendToFortnox = async () => {
    try {
      await CompanyService.sendCompanyToFortnox(companyId);
      toaster({ severity: 'success', summary: 'Success', details: 'Company info aready updated ' });
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: '' + error });
    }
  };

  const companySchema = Yup.object<CompanyModel>({
    invoiceOrganizationName: Yup.string().nullable().required(t('messages.required')),
    invoiceAddressLine: Yup.string().nullable().required(t('messages.required')),
    invoiceAddressLineSecond: Yup.string().nullable() as Yup.StringSchema,
    invoiceAddressCity: Yup.string().nullable().required(t('messages.required')),
    invoiceAddressZipCode: Yup.string().nullable().required(t('messages.required')),
    invoiceCountryId: Yup.string().nullable().required(t('messages.required')),
    invoiceOrderingEmail: Yup.string()
      .email(t('messages.invalidEmail'))
      .nullable()
      .required(t('messages.required')) as Yup.StringSchema,
    invoiceRemindingEmail: Yup.string()
      .nullable()
      .email(t('messages.invalidEmail'))
      .required(t('messages.required')) as Yup.StringSchema,
    invoicingEmail: Yup.string()
      .nullable()
      .email(t('messages.invalidEmail'))
      .required(t('messages.required')) as Yup.StringSchema,
    vat: (Yup.string() as any)
      .when([], {
        is: () => vatValidationRegex !== null,
        then: Yup.string()
          .matches(vatValidationRegex as RegExp, 'Invalid VAT format')
          .required(t('messages.required')),
      })
      .nullable(),
    gln: (Yup.string() as any)
      .when([], {
        is: () => selectedCountry === 'Sweden',
        then: Yup.string().matches(glnValidationRegex as RegExp, 'Invalid GLN format') as Yup.StringSchema,
      })
      .nullable(),
    needDataUpdating: Yup.boolean() as any,
  });

  return (
    <>
      <h3>{t('invoiceInfo.title')}</h3>{' '}
      <InfoBox style={{ marginBottom: '0.2rem', marginLeft: '1rem' }}>
        <img src={editIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>{t('invoiceInfo.info.info1')}</div>{' '}
        <ActionPrimaryButton
          style={{ marginLeft: '16px' }}
          label="Update in fortnox"
          onClick={() => sendToFortnox()}
          actionState={AuthService.isAdmin() ? ActionState.Enabled : ActionState.Hidden}
        />
      </InfoBox>
      <Formik initialValues={company} validationSchema={companySchema} onSubmit={(values) => updateCompany(values)}>
        {({ values, setFieldValue, isSubmitting }) => (
          <StyledForm mode={FormMode.Inline}>
            <FormColumn>
              <FixedLabel style={{ paddingBottom: 0 }}>{t('invoiceAddressLine')}</FixedLabel>
              <TextInput name="invoiceAddressLine" placeholder={t('placeholder.typeHere')} />
              <FixedLabel style={{ paddingBottom: 0 }}>{t('invoiceAddressLineSecond')}</FixedLabel>
              <TextInput name="invoiceAddressLineSecond" placeholder={t('placeholder.typeHere')} />
              <FixedLabel style={{ paddingBottom: 0 }}>{t('invoiceAddressCity')}</FixedLabel>
              <TextInput name="invoiceAddressCity" placeholder={t('placeholder.typeHere')} />
              <FixedLabel style={{ paddingBottom: 0 }}>{t('invoiceAddressZipCode')}</FixedLabel>
              <TextInput name="invoiceAddressZipCode" placeholder={t('placeholder.typeHere')} />
              <SelectInput
                label={t('invoiceAddressCountry')}
                name="invoiceCountryId"
                value={values.invoiceCountryId}
                onChange={(event: any) => handleCountryChange(event, values.invoiceCountryId, setFieldValue)}
              >
                {countries.map((c) => (
                  <option value={c.id} key={c.id}>
                    {c.name}
                  </option>
                ))}
              </SelectInput>
              <FixedLabel style={{ paddingBottom: 0 }}>{t('vat')}</FixedLabel>
              <TextInput name="vat" placeholder={t('placeholder.typeHere')} />
            </FormColumn>
            <FormColumn>
              <TextInput name="needDataUpdating" hidden />
              <FixedLabel style={{ paddingBottom: 0 }}>{t('invoiceOrganizationName')}</FixedLabel>
              <TextInput name="invoiceOrganizationName" placeholder={t('placeholder.typeHere')} />

              <FixedLabel style={{ paddingBottom: 0 }}>{t('invoicingEmail')}</FixedLabel>
              <TextInput name="invoicingEmail" placeholder={t('placeholder.email')} />

              <FixedLabel style={{ paddingBottom: 0 }}>{t('invoiceOrderingEmail')}</FixedLabel>
              <TextInput name="invoiceOrderingEmail" placeholder={t('placeholder.email')} />

              <FixedLabel style={{ paddingBottom: 0 }}>{t('invoiceRemindingEmail')}</FixedLabel>
              <TextInput name="invoiceRemindingEmail" placeholder={t('placeholder.email')} />

              <FixedLabel style={{ paddingBottom: 0 }}>{t('reference')}</FixedLabel>
              <TextInput name="reference" placeholder={t('placeholder.typeHere')} />
              {selectedCountry === 'Sweden' && (
                <>
                  <FixedLabel style={{ paddingBottom: 0 }}>{t('gln')}</FixedLabel>
                  <TextInput name="gln" placeholder={t('placeholder.typeHere')} />
                </>
              )}
            </FormColumn>
            <Button disabled={isSubmitting} type="submit" style={{ flexBasis: '100%', margin: '0 1rem' }}>
              {isSubmitting ? t('messages.saving') : t('messages.saveSettings')}
            </Button>
            <div style={{ padding: '1rem' }}>
              {status === ProcessStatus.Error && <ErrorText>{t('manageSettings.errors.notSaved')}</ErrorText>}
              {status === ProcessStatus.Success && <SuccessText>{t('manageSettings.messages.saved')}</SuccessText>}
            </div>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

const FixedLabel = styled.label`
  font-style: normal;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  ${(props) => props.theme.fonts.textSmall}
  cursor: pointer;
  &:hover {
    & + * {
      display: block;
      opacity: 1;
    }
  }
`;

const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px ${(props) => props.theme.colors.orange};
  margin: 0.5rem 0;
  background-color: #f3f3f3;
  margin-bottom: 3rem;

  @media print {
    display: none;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  padding: 1rem;
  box-sizing: border-box;
`;

export default ManageInvoiceInfo;
