import ToggleButton from 'components/v2/compiler/panels/toggleable/ToggleButton';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import { StatusAttentionIcon, StatusDeclinedIcon, StatusFilledIcon, StatusPendingIcon } from 'components/v2/icons';
import { EPDCopyGroupStatus, EPDModel, VersionStateType } from 'services/EpdClient';
import styled from 'styled-components';

import HeaderActionPanel from './HeaderActionPanel';

const EpdPanelHeaderTemplate = (
  options: any,
  epd: EPDModel,
  onChangeEpd: any,
  refetchGroupData: any,
  title: string | undefined,
  setTitle: any,
  onConfirmUpdate: any,
  isReadOnly: boolean,
  isMenuEnabled: boolean,
  groupStatus: EPDCopyGroupStatus
) => {
  const className = `${options.className} justify-content-start w-full`;

  const displayStatusIcon = () => {
    switch (epd.versionState) {
      case (VersionStateType.Approved ||
        VersionStateType.LicenseeAdminVerification ||
        VersionStateType.RegisteredEditorial) &&
        epd.epdState?.isMandatoryComplete:
        return <StatusFilledIcon />;

      case VersionStateType.DeclinedVerification:
        return <StatusDeclinedIcon />;

      case VersionStateType.UnderReview:
        return <StatusPendingIcon />;

      default:
        return epd.epdState?.isMandatoryComplete ? <StatusFilledIcon /> : <StatusAttentionIcon />;
    }
  };

  return (
    <div
      className={className}
      style={{ backgroundColor: '#FFFFFF', marginTop: '1rem', paddingTop: '1rem', paddingBottom: '1rem', minHeight: '70px' }}
    >
      {displayStatusIcon()}

      <ToggleButton options={options} withMargins />

      <ElementsContainer style={{ display: 'grid', width: '100%', gridTemplateColumns: 'auto 1fr auto', columnGap: '20px' }}>
        {isReadOnly ? (
          <>
            <EpdTitle>{epd.shortIdentificationNumber}</EpdTitle>
            <EpdTitle>{title}</EpdTitle>
          </>
        ) : (
          <>
            <NumberBox>{epd.shortIdentificationNumber}</NumberBox>
            <WizardTextInputControlledComponent
              value={title}
              required
              onChange={(e: any) => setTitle(e.target.value)}
              onChanged={onChangeEpd}
              name="title"
              maxLength={150}
              placeholder="Add EPD name"
              style={{ height: '38px' }}
            />
          </>
        )}
        <HeaderActionPanel
          epd={epd}
          isReadOnly={isReadOnly}
          isMenuEnabled={isMenuEnabled}
          refetchGroupData={refetchGroupData}
          onConfirmUpdate={onConfirmUpdate}
          groupStatus={groupStatus}
        />
      </ElementsContainer>
    </div>
  );
};

const ElementsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
`;

const NumberBox = styled.div`
  border-radius: 2px;
  padding: 0.75rem 1rem;
  margin: 0;
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  ${(props) => props.theme.typography.bodyCellBody};
  white-space: nowrap;
  align-items: center;
  display: flex;
  height: 38px;
  align-self: center;
`;

const EpdTitle = styled.div`
  ${(props) => props.theme.typography.bodyBody14Semibold}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.75rem 0rem;
  margin: 0;
`;
export default EpdPanelHeaderTemplate;
