import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React from 'react';
import styled from 'styled-components';
import { CheckBoxPanel, RadioButtonStyled } from 'styles/v2/Styles.styled';

const QRCodeItem: React.FunctionComponent<{ label: string; setValue: any; value: any; isChecked: any }> = ({
  label,
  setValue,
  value,
  isChecked
}) => {
  return (
    <CheckBoxPanel style={{ height: '6vh' }}>
      <RadioButtonStyled
        inputId={'QRCode_type'}
        value={value}
        name="editMode"
        checked={isChecked}
        onChange={(e: RadioButtonChangeEvent) => setValue(e.value)}
      />
      <StyledLabel htmlFor={'QRCode_type'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
        {label}
      </StyledLabel>
    </CheckBoxPanel>
  );
};

const StyledLabel = styled.label`
  color: var(--Primary-Black, #151515);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 0.5rem;
`;

export default QRCodeItem;
