import 'flag-icons/css/flag-icons.min.css';
import 'primeflex/primeflex.css';

import { OptionNameCode } from 'types/types';

type TProps = {
  option: OptionNameCode;
};

const LicenseeThemeIcon = ({ option }: TProps) => {
  return (
    <img
      alt={option?.name}
      src={`/theme-${option?.code?.toLowerCase()}.png`}
      onError={(e: any) => {
        e.target.onerror = null;
        e.target.src = '/theme-gl.png';
      }}
      style={{ width: '18px', height: '18px' }}
      className={'mr-2'}
    />
  );
};

export default LicenseeThemeIcon;
