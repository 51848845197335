import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    fallbackLng: 'en',
    debug: false,
    backend: {
      // REACT_APP_VERSION is being injected by an Azure Pipeline.
      // Adding this unqiue number avoids caching problems
      // when users don't refetch the translations file after a new app version had been deployed.
      loadPath: '/locales/{{lng}}/translation.json?v=' + (process.env.REACT_APP_VERSION ?? process.env.NODE_ENV),
    },
    detection: {
      order: ['cookie', 'queryString', 'localStorage', 'path', 'subdomain'],
      cash: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
