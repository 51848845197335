export const customToolbarPcr = {
  options: ['inline', 'list', 'blockType', 'link', 'history'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
  },
  link: {
    inDropdown: false,
    showOpenOptionOnHover: true,
    options: ['link', 'unlink'],
    linkCallback: undefined,
  },
  history: {
    inDropdown: false,
    options: ['undo', 'redo'],
  },
};

export const customToolbarEpd = {
  options: ['inline', 'list', 'history'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    options: ['unordered'],
  },
  history: {
    inDropdown: false,
    options: ['undo', 'redo'],
  },
};

export const customToolbarCompany = {
  ...customToolbarEpd,
};
