import React from 'react';
import { YES_NO } from 'components/v2/compiler/constants';
import { INDICATOR_GROUP_CODES } from 'services/api/constants';
import DebugOnly from 'util/dev-tools/DebugOnly';
import RenderCounter from 'util/dev-tools/RenderCounter';
import LcaResultsGridAdditionalConstruction from 'components/v2/compiler/grids/lca-results/LcaResultsGridAdditionalConstruction';
import LcaResultsGridCoreConstruction from 'components/v2/compiler/grids/lca-results/LcaResultsGridCoreConstruction';
import LcaResultsGridAdditionalMandatory from 'components/v2/compiler/grids/lca-results/LcaResultsGridAdditionalMandatory';
import LcaResultsGridResourceConstruction from 'components/v2/compiler/grids/lca-results/LcaResultsGridResourceConstruction';
import LcaResultsGridWasteConstruction from 'components/v2/compiler/grids/lca-results/LcaResultsGridWasteConstruction';
import LcaResultsGridOutputFlowConstruction from 'components/v2/compiler/grids/lca-results/LcaResultsGridOutputFlowConstruction';
import { PDF_H1, PDF_H2, PDF_PAGE } from './Styles.styled';
import LcaResultsGridAdditionalVoluntary from 'components/v2/compiler/grids/lca-results/LcaResultsGridAdditionalVoluntary';

const LCAResultsPage: React.FunctionComponent<{
  epdVersionId: string;
  infrastructureAndCapitalGoods: any;
  lcaResults: any;
}> = ({ epdVersionId, infrastructureAndCapitalGoods, lcaResults }) => {
  return (
    <PDF_PAGE className="landscape">
      <DebugOnly>
        <RenderCounter componentName="LCAResultsPage" threshold={3} />
      </DebugOnly>

      <PDF_H1>Results of the environmental performance indicators</PDF_H1>
      <br />
      <PDF_H2>Mandatory environmental performance indicators according to EN 15804</PDF_H2>
      <br />
      <LcaResultsGridCoreConstruction
        epdVersionId={epdVersionId}
        rows={lcaResults?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.CORE15804)}
        isAdditionalDisclaimerVisible={infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES}
        isForPdf
      />
      <PDF_H2 style={{ pageBreakBefore: 'always' }}>Additional mandatory environmental performance indicators</PDF_H2>
      <LcaResultsGridAdditionalMandatory
        epdVersionId={epdVersionId}
        rows={lcaResults?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALMANDATORY)}
        isForPdf
      />
      <br />
      <PDF_H2>Additional voluntary environmental performance indicators according to EN 15804</PDF_H2>
      <LcaResultsGridAdditionalConstruction
        epdVersionId={epdVersionId}
        rows={lcaResults?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALVOLUNTARY15804)}
        isAdditionalDisclaimerVisible={infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES}
        isForPdf
      />
      <PDF_H2 style={{ pageBreakBefore: 'always' }}>Additional voluntary environmental performance indicators</PDF_H2>
      <LcaResultsGridAdditionalVoluntary epdVersionId={epdVersionId} isForPdf />
      <PDF_H2>Resource use indicators according to EN 15804</PDF_H2>
      <LcaResultsGridResourceConstruction
        epdVersionId={epdVersionId}
        rows={lcaResults?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.RESOURCE15804)}
        isForPdf
      />
      <PDF_H2 style={{ pageBreakBefore: 'always' }}>WASTE INDICATORS ACCORDING TO EN 15804</PDF_H2>
      <LcaResultsGridWasteConstruction
        epdVersionId={epdVersionId}
        rows={lcaResults?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.WASTE15804)}
        isForPdf
      />
      <br />
      <PDF_H2>OUTPUT FLOW INDICATORS ACCORDING TO EN 15804</PDF_H2>
      <LcaResultsGridOutputFlowConstruction
        epdVersionId={epdVersionId}
        rows={lcaResults?.filter((x: any) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.OUTPUTFLOW15804)}
        isForPdf
      />
    </PDF_PAGE>
  );
};

export default LCAResultsPage;
