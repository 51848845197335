import FilesList from 'components/v2/file-row/FilesList';
import FileUpload from 'components/v2/file-upload/FileUpload';
import { HelpBox } from 'components/v2/help-boxes';
import { Dialog } from 'primereact/dialog';
import { ChangeEvent } from 'react';
import { FileModel } from 'services/EpdClient';
import styled from 'styled-components';

type TProps = {
  files: FileModel[] | undefined;
  isOpened: boolean;
  header: string;
  onHide: any;
  onUpload: any;
  onDelete: any;
  fileLoading?: boolean;
};

const UploadGeneralCertificateDialog = ({ isOpened, onHide, files, onUpload, onDelete, fileLoading }: TProps) => {
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];
    if (!file) {
      return null;
    }

    onUpload && onUpload(propertyName, file);
  };
  return (
    <Dialog
      header="Upload documents"
      visible={isOpened}
      style={{ width: '50vw', minHeight: '70vh' }}
      onHide={onHide}
      footer={null}
    >
      <DialogContentContainer>
        <HelpBox>Please upload the process certificate in PDF format.</HelpBox>
        <FileUpload
          label="Upload document"
          name="ProcessCertificate"
          icon="pi pi-upload"
          accept=".pdf"
          validExtensions={['pdf']}
          onFileUpload={uploadFile}
        />
        <FilesList files={files} onRemoveFile={onDelete} fileLoading={fileLoading} />
      </DialogContentContainer>
    </Dialog>
  );
};

const DialogContentContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
  overflow-y: auto;
`;

export default UploadGeneralCertificateDialog;
