import { useEpdLcaCustomResultGridData } from 'services/api/queries';

import { LcaResultsGridColumnsPdf } from '../LcaResultsGridColumns';
import LcaResultsGridPdf from '../LcaResultsGridPdf';

type TProps = {
  customResultId: string;
};

const LcaCustomResultsGridPdf = ({ customResultId }: TProps) => {
  const rows = useEpdLcaCustomResultGridData(customResultId).data;
  const notes = [
    {
      note: 'Acronyms',
      description: '',
    },
  ];

  return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsPdf} notes={notes} />;
};

export default LcaCustomResultsGridPdf;
