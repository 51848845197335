import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import SortedTable from 'components/form/SortedTable';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { FixedLabel } from 'pages/pcrWizard/PcrWizard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { EPDILCDRelationModel } from 'services/EpdClient';
import AdminService from 'services/admin/AdminService';
import styled from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  ErrorMessage,
  H2,
  LabelBox,
  Message,
  MessageBox,
  ResultMessageBox,
  SearchBox,
  StyledReactSelect,
  ValueBox,
} from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';
import { Option, environdecTypeEnum, ilcdTypeEnum } from './types';

//import theme from 'styles/theme';

const IlcdEpd = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [foundItems, setFoundItems] = useState<EPDILCDRelationModel[]>();
  const [underSearch, setUnderSearch] = useState<boolean>(false);
  const [numberOfFoundItems, setNumberOfFoundItems] = useState<number | undefined>();

  const createIlcdEpdRelation = async (item: EPDILCDRelationModel) => {
    const result = await AdminService.createIlcdEpdMapping(item.epdVersionId);
    result.id && history.push(EpdLinks.ilcdEpdMapping(result.id));
  };

  const editIlcdEpdRelation = (item: EPDILCDRelationModel) => {
    item.epdVersionId && history.push(EpdLinks.ilcdEpdMappingList(item.epdId || ''));
  };

  const titles = [
    { label: t('integration.ilcdEpd.envObject'), field: 'environdecObjectName', fieldStyle: { width: 'auto' } },
    { label: t('integration.ilcdEpd.companyName'), field: 'companyName', fieldStyle: { width: 'auto' } },
    { label: t('integration.ilcdEpd.ilcdEpdVersion'), field: 'ilcdVersion', fieldStyle: { width: 'auto' } },
    { label: t('integration.ilcdEpd.published'), field: 'publishedToEcoplatform', fieldStyle: { width: 'auto' } },
  ];

  const initialValues = {
    name: '',
    environdecType: null,
    ilcdType: null,
  };

  const onSearch = (values: any) => {
    const findIlcdEpd = async () => {
      const result = await AdminService.findIlcdEpdIntegrations(
        values.name,
        values.environdecType?.value,
        values.ilcdType?.value
      );
      setFoundItems(result);
      setNumberOfFoundItems(result.length);
      setUnderSearch(true);
    };
    findIlcdEpd();
  };

  return (
    <Container>
      <EditFull>
        <H2>{t('integration.ilcdEpd.ilcdEpdMapping')}</H2>
        <Formik initialValues={initialValues} onSubmit={onSearch}>
          <AdminBox>
            <AdminSearchGridForm>
              <LabelBox>
                <FixedLabel>{t('integration.ilcdEpd.filter')}</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput
                  style={{ boxSizing: 'border-box', width: '100%' }}
                  name="name"
                  type="text"
                  placeholder=""
                />
              </ValueBox>

              {/* <LabelBox>
                <FixedLabel>{t('integration.ilcdEpd.epdObject')}</FixedLabel>
              </LabelBox>
              <ValueRole>
                <StyledReactSelect
                  name="environdecType"
                  styles={editAdminSelectStyles}
                  options={environdecTypeEnum?.map((item) => {
                    return { value: item.value, label: item.text } as Option;
                  })}
                  isClearable
                  defaultValue=""
                />
              </ValueRole> */}

              {/* <LabelBox>
                <FixedLabel>{t('integration.ilcdEpd.ilcdType')}</FixedLabel>
              </LabelBox>
              <ValueRole>
                <StyledReactSelect
                  name="ilcdType"
                  styles={editAdminSelectStyles}
                  options={ilcdTypeEnum?.map((item) => {
                    return { value: item.value, label: item.text } as Option;
                  })}
                  isClearable
                  defaultValue=""
                />
              </ValueRole> */}

              <SearchBox>
                <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                  {t('integration.ilcdEpd.search')}
                </ButtonSmall>
              </SearchBox>
            </AdminSearchGridForm>
          </AdminBox>
        </Formik>

        {underSearch && isEmpty(foundItems) && (
          <ErrorMessage style={{ marginTop: '1rem', color: 'black' }}>{t('integration.ilcdEpd.notFound')}</ErrorMessage>
        )}

        {underSearch && !foundItems && (
          <div>
            <Spinner />
          </div>
        )}

        {underSearch && !isEmpty(foundItems) && (
          <MessageBox>
            <ResultMessageBox>
              {numberOfFoundItems === 1 ? (
                <Message style={{ marginLeft: 0 }}>Found {numberOfFoundItems} match</Message>
              ) : (
                <Message>Found {numberOfFoundItems} matches</Message>
              )}
            </ResultMessageBox>
            <div
              style={{
                display: 'inline-block',
                gridColumnStart: '2',
                gridColumnEnd: '3',
                minWidth: '100%',
                padding: '1px',
                borderBottomLeftRadius: '3px',
                borderBottom: `1px solid `, //${ (props) => props.theme.colors.mediumGrey}
                alignSelf: 'end',
                justifySelf: 'end',
              }}
            ></div>
          </MessageBox>
        )}

        {!isEmpty(foundItems) && (
          <div style={{ marginTop: '-1.2rem' }}>
            <SortedTable columns={titles} collection={foundItems ?? []} onClick={editIlcdEpdRelation} />
          </div>
        )}
      </EditFull>
    </Container>
  );
};

const ValueRole = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`;

const TableButton = styled.button`
  float: right;
  cursor: pointer;
  width: 5rem;
`;

export default IlcdEpd;
