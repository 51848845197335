import styled from 'styled-components';
import { ExpandablePanel } from 'styles/v2/Styles.styled';

export const HelpBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  padding: 0.5rem 1rem;
  gap: 10px;
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  border-radius: 4px;
  ${(props) => props.theme.typography.bodyBody14Regular};
`;

export const ExpandableHelpBox = styled(ExpandablePanel)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap: 5px;
  border-radius: 4px;
  ${(props) => props.theme.typography.bodyBody14Regular};
  padding: 0;
  background-color: ${(props) => props.theme.colors.elementsFilledInput};

  && .p-panel-header {
    width: 100%;
    padding: 0 0 0 1rem;
    margin-top: 0;
    min-height: 2.25rem;
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
    color: ${(props) => props.theme.colors.primaryBlack70};
  }
  .p-panel-content {
    padding: 0 2.5rem 0.5rem 1rem;
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
    color: ${(props) => props.theme.colors.primaryBlack70};
  }
`;

export const FileHelpBox = styled(HelpBox)`
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  flex-direction: row;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyFileHelpBox = styled(FileHelpBox)`
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  justify-content: start;
`;
