import { Label, Input, ErrorMessage } from 'styles/Styles.styled';
import { useField } from 'formik';

const TextInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      {label && (
        <Label htmlFor={props.id || props.name} required={props.required}>
          {label}
        </Label>
      )}
      <Input {...field} {...props} />
      {meta.touched && meta.error && !props.supressErrors ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  );
};

export default TextInput;
