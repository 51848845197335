import ConfirmModal from 'components/modals/ConfirmModal';
import { Form, Formik } from 'formik';
import errorIcon from 'images/icons/svg/error.svg';
import keyIcon from 'images/icons/svg/key.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MembershipModel } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';
import styled from 'styled-components';
import { ErrorText, SuccessText } from 'styles/Styles.styled';

import { Role } from '../../constants/constants';
import SelectInput from '../form/SelectInput';
import { RoleOptions, TableRow } from './ManageMembers';

//// import AuthService from 'services/AuthService';

const MemberRow: React.FunctionComponent<{ member: MembershipModel }> = ({ member }) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [isDeleted, setIsDeleted] = React.useState<boolean>(false);
  //// const [isNotified, setIsNotified] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

  const changeDeleteModalState = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const deleteMembership = async (id: string) => {
    try {
      await MembershipService.deleteMembership(id);
      setIsDeleted(true);
    } catch {}
  };

  // const notify = async (id: string) => {
  //   try {
  //     await MembershipService.notify(id);
  //     setIsNotified(true);
  //   } catch {}
  // };

  const handleRoleChange = async (value: any, member: MembershipModel) => {
    try {
      setSubmitting(true);
      setSuccess(false);
      setError(false);
      await MembershipService.updateMembership(Object.assign(member, { roleId: value }));
      setSuccess(true);
      setTimeout(() => setSuccess(false), 2000);
    } catch {
      setError(true);
      setTimeout(() => setError(false), 2000);
    }
    setSubmitting(false);
  };

  if (isDeleted) {
    return null;
  }

  return (
    <Formik initialValues={{ roleId: member.roleId, roleName: member.roleName }} onSubmit={() => {}}>
      {({ isSubmitting, errors, handleChange }) => (
        <Form>
          <TableRow key={member.id} isSubmitting={submitting}>
            <div style={{ flexGrow: 1 }}>
              {member.userName}
              {member.userName && member.userName !== member.userEmail && ` (${member.userEmail})`}
              {member.isAccountManager && (
                <img
                  alt="User is account manager"
                  title={t('manageMembers.userIsAccountMng')}
                  src={keyIcon}
                  style={{ height: '15px', margin: '0 1rem' }}
                />
              )}
              {error && <ErrorText style={{ display: 'inline' }}> {t('manageMembers.errors.error')}</ErrorText>}
              {success && <SuccessText style={{ display: 'inline' }}> {t('manageMembers.messages.saved')}</SuccessText>}
            </div>
            <SelectInput
              style={{ margin: 0 }}
              disabled={member.isAccountManager || member.roleId === Role.EPDVerifier}
              name="roleId"
              onChange={(e: any) => {
                handleChange(e);
                handleRoleChange(e.target.value, member);
              }}
            >
              <RoleOptions />
            </SelectInput>
            {/* {AuthService.isAdmin() && !isNotified && (
              <ButtonSmallSecondary disabled={isSubmitting} style={{ margin: '0 1rem' }} onClick={() => notify(member.id)}>
                {t('manageMembers.notify')}
              </ButtonSmallSecondary>
            )} 
            {AuthService.isAdmin() && isNotified && (
              <SuccessText style={{ margin: '0 1rem' }}>{t('manageMembers.messages.notified')}</SuccessText>
            )}*/}
            <DeleteIcon
              style={{ visibility: member.isAccountManager ? 'hidden' : 'visible' }}
              src={errorIcon}
              onClick={changeDeleteModalState}
            />
            {isDeleteModalOpen && (
              <ConfirmModal
                description={t('confirmModal.deleteMember')}
                confirmButtonText={t('delete')}
                cancelButtonText={t('cancel')}
                onClickConfirm={() => {
                  deleteMembership(member.id);
                  changeDeleteModalState();
                }}
                onClickCancel={() => changeDeleteModalState()}
              />
            )}
          </TableRow>
        </Form>
      )}
    </Formik>
  );
};

const DeleteIcon = styled.img`
  cursor: pointer;
  height: 15px;
  margin: 0 1rem;
`;

export default MemberRow;
