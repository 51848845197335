import WizardHTMLEditor from 'components/v2/epd-wizard-inputs/WizardHTMLEditor';
import WizardSelectComponent from 'components/v2/epd-wizard-inputs/WizardSelect';
import { HelpBox } from 'components/v2/help-boxes';
import { ImagesUpload } from 'components/v2/images-upload';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { useEffect, useState } from 'react';
import CpcService from 'services/CpcService';
import { EPDModel, GeographicalScopeModel } from 'services/EpdClient';
import EPDService from 'services/EpdService';
import EpdService from 'services/EpdService';
import { HyperLink } from 'styles/Styles.styled';
import { FieldContainer, FieldPanelInput } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';

import EditDialog from './EditDialog';

type TProps = {
  isOpened: boolean;
  onHide: any;
  epd?: EPDModel;
  onChangeEpd: any;
};

const EditProductInformationDialog = ({ isOpened, onHide, epd, onChangeEpd }: TProps) => {
  const [lookupUNCPCCodes, setLookupUNCPCCodes] = useState<Option[]>([]);
  const [marketplaceLookup, setMarketplaceLookup] = useState<Option[]>([]);

  const uploadFile = async (propertyName: string, file: File) => {
    if (!file || !epd) {
      return null;
    }
    onChangeEpd && onChangeEpd(propertyName, file);
  };

  useEffect(() => {
    const fetchUNCPCCodes = async () => {
      const result = await CpcService.getCpcs(undefined, undefined, undefined);
      setLookupUNCPCCodes(
        result.map((x) => {
          return { label: x.description, value: x.id } as Option;
        })
      );
    };

    const fetchMarketplaceLookup = async () => {
      const result = await EpdService.getLocations();
      setMarketplaceLookup(
        result.map((x: GeographicalScopeModel) => {
          return { value: x.id, label: x.name } as Option;
        })
      );
    };

    fetchUNCPCCodes();
    fetchMarketplaceLookup();
  }, []);

  return (
    <EditDialog header="Product information" isOpened={isOpened} onHide={onHide} maximizable>
      <HelpBox>
        Please fill-in the required information below. The filled data will be auto saved and available in the table.
      </HelpBox>
      <FieldPanelInput>
        <FieldContainer>
          <WizardHTMLEditor
            name="text"
            value={epd?.text}
            label="Product description"
            tooltip="For the presentation at www.environdec.com."
            required
            onChanged={onChangeEpd}
            stripPastedStyles={true}
          />
        </FieldContainer>
        <FieldContainer>
          <WizardHTMLEditor
            name="products"
            value={epd?.products}
            label="Products"
            tooltip="List of products included in the EPD."
            tooltipPosition="right"
            onChanged={onChangeEpd}
            stripPastedStyles={true}
          />
        </FieldContainer>
        <FieldContainer>
          <WizardSelectComponent
            label="UN CPC Code"
            tooltip="United Nations Central Product Classification code (based on v.2.1)."
            name="uncpcCode"
            options={lookupUNCPCCodes}
            value={{ value: epd?.uncpcCode?.id, label: epd?.uncpcCode?.description }}
            onChanged={onChangeEpd}
          />
        </FieldContainer>
        <FieldContainer>
          <WizardSelectComponent
            label="Geographical scope"
            tooltip="Region, for which the data set is representative / relevant. ISO 31666 country code or regional code."
            name="GeographicalScopes"
            options={marketplaceLookup}
            value={
              epd?.geographicalScopes &&
              epd?.geographicalScopes.map((x) => {
                return { label: x.name, value: x.id } as Option;
              })
            }
            onChanged={onChangeEpd}
            isMulti
            required
          />
        </FieldContainer>
        <FieldContainer>
          <SmartLabel
            label={'Product images'}
            required
            hasValue={epd?.productImages && epd?.productImages.length > 0}
            tooltip="Picture of the declared product or service."
          />
          <ImagesUpload
            name={'productImages'}
            images={epd?.productImages}
            onUpload={uploadFile}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onRemove={() => {}}
          />
          <FieldContainer>
            <HelpBox>
              <span>
                Use drag and drop to reorder the images.
                <br />
                First places image will be used as EPD cover in EPD library on{' '}
                <HyperLink target="_blank" rel="noopener noreferrer" href="environdec.com">
                  environdec.com
                </HyperLink>
              </span>
            </HelpBox>
          </FieldContainer>
        </FieldContainer>
      </FieldPanelInput>
    </EditDialog>
  );
};

export default EditProductInformationDialog;
