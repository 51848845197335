import HTMLEditor from 'components/controls/WysiwygEditor';
import { collectionSelectStyles } from 'components/form/CollectionSelect';
import UploadFile from 'components/form/UploadFile';
import { customToolbarEpd } from 'components/form/customToolbar';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import removeIcon from 'images/icons/svg/error.svg';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import CollectionService from 'services/CollectionService';
import CompanyService from 'services/CompanyService';
import { AccountModel, CollectionModel, EPDModel, FileModel, FileParameter } from 'services/EpdClient';
import EpdService from 'services/EpdService';
import FileService from 'services/FileService';
import styled from 'styled-components';
import { ButtonSmall, Container, MainView } from 'styles/Styles.styled';
import { InfoBox } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';

import HelpIcon from '../../components/icons/HelpIcon';
import MandatoryIcon from '../../components/icons/MandatoryIcon';
import ConfirmModal from '../../components/modals/ConfirmModal';
import editIcon from '../../images/icons/svg/edit-1.svg';
import EpdGrid from './components/EpdGrid';

const ManageCollection: React.FunctionComponent = () => {
  const [collection, setCollection] = React.useState<CollectionModel | undefined>(undefined);
  const [account, setAccount] = useState<AccountModel>({});
  const [selectedEpd, setSelectedEpd] = useState<Option | undefined | null>(null);
  const { changeCompany } = React.useContext(CompanyContext);
  const [allEpds, setAllEpds] = useState<EPDModel[]>([]);
  const history = useHistory();
  const { t } = useTranslation();
  const visibleEditorStyle = { border: '1px solid grey', padding: '0 1rem', fontSize: '14px', height: 'fit-content' };

  const { companyId, collectionId } = useParams<{
    companyId: string;
    collectionId: string;
  }>();

  const onChangeCollectionProperty = (
    collectionId?: string,
    propertyName?: string | undefined,
    newValue?: any | undefined
  ) => {
    return CollectionService.updateProperty(collectionId, propertyName, newValue);
  };

  const handleRemoveEpd = async (target: any, collectionId: string, epdId: string) => {
    target.disabled = true;
    await CollectionService.removeCollectionEpd(collectionId, epdId);
    await fetchEpds();
    return fetchCollection();
  };

  const onRemoveFile = async (fileId: string) => {
    await CollectionService.removeCollectionFile(collectionId, fileId);
    return fetchCollection();
  };

  const onChangeCollection = async (propertyName: string, val: FileParameter) => {
    if (!collectionId) {
      return;
    }
    await CollectionService.addCollectionFile(collectionId, propertyName, val);
  };

  const uploadFile = async (event: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const file = event?.target?.files?.[0];

    if (!file || !collectionId) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const fileBlob = reader.result as ArrayBuffer;
      if (fileBlob) {
        const blob = new Blob([new Uint8Array(fileBlob)], { type: file.type });
        await onChangeCollection(propertyName, {
          fileName: file.name,
          data: blob,
        });
        await fetchCollection();
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const addEpd = async (e: any) => {
    await CollectionService.addCollectionEPD(collectionId, e.value);
    await fetchEpds();
    await fetchCollection();
    return setSelectedEpd(null);
  };

  const fetchCollection = async () => {
    try {
      const result: any = await CollectionService.getCollection(collectionId);
      setCollection(result);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEpds = async () => {
    if (!account?.id) {
      setAllEpds([]);
      return;
    }

    try {
      const result = await EpdService.getEpds(account.id, companyId, undefined, collectionId);
      setAllEpds(result);
    } catch {}
  };

  const fetchAccount = async () => {
    if (companyId) {
      try {
        const res = await CompanyService.getCompany(companyId);
        changeCompany?.(companyId);
        res?.accounts && setAccount(res?.accounts[0]);
      } catch {}
    } else {
    }
  };

  const isEmptyValue = (v: any) => {
    return v === null || v === undefined || v === '' || v === '<p></p>\n' || (Array.isArray(v) && v.length === 0);
  };

  const handleInputChange = (propertyName: string, newValue?: any | undefined) => {
    setCollection({ ...collection, [propertyName]: newValue });
  };

  useEffect(() => {
    fetchEpds();
  }, [account?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchAccount();
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCollection();
  }, [collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!collection) {
    return <div>${t('messages.loading')}</div>;
  }

  return (
    <Container>
      <MainView>
        <h3 style={{ wordBreak: 'break-all' }}>{`${t('manageCollections.epdCollection')}: ${
          collection?.name || t('newCollection')
        }`}</h3>
        <InfoBox style={{ margin: '1rem 0 1rem 0', padding: '1rem' }}>
          <img src={editIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
          <div dangerouslySetInnerHTML={{ __html: t('manageCollections.info') }} />
        </InfoBox>
        <CollectionForm>
          <Column>
            <FixedRow>
              <FixedLabel>
                {t('manageCollections.titleEpdCollection')}
                <HelpIcon content={t('manageCollections.tooltips.title')} />
                {isEmptyValue(collection?.name) && <MandatoryIcon />}
              </FixedLabel>
              <input
                style={{
                  border: '1px solid grey',
                  padding: '1rem',
                  fontSize: '14px',
                  height: 'fit-content',
                  overflow: 'auto',
                  boxSizing: 'border-box',
                  width: '100%',
                }}
                maxLength={300}
                defaultValue={collection?.name}
                value={collection?.name}
                onChange={(e) => handleInputChange('name', e.currentTarget.value)}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeCollectionProperty(collection?.id!, 'name', e.currentTarget.value)
                }
              />
            </FixedRow>
          </Column>
          <Column>
            <FixedRow>
              <FixedLabel>
                {t('manageCollections.epdCollectionProfile')}
                <HelpIcon content={t('manageCollections.tooltips.profile')} />
                {isEmptyValue(collection?.description) && <MandatoryIcon />}
              </FixedLabel>
              <HTMLEditor
                editorStyle={visibleEditorStyle}
                value={collection?.description}
                toolbarHidden={false}
                toolbar={customToolbarEpd}
                onEdit={(newValue: string) => {
                  onChangeCollectionProperty(collection.id!, 'description', newValue);
                  handleInputChange('description', newValue);
                }}
                disabled={false}
                stripPastedStyles={true}
              ></HTMLEditor>
            </FixedRow>
          </Column>
          <Column>
            <FixedRow>
              <FixedLabel>
                {t('manageCollections.image')}
                <HelpIcon content={t('manageCollections.tooltips.image')} />
                {isEmptyValue(collection?.image) && <MandatoryIcon />}
              </FixedLabel>
              <UploadBox>
                <ImagesUpload
                  name={t('manageCollections.image')}
                  images={collection?.image ? [collection?.image] : []}
                  onUpload={(e: any) => uploadFile(e, 'ProductImage')}
                  onRemoveFile={onRemoveFile}
                />
              </UploadBox>
            </FixedRow>
          </Column>
          <Column>
            <FixedRow>
              <FixedLabel style={{ position: 'relative' }}>
                {t('manageCollections.complementary')}
                <HelpIcon content={t('manageCollections.tooltips.documents')} />
              </FixedLabel>
              <div style={{ width: '100%' }}>
                <UploadFile
                  accept=".pdf"
                  title={t('manageCollections.upload.documents')}
                  uploadFile={(e: any) => uploadFile(e, 'Document')}
                  disabled={false}
                  documents={collection?.documents}
                  onRemoveFile={onRemoveFile}
                  field="Document"
                />
              </div>
            </FixedRow>
          </Column>
          <Column>
            <FixedRow>
              <FixedLabel style={{ marginTop: 0 }}>{t('manageCollections.epdsInCollection')}</FixedLabel>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                {collection.epDs && (
                  <>
                    <AddEpdBox>{t('manageCollections.addEpd')}</AddEpdBox>
                    <Select
                      value={selectedEpd}
                      placeholder={t('placeholder.defaultSelect')}
                      styles={collectionSelectStyles}
                      onChange={(e) => addEpd(e)}
                      options={allEpds.map((epd: EPDModel) => {
                        return {
                          value: epd.id,
                          label: epd.name,
                        } as Option;
                      })}
                    />
                  </>
                )}
                <EpdGrid collection={collection} collectionId={collectionId} onPress={handleRemoveEpd} />
              </div>
            </FixedRow>
          </Column>

          <Column>
            <FixedRow style={{ gridTemplateColumns: '100%' }}>
              <BackButton style={{ justifySelf: 'end' }} onClick={history.goBack}>
                {t('close')}
              </BackButton>
            </FixedRow>
          </Column>
        </CollectionForm>
      </MainView>
    </Container>
  );
};

const CollectionForm = styled.div`
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  box-sizing: border-box;
  margin: 1rem 0;
`;

export const FixedRow = styled.div`
  grid-column-gap: 10px;
  display: grid;
  grid-template-columns: 100%;

  ${(props) => props.theme.media.tablet} {
    flex-wrap: nowrap;
    margin: 1rem 0;
  }

  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 33% auto;
  }
`;

export const FixedLabel = styled.label`
  font-weight: normal;
  text-transform: uppercase;
  ${(props) => props.theme.fonts.textSmall}
  flex: 0 0 200px;
  align-self: auto;
  margin: 0.5rem 1rem 0.5rem 0; /*risky change? */

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

const UploadBox = styled.div`
  display: flex;
`;

const UploadButton = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

const BackButton = styled.div`
  background-color: ${(props) => props.theme.colors.orange};
  color: white;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  margin: 0;
  :hover {
    background-color: ${(props) => props.theme.colors.lightOrange};
  }
`;

const AddEpdBox = styled.div<any>`
  margin-bottom: 1rem;
`;

interface ImageUploadProps {
  name: string;
  images?: FileModel[];
  onUpload: (e: any) => any;
  onRemoveFile: (e: any) => any;
}

const ImagesUpload: React.FunctionComponent<ImageUploadProps> = ({ name, images, onUpload, onRemoveFile }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const closeDeleteModal = () => {
    setIsModalOpen(false);
  };
  const deleteImage = (img: any) => {
    setIsModalOpen(true);
    setSelectedImage(img);
  };
  const getImageBox = (img: any) => {
    return (
      <ImageBox key={img.id}>
        <ImageBoxImage>{img.id && <img alt={`Custom ${name}`} src={FileService.getImageUrl(img.id)} />}</ImageBoxImage>
        <ImageRemove>
          <img src={removeIcon} style={{ height: '1rem' }} alt="Remove" onClick={() => deleteImage(img)} />
        </ImageRemove>
      </ImageBox>
    );
  };

  return (
    <div>
      <UploadButton>
        <ButtonSmall>
          {t('upload')} {name}
        </ButtonSmall>
        <input type="file" name={name} onChange={onUpload} />
      </UploadButton>
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>{images && images.map(getImageBox)}</div>
      {isModalOpen && (
        <ConfirmModal
          description={t('confirmModal.deleteImage')}
          confirmButtonText={t('delete')}
          cancelButtonText={t('cancel')}
          onClickConfirm={() => {
            onRemoveFile(selectedImage?.id);
            closeDeleteModal();
          }}
          onClickCancel={() => closeDeleteModal()}
        />
      )}
    </div>
  );
};

const ImageRemove = styled.div`
  cursor: pointer;
  margin: 0.5rem;
  position: absolute;
  right: 0.5rem;
  right: 0.5rem;
  top: 0;
`;

const ImageBox = styled.div`
  position: relative;
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 1rem;
  margin-right: 20px;
  max-width: 120px;
  background-color: ${(props) => props.theme.colors.regionColorLight};
`;

const ImageBoxImage = styled.div`
  flex: 0 0 auto;
  align-self: center;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80%;
    object-fit: scale-down;
    height: 100px;
  }
`;

export default ManageCollection;
