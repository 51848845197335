import React, { ReactNode } from 'react';
import { EPDCopyGroupStatus, EPDVersionState, UserRoleNames, VerificationType } from 'services/EpdClient';
import { ErrorBox } from 'styles/Styles.styled';

import AwaitingProductInformation from '../step-placeholder/AwaitingProductInformation';
import NoCopies from '../step-placeholder/NoCopies';
import NoPermissions from '../step-placeholder/NoPermissions';
import OwnerWillInitiate from '../step-placeholder/OwnerWillInitiate';
import VerifierWillCheck from '../step-placeholder/VerifierWillCheck';

// This objext is used as multidimention array/matrix to get statuses by current state
// returns object {component, isReadOnly, canUpload}
// which is result of function = f(VerificationType, UserRole, GroupStatus, step number /*,allApprovedFlag*/)

type AccessMatrixValue = {
  component: string | ReactNode;
  isReadOnly: boolean;
  canUpload: boolean;
  message?: string | ReactNode;
};

export type AccessMatrixByRoleType = {
  [key in EPDCopyGroupStatus]: {
    [stepNumber: number]: AccessMatrixValue | null;
  };
};

export type AccessMatrixType = {
  [key in VerificationType]: {
    [role in UserRoleNames]: AccessMatrixByRoleType | null;
  };
};

const verificationOwnerPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: {
      component: 'default',
      isReadOnly: false,
      canUpload: true,
      message: 'EPD review has been completed. No actions needed.',
    },
    2: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    3: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: <VerifierWillCheck />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForVerification]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: <VerifierWillCheck />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Verified]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
};

const verificationPractitionerPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: <VerifierWillCheck />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForVerification]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: <VerifierWillCheck />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Verified]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
};

const verificationVerifierPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: <AwaitingProductInformation />, isReadOnly: true, canUpload: false },
    3: { component: <OwnerWillInitiate />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForVerification]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Verified]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
};

const verificationLicenseeAdminPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: 'default', isReadOnly: false, canUpload: true },
    2: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    3: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: <VerifierWillCheck />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForVerification]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: true },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Verified]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
};

const verificationAdminPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: 'default', isReadOnly: false, canUpload: true },
    2: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    3: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: <VerifierWillCheck />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForVerification]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: true },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Verified]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
};

const certificationOwnerPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: 'default', isReadOnly: false, canUpload: true },
    2: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    3: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForVerification]: {},
  [EPDCopyGroupStatus.Verified]: {},
};

const certificationPractitionerPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: true, canUpload: false },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForVerification]: {},
  [EPDCopyGroupStatus.Verified]: {},
};

const unknownRolePermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.SentForVerification]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Verified]: {
    1: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    2: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    3: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
    4: { component: <NoPermissions />, isReadOnly: true, canUpload: false },
  },
};

const certificationLicenseeAdminPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: 'default', isReadOnly: false, canUpload: true },
    2: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    3: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForVerification]: {},
  [EPDCopyGroupStatus.Verified]: {},
};

const certificationAdminPermissions: AccessMatrixByRoleType = {
  [EPDCopyGroupStatus.Draft]: {
    1: { component: 'default', isReadOnly: false, canUpload: true },
    2: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    3: { component: <NoCopies />, isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: true, canUpload: false },
  },
  [EPDCopyGroupStatus.Reserved]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.ApprovedByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedPublication]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: false, canUpload: true },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin]: {
    1: { component: 'default', isReadOnly: true, canUpload: false },
    2: { component: 'default', isReadOnly: false, canUpload: true },
    3: { component: 'default', isReadOnly: true, canUpload: false },
    4: { component: 'default', isReadOnly: false, canUpload: true },
  },
  [EPDCopyGroupStatus.SentForVerification]: {},
  [EPDCopyGroupStatus.Verified]: {},
};

export const AccessMatrix: AccessMatrixType = {
  [VerificationType.EPDVerification]: {
    Owner: verificationOwnerPermissions,
    Practitioner: verificationPractitionerPermissions,
    Verifier: verificationVerifierPermissions,
    Admin: verificationAdminPermissions,
    LicenseeAdmin: verificationLicenseeAdminPermissions,
    Unknown: unknownRolePermissions,
  },
  [VerificationType.EPDProcessCertification]: {
    Owner: certificationOwnerPermissions,
    Practitioner: certificationPractitionerPermissions,
    Admin: certificationAdminPermissions,
    LicenseeAdmin: certificationLicenseeAdminPermissions,
    Verifier: null,
    Unknown: unknownRolePermissions,
  },
  [VerificationType.EPDPreverifiedTool]: {
    Owner: certificationOwnerPermissions,
    Practitioner: certificationPractitionerPermissions,
    Admin: certificationAdminPermissions,
    LicenseeAdmin: certificationLicenseeAdminPermissions,
    Verifier: null,
    Unknown: unknownRolePermissions,
  },
};

export const DefaultMatrixValue: AccessMatrixValue = {
  component: (
    <ErrorBox>
      Oops! Something went wrong. The contents couldn't be displayed.
      <br />
      This could be due to wrong status of the group for the process certification flow, or user has incorrect role.
      <br /> Please contact system administrator for more details.
    </ErrorBox>
  ),
  isReadOnly: true,
  canUpload: false,
  message: 'Something went worong.',
};
