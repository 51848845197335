import { PropsWithChildren } from 'react';
import { DialogStyled, DialogContentContainer } from './styled';

type TProps = PropsWithChildren<{
  isOpened: boolean;
  header: string;
  onHide: any;
  maximizable?: boolean;
}>;

const PcrSelectorFilesDialog = ({ isOpened, header, onHide, children, maximizable, ...props }: TProps) => {
  return (
    <DialogStyled
      header={header}
      visible={isOpened}
      style={{ width: '40vw' }}
      onHide={onHide}
      footer={null}
      maximizable={maximizable}
      {...props}
    >
      <DialogContentContainer>{children}</DialogContentContainer>
    </DialogStyled>
  );
};

export default PcrSelectorFilesDialog;
