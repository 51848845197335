import useCompilerLogic from 'components/v2/compiler/useCompilerLogic';
import EpdCompiler from 'pages/v2/compiler/EpdCompiler';
import React from 'react';
import { EPDGeneralInformationModel } from 'types/types';

import EpdWizard from './EpdWizard';

const WizardSelector: React.FunctionComponent = () => {
  const { epdFormatQuery } = useCompilerLogic();

  const epdFormat = epdFormatQuery?.data ?? 'wizard';

  return <>{epdFormat === 'compiler' ? <EpdCompiler /> : <EpdWizard />}</>;
};

export default WizardSelector;
