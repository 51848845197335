import styled from 'styled-components';

export const InfoErrorContainer = ({ infoText }: any) => {
  return (
    <StyledValidateErrorsContainer>
      <StyledValidateErrorsMessageContainer>{infoText}</StyledValidateErrorsMessageContainer>
    </StyledValidateErrorsContainer>
  );
};

const StyledValidateErrorsContainer = styled.div`
  width: 100%;
  background: #f6e5e4;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledValidateErrorsMessageContainer = styled.div`
  color: #cc302b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400px;
  line-height: 20px;
`;
