import { ProcessStatus } from 'constants/constants';
import { DashboardIcon } from 'images/icons/png/v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import CollectionService from 'services/CollectionService';
import styled from 'styled-components';
import { ButtonSmall, H2 } from 'styles/Styles.styled';

//import theme from 'styles/theme';

const EmptyLicenseeList = () => {
  const { t } = useTranslation();

  return (
    <WelcomeBox>
      <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
        <IconBox>
          <img src={DashboardIcon} alt="dashboard icon" />
        </IconBox>
        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
          <H2>{t('manageLicensees.noLicensees')}</H2>
          <div>Ones you add Licensee it will be displayed here</div>
        </div>
      </div>
    </WelcomeBox>
  );
};

const WelcomeBox = styled.div`
  width: 100%;
  height: 400px;
  margin: auto;
  background-color: #ffffff;
  /* background-color: ${(props) => props.theme.colors.primaryWhite}; */
  border-radius: 4px;
  //text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div {
    flex: 1;
  }
`;

const IconBox = styled.div`
  width: 158px;
  height: 158px;
  margin: auto;
  align-self: flex-start;
`;

export default EmptyLicenseeList;
