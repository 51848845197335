import React from 'react';

export const ExpandArrow = ({ isOpen }: { isOpen: boolean }) => (
  <svg
    viewBox="0 0 320 512"
    width="20"
    height="20"
    style={{
      transition: 'transform 300ms ease-out',
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }}
  >
    <path
      fill="currentColor"
      d="M31.3 192h257.3a20 20 0 0114.1 34.1L174.1 354.8a20 20 0 01-28.3 0L17.2 226.1A20 20 0 0131.3 192z"
    />
  </svg>
);
