import { customToolbarEpd } from 'components/form/customToolbar';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { isNullOrEmptyHtml } from 'pages/wizard/Validation';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTheme } from 'styled-components';
import { Theme } from 'styles/theme';

import SmartLabel from '../labels/SmartLabel';
import useIsReadOnlyMode from '../compiler/hooks/useIsReadOnlyMode';

const WizardHTMLEditor = ({ name, label, tooltip, tooltipPosition, labelStyle, value, error, onChanged, disabled, ...props }: any) => {
  const theme = useTheme() as Theme;
  const visibleEditorStyle = {
    border: `1px solid ${theme.colors.elementsInputBorder}`,
    padding: '0 1rem',
    fontSize: '14px',
    height: 'fit-content',
    minHeight: '100px',
  };

  const [editorState, setEditorState] = useState<EditorState | null>(
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value ?? '') as any))
  );

  const newValue = draftToHtml(convertToRaw(editorState!.getCurrentContent()));
  const isReadOnly = useIsReadOnlyMode() || disabled;

  return (
    <div>
      {label && (
        <SmartLabel
          label={label}
          required={props.required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!value && !isNullOrEmptyHtml(newValue)}
          error={error}
        />
      )}
      <Editor
        toolbarHidden={false}
        toolbar={customToolbarEpd}
        toolbarClassName={'p-test'}
        editorStyle={visibleEditorStyle}
        editorState={editorState}
        onEditorStateChange={(newEditorState) => setEditorState(newEditorState)}
        onBlur={() => {
          onChanged(name, newValue);
        }}
        readOnly={isReadOnly}
        {...props}
      />
    </div>
  );
};

export default WizardHTMLEditor;
