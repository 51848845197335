import { StepStatus } from 'pages/wizard/types';
import styled from 'styled-components';

type TProps = {
  stepNumber: number;
  status: StepStatus;
  isSelected: boolean;
};

const StepBadge = ({ stepNumber, status, isSelected }: TProps) => {
  return status === StepStatus.Done ? (
    <CheckIcon isSelected={isSelected} className="pi pi-check" />
  ) : (
    <NumberIcon isSelected={isSelected}>{stepNumber}</NumberIcon>
  );
};

const IconBox = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  aspect-ratio: 1;
  line-height: 20px; /* Adjust this value to vertically center the text */
  text-align: center;
`;

const CheckIcon = styled(IconBox)<{ isSelected: boolean }>`
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primaryGreen : props.theme.colors.secondaryLightGreen30};
  color: ${(props) => (props.isSelected ? props.theme.colors.primaryWhite : props.theme.colors.primaryGreen)};
`;

const NumberIcon = styled(IconBox)<{ isSelected: boolean }>`
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primaryOrange : props.theme.colors.elementsButtonDisabled};
  color: ${(props) => props.theme.colors.primaryWhite};
`;

export default StepBadge;
