import React from 'react';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySection from '../VersionHistorySection';
import styled from 'styled-components';

const VersionHistoryReferencesSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();
  if (!data || !data.additionalInformation || isFalsyOrEmptyHtmlRichText(data.additionalInformation.references)) {
    return null;
  }

  return (
    <VersionHistorySection title="References">
      <RichTextFrame dangerouslySetInnerHTML={{ __html: data.additionalInformation.references }} />
    </VersionHistorySection>
  );
};

export default VersionHistoryReferencesSection;

function isFalsyOrEmptyHtmlRichText(text: string | null | undefined): text is null | undefined {
  if (typeof text !== 'string') {
    return true;
  }
  if (text.length === 0) {
    return true;
  }
  const trimmedText = text.trim();
  return trimmedText.length === 0 || trimmedText === '<p></p>';
}

const RichTextFrame = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.elementsInputBorder};
  border-radius: 4px;
  padding-left: 21px;
  padding-right: 21px;
`;
