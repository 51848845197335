import editIcon from 'images/icons/svg/edit-1.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import CollectionService from 'services/CollectionService';
import CompanyService from 'services/CompanyService';
import { CollectionModel, CompanyModel } from 'services/EpdClient';
import styled, { useTheme } from 'styled-components';
import { ButtonSmall, ButtonSmallSecondary, ErrorText, InfoText } from 'styles/Styles.styled';
import { Theme } from 'styles/theme';
import { InfoBox } from 'styles/v2/Styles.styled';

import { ProcessStatus } from '../../constants/constants';

const ManageCollections: React.FunctionComponent<{ companyId: string }> = ({ companyId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [company, setCompany] = React.useState<CompanyModel>({} as CompanyModel);
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [collections, setCollections] = React.useState<CollectionModel[]>([]);
  const [isEditOpened, setIsEditOpened] = React.useState<string | undefined>(undefined);
  const theme = useTheme() as Theme;

  const deleteCollection = async (collectionId: string) => {
    try {
      await CollectionService.deleteCollection(collectionId);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const createNewCollection = async () => {
    try {
      const result = await CollectionService.AddNewCollection(companyId);
      openCollection(companyId, result.id as string);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCompany = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const result = await CompanyService.getCompany(companyId);
      await CollectionService.getCollections();
      setCompany(result);
    } catch (error) {}
    setStatus(ProcessStatus.None);
  };

  const fetchCollections = async () => {
    try {
      const result = await CollectionService.getCollections(companyId);
      setCollections(result);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchCompany();
    fetchCollections();
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (status === ProcessStatus.Fetching) {
    return <InfoText>{t('manageSettings.messages.fetching')}</InfoText>;
  }

  if (!company?.id) {
    return <ErrorText>{t('manageSettings.errors.technical')}</ErrorText>;
  }

  const openCollection = async (companyId: string, collectionId: string) => {
    try {
      setStatus(ProcessStatus.Fetching);
      const route = EpdLinks.manageCollection(companyId, collectionId);
      history.push(route);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  return (
    <>
      <h3>{t('manageCollections.yourCollections')}</h3>
      <InfoBox style={{ margin: '1rem 0 1rem 0', padding: '1rem' }}>
        <img src={editIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
        <div dangerouslySetInnerHTML={{ __html: t('epdWizard.wizardStep2.info.collections') }} />
      </InfoBox>

      <OpenCollection>
        <ButtonSmall style={{ paddingLeft: '1.4rem', paddingRight: '1.4rem' }} onClick={() => createNewCollection()}>
          {t('createCollection')}
        </ButtonSmall>
      </OpenCollection>

      {collections.map((collection: any) => (
        <CollectionRow key={collection.id}>
          <div style={{ flexGrow: 1, wordBreak: 'break-all' }}>{collection?.name}</div>
          <ButtonSmallSecondary
            disabled={false}
            style={{ margin: '0 1rem' }}
            onClick={() => openCollection(companyId, collection?.id)}
          >
            {t('edit')}
          </ButtonSmallSecondary>
          <ButtonSmallSecondary
            disabled={false}
            style={{ backgroundColor: theme.colors.lightOrange }}
            onClick={() => deleteCollection(collection?.id)}
          >
            {t('delete')}
          </ButtonSmallSecondary>
        </CollectionRow>
      ))}
    </>
  );
};

const CollectionRow = styled.div`
  padding: 1rem 0 1rem 1rem;
  margin: 1px 0;
  border-bottom: 1px solid rgb(165, 204, 178);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OpenCollection = styled.div`
  padding: 1rem 0;
  margin: 1px 0;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(165, 204, 178);
`;

export default ManageCollections;
