import { ActionDeclineButton, ActionPrimaryButton } from 'components/v2/buttons';
import { HelpBox } from 'components/v2/help-boxes';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { EPDModel } from 'services/EpdClient';
import EpdStateService from 'services/EpdStateService';
import styled from 'styled-components';

import EditDialog from './EditDialog';

type TProps = {
  epd?: EPDModel;
  isOpened: boolean;
  header: string;
  onHide: any;
  refreshEPD: any;
};

const DeclineVerificationDialog = ({ isOpened, onHide, epd, refreshEPD }: TProps) => {
  const [value, setValue] = React.useState<string>('');
  return (
    <EditDialog header="Decline EPD verification" isOpened={isOpened} onHide={onHide}>
      <HelpBox>
        Please describe the reason to decline verification of the EPD document
        <b>
          {epd?.fullIdentificationNumber} {epd?.name}
        </b>
        The EPD owner will be informed via e-mail about the reason of declined verification.
      </HelpBox>
      <div style={{ textAlign: 'left', margin: '50px 0px 10px 0px' }}>DECLINE COMMENT</div>
      <InputTextarea value={value} onChange={(e) => setValue(e.target.value)} style={{ width: '100%' }} rows={5} />
      <br />
      <div style={{ textAlign: 'center' }}>
        <ButtonContainer>
          <ActionDeclineButton
            style={{
              marginLeft: '16px',
              border: '2px solid #E75113',
              minWidth: '170px',
              height: '3rem',
              borderRadius: '4px',
              fontWeight: '500',
              fontSize: '1.2rem',
            }}
            label="Cancel"
            onClick={() => onHide(true)}
            actionState={epd?.epdVerificationActionStates?.declineVerification}
          />
          <ActionPrimaryButton
            style={{ marginLeft: '16px' }}
            label="Decline"
            onClick={() =>
              EpdStateService.declineVerification(epd?.id, true, value).then(() => {
                refreshEPD(epd?.id);
                onHide(true);
              })
            }
            actionState={epd?.epdVerificationActionStates?.declineVerification}
          />
        </ButtonContainer>
      </div>
    </EditDialog>
  );
};

const ButtonContainer = styled.div`
  margin: 30px 10px 10px 0px;
`;
export default DeclineVerificationDialog;
