import TermsAndConditionsDialog from 'components/dialogs/TermsAndConditionsDialog';
import UpdateCompanyAddressDialog from 'components/dialogs/UpdateCompanyAddressDialog';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { useContext, useState } from 'react';
import AuthService from 'services/AuthService';

const StartupDialogsComponent = () => {
  const { companyId, company } = useContext(CompanyContext);
  const [userAcceptedTerms, setUserAcceptedTerms] = useState<boolean>(false);
  const [confirmAddressDialogOpened, setConfirmAddressDialogOpened] = useState<boolean>(false);

  const onTermsAccept = (termsAccepted: boolean) => {
    AuthService.acceptNewTermsAndConditions(termsAccepted);
    setUserAcceptedTerms(true);
  };

  if (!AuthService.isLoggedin()) return null;

  return (
    <>
      <TermsAndConditionsDialog
        isOpened={!userAcceptedTerms && !AuthService.getUser()?.acceptsNewTermsOfConditionsDate}
        onTermsAccept={onTermsAccept}
      />

      {companyId && AuthService.isCompanyOwner(companyId) && company && company.needDataUpdating && (
        <UpdateCompanyAddressDialog
          isOpened={confirmAddressDialogOpened}
          onHide={() => setConfirmAddressDialogOpened(false)}
          companyId={companyId}
          companyName={company.name}
        />
      )}
    </>
  );
};

export default StartupDialogsComponent;
