import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpsertEpdConversionFactor } from 'services/api/mutations';
import { useEpdConversionFactor, useEpdReferenceFlow } from 'services/api/queries';
import { FieldPanel, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { ConversionFactorModel, EPDBasedOnUnitEnum } from 'types/types';

const ConversionFactorSection: React.FunctionComponent<{
  isConversionFactorLock?: boolean;
  epdVersionId: string;
  epdBasedOnUnit?: any;
  errors?: any;
}> = ({ epdVersionId, isConversionFactorLock, errors, epdBasedOnUnit }) => {
  const { t } = useTranslation();
  const conversionFactorData = useEpdConversionFactor(epdVersionId!).data;
  const upsertEpdConversionFactor = useUpsertEpdConversionFactor(epdVersionId);
  const [unitValue, setUnitValue] = useState(conversionFactorData?.value);

  const onChangeConversionFactor = async (propertyName: string, val: any) => {
    const newData = { ...conversionFactorData, [propertyName]: val } as ConversionFactorModel;
    upsertEpdConversionFactor.mutate(newData);
  };

  useEffect(() => {
    setUnitValue(conversionFactorData?.value);
  }, [conversionFactorData?.value]);

  return (
    <FieldPanel>
      <FieldPanelHeader className="w-full" style={{ display: 'flex', alignItems: 'center' }}>
        {t(`epdCompiler.step3.lcaSpecificationTab.properties.conversionFactor.title`)}{' '}
        <TooltipHelpIcon content={t(`epdCompiler.step3.lcaSpecificationTab.properties.conversionFactor.tooltip`)} />
      </FieldPanelHeader>
      <WizardTextInputComponent
        label={t(`epdCompiler.step3.lcaSpecificationTab.properties.conversionFactor.unit`)}
        name="unitId"
        disabled
        value="-"
      />
      <WizardTextInputControlledComponent
        label={t(`epdCompiler.step3.lcaSpecificationTab.properties.conversionFactor.value`)}
        name="value"
        required
        placeholder="Type number"
        disabled={isConversionFactorLock && epdBasedOnUnit === EPDBasedOnUnitEnum.DeclaredUnit}
        value={unitValue || ''}
        onChange={(e: any) => setUnitValue(e.target.value)}
        onChanged={onChangeConversionFactor}
        error={errors?.conversionFactorValue}
      />
    </FieldPanel>
  );
};

export default ConversionFactorSection;
