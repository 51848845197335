import * as Yup from 'yup';
import { EPDModel } from 'services/EpdClient';

export type WizardStepValidationSchema = Yup.ObjectSchema<Partial<EPDModel> | undefined>;

export type DashboardParams = {
  companyid: string;
};

export enum StepStatus {
  Active = 'Active',
  Done = 'Done',
  Todo = 'Todo',
}
