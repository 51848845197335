import ecoPlatformLogo from 'assets/images/eco-epd-logo.png';
import { SECTOR_OF_MULTIPLE_PRODUCTS_ID } from 'components/v2/compiler/constants';
import logo from 'images/EPD-logo.png';
import React from 'react';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { EPDCoverPageModel, EPDDefinitionModel, EPDGeneralInformationModel, EPDPDFModel, EPDProductInformationModel } from 'types/types';
import DebugOnly from 'util/dev-tools/DebugOnly';
import RenderCounter from 'util/dev-tools/RenderCounter';
import { formatDate } from 'util/utils';

import {
  BottomText,
  Container,
  DeclarationAccordance,
  DeclarationHeader,
  DeclarationName,
  GreenLeftLine,
  LeftSubsection,
  LeftSubsectionInnerContainer,
  PDF_COVER_PAGE,
  ProductsIncluded,
  ProductsIncludedHeader,
  RightSubsection,
  RightSubsectionCaption,
  RightSubsectionText,
  SecondSection,
  ThirdSection,
  TopSection,
} from './Styles.styled';
import FileService from 'services/FileService';
import { useDictionaryValues } from 'services/api/queries';

const CoverPage: React.FunctionComponent<{
  generalInformation: EPDGeneralInformationModel;
  coverPageInformation: EPDCoverPageModel;
  epdDefinitionInformation: EPDDefinitionModel;
  products: EPDProductInformationModel[];
  companyLogo?: string | undefined;
  getOptionsFromDictionary: any;
  organizationInformation?: any;
  verificationInfo?: any;
  qrImageUrl: any;
}> = ({
  generalInformation,
  coverPageInformation,
  products,
  companyLogo,
  getOptionsFromDictionary,
  organizationInformation,
  verificationInfo,
  qrImageUrl,
  epdDefinitionInformation
}) => {
  const epdClassificationOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.EPD_CLASSIFICATION);
  const epdClassification = epdClassificationOptions.find((x: any) => x.value == generalInformation.epdClassification);
  const productsInEPD = products?.map((x) => x.productName).join(', ');
  const conformityStandardsOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.CONFORMITY_STANDARDS);

  function getSelectedStandards(declaredStandards: string | undefined, conformityStandardsOptions: any[]) {
    if (!declaredStandards) return [];

    const standardsArray = declaredStandards?.split(',');

    return standardsArray?.map((x: any) => conformityStandardsOptions.find((y) => y.value == x));
  }

  const inAccordanceWithStandarts = getSelectedStandards(epdDefinitionInformation?.declaredStandards, conformityStandardsOptions).map(x => x.label).join(', ');

  return (
    <PDF_COVER_PAGE className="cover">
      <DebugOnly>
        <RenderCounter componentName="cover page" threshold={3} />
      </DebugOnly>
      <GreenLeftLine />
      <Container>
        <TopSection>
          <LeftSubsection>
            <LeftSubsectionInnerContainer>
              <div>
                <div>
                  <DeclarationHeader>Environmental </DeclarationHeader>
                  <DeclarationHeader>product</DeclarationHeader>
                  <DeclarationHeader>declaration</DeclarationHeader>
                </div>

                <DeclarationName>{generalInformation.declarationName}</DeclarationName>

                <DeclarationAccordance>In accordance with: {inAccordanceWithStandarts}</DeclarationAccordance>
                <ProductsIncludedHeader>Products included in the EPD:</ProductsIncludedHeader>
                <ProductsIncluded>{productsInEPD}</ProductsIncluded>
              </div>

              <div>
                {products?.some((x: EPDProductInformationModel) => x.isTheProjectInThePlanningOrDesignPhase === true) && (
                  <BottomText>Project in planning/design phase</BottomText>
                )}
                <BottomText>
                  An EPD may be updated or depublished if conditions change. To find the latest version of the EPD and to
                  confirm its validity, see www.environdec.com
                </BottomText>
                {!!epdClassification && <BottomText>{epdClassification.label}</BottomText>}
                {epdClassification?.value === SECTOR_OF_MULTIPLE_PRODUCTS_ID.toString() && (
                  <BottomText>{generalInformation.documentCoverageStatement}</BottomText>
                )}
              </div>
            </LeftSubsectionInnerContainer>
          </LeftSubsection>
          <RightSubsection>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <div>
                <RightSubsectionCaption>EPD owner</RightSubsectionCaption>
                <RightSubsectionText>{organizationInformation?.companyName}</RightSubsectionText>

                <RightSubsectionCaption>Programme</RightSubsectionCaption>
                <RightSubsectionText>
                  The International EPD® System <br />
                  www.environdec.com
                </RightSubsectionText>

                <RightSubsectionCaption>Programme operator</RightSubsectionCaption>
                <RightSubsectionText>EPD International AB EPD</RightSubsectionText>

                <RightSubsectionCaption>Registration number</RightSubsectionCaption>
                <RightSubsectionText>{generalInformation.fullIdentificationNumber}</RightSubsectionText>

                <RightSubsectionCaption>Version date</RightSubsectionCaption>
                <RightSubsectionText>{formatDate(verificationInfo?.approvalDate)}</RightSubsectionText>

                <RightSubsectionCaption>Validity date</RightSubsectionCaption>
                <RightSubsectionText>{formatDate(verificationInfo?.validityDate)}</RightSubsectionText>
              </div>
              <div style={{ display: 'flex', alignContent: 'flex-end', justifyContent: 'flex-end' }}>
                <img style={{ maxHeight: '200px', aspectRatio: 1 }} src={qrImageUrl} alt="QR code" />
              </div>
            </div>
          </RightSubsection>
        </TopSection>
        <SecondSection>
          <img
            height={'100%'}
            width={'100%'}
            src={FileService.getImageUrl(coverPageInformation?.productImages![0]?.id)}
            alt="Cover page image"
          />
        </SecondSection>
        <ThirdSection>
          <div>
            <img height={50} style={{ maxHeight: '50px' }} src={logo} alt="EPD Logotype" />
          </div>
          <div>
            <img height={50} style={{ maxHeight: '50px' }} src={ecoPlatformLogo} alt="ECO Platform logotype" />
          </div>
          <div>
            <img
              height={50}
              style={{ maxHeight: '50px' }}
              src={FileService.getImageUrl(coverPageInformation?.companyLogoImages![0]?.id)}
              alt="Organization logotype"
            />
          </div>
        </ThirdSection>
      </Container>
    </PDF_COVER_PAGE>
  );
};

export default CoverPage;
