import { useParams } from 'react-router-dom';

const useCompilerPdfLogic = () => {
  const { id } = useParams<any>();

  return {
    epdVersionId: id,
  };
};

export default useCompilerPdfLogic;
