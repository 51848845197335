import React from 'react';
import VersionHistorySection from '../VersionHistorySection';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import { VersionHistorySummaryGridSkeleton } from '../VersionHistorySummaryGrid';
import VersionHistoryLcaInformationGrid from './VersionHistoryLcaInformationGrid';
import DataQualityAssessmentAndReferenceYearsGridPdf from '../../grids/data-quality-assessment-and-ref-years/DataQualityAssessmentAndReferenceYearsGridPdf';
import { ScrapInputsContributionLevelEnum } from 'types/types';
import ContributionOfScrapInputsGridPdf from '../../grids/contribution-of-scrap-inputs/ContributionOfScrapInputsGridPdf';
import ElectricityGridPdf from '../../grids/electricity/ElectricityGridPdf';
import TransportationPerformanceGridPdf from '../../grids/specification/TransportationPerformanceGridPdf';
import DeclarationOfCO2GridPdf from '../../grids/declaration-of-co2/DeclarationOfCo2GridPdf';
import { YES_NO } from '../../constants';

const VersionHistoryLcaInformationSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();

  return (
    <VersionHistorySection title="LCA information">
      {data ? (
        <>
          <VersionHistoryLcaInformationGrid />

          <DataQualityAssessmentAndReferenceYearsGridPdf
            dataQualityAssessmentItems={data.dataQualityAssessmentAndReferenceYears.dataQualityAssessmentItems}
          />

          <ContributionOfScrapInputsGridPdf epdVersionId={data.epdVersionId} />

          <ElectricityGridPdf epdVersionId={data.epdVersionId} />

          {data.lcaSpecification?.shouldTransportationPerformanceBeFetched && (
            <TransportationPerformanceGridPdf epdVersionId={data.epdVersionId} />
          )}

          {data.lcaSpecification?.shouldDeclarationsBeFetched && (
            <DeclarationOfCO2GridPdf
              epdVersionId={data.epdVersionId}
              disabled={data.cO2UptakeAssociation.association !== YES_NO.YES}
            />
          )}
        </>
      ) : (
        <>
          <VersionHistorySummaryGridSkeleton skeletonRowsCount={11} />
          <VersionHistorySummaryGridSkeleton skeletonRowsCount={3} />
        </>
      )}
    </VersionHistorySection>
  );
};

export default VersionHistoryLcaInformationSection;
