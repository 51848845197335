import { useEpdLcaCustomResultGridData } from 'services/api/queries';

import { LcaResultsGridColumnsPdf } from '../LcaResultsGridColumns';
import LcaResultsGridPdf from '../LcaResultsGridPdf';

type TProps = {
  customResultId: string;
};

const LcaCustomBiogenicResultsGridPdf = ({ customResultId }: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: 'GWP-biogenic = Global Warming Potential biogenic.',
    },
  ];

  const rows = useEpdLcaCustomResultGridData(customResultId).data;
  return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsPdf} notes={notes} />;
};

export default LcaCustomBiogenicResultsGridPdf;
