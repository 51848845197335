import ErrorFallback from 'components/error-fallback/ErrorFallback';
import { PrimaryButton } from 'components/v2/buttons';
import { Description, DialogContainer } from 'components/v2/confirm-dialog/styled';
import { HelpBox } from 'components/v2/help-boxes';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { MutableRefObject, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import EpdService from 'services/EpdService';
import styled from 'styled-components';
import { ButtonSmall } from 'styles/Styles.styled';
import { CheckBoxPanel, RadioButtonStyled } from 'styles/v2/Styles.styled';
import { Modal } from 'util/Modal';

import { COMPANIES_WITH_COMPILER, ProcessStatus } from '../../constants/constants';
import CopyEpd from './CopyEpd';

const CreateEpd: React.FunctionComponent<{
  anyEpd: boolean;
}> = ({ anyEpd }) => {
  const history = useHistory();
  const modalRef = useRef<Modal>();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const { t } = useTranslation();
  const [copying, setCopying] = useState<boolean>(false);
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState<boolean>(false);
  const { companyId, companyAccountId, company } = React.useContext(CompanyContext);
  const [epdFormat, setEpdFormat] = useState<string>('compiler');

  const createEpd = async () => {
    if (!companyAccountId) {
      return;
    }
    try {
      setStatus(ProcessStatus.Fetching);
      const newEpd =
        epdFormat === 'compiler' && COMPANIES_WITH_COMPILER.includes(company?.name || '')
          ? await EpdService.createCompilerEpd(companyAccountId)
          : await EpdService.createEpd(companyAccountId);
      const route = EpdLinks.epd(newEpd.id);
      history.push(route);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  if (!companyAccountId) {
    return null;
  }

  const onCloseDialog = () => {
    setCopying(false);
  };

  const handleClickNewEpd = (e: any) => {
    if (status === ProcessStatus.Fetching) {
      e.preventDefault();
      return;
    }

    if (COMPANIES_WITH_COMPILER.includes(company?.name || '')) {
      setIsCreateDialogOpened(true);
      return;
    }
    createEpd();
  };

  const acceptHandler = () => {
    createEpd();
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div style={{ marginLeft: '1rem' }}>
        {copying && companyId ? (
          <Modal ref={modalRef as MutableRefObject<Modal>}>
            <CopyEpd companyId={companyId} accountId={companyAccountId} onClose={onCloseDialog} />
          </Modal>
        ) : null}
        <ButtonSmall onClick={handleClickNewEpd}>
          {status === ProcessStatus.Fetching ? t('epdDashboard.newEpd.creating') : t('epdDashboard.newEpd.created')}
        </ButtonSmall>
        {anyEpd && (
          <>
            <ButtonSmall style={{ marginLeft: '1rem' }} onClick={() => history.push(EpdRoutes.copyEpd)}>
              {t('epdDashboard.copyEpd.created')}
            </ButtonSmall>
          </>
        )}
        <ConfirmDialog
          header={<>New format - EPD compiler</>}
          closable={false}
          visible={isCreateDialogOpened}
          style={{ width: '50vw' }}
          onHide={() => setIsCreateDialogOpened(false)}
          acceptLabel="Submit"
          rejectLabel="Cancel"
          accept={acceptHandler}
          className="custom-dialog"
          message={
            <StyledContentContainer>
              <HelpBox>
                <HeaderText>We are glad to announce new EPD format - EPD Compiler!</HeaderText>
                The EPD Compiler is a feature of the EPD Portal, facilitating the seamless compilation of necessary data and
                information for EPDs.From now on you can start working with EPDs in a new EPD Compiler format, or you can
                continue to use the usual EPD data format.
                <p>
                  You can easily switch between the formats from “i” menu in the right bottom corner. Please be aware, when
                  switching between the formats,all entered data will be removed due to different data inputs functionality.
                </p>
              </HelpBox>
              <CheckBoxPanel>
                <RadioButtonStyled
                  inputId={'createNewEpd_compiler'}
                  value="compiler"
                  name="editMode"
                  checked={epdFormat === 'compiler'}
                  onChange={(e) => {
                    setEpdFormat(e.value);
                  }}
                />
                <label htmlFor={'createNewEpd_compiler'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
                  Continue with a new EPD Compiler format.
                </label>
              </CheckBoxPanel>
              <CheckBoxPanel style={{ marginTop: '0.5rem' }}>
                <RadioButtonStyled
                  inputId={'createNewEpd_wizard'}
                  value="wizard"
                  name="editMode"
                  checked={epdFormat === 'wizard'}
                  onChange={(e: RadioButtonChangeEvent) => {
                    setEpdFormat(e.value);
                  }}
                />
                <label htmlFor={'createNewEpd_wizard'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
                  Continue with a usual EPD data format.
                </label>
              </CheckBoxPanel>
            </StyledContentContainer>
          }
        />
      </div>
    </ErrorBoundary>
  );
};

const StyledContentContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
  ${(props) => props.theme.fonts.textSmall}
`;

const HeaderText = styled.p`
  ${(props) => props.theme.typography.bodyBody14Semibold}
`;

export default CreateEpd;
