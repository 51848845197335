import React from 'react';
import { ISO_21930_ID, YES_NO } from 'components/v2/compiler/constants';
import DangerMaterialsGridPdf from 'components/v2/compiler/grids/product-content/DangerMaterialsGridPdf';
import PackagingMaterialsGridPdf from 'components/v2/compiler/grids/product-content/PackagingMaterialsGridPdf';
import PanelContentGridPdf from 'components/v2/compiler/grids/product-content/PanelContentGridPdf';
import ProductComponentsGridPdf from 'components/v2/compiler/grids/product-content/ProductComponentsGridPdf';
import { useEPDDefinitionInformation } from 'services/api/queries';
import {
  EPDContentDeclarationProductType,
  EPDDeclareContentProductModel,
  EPDDefinitionModel,
  EPDGeneralInformationModel,
  PCRInformationModel,
} from 'types/types';

import { PDF_H1, PDF_H2, PDF_P, PDF_PAGE, PDF_Table } from './Styles.styled';

const ContentDeclarationPage: React.FunctionComponent<{
  generalInformation: EPDGeneralInformationModel;
  contentDeclarationProducts: EPDDeclareContentProductModel[];
  pcrInformation?: PCRInformationModel;
  epdDefinitionInformation: EPDDefinitionModel;
}> = ({ generalInformation, contentDeclarationProducts, pcrInformation,epdDefinitionInformation }) => {
  const isPanelContentEnabled = pcrInformation?.cpcr?.name?.includes('c-PCR-014');
  const selectedStandards = epdDefinitionInformation?.declaredStandards?.split(',');
  const isISO21930StandardSelected = selectedStandards?.some((x) => x === ISO_21930_ID);
  const averageProductContentDeclaration =
    contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.Average) ?? null;
  const bestProductContentDeclaration =
    contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.BestProduct) ?? null;
  const worstProdutContentDeclaration =
    contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.WorstProduct) ?? null;
  const isBestCaseEnabled = !!bestProductContentDeclaration?.productDescription;
  const isWorstCaseEnabled = !!worstProdutContentDeclaration?.productDescription;

  return (
    <>
      <PDF_PAGE>
        <PDF_H1>Content declaration</PDF_H1>
        <br />
        {generalInformation.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
          <>
            <PDF_H2>Explanation why content declaration is not relevant</PDF_H2>
            <PDF_P>{generalInformation.declarationIrrelevanceExplanation}</PDF_P>
          </>
        )}

        <ProductComponentsGridPdf
          epdVersionId={generalInformation.epdVersionId}
          serviceIncludesProductInSystemBoundary={generalInformation.serviceIncludesProductInSystemBoundary}
          contentDeclarationType={EPDContentDeclarationProductType.Average}
          isISO21930StandardSelected={isISO21930StandardSelected}
        />

        {isPanelContentEnabled && (
          <>
            <br />
            <br />
            <PanelContentGridPdf
              epdVersionId={generalInformation.epdVersionId}
              contentDeclarationType={EPDContentDeclarationProductType.Average}
              isISO21930StandardSelected={isISO21930StandardSelected}
            />
          </>
        )}
        <br />
        <br />
        <PackagingMaterialsGridPdf
          epdVersionId={generalInformation.epdVersionId}
          serviceIncludesProductInSystemBoundary={generalInformation.serviceIncludesProductInSystemBoundary}
          contentDeclarationType={EPDContentDeclarationProductType.Average}
          isISO21930StandardSelected={isISO21930StandardSelected}
        />
        {averageProductContentDeclaration?.hasDangerMaterials && (
          <>
            <br />
            <br />
            <DangerMaterialsGridPdf
              epdVersionId={generalInformation.epdVersionId}
              contentDeclarationType={EPDContentDeclarationProductType.Average}
            />
          </>
        )}
      </PDF_PAGE>

      {isBestCaseEnabled && (
        <PDF_PAGE>
          <PDF_H1>Best-case product</PDF_H1>
          <PDF_Table>
            <thead>
              <tr>
                <th style={{ width: '30%' }}></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product name</td>
                <td>{bestProductContentDeclaration?.productDescription}</td>
              </tr>
            </tbody>
          </PDF_Table>
          <br />
          {generalInformation.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
            <>
              <PDF_H2>Explanation why content declaration is not relevant</PDF_H2>
              <PDF_P>{generalInformation.declarationIrrelevanceExplanation}</PDF_P>
            </>
          )}
          <ProductComponentsGridPdf
            epdVersionId={generalInformation.epdVersionId}
            serviceIncludesProductInSystemBoundary={generalInformation.serviceIncludesProductInSystemBoundary}
            contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
            isISO21930StandardSelected={isISO21930StandardSelected}
          />
          {isPanelContentEnabled && (
            <>
              <br />
              <br />
              <PanelContentGridPdf
                epdVersionId={generalInformation.epdVersionId}
                contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                isISO21930StandardSelected={isISO21930StandardSelected}
              />
            </>
          )}
          <br />
          <br />
          <PackagingMaterialsGridPdf
            epdVersionId={generalInformation.epdVersionId}
            serviceIncludesProductInSystemBoundary={generalInformation.serviceIncludesProductInSystemBoundary}
            contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
            isISO21930StandardSelected={isISO21930StandardSelected}
          />
          {bestProductContentDeclaration?.hasDangerMaterials && (
            <>
              <br />
              <br />
              <DangerMaterialsGridPdf
                epdVersionId={generalInformation.epdVersionId}
                contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
              />
            </>
          )}
        </PDF_PAGE>
      )}

      {isWorstCaseEnabled && (
        <PDF_PAGE>
          <PDF_H1>Worst-case product</PDF_H1>
          <PDF_Table>
            <thead>
              <tr>
                <th style={{ width: '30%' }}></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product name</td>
                <td>{worstProdutContentDeclaration?.productDescription}</td>
              </tr>
            </tbody>
          </PDF_Table>
          <br />
          {generalInformation.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
            <>
              <PDF_H2>Explanation why content declaration is not relevant</PDF_H2>
              <PDF_P>{generalInformation.declarationIrrelevanceExplanation}</PDF_P>
            </>
          )}
          <ProductComponentsGridPdf
            epdVersionId={generalInformation.epdVersionId}
            serviceIncludesProductInSystemBoundary={generalInformation.serviceIncludesProductInSystemBoundary}
            contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
            isISO21930StandardSelected={isISO21930StandardSelected}
          />
          {isPanelContentEnabled && (
            <>
              <br />
              <br />
              <PanelContentGridPdf
                epdVersionId={generalInformation.epdVersionId}
                contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                isISO21930StandardSelected={isISO21930StandardSelected}
              />
            </>
          )}
          <br />
          <br />
          <PackagingMaterialsGridPdf
            epdVersionId={generalInformation.epdVersionId}
            serviceIncludesProductInSystemBoundary={generalInformation.serviceIncludesProductInSystemBoundary}
            contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
            isISO21930StandardSelected={isISO21930StandardSelected}
          />
          {bestProductContentDeclaration?.hasDangerMaterials && (
            <>
              <br />
              <br />
              <DangerMaterialsGridPdf
                epdVersionId={generalInformation.epdVersionId}
                contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
              />
            </>
          )}
        </PDF_PAGE>
      )}
    </>
  );
};

export default ContentDeclarationPage;
