import { ProcessStatus } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { AccountModel, CompanyListModel } from 'services/EpdClient';
import AccountsService from 'services/admin/AccountsService';
import AdminService from 'services/admin/AdminService';
import styled from 'styled-components';
import { ButtonSmall, ErrorMessage, InfoText } from 'styles/Styles.styled';

import AdminEpdService from '../../../services/admin/AdminEpdService';

const CompanySelector: React.FunctionComponent<any> = (props) => {
  const [companies, setCompanies] = useState<{ value: string | undefined; label: string | undefined }[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>();
  const [accounts, setAccounts] = useState<AccountModel[]>();
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);

  const handleCompanySelect = (e: any) => {
    setSelectedCompany(e?.value);
  };

  const toggleAccount = (e: any) => {
    setSelectedAccountId(e.value);
  };

  const onMoveEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await AdminEpdService.moveEpd(props.epdId, selectedAccountId as string);
      setStatus(ProcessStatus.Success);
      setSelectedAccountId(undefined);
    } catch (error) {
      setStatus(ProcessStatus.Error);
    }
  };

  useEffect(() => {
    const fetchAccounts = async () => {
      const result = await AccountsService.getAccounts(selectedCompany as string);
      const accountOptions: any = result.map((account: AccountModel) => {
        return { value: account.id, label: account.name };
      });
      setAccounts(accountOptions);
    };
    fetchAccounts();
  }, [selectedCompany]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const result = await AdminService.getCompanies(undefined, undefined, undefined);
      const companyOptions: any = result.map((company: CompanyListModel) => {
        return { value: company.id, label: company.name };
      });
      setCompanies(companyOptions);
    };
    fetchCompanies();
  }, []);

  return (
    <Container>
      <Select
        options={companies}
        styles={dialogSelectStyles}
        placeholder={'Select by company'}
        isLoading={!companies || companies.length === 0}
        isClearable={false}
        onChange={handleCompanySelect}
      />
      {selectedCompany && accounts && (
        <>
          <p>Select the account you want to move this epd to from the list below.</p>
          <Select
            options={accounts}
            styles={dialogSelectStyles}
            placeholder={'Select by account'}
            isLoading={!accounts || accounts.length === 0}
            isClearable={false}
            onChange={toggleAccount}
          />
        </>
      )}
      {selectedAccountId && (
        <ButtonBox>
          <InquireButton
            type={'button'}
            disabled={!selectedCompany || status === ProcessStatus.Fetching}
            onClick={onMoveEpd}
          >
            Move to this account
          </InquireButton>
        </ButtonBox>
      )}
      {status === ProcessStatus.Success && <InfoText>EPD is moved successfully.</InfoText>}
      {status === ProcessStatus.Error && (
        <ErrorMessage style={{ marginTop: '1rem', color: 'red' }}>Failed to move EPD.</ErrorMessage>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
`;

const ButtonBox = styled.div`
  align-self: flex-end;
  margin: 1rem 0 0.5rem 0;
`;

const InquireButton = styled(ButtonSmall)`
  padding: 0.5rem;
`;

const dialogSelectStyles = {
  container: (styles: any) => ({
    ...styles,
    minWidth: '40rem%',
  }),
  menu: (styles: any) => ({ ...styles }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 0,
    borderColor: 'gray',
    color: 'black',
    fontSize: '14px',
    minHeight: '49px',
    margin: '0.5rem 0 ',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: '100%',
  }),
};

export default CompanySelector;
