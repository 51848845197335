import { InvoiceModel, InvoicesClient } from './EpdClient';

const client = new InvoicesClient(process.env.REACT_APP_API_URL);

class InvoiceService {
  static getInvoice = async (id: string) => {
    return await client.get(id);
  };

  static getInvoiceSummary = async (accountId: string) => {
    return await client.get2(accountId);
  };

  static approve = async (invoice: InvoiceModel) => {
    return await client.approve(getId(invoice));
  };

  static decline = async (invoice: InvoiceModel, message: string) => {
    const invoiceId = getId(invoice);
    return await client.decline(invoiceId, { invoiceId, message });
  };

  static update = async (invoice: InvoiceModel) => {
    return await client.put(getId(invoice), invoice);
  };
}

function getId(invoice: InvoiceModel) {
  if (invoice.id == null) {
    throw new Error('Missing invoice id');
  }
  return invoice.id;
}

export default InvoiceService;
