import { components } from 'react-select';
import styled from 'styled-components';
import { ReactComponent as CrossIconComponent } from 'images/v2/icons/svg/fill/svg/cross.svg';
import { ReactComponent as ChevronDownIconComponent } from 'images/v2/icons/svg/fill/svg/chevron-down.svg';
import { ReactComponent as DownloadIconComponent } from 'images/v2/icons/svg/fill/svg/download.svg';
import { ReactComponent as CopyIconComponent } from 'images/v2/icons/svg/fill/svg/copy.svg';

export const StyledCrossIcon = styled(CrossIconComponent)`
  width: 16px;
  height: 16px;
  color: ${(props) => props.theme.colors.primaryBlack};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.primaryOrange};
    cursor: pointer;
  }
`;

export const StyledChevronDownIcon = styled(ChevronDownIconComponent)`
  width: 18px;
  height: 18px;
  color: ${(props) => props.theme.colors.primaryBlack};
  &:hover {
    color: ${(props) => props.theme.colors.primaryOrange};
    cursor: pointer;
  }
`;

export const StyledDownloadIcon = styled(DownloadIconComponent)`
  width: 16px;
  height: 16px;
  color: ${(props) => props.theme.colors.primaryBlack};
  &:hover {
    color: ${(props) => props.theme.colors.primaryOrange};
    cursor: pointer;
  }
`;

export const StyledCopyIcon = styled(CopyIconComponent)`
  width: 16px;
  height: 16px;
  color: ${(props) => props.theme.colors.primaryBlack};
  &:hover {
    color: ${(props) => props.theme.colors.primaryOrange};
    cursor: pointer;
  }
`;

export const ClearIndicator = (props: any) => (
  <components.ClearIndicator {...props}>
    <StyledCrossIcon />
  </components.ClearIndicator>
);

export const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <StyledChevronDownIcon />
  </components.DropdownIndicator>
);
