import { LcaResultModel } from 'types/types';
import LcaResultsGrid from './LcaResultsGrid';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import { LcaResultsGridColumnsNoCategory, LcaResultsGridColumnsNoCategoryPdf } from './LcaResultsGridColumns';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaResultsGridResourceConstruction = ({
  epdVersionId,
  rows,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description:
        'PERE = Use of renewable primary energy excluding renewable primary energy resources used as raw materials; PERM = Use of renewable primary energy resources used as raw materials; PERT = Total use of renewable primary energy resources; PENRE = Use of non-renewable primary energy excluding non-renewable primary energy resources used as raw materials; PENRM = Use of non-renewable primary energy resources used as raw materials; PENRT = Total use of non-renewable primary energy re-sources; SM = Use of secondary material; RSF = Use of renewable secondary fuels; NRSF = Use of non-renewable secondary fuels; FW = Use of net fresh water.',
    },
  ];

  if (isForPdf) {
    return <LcaResultsGridPdf value={rows} columns={LcaResultsGridColumnsNoCategoryPdf} notes={notes} />;
  }
  return (
    <LcaResultsGrid
      epdVersionId={epdVersionId}
      value={rows}
      columns={LcaResultsGridColumnsNoCategory}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridResourceConstruction;
