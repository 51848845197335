import 'primeflex/primeflex.css';

type TProps = {
  value: string;
};

const GuideText = ({ value }: TProps) => {
  return (
    <div
      className="border-none mb-3 w-full p-3"
      style={{ borderRadius: '4px', backgroundColor: 'rgba(180, 208, 182, 0.3)' }}
    >
      <p className="m-0">{value}</p>
    </div>
  );
};

export default GuideText;
