import PasswordInput from 'components/form/PasswordInput';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ButtonSmall, ErrorText, H2, InfoBox, StyledForm } from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import warningIcon from '../../images/icons/svg/warning.svg';
import AdminUserService from '../../services/admin/AdminUserService';

const SetPasswordModal = (props: any) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState<string>();
  const initialValues = { password: null, confirmPassword: null };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('account.validation.passwordRequired'))
      .nullable()
      .matches(/\w*[a-z]\w*/, t('account.validation.invalidPasswordSmallLetter'))
      .matches(/\w*[A-Z]\w*/, t('account.validation.invalidPasswordCapitalLetter'))
      .matches(/\d/, t('account.validation.invalidPasswordNumber'))
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, t('account.validation.invalidPasswordSpecial'))
      .min(6, ({ min }) => t('account.validation.passwordLength', { min })),
    confirmPassword: Yup.string()
      .nullable()
      .oneOf([Yup.ref('password')], t('account.validation.passwordsNotMatch'))
      .required(t('account.validation.confirmPasswordRequired')),
  });

  const handleSubmit = async (rowValues: any) => {
    const values = {
      ...rowValues,
    };
    try {
      setServerError('');
      await AdminUserService.setUserPassword(props.userid, { id: props.userid, ...values });
      toast.success(t('account.passwordSetSuccess') as string, {
        position: 'top-center',
        autoClose: 1000,
      });
      props.onClickConfirm();
    } catch (error) {
      toast.error(t('something went wrong') as string, {
        position: 'top-center',
        autoClose: 1000,
      });
      setServerError(PoorMansError(error));
    }
  };

  return (
    <Overlay>
      <Modal>
        <Content>
          <H2>{props.description}</H2>
          <InfoBox>
            <img src={warningIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
            <div>{t('account.setPasswordWarning')}</div>
          </InfoBox>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ textTransform: 'uppercase' }}>{t('user')}: </span>
            {props.firstName && props.firstName} {props.lastName && props.lastName}{' '}
            {props.email && <i>&lt;{props.email}&gt;</i>}
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting, dirty, isValid }) => (
              <StyledForm id={'setPasswordForm'}>
                <InputContainer>
                  <PasswordInput
                    style={{ width: '100%' }}
                    label={t('account.password')}
                    name="password"
                    autoComplete="new-password"
                    placeholder={t('account.password')}
                  />
                  <PasswordInput
                    style={{ width: '100%' }}
                    label={t('account.confirmPassword')}
                    name="confirmPassword"
                    placeholder={t('account.confirmPassword')}
                  />
                </InputContainer>
                {serverError && <ErrorText>{serverError}</ErrorText>}
                <ButtonsContainer>
                  <CancelBox>
                    <ButtonSmall onClick={props.onClickCancel} type={'button'}>
                      {props.cancelButtonText}
                    </ButtonSmall>
                  </CancelBox>

                  <ButtonSmall disabled={isSubmitting || !isValid || !dirty} type="submit">
                    {props.confirmButtonText}
                  </ButtonSmall>
                </ButtonsContainer>
              </StyledForm>
            )}
          </Formik>
        </Content>
      </Modal>
    </Overlay>
  );
};

export default SetPasswordModal;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: rgb(128, 128, 128, 0.7);
  z-index: 1;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Modal = styled.div`
  display: block;
  z-index: 10;
  overflow: hidden;
  background-color: transparent;
  position: fixed;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray}};
  padding: 16px;
  text-align: left;
  border: 1px solid #888;
  width: 50vw;
  > p {
    font-size: 16px;
  }
  ${(props) => props.theme.media.tablet} {
    width: 25vw;
  }
`;

const CancelBox = styled.div`
  padding-right: 1rem;
  > button {
    background: rgb(128, 128, 128);
    border-radius: 5px;
    color: white;
    :hover {
      background: rgb(105, 105, 105);
      cursor: pointer;
    }
  }
`;
