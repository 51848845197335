import React from 'react';
import { EPDModel } from 'services/EpdClient';
import { FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { EPDGeneralInformationModel } from 'types/types';

import { HelpBox } from '../help-boxes';
import { Button } from 'primereact/button';
import { ButtonUploadIcon } from '../icons';
import styled from 'styled-components';
import { EpdLinks } from 'routes/EpdRoutes';
import QRCodeDownloadDialog from './dialogs/QRCodeDownloadDialog';

const Step6: React.FunctionComponent<{
  epd: EPDModel;
  generalInformation: EPDGeneralInformationModel;
}> = ({ epd, generalInformation }) => {
  const handleDownloadPDF = () => {
    window.open(EpdLinks.epdVersionDocumentPreview(generalInformation?.epdVersionId), '_blank');
  };

  return (
    <>
      <FieldPanelFullWidth style={{ marginTop: 0, gridRowGap: '5px' }}>
        <FieldPanelHeader>Generated EPD</FieldPanelHeader>
        <HelpBox style={{ marginTop: '30px' }}>
          After the review and publication will be completed by the Secretariat, you will be able to see and download the
          generated EPD document in PDF format in this section.
        </HelpBox>
        {epd?.publishedVersionDate && (
          <DownloadPDFDocumentBox>
            <Button
              icon={ButtonUploadIcon}
              style={{ fontSize: 15, fontWeight: 500 }}
              type="button"
              label="Download PDF"
              text
              onClick={handleDownloadPDF}
              disabled={false}
            />
          </DownloadPDFDocumentBox>
        )}
      </FieldPanelFullWidth>
      <FieldPanelFullWidth style={{ gridRowGap: '5px' }}>
        <FieldPanelHeader>Generate QR code</FieldPanelHeader>
        <HelpBox style={{ marginTop: '30px' }}>
          After publication you can generate a QR code with a link to the published EPD on environdec.com
        </HelpBox>
        <QRCodeDownloadDialog friendlyUrl={epd?.friendlyUrl as string} epd={epd} />
      </FieldPanelFullWidth>
    </>
  );
};

const DownloadPDFDocumentBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 70px;
  margin-top: 10px;
  cursor: pointer;

  .p-button.p-button-text {
    padding: 0;
    background-color: transparent;
    border: none;
    ${(props) => props.theme.fonts.standard};
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;

export default Step6;
