import React from 'react';
import styled from 'styled-components';
import Spinner from 'styles/spinner-left.styled';

const ApplicationLoadingSpinner = () => {
  return (
    <center>
      <div>
        <Spinner inline style={{ position: 'absolute' }} />
      </div>
    </center>
  );
};

export default ApplicationLoadingSpinner;
