import styled from 'styled-components';

const DebugOnly = ({ children }: { children: React.ReactNode }) => {
  return process.env.NODE_ENV === 'development' ? <BackgroundTextDiv>{children}</BackgroundTextDiv> : null;
};

const BackgroundTextDiv = styled.div`
  background-color: #f0f0f0;
  opacity: 0.5;
`;

export default DebugOnly;
