import { toaster } from 'components/v2/toast';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TextButton } from '../buttons';
import useIsReadOnlyMode from '../compiler/hooks/useIsReadOnlyMode';

type TProps = {
  label?: string;
  icon?: any;
  accept?: string;
  singleFile?: boolean;
  hasFiles?: boolean;
  validExtensions?: string[];
  onFileUpload: any;
  name: string;
  disabled?: boolean;
};

const FileUpload = (props: TProps) => {
  const { t } = useTranslation();
  const inputRef = React.createRef();
  const isReadOnly = useIsReadOnlyMode() || props.disabled;

  const selectFile = () => {
    if (props.singleFile && props.hasFiles) {
      toaster({ severity: 'error', summary: 'Error', details: t('messages.uploadFileSingle') });
      return;
    }

    (inputRef as any).current.click();
  };

  const upload = (e: any, validExtensions: Array<string>) => {
    if (validExtensions?.length > 0) {
      for (const file of e.target.files) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!props.validExtensions?.includes(fileExtension)) {
          toast.error(`Please upload file having extensions ${validExtensions.join('/')} only.`, { position: 'top-center' });
          return false;
        }
      }
    }

    props.onFileUpload(e, props.name);
  };

  return (
    <div>
      <input
        ref={inputRef as any}
        type="file"
        accept={props.accept}
        value={''}
        onChange={(e) => upload(e, props.validExtensions!)}
        disabled={isReadOnly}
        style={{ visibility: 'hidden', display: 'none' }}
      />

      <TextButton
        className="white-space-nowrap"
        style={{ cursor: 'pointer' }}
        label={props.label}
        icon={props.icon ?? 'pi pi-upload'}
        text
        onClick={() => selectFile()}
        disabled={isReadOnly}
      />
    </div>
  );
};

export default FileUpload;

export const FileBox = styled.div`
  background-color: ${(props) => props.theme.colors.regionColorLight};
  padding: 1rem 3rem;
  flex: 1 0 auto;

  > h3 {
    margin-top: 0;
  }
`;
