import { LcaCustomResultGridDataModel, LcaCustomResultModel, LcaResultModel, LcaResultsViewModel } from 'types/types';

import { axiosInstance } from './epdApi';

// LCA Results
export const getEpdLcaResults = async (id: string) => {
  return (await axiosInstance.get<LcaResultsViewModel>(`/api/v1/compiler/EPDVersion/${id}/LCAResults`)).data;
};

export const upsertEpdLcaResult = async (data: LcaResultModel) => {
  if (data.id) {
    await axiosInstance.put(`/api/v1/compiler/EPDVersion/LcaResults/${data.id}`, data);
  } else {
    await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/LcaResults`, data);
  }
};

export const parseAndSaveLCAFile = async (id: string) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${id}/LCAResults/ParseAndSaveLCAFile`);
};

//LCA Results additional
export const createEpdLcaResultAdditional = async (data: LcaResultModel) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/LCAResultsAdditional`, data);
};

export const getEpdLcaResultsAdditional = async (id: string) => {
  return (await axiosInstance.get<LcaResultModel[]>(`/api/v1/compiler/EPDVersion/${id}/LCAResultsAdditional`)).data;
};

export const updateEpdLcaResultAdditional = async (data: LcaResultModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/LCAResultsAdditional`, data);
};

export const deleteEpdLcaResultsAdditional = async (ids: string[]) => {
  if (!ids?.length) {
    return;
  }
  await axiosInstance.delete('/api/v1/compiler/EPDVersion/LCAResultsAdditional', { data: ids });
};

// LCA custom results
export const createLcaCustomResult = async (data: LcaCustomResultModel) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/LCACustomResults`, data);
};

export const getEpdLcaCustomResults = async (id: string) => {
  return (await axiosInstance.get<LcaCustomResultModel[]>(`/api/v1/compiler/EPDVersion/${id}/LCACustomResults`)).data;
};

export const updateLcaCustomResult = async (data: LcaCustomResultModel) => {
  await axiosInstance.put(`/api/v1/compiler/LCACustomResults/${data.id}`, data);
};

export const deleteLcaCustomResult = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/LCACustomResults/${id}`);
};

// LCA custom results grid data
export const createLcaCustomResultGridDataRow = async (data: LcaCustomResultGridDataModel) => {
  await axiosInstance.post(`/api/v1/compiler/LCACustomResults/${data.lcaCustomResultId}/GridData`, data);
};

export const getEpdLcaCustomResultGridData = async (customResultId: string) => {
  return (
    await axiosInstance.get<LcaCustomResultGridDataModel[]>(`/api/v1/compiler/LCACustomResults/${customResultId}/GridData`)
  ).data;
};

export const updateLcaCustomResultGridDataRow = async (data: LcaCustomResultGridDataModel) => {
  await axiosInstance.put(`/api/v1/compiler/LCACustomResults/${data.lcaCustomResultId}/GridData`, data);
};

export const deleteLcaCustomResultGridDataRow = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/LCACustomResults/GridData/${id}`);
};

export const deleteLcaCustomResultGridDataRows = async (epdVersionId: string, ids: string[]) => {
  if (!ids?.length) {
    return;
  }
  await axiosInstance.delete(`/api/v1/compiler/version/${epdVersionId}/LCACustomResults/GridData`, { data: ids });
};
