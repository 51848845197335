import { ActionDeclineButton, ActionPrimaryButton } from 'components/v2/buttons';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import { HelpBox } from 'components/v2/help-boxes';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { ActionState, EPDModel } from 'services/EpdClient';
import styled from 'styled-components';
import { FieldContainer, FieldPanelInput } from 'styles/v2/Styles.styled';

import EditDialog from './EditDialog';

type TProps = {
  epd?: EPDModel;
  isOpened: boolean;
  header: string;
  onHide: any;
};

const ImpersonateDialog = ({ isOpened, onHide }: TProps) => {
  const [impersonateAs, setImpersonateAs] = React.useState<string>('');
  const { companyId, changeCompany } = useContext(CompanyContext);
  const history = useHistory();
  return (
    <EditDialog header="Impersonate" isOpened={isOpened} onHide={onHide}>
      <>
        <HelpBox>
          Please note, after you enable the impersonate function you will enter the EPD Portal on behalf of this user.
          <br />
          To access the EPD Portal on behalf of your user account, please logout and login again using your credentials.
        </HelpBox>
        <FieldPanelInput>
          <FieldContainer>
            <WizardTextInputComponent
              label="Add user e-mail address"
              placeholder="Type email"
              value={impersonateAs}
              name="impersonateAs"
              onChange={(e: any) => {
                setImpersonateAs(e.target.value);
              }}
              onChanged={() => {}}
            />
          </FieldContainer>
        </FieldPanelInput>
      </>
      <div style={{ textAlign: 'center' }}>
        <ButtonContainer>
          <ActionDeclineButton
            style={{
              marginLeft: '16px',
              border: '2px solid #E75113',
              minWidth: '170px',
              height: '3rem',
              borderRadius: '4px',
              fontWeight: '500',
              fontSize: '1.2rem',
            }}
            label="Cancel"
            onClick={() => onHide(true)}
            actionState={ActionState.Enabled}
          />
          <ActionPrimaryButton
            style={{ marginLeft: '16px' }}
            label="Impersonate"
            onClick={
              async () => {
                try {
                  let result = await AuthService.loginAs({ userName: impersonateAs, password: '' });
                  if (result?.memberships && result?.memberships.length > 0) {
                    if (!result?.memberships.some((x) => x.companyId == companyId && x.companyEnabled)) {
                      changeCompany?.(result?.latestLoginCompanyId ?? result?.memberships[0].companyId);
                      history.push(EpdLinks.dashboard());
                    } else {
                      history.go(0);
                    }
                  } else {
                    ///  setDefaultOrFirstCompany?.();
                    history.go(0);
                  }
                } catch (e) {
                  console.error(e);
                  ///// setServerError(t('footer.impersonate.errorMessage'));
                } finally {
                  onHide(true);
                }
              }
              // EpdStateService.declineVerification(epd?.id, true, value).then(() => {
              //   refreshEPD(epd?.id);
              //   onHide(true);
              // })
            }
            actionState={ActionState.Enabled}
          />
        </ButtonContainer>
      </div>
    </EditDialog>
  );
};

const ButtonContainer = styled.div`
  margin: 30px 10px 10px 0px;
`;
export default ImpersonateDialog;
