import styled from 'styled-components';
import { AdminLicenseeModel } from 'services/EpdClient';
import LicenseeItem from './LicenseeItem';

type TProps = {
  licensees: AdminLicenseeModel[];
  fetchLicensees: () => {};
  handleEditLicensee: (licensee: AdminLicenseeModel) => void;
};

const LicenseeList = ({ licensees, fetchLicensees, handleEditLicensee }: TProps) => {
  return (
    <WelcomeBox>
      {licensees.map((x) => (
        <LicenseeItem key={x.id} licensee={x} fetchLicensees={fetchLicensees} handleEditLicensee={() => handleEditLicensee(x)} />
      ))}
    </WelcomeBox>
  );
};

const WelcomeBox = styled.div`
  width: 100%;
  border-radius: 4px;
  text-align: center;
  padding-top: 0rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: top;

  > div {
    flex: 1;
  }
`;

export default LicenseeList;
