import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { A } from 'styles/Styles.styled';

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <MainFooter>
      {t('footer.text')}
      <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>{'|'}</span>
      <A href={t('footer.link')}>{t('footer.linkText')}</A>
    </MainFooter>
  );
};

const MainFooter = styled.footer`
  width: 100%;
  height: 70px;
  margin-top: auto;
  background-color: ${(props) => props.theme.colors.secondaryLightGreen};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media print {
    display: none;
  }
`;

export default Footer;
