import React from 'react';
import useIsReadOnlyMode from '../compiler/hooks/useIsReadOnlyMode';
import styled from 'styled-components';
import { Button } from 'primereact/button';

type Props = {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

const WizardAddButton: React.FC<Props> = ({ label, onClick, disabled }) => {
  const isReadOnly = useIsReadOnlyMode();
  if (isReadOnly) {
    return null;
  }

  return (
    <AddButtonBox>
      <Button type="button" label={label} onClick={onClick} disabled={disabled} text />
    </AddButtonBox>
  );
};

export default WizardAddButton;

const AddButtonBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  justify-content: start;
  display: flex;
  max-height: 70px;

  .p-button.p-button-text {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 0;
    background-color: transparent;
    border: none;
    margin-top: 0.25rem;
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;
