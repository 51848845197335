import { AdminDataSourcesClient } from '../EpdClient';

const client = new AdminDataSourcesClient(process.env.REACT_APP_API_URL);

class AdminDataSourcesService {
  static find = async (filter?: string) => {
    return await client.getAll(filter);
  };

  static get = async (id?: string) => {
    return await client.get(id as any);
  };

  static update = async (id: string, values: any) => {
    const result = await client.update(id, values);
    return result;
  };
}

export default AdminDataSourcesService;
