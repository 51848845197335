import ManageCollections from 'components/collections/ManageCollections';
import { ProcessStatus } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useEffect } from 'react';
import { Aside, Container, H2, MainView } from 'styles/Styles.styled';

const CompanyCollections: React.FunctionComponent = () => {
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const { company, changeCompany } = React.useContext(CompanyContext);

  return (
    <Container>
      <MainView>
        <H2>{company?.name}&nbsp;</H2>
        <ManageCollections companyId={company?.id || ''} key={'collections'} />
      </MainView>
      {status && <Aside style={{ backgroundColor: 'white' }}> </Aside>}
    </Container>
  );
};

export default CompanyCollections;
