import { HelpBox } from 'components/v2/help-boxes';
import React from 'react';
import { FormPanel, HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

const AwaitingProductInformation: React.FunctionComponent = () => {
  return (
    <FormPanel style={{ display: 'block' }}>
      <HeaderCaptionSemiBold className="white-space-nowrap">Epd product information</HeaderCaptionSemiBold>
      <HelpBox style={{ marginTop: '30px' }}>
        The EPD owner and/or relevant roles need to fill the mandatory fields in Step 2 &gt; Product information.
        <br />
        EPD verifier will be able to check the filled information in Step 2 &gt; Product information and EPD document when
        verification process is initiated.
      </HelpBox>
    </FormPanel>
  );
};

export default AwaitingProductInformation;
