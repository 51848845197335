import { LabelGroup } from 'components/v2/form/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { A } from 'styles/Styles.styled';
import { Theme } from 'styles/theme';

import InfoIcon from './InfoIcon';

const ConfirmationAreaOwner = ({ label, labelStyle, tooltip }: any) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <LabelGroup>
        <span style={labelStyle}>{label}</span>
        {tooltip && <InfoIcon content={tooltip} />}
      </LabelGroup>
      <ContentWrapper>
        <p>{t('epdWizard.wizardStep4.properties.confirmation.info1')}</p>
        <p>{t('epdWizard.wizardStep4.properties.confirmation.info2')}</p>
        <p>
          <strong>{t('epdWizard.wizardStep4.properties.confirmation.infoFees')}</strong>
        </p>
        <ServiceAgreementArea href={theme.serviceAgreementUrl}>
          <i
            className="pi pi-external-link"
            style={{ fontSize: '0.75rem', color: theme.colors.primaryWhite, marginRight: '0.5rem' }}
          ></i>
          <p style={{ color: theme.colors.primaryWhite, margin: 0 }}>Service agreement</p>
        </ServiceAgreementArea>
      </ContentWrapper>
    </div>
  );
};

const ContentWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
  padding: 1rem 1.5rem 1rem 1rem;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;

  p {
    margin-top: 0;
  }
`;

const ServiceAgreementArea = styled(A)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 160px;
  height: 2.5vh;
  background-color: ${(props) => props.theme.colors.primaryOrange};
  border-radius: 2px;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightOrange};
  }
`;

export default ConfirmationAreaOwner;
