import 'flag-icons/css/flag-icons.min.css';
import 'primeflex/primeflex.css';

import Checkbox from 'components/form/Checkbox';
import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useRef } from 'react';
import { AdminLicenseeModel } from 'services/EpdClient';
import styled from 'styled-components';
import { Label } from 'styles/Styles.styled';
import { Option, OptionNameCode } from 'types/types';
import * as Yup from 'yup';

import { StyledReactSelect } from '../../../../styles/Styles.styled';
import PrimeDropdownField from '../../form/PrimeDropdownField';
import PrimeInputTextField from '../../form/PrimeInputTextField';
import LicenseeThemeIcon from './LicenseeThemeIcon';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Licensee name is required'),
  countries: Yup.array().min(1, 'Required'),
  supportEmail: Yup.string().email('Invalid email address').required('Email is required'),
  localHomepage: Yup.string().required('Local homepage is required'),
});

type TProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: (item: AdminLicenseeModel) => void;
  item?: AdminLicenseeModel;
  countriesDs: Option[];
  usersDs: Option[];
  themesDs: OptionNameCode[];
  verifiersDs: Option[];
  certificationBodyDs: Option[];
};

const EditLicenseeDialog = ({
  show,
  onHide,
  onSubmit,
  item,
  countriesDs,
  usersDs,
  themesDs,
  verifiersDs,
  certificationBodyDs,
}: TProps) => {
  const formRef = useRef<any>();
  const initialValues: any = item
    ? {
        name: item.name,
        countries: item.countries.map((x) => {
          return { value: x.countryId, label: x.name };
        }),
        users: item.users.map((x) => {
          return { value: x.userId, label: x.name };
        }),
        theme: item.theme,
        supportEmail: item.supportEmail,
        localHomepage: item.localHomepage,
        hasVerifiersList: item.hasVerifiersList,
        licenseeIndividualVerifiers: item.licenseeIndividualVerifiers?.map((x) => {
          return { value: x.id, label: x.name };
        }),
        licenseeCertificationBodyVerifiers: item.licenseeCertificationBodyVerifiers?.map((x) => {
          return { value: x.id, label: x.name };
        }),
      }
    : {
        name: '',
        countries: [],
        users: [],
        theme: 'GL',
        supportEmail: '',
        localHomepage: '',
        hasVerifiersList: false,
        licenseeIndividualVerifiers: [],
        licenseeCertificationBodyVerifiers: [],
      };

  const handleUpdateLicensee = async () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleCancelAddLicensee = async () => {
    onHide();
  };

  const licenseeDialogFooterContent = (
    <div>
      <Button label="Cancel" onClick={handleCancelAddLicensee} className="p-button-text" />
      <Button label="Save" onClick={handleUpdateLicensee} autoFocus />
    </div>
  );

  const selectedCountryTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <LicenseeThemeIcon option={option} />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <LicenseeThemeIcon option={option} />
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <Dialog header="Edit Licensee" visible={show} style={{ minHeight: '80vh' }} onHide={onHide} footer={licenseeDialogFooterContent}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <Column>
              <FieldGroup>
                <Label required>Licensee name</Label>
                <PrimeInputTextField name="name" className={'w-full md:w-30rem'} type="text" placeholder="Licensee name" />
              </FieldGroup>
              <FieldGroup>
                <Label required>Countries</Label>
                <StyledReactSelect
                  name={'countries'}
                  className={'w-full md:w-30rem'}
                  options={countriesDs}
                  isMulti
                  isClearable
                  placeholder="Pick countries in the Licensee"
                />
              </FieldGroup>
              <FieldGroup>
                <Label>Licensee Administrator</Label>
                <StyledReactSelect
                  name={'users'}
                  options={usersDs}
                  isMulti
                  isClearable
                  className={'w-full md:w-30rem'}
                  placeholder="Add Licensee administrator(s)"
                />
              </FieldGroup>
              <FieldGroup>
                <Label required>Support Email</Label>
                <PrimeInputTextField
                  name="supportEmail"
                  className={'w-full md:w-30rem'}
                  type="text"
                  placeholder="Enter email address"
                />
              </FieldGroup>
              <FieldGroup>
                <Label required>Local homepage</Label>
                <PrimeInputTextField
                  name="localHomepage"
                  className={'w-full md:w-30rem'}
                  type="text"
                  placeholder="Enter local homepage address"
                />
              </FieldGroup>
              <FieldGroup>
                <Label required>UI Skin/theme</Label>
                <PrimeDropdownField
                  name="theme"
                  options={themesDs}
                  defaultValue={initialValues.theme}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select theme"
                  className={'w-full md:w-15rem'}
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                />
              </FieldGroup>
              <FieldGroup>
                <Checkbox label={'Has restricted verifiers list'} name="hasVerifiersList" id="HasVerifiersList" />
              </FieldGroup>
              {values.hasVerifiersList && (
                <>
                  <FieldGroup>
                    <Label>Individual verifiers</Label>
                    <StyledReactSelect
                      name={'licenseeIndividualVerifiers'}
                      className={'w-full md:w-30rem'}
                      options={verifiersDs}
                      isMulti
                      isClearable
                      placeholder="Pick individual verifiers"
                    />
                  </FieldGroup>

                  <FieldGroup>
                    <Label>Accredited certification bodies</Label>
                    <StyledReactSelect
                      name={'licenseeCertificationBodyVerifiers'}
                      className={'w-full md:w-30rem'}
                      options={certificationBodyDs}
                      isMulti
                      isClearable
                      placeholder="Pick certification bodies"
                    />
                  </FieldGroup>
                </>
              )}
            </Column>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const Column = styled.div`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  box-sizing: border-box;
  margin: 1rem 0;
`;

const FieldGroup = styled.div`
  width: 100%;
  margin: 0rem 0 1rem 0;
  > div {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }
`;

export default EditLicenseeDialog;
