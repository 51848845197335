import React from 'react';
import styled from 'styled-components';

type TProps = React.PropsWithChildren<{ onCloseButtonClick: React.MouseEventHandler<HTMLElement> | undefined }>;

const ExplanationText = ({ onCloseButtonClick, children }: TProps) => {
  return (
    <ContentWrapper>
      <CloseButton className="pi pi-times" onClick={onCloseButtonClick}></CloseButton>
      {children}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  padding: 1rem 3rem 1rem 2rem;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const CloseButton = styled.i`
  font-size: 0.75rem;
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer;
`;

export default ExplanationText;
