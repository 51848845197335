import React from 'react';
import { useTranslation } from 'react-i18next';
import EpdRoutes from 'routes/EpdRoutes';
import { StyledLink } from 'styles/Styles.styled';

import { CardsEmpty } from './styles';

const Welcome: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <CardsEmpty>
      <h2>{t('epdDashboard.userHasNoCompanies.welcome')}</h2>
      <div>
        {t('epdDashboard.userHasNoCompanies.startEpd')}
        <br />
        <p>
          <StyledLink to={EpdRoutes.account.register.company}>
            {t('epdDashboard.userHasNoCompanies.connectToOrg')}
          </StyledLink>
        </p>
        <p>
          <StyledLink to={EpdRoutes.account.register.company}>{t('epdDashboard.userHasNoCompanies.createOrg')}</StyledLink>
        </p>
      </div>
    </CardsEmpty>
  );
};

export default Welcome;
