import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import HelpIcon from '../icons/HelpIcon';

type SortComparator = (a: any, b: any, direction: Direction) => number;

type Column = {
  label: string;
  field: string;
  fieldStyle?: any;
  sortComparator?: SortComparator;
  tooltip?: string | JSX.Element;
};

enum Direction {
  Asc = 1,
  Desc = -1,
}

const SortedTable: React.FunctionComponent<{
  collection: any[];
  columns: Column[];
  onClick?: (item: any) => void;
}> = ({ collection, columns, onClick }) => {
  const [rows, setRows] = useState<any[]>(collection);
  useEffect(() => {
    setRows(collection);
  }, [collection]);

  const sortFunc = (field: string, direction: Direction, comparator?: SortComparator) => {
    const sortedList = [...rows];
    sortedList?.sort(function (a, b) {
      const aValue = a[field];
      const bValue = b[field];
      if (comparator) {
        return comparator(aValue, bValue, direction);
      }
      if (aValue > bValue) {
        return direction;
      }
      if (aValue < bValue) {
        return -direction;
      }
      return 0;
    });
    setRows(sortedList);
  };

  const sortByField = (event: any, field: string, comparator?: SortComparator) => {
    const classList = event.target.classList;
    if (classList.contains('up')) {
      sortFunc(field, Direction.Asc, comparator);
      classList.remove('up');
      classList.toggle('down');
    } else if (classList.contains('down')) {
      sortFunc(field, Direction.Desc, comparator);
      classList.remove('down');
      classList.toggle('up');
    } else {
      classList.add('down');
      sortFunc(field, Direction.Asc, comparator);
    }
  };

  return (
    <CustomTable style={{ marginTop: '2rem', overflowX: 'auto', fontSize: '14px' }}>
      <>
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th
                key={index}
                onClick={(e) => {
                  sortByField(e, column.field, column.sortComparator);
                }}
              >
                {column.label}
                {column.tooltip && <HelpIcon content={column.tooltip} />}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((item, index) => (
            <tr key={index} onClick={() => onClick?.(item)}>
              {columns.map((column, idx) => (
                <td key={idx} style={column?.fieldStyle}>
                  {item[column.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </>
    </CustomTable>
  );
};

const CustomTable = styled.table`
    &&& {
        border-spacing: 0;
        width: 100%;
        border-top: 1px solid #b6b6b6;
        border-bottom: 1px solid #b6b6b6;
        thead th{
          border-bottom: 1px solid #b6b6b6;
        }
        th {
          background-color: #f0f0f0;
          border-top: 1px solid #f0f0f0;
        }
        th, td {
            text-align: left;
            padding: 5px 2px;
            min-width: 100px;
            cursor: pointer;
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;  
        }
        tr:nth-child{
          padding: 8px;
        }
        tr:nth-child(even){
          background-color: #FAFAFA;
        }
        th.up:after {
            content: " ";
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid rgb(217,218,219);
        }
        th.down:after {
            content: " ";
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid rgb(217,218,219);
        }
`;

export default SortedTable;
