import { ProcessStatus } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import AdminService from 'services/admin/AdminService';
import styled from 'styled-components';
import { ButtonSmall, Container } from 'styles/Styles.styled';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { MainViewFull } from 'styles/v2/Styles.styled';

const FortnoxInvoicesPage: React.FunctionComponent = () => {
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);

  const [selectedDateFrom, setSelectedDateFrom] = useState<any>(null);
  const [selectedDateTo, setSelectedDateTo] = useState<any>(null);

  const [selectedDateFromWithCompany, setSelectedDateFromWithCompany] = useState<any>(null);
  const [selectedDateToWithCompany, setSelectedDateToWithCompany] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState<any>([]);

  useEffect(() => {
    setStatus(ProcessStatus.Fetching);
    const loadCompanies = async () => {
      const companyList = await AdminService.getCompanies();
      if (companyList) {
        setCompanies(companyList.map((x) => ({ name: x.name, code: x.id })));
      }
    };
    loadCompanies();
    setStatus(ProcessStatus.None);
  }, []);

  const startCreateInvoices = async () => {
    if (!selectedDateFrom) {
      showWarningToast('Date from should be selected');
      return;
    }
    if (!selectedDateTo) {
      showWarningToast('Date to should be selected');
      return;
    }

    await AdminService.CreateInvoices(getUTCFrom(selectedDateFrom), getUTCTo(selectedDateTo)).finally(() => {
      showInfoToast('Command for creation quartal invoices already sent');
    });
  };

  const startCreateInvoiceForSpecificCompany = async () => {
    if (!selectedDateFromWithCompany) {
      showWarningToast('Date from should be selected');
      return;
    }
    if (!selectedDateToWithCompany) {
      showWarningToast('Date to should be selected');
      return;
    }
    if (!selectedCompany) {
      showWarningToast('Company is not selected');
      return;
    }

    await AdminService.CreateInvoiceForSpecificCompany(
      getUTCFrom(selectedDateFromWithCompany),
      getUTCTo(selectedDateToWithCompany),
      (selectedCompany as any).code
    ).finally(() => {
      showInfoToast('Command for creation quartal invoice for specific company already sent');
    });
  };

  const getUTCFrom = (date: any) => {
    var dateValue = date as Date;
    return new Date(Date.UTC(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate()));
  };

  const getUTCTo = (date: any) => {
    var dateValue = date as Date;
    return new Date(Date.UTC(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), 23, 59));
  };

  const showInfoToast = (text: string) => {
    toast.info(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const showWarningToast = (text: string) => {
    toast.warning(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  return (
    <Container>
      <MainViewFull>
        <Card title="Fortnox quarterly invoices">
          <p className="m-0" style={{ lineHeight: '35px', fontSize: '17px' }}>
            You could create quarterly invoices for <b>all regular companies</b> (not holding structure) in Fortnox.
          </p>
          <p> To create you should select dates 'from' and 'to' and click "Create invoices"</p>
          <br />
          <p style={{ color: 'red' }}>
            Be careful! Pressing this button in Fortnox will create many invoices (which cannot be deleted, only voided)
          </p>
          <br />
          <p className="m-0">
            <Calendar
              id="buttondisplay"
              value={selectedDateFrom}
              onChange={(e) => setSelectedDateFrom(e.value)}
              showIcon
              dateFormat="dd/mm/yy"
            />{' '}
            <Calendar
              id="buttondisplay"
              value={selectedDateTo}
              onChange={(e) => setSelectedDateTo(e.value)}
              showIcon
              dateFormat="dd/mm/yy"
            />{' '}
            <Button label="Create invoices" severity="info" onClick={() => startCreateInvoices()} text raised />
          </p>
        </Card>
        <br /> <br /> <br />
        <Card title="Quarterly invoices for specific company in Fortnox">
          <p className="m-0" style={{ lineHeight: '35px', fontSize: '17px' }}>
            You could create quarterly invoice for <b>specific company</b> in Fortnox.
          </p>
          <p> To create you should select dates 'from', 'to' and 'company' and click "Create invoice"</p>
          <br />
          <p className="m-0">
            <Calendar
              id="buttondisplay"
              value={selectedDateFromWithCompany}
              onChange={(e) => setSelectedDateFromWithCompany(e.value)}
              showIcon
              dateFormat="dd/mm/yy"
            />{' '}
            <Calendar
              id="buttondisplay"
              value={selectedDateToWithCompany}
              onChange={(e) => setSelectedDateToWithCompany(e.value)}
              showIcon
              dateFormat="dd/mm/yy"
            />{' '}
            <Dropdown
              style={{ whiteSpace: 'nowrap' }}
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.value)}
              options={companies}
              optionLabel="name"
              showClear
              placeholder="Select a company"
              className="w-full md:w-14rem"
              itemTemplate={(option) => {
                return <div style={{ maxWidth: '40rem', wordWrap: 'break-word', fontSize: '12px' }}>{option.name}</div>;
              }}
              filter
            />{' '}
            <Button
              label="Create invoice"
              severity="info"
              onClick={() => startCreateInvoiceForSpecificCompany()}
              text
              raised
            />
          </p>
        </Card>
      </MainViewFull>
    </Container>
  );
};

export default FortnoxInvoicesPage;
