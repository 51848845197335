import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Container, CenterColumn, H1, Text } from '../../../../styles/Styles.styled';
import AdminService from 'services/admin/AdminService';
import { EPDRelationsListItem } from 'services/EpdClient';
import Spinner from 'styles/spinner.styled';
import { isEmpty } from 'lodash';
import SortedTable from 'components/form/SortedTable';
import { EpdLinks } from 'routes/EpdRoutes';
import { useHistory } from 'react-router-dom';

const IlcdEpdMappingList: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [relationsList, setRelationsList] = useState<EPDRelationsListItem[]>([]);
  const [waitingResponse, setWaitingResponse] = useState<boolean>(false);

  const titles = [
    { label: 'EPD ver. id', field: 'epdVersionId', fieldStyle: { width: '250pt' } },
    { label: 'Reg. date', field: 'registrationDate', fieldStyle: { width: '170pt' } },
    { label: 'Publ. portal', field: 'isPublishedOnPortal', fieldStyle: { width: 'auto' } },
    { label: 'Publ. SODA', field: 'wasPublishedOnSODA', fieldStyle: { width: 'auto' } },
    { label: 'Has relation', field: 'hasCreatedRelation', fieldStyle: { width: 'auto' } },
    { label: 'Action', field: 'actionText', fieldStyle: { width: 'auto' } },
  ];

  const createOrOpenRelation = async (item: EPDRelationsListItem) => {
    if (item.hasCreatedRelation && item.relationId) {
      return history.push(EpdLinks.ilcdEpdMapping(item.relationId));
    }
    const result = await AdminService.createIlcdEpdMapping(item.epdVersionId);
    result.id && history.push(EpdLinks.ilcdEpdMapping(result.id));
  };

  useEffect(() => {
    const fetchMappingList = async () => {
      var result = await AdminService.GetILCDEPDMappingList(id);
      if (result) {
        setRelationsList(result);
      }
    };

    try {
      setWaitingResponse(true);
      fetchMappingList();
      setWaitingResponse(false);
    } catch {
      setWaitingResponse(false);
    }
  }, [id]);
  return (
    <Container>
      {waitingResponse && (
        <div>
          <Spinner />
        </div>
      )}{' '}
      {!isEmpty(relationsList) ? (
        <div style={{ marginTop: '-1.2rem' }}>
          <SortedTable columns={titles} collection={relationsList ?? []} onClick={createOrOpenRelation} />
        </div>
      ) : (
        <CenterColumn>
          <H1>EPD has no registered versions.</H1>
          <Text>Oops. You can only manage registered versions.</Text>
        </CenterColumn>
      )}
    </Container>
  );
};

export default IlcdEpdMappingList;
