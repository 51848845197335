import { EPDModel } from 'services/EpdClient';
import styled from 'styled-components';

import { StatusCheckIcon } from '../icons';

const EpdItem = (epd: EPDModel, selected: boolean) => {
  return (
    <EpdCollectionItemBox>
      <EpdTitle>
        {`${epd?.registrationNumber} - ${epd?.status}`}
        {selected ? <StatusCheckIcon /> : <i className={'pi pi-circle'}></i>}
      </EpdTitle>
      <EpdName>{epd?.name}</EpdName>
    </EpdCollectionItemBox>
  );
};

const EpdCollectionItemBox = styled.div`
  align-self: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  background: rgba(180, 208, 182, 0.3);
  border-radius: 2px;
  margin: 4px 0px;
  padding: 0.75rem 1rem;
`;

const EpdTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: rgba(21, 21, 21, 0.5);
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const EpdName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #151515;
`;

export default EpdItem;
