import { LcaResultsAdditionalGridColumns, LcaResultsAdditionalGridColumnsPdf } from './LcaResultsGridColumns';
import LcaResultsGridExtended from './LcaResultsGridExtended';
import LcaResultsGridExtendedPdf from './LcaResultsGridExtendedPdf';

type TProps = {
  epdVersionId: string;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaResultsGridAdditionalVoluntary = ({
  epdVersionId,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const notes = [
    {
      note: 'Acronyms',
      description: '',
      placeholder: 'Acronym1 description; Acronym2  - description; etc.',
    },
  ];

  if (isForPdf) {
    return (
      <LcaResultsGridExtendedPdf epdVersionId={epdVersionId} columns={LcaResultsAdditionalGridColumnsPdf} notes={notes} />
    );
  }
  return (
    <LcaResultsGridExtended
      epdVersionId={epdVersionId}
      columns={LcaResultsAdditionalGridColumns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridAdditionalVoluntary;
