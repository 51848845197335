import EpdVerificationDescription from 'components/v2/copy/epd-details/EpdVerificationDescription';
import ProcessCertificateDescription from 'components/v2/copy/epd-details/ProcessCertificateDescription';
import EpdPanelHeaderProcessCertificateTemplate from 'components/v2/copy/header/EpdPanelHeaderProcessCertificateTemplate';
import EpdPanelHeaderVerificationStepTemplate from 'components/v2/copy/header/EpdPanelHeaderVerificationStepTemplate';
import { Paginator } from 'primereact/paginator';
import React, { useState } from 'react';
import { EPDModel, EpdCopyGroupWizardModel, FileLoadingModel, FileModel, VerificationType } from 'services/EpdClient';
import { EpdItemPanel } from 'styles/v2/Styles.styled';

const WizardStep3: React.FunctionComponent<{
  groupData?: EpdCopyGroupWizardModel;
  onChangeEpd: any;
  refreshEPD: any;
  canUpload: boolean;
  isReadOnly: boolean;
  updateGroupCertificateFilesArray: any;
  fileLoading?: FileLoadingModel[];
}> = ({ groupData, onChangeEpd, refreshEPD, canUpload, isReadOnly, updateGroupCertificateFilesArray, fileLoading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
  const handleChangePage = (event: any) => {
    setCurrentPage(event.page + 1); // Paginator starts counting from 0
  };
  const onChangeEpdInternal = (id: string, versionId: string, name: string, value: any) => {
    onChangeEpd(id, versionId, name, value);
  };

  return (
    <div>
      {groupData?.verificationType === VerificationType.EPDProcessCertification && (
        <EpdItemPanel
          key={'ProcessCertificatesFiles'}
          headerTemplate={(options) =>
            EpdPanelHeaderProcessCertificateTemplate(
              options,
              groupData?.processCertificatesFiles || [],
              groupData?.id || '',
              updateGroupCertificateFilesArray,
              canUpload
            )
          }
          toggleable
          collapsed
        >
          <ProcessCertificateDescription processCertificates={groupData?.processCertificatesFiles || []} />
        </EpdItemPanel>
      )}

      {groupData?.epds?.slice(startIdx, startIdx + ITEMS_PER_PAGE).map((item: EPDModel) => (
        <EpdItemPanel
          key={item.id}
          headerTemplate={(options) =>
            EpdPanelHeaderVerificationStepTemplate(
              options,
              item,
              (name: string, value: any) => onChangeEpdInternal(item.id!, item.versionId!, name, value),
              refreshEPD,
              canUpload,
              isReadOnly,
              fileLoading
            )
          }
          toggleable
          collapsed
        >
          <EpdVerificationDescription
            epd={item}
            groupStatus={groupData?.status}
            isVerifier={groupData?.userRoles?.isVerifier}
            onChangeEpd={(name: string, value: any) => onChangeEpdInternal(item.id!, item.versionId!, name, value)}
          />
        </EpdItemPanel>
      ))}

      <Paginator
        className="paginator-right"
        style={{ padding: 0, marginTop: '1rem' }}
        alwaysShow={false}
        first={startIdx}
        rows={ITEMS_PER_PAGE}
        totalRecords={groupData?.epds?.length}
        onPageChange={handleChangePage}
        template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        rowsPerPageOptions={[ITEMS_PER_PAGE]}
      />
    </div>
  );
};

export default WizardStep3;
