export const useGetRegexVATPattern = (selectedCountry: string) => {
  switch (selectedCountry) {
    case 'Austria':
      return /^ATU\d{8}$/;

    case 'Belgium':
      return /^BE\d{10}$/;

    case 'Bulgaria':
      return /^BG\d{9,10}$/;

    case 'Croatia':
      return /^HR\d{11}$/;

    case 'Cyprus':
      return /^CY\d{8}[A-Z]$/;

    case 'Czech Republic':
      return /^CZ\d{8,10}$/;

    case 'Denmark':
      return /^DK\d{8}$/;

    case 'Estonia':
      return /^EE\d{9}$/;

    case 'Finland':
      return /^FI\d{8}$/;

    case 'France':
      return /^FR\d{11}$/;

    case 'Germany':
      return /^DE\d{9}$/;

    case 'Greece':
      return /^EL\d{9}$/;

    case 'Hungary':
      return /^HU\d{8}$/;

    case 'Ireland':
      return /^IE\d{1}[A-Z]\d{5}[A-Z]$/;

    case 'Italy':
      return /^IT\d{11}$/;

    case 'Latvia':
      return /^LV\d{11}$/;

    case 'Lithuania':
      return /^LT\d{9,12}$/;

    case 'Luxembourg':
      return /^LU\d{8}$/;

    case 'The Netherlands':
      return /^NL\d{9}B\d{2}$/;

    case 'Poland':
      return /^PL\d{10}$/;

    case 'Portugal':
      return /^PT\d{9}$/;

    case 'Romania':
      return /^RO\d{2,10}$/;

    case 'Malta':
      return /^MT\d{8}$/;

    case 'Slovakia':
      return /^SK\d{10}$/;

    case 'Slovenia':
      return /^SI\d{8}$/;

    case 'Spain':
      return /^ES[A-Z0-9]\d{7}[A-Z0-9]$/;

    case 'Sweden':
      return /^SE\d{10}01$/;

    default:
      return null;
  }
};
