import { StyledAutocomplete } from 'styles/v2/Styles.styled';

import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';
import { AutoCompleteChangeEvent, AutoCompleteSelectEvent } from 'primereact/autocomplete';
import { useEffect, useState } from 'react';
import useIsReadOnlyMode from '../compiler/hooks/useIsReadOnlyMode';

type TProps = Partial<TSmartLabelProps> & {
  value: any;
  name: string;
  onChanged?: any;
  onSelectSuggested?: any;
  disabled?: boolean;
  placeholder?: string;
  suggestions?: any;
  completeMethod?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;
const WizardTextInputWithAutoComplete: React.FunctionComponent<TProps> = ({
  label,
  tooltip,
  tooltipPosition,
  labelStyle,
  name,
  value,
  onChanged,
  onSelectSuggested,
  disabled,
  placeholder,
  required,
  error,
  suggestions,
  completeMethod,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(value);
  const isReadOnly = useIsReadOnlyMode() || disabled;

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!value}
          error={error}
        />
      )}
      <StyledAutocomplete
        value={selectedValue}
        suggestions={suggestions}
        completeMethod={completeMethod}
        onBlur={(e: any) => {
          e.target.value !== value?.toString() && onChanged(name, selectedValue);
        }}
        onSelect={(e: AutoCompleteSelectEvent) => onSelectSuggested(e.value)}
        onChange={(e: AutoCompleteChangeEvent) => {
          setSelectedValue(e.value);
        }}
        disabled={isReadOnly}
        placeholder={isReadOnly ? undefined : placeholder}
      />
    </div>
  );
};

export default WizardTextInputWithAutoComplete;
