import { useState } from 'react';

const useHelpDialog = () => {
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const [helpDialogTitle, setHelpDialogTitle] = useState<string>('Help');

  const openHelpDialog = (title: string) => {
    setHelpDialogTitle(title);
    setIsHelpDialogOpen(true);
  };

  const closeHelpDialog = () => {
    setIsHelpDialogOpen(false);
  };

  return {
    isHelpDialogOpen,
    openHelpDialog,
    closeHelpDialog,
    helpDialogTitle,
  };
};

export default useHelpDialog;
