import 'primeflex/primeflex.css';

import { Tag } from 'primereact/tag';
import { EPDCollectionStatus } from 'services/EpdClient';
import styled from 'styled-components';

type TProps = {
  status: EPDCollectionStatus;
};

export const CollectionStatusBadge = ({ status }: TProps) => {
  return <StatusBox status={status} value={status} />;
};

const StatusBox = styled(Tag)<{ status: EPDCollectionStatus }>`
  max-width: 6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.5rem;
  border-radius: 2px;
  padding: 4px 10px;
  text-transform: uppercase;
  background-color: ${(props) => {
    switch (props.status) {
      case EPDCollectionStatus.Created:
        return '#F6E5AF';
      case EPDCollectionStatus.Published:
        return '#E5F8F1';
      case EPDCollectionStatus.Depublished:
        return '#FCF4F7';
      default:
        return '#EBECF4';
    }
  }};
  color: ${(props) => {
    switch (props.status) {
      case EPDCollectionStatus.Created:
        return '#D48540';
      case EPDCollectionStatus.Published:
        return '#4A9559';
      case EPDCollectionStatus.Depublished:
        return '#DA605B';
      default:
        return '#7F8081';
    }
  }};
`;

export default CollectionStatusBadge;
