import { useEffect, useState } from 'react';

import { StyledPrimeCheckbox } from '../form/styled';
import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';

type TProps = Partial<TSmartLabelProps> & {
  name: string;
  value: boolean;
  style?: any;
  onChanged: any;
  disabled?: boolean;
  inputId?: string;
};

const WizardCheckboxComponent: React.FunctionComponent<TProps> = ({
  label,
  labelStyle,
  tooltip,
  tooltipPosition,
  required,
  name,
  value,
  onChanged,
  disabled,
  style,
  inputId,
  ...props
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(value);
  }, [value]);
  return (
    <>
      <StyledPrimeCheckbox
        inputId={inputId}
        onChange={(e) => {
          setChecked(e.checked as boolean);
          onChanged(name, e.checked);
        }}
        checked={checked}
        disabled={disabled}
        {...props}
      />

      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={{ textTransform: 'none', fontSize: '14px', ...labelStyle }}
          boxStyle={{ margin: 0 }}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={checked}
          htmlFor={inputId}
        />
      )}
    </>
  );
};

export default WizardCheckboxComponent;
