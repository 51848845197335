import { CanceledError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEpdPdfDocumentPreview } from 'services/api/epdApi';

const EpdCompilerDocumentPreview: React.FunctionComponent = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const { id } = useParams<any>();

  useEffect(() => {
    const abortController = new AbortController();

    const fetchPdf = async () => {
      try {
        const pdfUrl = await getEpdPdfDocumentPreview(id, { signal: abortController.signal });
        setPdfUrl(pdfUrl);
        setLoading(false);
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }
        console.error('Error generating PDF:', error);
        setLoading(false);
      }
    };

    if (id) {
      fetchPdf();
    }

    return () => abortController.abort();
  }, [id]);

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {loading ? (
        <p>Loading PDF...</p>
      ) : pdfUrl ? (
        <object data={pdfUrl} type="application/pdf" width="100%" height="100%">
          <p>
            Your browser does not support PDFs. Download the PDF to view it: <a href={pdfUrl}>Download PDF</a>.
          </p>
        </object>
      ) : (
        <p>Failed to load PDF.</p>
      )}
    </div>
  );
};

export default EpdCompilerDocumentPreview;
