import { VerificationType } from 'services/EpdClient';

export default function getVerificationTypeLabel(verificationType: string) {
  switch (verificationType) {
    case VerificationType.EPDVerification:
      return 'Individual EPD verification';
    case VerificationType.EPDProcessCertification:
      return 'EPD Process Certification';
    case VerificationType.EPDPreverifiedTool:
      return 'EPD Pre-verified tool';
    default:
      throw new Error('Invalid verification type has been passed.');
  }
}
