import * as React from 'react';
import styled from 'styled-components';

export const ExpandButton = (props: {
  isOpen: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  return (
    <Button onClick={props.onClick}>
      <svg
        viewBox="0 0 10 10"
        width="8"
        height="8"
        style={{ transform: `rotate(${props.isOpen ? 180 : 0}deg)`, transition: 'transform 200ms' }}
      >
        <path stroke="currentColor" strokeWidth="1" fill="none" d="m1,2l4,6l4,-6" />
      </svg>
    </Button>
  );
};

const Button = styled.button`
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 100%;
  padding: 0;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
