export const editAdminSelectStyles = {
  menu: (styles: any) => ({ ...styles }),
  control: (styles: any) => ({
    ...styles,
    borderColor: 'gray',
    fontSize: '14px',
    lineHeight: '17px',
    borderRadius: '3px',
    boxShadow: 'none !important',
    color: '#222',
    margin: 0,
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: '100%',
  }),
};
