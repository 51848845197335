import {
  LcaCustomResultGridDataModel,
  LcaCustomResultModel,
  LcaResultModel,
  LcaResultsViewModel,
} from 'types/types';

import { QUERY_KEYS } from './constants';
import {
  createEpdLcaResultAdditional,
  createLcaCustomResult,
  createLcaCustomResultGridDataRow,
  deleteEpdLcaResultsAdditional,
  deleteLcaCustomResult,
  deleteLcaCustomResultGridDataRows,
  updateEpdLcaResultAdditional,
  updateLcaCustomResult,
  updateLcaCustomResultGridDataRow,
  upsertEpdLcaResult,
} from './epdLcaResultsApi';
import { useGenericMutation } from './mutations';
import useCompilerOptimisticMutation from './useCompilerOptimisticMutation';

export const useUpsertEpdLcaResult = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<LcaResultModel, LcaResultsViewModel>({
    mutationFn: (data) => upsertEpdLcaResult(data),
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

// LCA Results additional
export const useCreateEpdLcaResultAdditional = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<LcaResultModel, LcaResultModel[]>({
    mutationFn: (data) => createEpdLcaResultAdditional(data),
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return [...queryData, args];
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdLcaResultAdditional = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<LcaResultModel, LcaResultModel[]>({
    mutationFn: (data) => updateEpdLcaResultAdditional(data),
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.ord === args.ord ? args : d));
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteEpdLcaResultsAdditional = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string[], LcaResultModel[]>({
    mutationFn: (ids) => deleteEpdLcaResultsAdditional(ids),
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL, epdVersionId],
    applyOptimisticChanges(queryData, ids) {
      return queryData.filter((d) => !d.id || !ids.includes(d.id));
    },
    serialScopeId: epdVersionId,
  });
};

// LCA custom results
export const useCreateLcaCustomResult = () => {
  return useGenericMutation((data: any) => createLcaCustomResult(data), [[QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS]]);
};

export const useDeleteLcaCustomResult = () => {
  return useGenericMutation((id: string) => deleteLcaCustomResult(id), [[QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS]]);
};

export const useUpdateLcaCustomResult = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<LcaCustomResultModel, LcaCustomResultModel[]>({
    mutationFn: (data) => updateLcaCustomResult(data),
    queryKey: [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
    onAllSettled({ queryClient, args }) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, args.id] });
    },
  });
};

// LCA custom results grid data
export const useCreateLcaCustomResultGridDataRow = (epdVersionId: string, customResultId: string) => {
  return useCompilerOptimisticMutation<LcaCustomResultGridDataModel, LcaCustomResultGridDataModel[]>({
    mutationFn: (data) => createLcaCustomResultGridDataRow(data),
    queryKey: [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, customResultId],
    applyOptimisticChanges(queryData, args) {
      return [...queryData, args];
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteLcaCustomResultGridDataRows = (epdVersionId: string, customResultId: string) => {
  return useCompilerOptimisticMutation<string[], LcaCustomResultGridDataModel[]>({
    mutationFn: (ids) => deleteLcaCustomResultGridDataRows(epdVersionId, ids),
    queryKey: [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, customResultId],
    applyOptimisticChanges(queryData, ids) {
      return queryData.filter((d) => !d.id || !ids.includes(d.id));
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateLcaCustomResultGridDataRow = (epdVersionId: string, customResultId: string) => {
  return useCompilerOptimisticMutation<LcaCustomResultGridDataModel, LcaCustomResultGridDataModel[]>({
    mutationFn: (data) => updateLcaCustomResultGridDataRow(data),
    queryKey: [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, customResultId],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.ord === args.ord ? args : d));
    },
    serialScopeId: epdVersionId,
  });
};
