import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import styled from 'styled-components';

const LoadingCopies: React.FunctionComponent = () => {
  return (
    <>
      <StyledSkeleton height="70px" />
      <StyledSkeleton height="70px" />
      <StyledSkeleton height="70px" />
      <StyledSkeleton height="70px" />
      <StyledSkeleton height="70px" />
      <StyledSkeleton height="70px" />
      <StyledSkeleton height="70px" />
    </>
  );
};
const StyledSkeleton = styled(Skeleton)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.elementsInputBorder};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
  border: 0;
  margin: 1rem 0;
`;

export default LoadingCopies;
