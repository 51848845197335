import { EpdLinks } from 'routes/EpdRoutes';
import { EPDCopyGroupStatus, EpdGroupDashboardModel } from 'services/EpdClient';
import { EPDGroupCardDashboardStatus } from 'styles/v2/Styles.styled';

import { Card, CardHeading, CardName, CopyGroupCard } from './styles';

type TProps = {
  epdGroup: EpdGroupDashboardModel;
  background: any;
};

const EpdGroupCard = ({ epdGroup, background }: TProps) => {
  const groupStatus = epdGroup.status === EPDCopyGroupStatus.ApprovedByLicenseeAdmin ? 'Sent for publication' 
  : epdGroup.status === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin ? 'Declined publication'
  : epdGroup.status;
  return (
    <CopyGroupCard to={EpdLinks.editCopyEpd(epdGroup.id)} background={background}>
      <CardHeading>
        <i className="pi pi-copy" />
        {` EPD copy group`}
      </CardHeading>
      <CardHeading>{`with ${epdGroup.itemsCount} item(s)`}</CardHeading>
      <CardName>{epdGroup.name}</CardName>
      <EPDGroupCardDashboardStatus status={epdGroup.status}>{groupStatus}</EPDGroupCardDashboardStatus>
    </CopyGroupCard>
  );
};

export default EpdGroupCard;
