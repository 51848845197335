import { Dialog } from 'primereact/dialog';
import { PropsWithChildren } from 'react';
import { PrimaryButton, SecondaryButton } from 'components/v2/buttons';
import { DialogContentContainer } from './styled';

type TProps = PropsWithChildren<{
  isOpened: boolean;
  header: string;
  onHide: any;
  maximizable?: boolean;
  onChange: any;
  contentStyle?: any;
}>;

const PcrSelectorDialog = ({
  isOpened,
  header,
  onHide,
  children,
  maximizable,
  onChange,
  contentStyle,
  ...props
}: TProps) => {
  const footerContent = (
    <div>
      <SecondaryButton label="Cancel" onClick={onHide} outlined />
      <PrimaryButton label="Add" onClick={onChange} autoFocus />
    </div>
  );

  return (
    <Dialog
      header={header}
      visible={isOpened}
      style={{ width: '40vw' }}
      contentStyle={contentStyle}
      onHide={onHide}
      footer={footerContent}
      maximizable={maximizable}
      {...props}
    >
      <DialogContentContainer>{children}</DialogContentContainer>
    </Dialog>
  );
};

export default PcrSelectorDialog;
