import { UploadDocumentTypesClient } from './EpdClient';

const client = new UploadDocumentTypesClient(process.env.REACT_APP_API_URL);

class UploadDocumentTypesService {
  static getDocumentTypes = async () => {
    return await client.getDocumentTypes();
  };
}

export default UploadDocumentTypesService;
