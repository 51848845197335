export default class CompilerStepsValidator {
  private steps: CompilerSingleStepErrorChecker[] = [];

  registerStep(stepNumber: number, configureStep: (options: CompilerSingleStepErrorChecker) => void) {
    const newStep = new CompilerSingleStepErrorChecker(stepNumber);
    configureStep(newStep);
    this.steps.push(newStep);
    return this;
  }

  isStepValid(stepNumber: number, errors: any): boolean {
    if (typeof errors !== 'object') {
      return true;
    }

    const step = this.steps.find((s) => s.stepNumber === stepNumber);
    if (!step) {
      throw new Error(`Step ${stepNumber} not registered.`);
    }

    for (const field in errors) {
      if (step.isErrorFromThisStep(field)) {
        return false;
      }
    }

    this.ensureAllErrorsAreReflectedInSteps(errors);
    return true;
  }

  private ensureAllErrorsAreReflectedInSteps(errors: any) {
    if (process.env.NODE_ENV !== 'development') {
      return;
    }
    // Notifies in case a new error was added, and the developer forgot to associate it with a step.
    // This helps to detect the issue when a field displays an error but its step is still marked with green checkmark.
    for (const field in errors) {
      let isStepFound = false;
      for (const step of this.steps) {
        if (step.isErrorFromThisStep(field)) {
          isStepFound = true;
          break;
        }
      }

      if (!isStepFound) {
        throw new Error(`Error '${field}' does not correspond to any step. Please go and assign it.`);
      }
    }
  }
}

class CompilerSingleStepErrorChecker {
  public stepNumber: number;

  private fieldNames: string[] = [];
  private patterns: RegExp[] = [];

  constructor(stepNumber: number) {
    this.stepNumber = stepNumber;
  }

  withFields(...fieldNames: (string | RegExp)[]) {
    for (const field of fieldNames) {
      if (typeof field === 'string') {
        this.fieldNames.push(field);
        continue;
      }
      this.patterns.push(field);
    }

    return this;
  }

  isErrorFromThisStep(fieldName: string): boolean {
    if (this.fieldNames.includes(fieldName)) {
      return true;
    }

    for (const regExp of this.patterns) {
      if (fieldName.match(regExp)) {
        return true;
      }
    }
    return false;
  }
}
