import React from 'react';
import DebugOnly from 'util/dev-tools/DebugOnly';
import RenderCounter from 'util/dev-tools/RenderCounter';

import { PDF_H1, PDF_P, PDF_PAGE } from './Styles.styled';

const ReferencePage: React.FunctionComponent<{
  references?: string;
}> = ({ references }) => {
  return (
    <PDF_PAGE>
      <DebugOnly>
        <RenderCounter componentName="ReferencesPage" threshold={3} />
      </DebugOnly>
      <PDF_H1>References</PDF_H1>
      <PDF_P dangerouslySetInnerHTML={{ __html: references ?? '' }} />
    </PDF_PAGE>
  );
};

export default ReferencePage;
