import ErrorIcon from 'images/v2/icons/svg/fill/svg/error.svg';
import { ReactNode } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';

type TProps = {
  severity?: 'success' | 'warning' | 'info' | 'error';
  summary?: string | undefined | ReactNode;
  details?: string | undefined | ReactNode;
};

const ToastTemplate = ({ summary, details }: TProps) => {
  return (
    <Container>
      <b>{summary}</b>
      <p style={{ marginBottom: 0 }}>{details}</p>
    </Container>
  );
};

export const toaster = ({ severity, summary, details }: TProps) => {
  switch (severity) {
    case 'success':
      return toast.success(<ToastTemplate summary={summary} details={details} />);
    case 'warning':
      return toast.warning(<ToastTemplate summary={summary} details={details} />);
    case 'info':
      return toast.info(<ToastTemplate summary={summary} details={details} />);
    case 'error':
      return toast.error(<ToastTemplate summary={summary} details={details} />, {
        icon: ({ theme, type }) => <img src={ErrorIcon} alt="Error" />,
      });
    default:
      return toast.success(<ToastTemplate summary={summary} details={details} />);
  }
};

const Container = styled.div`
  padding-top: 3px;
`;

const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    min-width: 400px;
  }
  .Toastify__toast-theme--light {
    color: #151515;
    font-size: 14px;
  }
  .Toastify__close-button--light {
    opacity: 1;
  }
`;

export const ApplicationToastRoot = () => {
  return (
    <StyledToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};
