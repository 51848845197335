import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import EpdRoutes from 'routes/EpdRoutes';
import { LinkedCompanyModel } from 'services/EpdClient';

const CompanySettingsLink: React.FunctionComponent<{ company: LinkedCompanyModel }> = ({ company }) => {
  const { changeCompany } = useContext(CompanyContext);
  return (
    <Link
      to={EpdRoutes.param(EpdRoutes.admin.companies.editCompany, ':companyid', company.id || '')}
      style={{ all: 'unset', cursor: 'pointer' }}
      onClick={() => changeCompany?.(company.id)}
    >
      {company.name}
    </Link>
  );
};

export default CompanySettingsLink;
