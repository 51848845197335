import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useField } from 'formik';

const PrimeInputTextField = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <div className="p-field">
      <label htmlFor={props.id || props.name}>{label}</label>
      <InputText {...field} {...props} />
      {meta.touched && meta.error && <span className="p-error">{meta.error}</span>}
    </div>
  );
};

export default PrimeInputTextField;
