import ErrorFallback from 'components/error-fallback/ErrorFallback';
import { Column, ColumnProps } from 'primereact/column';
import { DataTableValueArray } from 'primereact/datatable';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { DataTableStyled } from '../StyledGrid';

interface ColumnPropsExtended extends ColumnProps {
  isDataReadonly?: boolean;
  isTitleReadonly?: boolean;
  placeholder?: string;
}

type TProps = {
  value?: DataTableValueArray | undefined;
  columns?: ColumnPropsExtended[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
};

const DynamicGridPdf = ({ value, columns }: TProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DataTableStyled value={value} dataKey="id" tableStyle={{ minWidth: '49rem' }} showGridlines editMode="cell">
        {columns?.map((x) => (
          <Column key={x.field} {...x} hidden={!value?.some((i) => i[x.field!] && i[x.field!] != '')} />
        ))}
      </DataTableStyled>
    </ErrorBoundary>
  );
};

export default DynamicGridPdf;
