import { HelpBox } from 'components/v2/help-boxes';
import React from 'react';
import { FormPanel, HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

const NoPermissions: React.FunctionComponent = () => {
  return (
    <FormPanel style={{ display: 'block' }}>
      <HeaderCaptionSemiBold className="white-space-nowrap">No permissions</HeaderCaptionSemiBold>
      <HelpBox style={{ marginTop: '30px' }}>
        You are not supposed to see this page under the credentials of the user you've logged in.
        <br /> This could be in case you are verifier or practitioner but you are not assigned to this group.
        <br />
        Or the group with the current status is not available for the user.
        <span>Please contact system administrator for more information.</span>
      </HelpBox>
    </FormPanel>
  );
};

export default NoPermissions;
