import { ProcessStatus } from 'constants/constants';
import { FolderIcon } from 'images/icons/png/v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import CollectionService from 'services/CollectionService';
import styled from 'styled-components';
import { ButtonSmall, H1 } from 'styles/Styles.styled';

//import theme from 'styles/theme';

const WelcomeCollections: React.FunctionComponent<{ companyId: string }> = ({ companyId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);

  const createNewCollection = async () => {
    try {
      openCollection('new');
    } catch (error) {
      console.error(error);
    }
  };

  const openCollection = async (collectionId: string) => {
    try {
      setStatus(ProcessStatus.Fetching);
      const route = EpdLinks.editCollection(collectionId);
      history.push(route);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  return (
    <WelcomeBox>
      <IconBox>
        <img src={FolderIcon} alt="Collections folder" />
      </IconBox>

      <H1>{t('manageCollections.collections')}</H1>
      <div>
        Collections help you to organise published EPD documents in a hierarchical and structured way. Classify your EPDs
        under collections to make them easy reachable for the customers in our EPD library!
        <br />
        EPD Collections is available on EPD website under EPD Library section.
      </div>

      <ButtonSmall onClick={() => createNewCollection()}>{t('createCollection')}</ButtonSmall>
    </WelcomeBox>
  );
};

const WelcomeBox = styled.div`
  width: 630px;
  height: 561px;
  margin: auto;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border-radius: 4px;
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div {
    flex: 1;
  }
`;

const IconBox = styled.div`
  width: 220px;
  height: 220px;
  margin: auto;
`;

export default WelcomeCollections;
