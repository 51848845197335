import { useField } from 'formik';
import styled from 'styled-components';
import { ErrorMessage } from 'styles/Styles.styled';

import SmartLabel from '../labels/SmartLabel';

const InputFormField = ({ label, tooltip, labelStyle, tooltipPosition, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {label && (
        <SmartLabel
          label={label}
          required={props.required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={props.value || field.value}
        />
      )}

      <Input {...field} {...props} />
      {meta.touched && meta.error && !props.supressErrors ? (
        <ErrorMessage style={{ margin: '0.5rem 0 0.5rem' }}>{meta.error}</ErrorMessage>
      ) : null}
    </div>
  );
};

const Input = styled.input`
  padding: 1rem;
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.elementsInputBorder)};
  border-radius: 2px;
  margin: 0;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryWhite};
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack70 : props.theme.colors.black)};
  &:focus {
    border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.primaryOrange)};
  }

  &:focus-visible {
    outline: 2px solid
      ${(props) => (props.disabled ? props.theme.colors.elementsInputBorder : props.theme.colors.primaryOrange)};
  }

  &:hover {
    border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.primaryOrange)};
  }

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 44px;
`;

export default InputFormField;
