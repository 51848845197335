import { pcrSelectStyles } from 'components/form/PcrSelect';
import UploadFile from 'components/form/UploadFile';
import { Formik } from 'formik';
import React, { ChangeEvent, FunctionComponent } from 'react';
import Select from 'react-select';
import { ButtonSmall, Input, Label } from 'styles/Styles.styled';
import { FixedLabel, FixedTextAreaComponent, RegBox, Row } from './PcrWizard';

const WizardStep2: FunctionComponent<{
  pcr?: any;
  onChangePcr?: any;
  validation: any;
  goToStep: any;
}> = ({ pcr, onChangePcr, validation, goToStep }) => {
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !pcr) {
      return null;
    }

    onChangePcr && onChangePcr(propertyName, file);
  };

  return (
    <div>
      <Row style={{ margin: 0 }}>
        <FixedLabel style={{ alignSelf: 'start' }}>
          The draft PCR fulfills all requirements in the general programme instructions
        </FixedLabel>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <input
            type="checkbox"
            style={{ margin: '0.5rem 0', alignSelf: 'flex-start' }}
            defaultChecked={pcr?.isFulfilledDraft}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('isFulfilledDraft', e.currentTarget.checked)}
          />
          <Label style={{ marginTop: '1.5rem' }}>Describe and motivate any deviations</Label>
          <FixedTextAreaComponent
            value={pcr?.deviations}
            propertyName={'deviations'}
            onChangePcr={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('deviations', e.currentTarget.value)}
            disabled={false}
          />
        </div>
      </Row>
      <Row>
        <FixedLabel style={{ alignSelf: 'start' }}> Stakeholders to be invited to open consultation</FixedLabel>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.5rem', alignSelf: 'start' }}>
          <Label>Add stakeholders from the list</Label>
          <Select onChange={(e) => {}} isMulti={false} styles={pcrSelectStyles} isClearable={true} />
          <Label style={{ margin: '2rem 0 1rem 0' }}>Add new stakeholders</Label>
          <Formik initialValues={{}} onSubmit={() => {}}>
            <RegBox>
              <Label style={{ margin: '0.5rem 0' }}>Organisation</Label>
              <Input
                value={pcr?.organization}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('organization', e.currentTarget.value)}
              />
              <Label style={{ margin: '0.5rem 0' }}>Email</Label>
              <Input
                value={pcr?.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('email', e.currentTarget.value)}
              />
              <Label style={{ margin: '0.5rem 0' }}>Type of stakeholder</Label>
              <Input
                value={pcr?.stakeholderType}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('stakeholderType', e.currentTarget.value)}
              />
              <Label style={{ marginTop: '0.5rem' }}>Comment</Label>
              <Input
                value={pcr?.comment}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePcr('comment', e.currentTarget.value)}
              />
              <div style={{ alignSelf: 'flex-end' }}>
                <ButtonSmall type="submit">+ Add</ButtonSmall>
              </div>
            </RegBox>
          </Formik>
        </div>
      </Row>
      <Row style={{ marginTop: '0.5rem', justifyContent: 'left' }}>
        <FixedLabel></FixedLabel>
        <div style={{ justifySelf: 'end', marginLeft: '11rem' }}>
          <ButtonSmall>Invite stakeholder</ButtonSmall>
        </div>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Upload PCR draft</FixedLabel>
        <UploadFile
          accept=".pdf"
          title="Please upload PCR draft (PDF)"
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.draftDocuments}
          onChangeFile={onChangePcr}
          field="PCR-DRAFT"
        />
      </Row>
    </div>
  );
};

export default WizardStep2;
