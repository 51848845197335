import HelpIcon from 'components/icons/HelpIcon';
import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, InputRadio, Label } from 'styles/Styles.styled';

const RadioButton = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <RadioButtonLabel htmlFor={props.id || props.name}>
        <div style={{ paddingLeft: '2rem' }}>
          <InputRadio {...field} {...props} type={'radio'} />
        </div>
        <div style={{ alignSelf: 'start' }}>
          {label ? <Label htmlFor={props.id || props.name}>{label}</Label> : null}
          {props?.tooltip && (
            <span>
              <HelpIcon content={props?.tooltip} />
            </span>
          )}
        </div>
      </RadioButtonLabel>
      {meta.touched && meta.error && !props.supressErrors ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  );
};

const RadioButtonLabel = styled(Label)`
  ${(props) => props.theme.fonts.text};
  display: grid;
  grid-template-columns: 10% auto;
  align-items: start;
  grid-column-gap: 2rem;
  margin: 0.5rem 0;
`;

export default RadioButton;
