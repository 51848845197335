import { DataSourcesClient } from './EpdClient';

const client = new DataSourcesClient(process.env.REACT_APP_API_URL);

class DataSourcesService {
  static getDataSources = async () => {
    return await client.get();
  };
}

export default DataSourcesService;
