import { LabelGroup } from 'components/v2/form/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { A } from 'styles/Styles.styled';
import { Theme } from 'styles/theme';

import InfoIcon from './InfoIcon';

const ConfirmationAreaLCAPractitioner = ({ label, labelStyle, tooltip }: any) => {

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: 'auto' }}>
        <LabelGroup>
        <span style={labelStyle}>{label}</span>
        {tooltip && <InfoIcon content={tooltip} />}
      </LabelGroup>
      <ContentWrapper>
        <p>{'EPD owner should accept the service agreement and send the EPD copy group for publication.'}</p>
        <p>{'Please notify EPD owner that EPD copy group is ready to be published.'}</p>
      </ContentWrapper>
    </div>
  );
};

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  border-radius: 4px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 76px;
  color: ${(props) => (props.theme.colors.primaryBlack70)};

  p {
    margin-top: 0rem;
    margin-bottom: 0;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export default ConfirmationAreaLCAPractitioner;
