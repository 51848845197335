import { AccountModel, CollectionEPDModel, CollectionModel, EPDModel, FileModel, FileParameter } from 'services/EpdClient';
import styled from 'styled-components';

import { IconButton } from '../buttons';

const EpdCollectionItem = (epd: CollectionEPDModel, isEditMode: boolean, onRemove: any) => {
  return (
    <EpdCollectionItemBox style={isEditMode ? undefined : { backgroundColor: '#EEF1F4' }} key={epd.id}>
      <div>
        <EpdTitle>{`${epd.fullNumber} - ${epd.epdStatus}`}</EpdTitle>
        <EpdName>{epd.name}</EpdName>
      </div>
      {isEditMode && <IconButton icon="pi pi-trash" text onClick={(e) => onRemove(epd.id)} />}
    </EpdCollectionItemBox>
  );
};

const EpdCollectionItemBox = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  background: rgba(180, 208, 182, 0.3);
  border-radius: 2px;
  margin: 4px 29px;
  padding: 0.75rem 1rem;
`;

const EpdTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: -0.02em;

  /* Primary/Black 50 */

  color: rgba(21, 21, 21, 0.5);
`;

const EpdName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.02em;

  /* Primary/Black */

  color: #151515;
`;

export default EpdCollectionItem;
