import React from 'react';
import { EPDModel } from 'services/EpdClient';
import {
  useCopyProductInformation,
  useCreateEpdProductInformation,
  useDeleteProductInformation,
  useUpdateEpdProductInformation,
} from 'services/api/mutations';
import { useEpdProductInformation } from 'services/api/queries';
import styled from 'styled-components';
import {
  ExpandablePanel,
  ExpandablePanelHeaderName,
  HeaderCaptionSemiBold,
  IconsSection,
  PanelContainer,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel } from 'types/types';

import { IconButton } from '../buttons';
import { confirmDelete } from '../confirm-dialog/ConfirmDelete';
import { StyledCopyIcon } from '../epd-compiler-inputs/common-icons';
import ToggleButton from './panels/toggleable/ToggleButton';
import ProductDataSection from './sections/productInformation/ProductDataSection';
import useIsReadOnlyMode from './hooks/useIsReadOnlyMode';
import { AddCustomBlockButton } from '../buttons/AddCustomBlockButton';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';
import { SINGLE_PRODUCT_ID } from './constants';

const ProductInformationTab: React.FC<{
  epd?: EPDModel;
  epdVersionId: string;
  epdClassification: number | null;
  validationState: any;
}> = ({ epd, epdVersionId, epdClassification, validationState }) => {
  const productInformation = useEpdProductInformation(epdVersionId).data;
  const updateMutation = useUpdateEpdProductInformation(epdVersionId);
  const createMutation = useCreateEpdProductInformation();
  const deleteMutation = useDeleteProductInformation();
  const copyProductMutation = useCopyProductInformation();
  const isReadOnly = useIsReadOnlyMode();

  const confirmDeleteProduct = (product: EPDProductInformationModel) => {
    const productName = product.productName ?? 'Unnamed product';
    confirmDelete({
      caption: `Are you sure you want to delete product ${productName}?`,
      onConfirm: () => deleteMutation.mutate(product.id as string),
    });
  };

  const onChangeEpdProductInformation = async (propertyName: string, val: any, productId: string, versionId: string) => {
    updateMutation.mutate({ productId: productId, versionId: versionId, propertyName: propertyName, newValue: val });
  };

  const addProduct = () => {
    createMutation.mutate({ epdVersionId });
  };

  const handleCopyProduct = (productId: string | undefined) => {
    if (productId) {
      copyProductMutation.mutate({ epdVersionId, id: productId });
    }
  };

  const productErrors = (validationState: any, index: any) => {
    return validationState?.errors?.[`products[${index}]`];
  };

  const headerTemplate = (options: any, product: EPDProductInformationModel, errors: any) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{product.productName ?? `Unnamed product`}</HeaderCaptionSemiBold>
          {errors && <TooltipErrorIcon />}
          <ToggleButton options={options} withMargins />
        </ExpandablePanelHeaderName>
        {!isReadOnly && (
          <IconsSection>
            <DeleteProductIconButton icon="pi pi-trash" text onClick={() => confirmDeleteProduct(product)} />
            <StyledCopyIcon onClick={() => handleCopyProduct(product.id)} />
          </IconsSection>
        )}
      </div>
    );
  };

  return (
    <>
      {epdClassification && epdClassification !== SINGLE_PRODUCT_ID ? (
        <>
          {productInformation?.map((product: EPDProductInformationModel, index: number) => (
            <ExpandablePanelMarginContainer key={product?.id}>
              <ExpandablePanelMultiContainer
                key={product?.id}
                headerTemplate={(options) => headerTemplate(options, product, productErrors(validationState, index))}
                toggleable
                collapsed
              >
                <ProductDataSection
                  product={product}
                  epd={epd}
                  epdId={epd?.id!}
                  epdVersionId={epdVersionId}
                  onChange={onChangeEpdProductInformation}
                  errors={productErrors(validationState, index)}
                />
              </ExpandablePanelMultiContainer>
            </ExpandablePanelMarginContainer>
          ))}
          {!isReadOnly && <AddCustomBlockButton caption="Add product" onClick={addProduct} />}
        </>
      ) : (
        <ExpandablePanelMultiContainer>
          <ProductDataSection
            product={productInformation ? productInformation[0] : undefined}
            epdId={epd?.id!}
            epd={epd}
            epdVersionId={epdVersionId}
            onChange={onChangeEpdProductInformation}
            errors={productErrors(validationState, 0)}
          />
        </ExpandablePanelMultiContainer>
      )}
    </>
  );
};

const ExpandablePanelMarginContainer = styled(PanelContainer)`
  box-shadow: none;
`;

const ExpandablePanelMultiContainer = styled(ExpandablePanel)`
  .p-panel-content {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: grid;
  }
`;

export const DeleteProductIconButton = styled(IconButton)`
  width: 16px !important;
  height: 16px !important;
  &.p-button.p-button-text {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  &.p-button.p-button-text:hover {
    background: transparent;
  }
`;

export default ProductInformationTab;
