import React from 'react';
import { VerifierModel } from 'services/EpdClient';
import { EPDVerifierModel } from 'types/types';
import VerifierCompanyCard from './VerifierCompanyCard';

interface VerifierCompaniesListProps {
  epdVerifier?: EPDVerifierModel | undefined;
  verifierCompanies: VerifierModel[];
  selectedVerifierCompany: VerifierModel | undefined;
  onSelectVerifierCompany: any;
  onRequestCollaboration: any;
  onAddVerifier: any;
  buttonsAreDisabled?: boolean;
}

const VerifierCompaniesList: React.FunctionComponent<VerifierCompaniesListProps> = ({
  epdVerifier,
  verifierCompanies,
  selectedVerifierCompany,
  onSelectVerifierCompany,
  onRequestCollaboration,
  onAddVerifier,
  buttonsAreDisabled,
}) => (
  <div>
    {verifierCompanies.map((company) => (
      <VerifierCompanyCard
        key={company.id}
        data={company}
        onSelectVerifier={() =>
          onSelectVerifierCompany((prev: VerifierModel | undefined) => (prev?.id === company.id ? undefined : company))
        }
        onRequestCollaboration={onRequestCollaboration}
        onAddVerifier={onAddVerifier}
        expanded={selectedVerifierCompany?.id === company.id}
        buttonsAreDisabled={
          company.id === epdVerifier?.companyId || company.information === epdVerifier?.companyName || buttonsAreDisabled
        }
      />
    ))}
  </div>
);

export default VerifierCompaniesList;
