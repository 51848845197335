import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" style={{ backgroundColor: '#FFFFBB', overflowX: 'auto' }}>
      <p style={{ color: 'red' }}>Something went wrong:</p>
      <pre>{error.message}</pre>

      {process.env.REACT_APP_DEBUG && (
        <div>
          <pre>
            {JSON.stringify(
              {
                message: error.message,
                name: error.name,
                stack: error.stack,
              },
              null,
              2
            )}
          </pre>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      )}
    </div>
  );
};

export default ErrorFallback;
