import { ScrollTopButton } from 'components/v2/buttons';
import React, { useEffect } from 'react';

export const withGreyBackground = (WrappedComponent: any) => {
  const WithGreyBackground = (props: any) => {
    useEffect(() => {
      document.body.classList.add('new-ui');
      return () => {
        document.body.classList.remove('new-ui');
      };
    }, []);

    return (
      <>
        <WrappedComponent {...props} />
        <ScrollTopButton icon="pi pi-arrow-circle-up" />
      </>
    );
  };

  return WithGreyBackground;
};
