import { AdminLicenseesClient, CreateLicenseeModel, UpdateLicenseeModel } from '../EpdClient';

const licenseesClient = new AdminLicenseesClient(process.env.REACT_APP_API_URL);

class AdminLicenseesService {
  static getLicensees = async () => {
    return await licenseesClient.getAll();
  };

  static createLicensee = async (licensee: CreateLicenseeModel) => {
    return await licenseesClient.create(licensee);
  };

  static updateLicensee = async (licenseeId: string, licensee: UpdateLicenseeModel) => {
    return await licenseesClient.update(licenseeId, licensee);
  };

  static deleteLicensee = async (licenseeId: string) => {
    return await licenseesClient.delete(licenseeId);
  };

  static getLicenseeCompanies = async (licenseeId: string) => {
    return await licenseesClient.getCompanies(licenseeId);
  };
}

export default AdminLicenseesService;
