import { useField } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ErrorMessage, Input, Label } from 'styles/Styles.styled';

import hideIcon from '../../images/icons/svg/hide.svg';
import viewIcon from '../../images/icons/svg/view.svg';

const PasswordInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  const [exposed, setExposed] = useState<boolean>(false);
  return (
    <>
      {label && <Label htmlFor={props.id || props.name}>{label}</Label>}
      <div style={{ ...props.style, position: 'relative', display: 'flex' }}>
        <Input
          {...field}
          type={exposed ? 'text' : 'password'}
          style={{ ...props.style, flex: 1, paddingRight: '3.5rem' }}
          {...props}
        />
        <Image src={exposed ? hideIcon : viewIcon} alt="eye-icon" onClick={() => setExposed(!exposed)} />
      </div>
      {meta.touched && meta.error && !props.supressErrors ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  );
};

const Image = styled.img`
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 1.25rem;
  cursor: pointer;
`;

export default PasswordInput;
