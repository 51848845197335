import 'primeflex/primeflex.css';

import { confirmDialog } from 'primereact/confirmdialog';
import { CSSProperties, ReactNode } from 'react';

import { DialogContainer } from './styled';

type TProps = {
  contents?: string | undefined | ReactNode;
  acceptLabel?: string | undefined;
  rejectLabel?: string | undefined;
  onConfirm: any;
  onReject?: any | undefined;
  closable?: boolean | undefined;
  contentStyle?: CSSProperties | undefined;
};

export const confirmGeneric = ({
  contents,
  acceptLabel,
  rejectLabel,
  onConfirm,
  onReject,
  closable,
  contentStyle,
}: TProps) => {
  return confirmDialog({
    message: <DialogContainer>{contents}</DialogContainer>,
    rejectLabel: rejectLabel || 'Cancel',
    acceptLabel: acceptLabel || 'Confirm',
    accept: onConfirm,
    reject: onReject,
    headerClassName: 'm-0 p-0',
    className: 'custom-dialog',
    closable: closable ?? false,
    contentStyle: contentStyle,
  });
};
