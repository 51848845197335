import SearchIcon from 'assets/images/search.png';
import React from 'react';
import styled from 'styled-components';
import { FormPanel } from 'styles/v2/Styles.styled';

const NoCopies: React.FunctionComponent = () => {
  return (
    <FormPanel>
      <IconBox>
        <img src={SearchIcon} alt="No EPD copies" />
      </IconBox>
      <TextBox>
        <H3>{'There is no EPD copies yet'}</H3>
        <DescriptionText>{'To create EPD copies please select source EPD under Step 1 - Select source EPD'}</DescriptionText>
      </TextBox>
    </FormPanel>
  );
};

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 20rem;
  width: 45%;
`;

const DescriptionText = styled.p`
  color: ${(props) => props.theme.colors.primaryBlack50};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const H3 = styled.p`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const TextBox = styled.div`
  width: 18rem;
  margin-left: 0;
  margin-right: auto;
`;

export default NoCopies;
