import { Column } from 'primereact/column';
import React, { CSSProperties } from 'react';
import { useGetEpdDeclarationsOfCO2 } from 'services/api/queries';
import { defaultThemeColors } from 'styles/theme';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { DeclarationOfCO2Model } from 'types/types';

import { DataTableStyled } from '../StyledGrid';

const mainRowBodyStyle = {
  minHeight: 32,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 14,
};

const selectedRowStyle: CSSProperties = {
  ...mainRowBodyStyle,
  background: defaultThemeColors.primaryWhite,
};

const unselectedRowStyle: CSSProperties = {
  ...mainRowBodyStyle,
  background: defaultThemeColors.elementsFilledInput,
};

const DeclarationOfCO2GridPdf: React.FunctionComponent<{ epdVersionId: string; disabled: boolean }> = ({
  epdVersionId,
  disabled,
}) => {
  const declarationsOfCO2 = useGetEpdDeclarationsOfCO2(epdVersionId!).data;

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">
        CO<sub>2</sub> uptake associated with carbonation and its assumptions
      </HeaderCaptionSemiBold>
    </div>
  );

  const renderEmptyColumnBody = (rowData: DeclarationOfCO2Model) => {
    return <div style={getRowBodyStyle(rowData)} />;
  };

  const renderNameColumnBody = (rowData: DeclarationOfCO2Model) => {
    const { name } = rowData;
    return <div style={getRowBodyStyle(rowData)}>{name}</div>;
  };

  const renderSelectedRowAssumptionCell = (assumptions: string | undefined) => {
    return <span>{assumptions}</span>;
  };

  const renderAssumptionsColumnBody = (rowData: DeclarationOfCO2Model) => {
    const { assumptions } = rowData;
    return (
      <div style={getRowBodyStyle(rowData)}>
        {isRowSelected(rowData) ? renderSelectedRowAssumptionCell(assumptions) : 'Excluded'}
      </div>
    );
  };

  const isRowSelected = (rowData: DeclarationOfCO2Model) => {
    return rowData.isSelected && !disabled;
  };

  const getRowBodyStyle = (rowData: DeclarationOfCO2Model) => {
    return isRowSelected(rowData) ? selectedRowStyle : unselectedRowStyle;
  };

  return (
    <DataTableStyled
      dataKey="nameId"
      header={header}
      value={declarationsOfCO2}
      showGridlines
    >
      <Column
        headerStyle={{ display: 'none' }}
        style={{ width: '5%' }}
        align="center"
        bodyStyle={{ padding: 0 }}
        body={renderEmptyColumnBody}
      />
      <Column
        field="name"
        headerStyle={{ display: 'none', overflow: 'hidden', position: 'relative' }}
        bodyStyle={{ padding: 0 }}
        body={renderNameColumnBody}
      />
      <Column
        field="assumptions"
        headerStyle={{ display: 'none' }}
        style={{ width: '40%' }}
        bodyStyle={{ padding: 0 }}
        body={renderAssumptionsColumnBody}
      />
    </DataTableStyled>
  );
};

export default DeclarationOfCO2GridPdf;
