import Checkbox from 'components/form/Checkbox';
import SetPasswordModal from 'components/modals/SetPasswordModal';
import { Formik } from 'formik';
import warningIcon from 'images/icons/svg/warning.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AuthService from 'services/AuthService';
import { UserModel2 } from 'services/EpdClient';
import { A, AdminBox, ButtonSmall, Container, EditFull, H2, InfoBox, InfoText, LabelBox } from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import AdminUserService from '../../../services/admin/AdminUserService';
import EditUserMembership from './EditUserMembership';
import UserLicenseeMembership from './UserLicenseeMembership';
import {
  AdminGridForm,
  AdminTextInput,
  ArchivedLabel,
  ArchivedValue,
  ButtonsContainer,
  EmailValue,
  FixedLabel,
  Half,
  TwoColumnsDiv,
} from './style';

const EditUser: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [isSetPasswordOpen, setIsSetPasswordOpen] = useState<boolean>(false);
  const [user, setUser] = useState<UserModel2>();
  const { userid } = useParams<{ userid: string }>();
  const [initialValues, setInitialValues] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const isAdmin = AuthService.isAdmin();
    setIsAdmin(isAdmin ?? false);
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string().email(t('messages.invalidEmail')).nullable().required(t('account.validation.emailRequired')) as any,
    firstName: Yup.string()
      .max(40, ({ max }) => t('account.validation.tooLongFirstName', { max }))
      .matches(/^[^\d]*$/, t('account.validation.invalidFirstName'))
      .nullable()
      .required(t('account.validation.firstNameRequired')),
    lastName: Yup.string()
      .max(40, ({ max }) => t('account.validation.tooLongLastName', { max }))
      .matches(/^[^\d]*$/, t('account.validation.invalidLastName'))
      .nullable()
      .required(t('account.validation.lastNameRequired')),
    emailConfirmed: Yup.boolean(),
    archived: Yup.boolean(),
  });

  const loadUser = async () => {
    try {
      const result = await AdminUserService.getUser(userid);
      setUser(result);
      setIsLoaded(true);
      if (result) {
        setInitialValues({
          email: result.email,
          firstName: result.firstName,
          lastName: result.lastName,
          emailConfirmed: result.emailConfirmed,
          archived: result.archived,
        });
      }
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
  };

  const onSaveChanges = async (rowValues: any) => {
    const values = {
      ...rowValues,
      id: userid,
      email: isAdmin ? rowValues.email : user?.email,
    };
    try {
      await AdminUserService.updateUser(userid, values);
      setConfirmMessage(t('account.savedMessage'));
      setErrorMessage(undefined);
      loadUser();
    } catch (error) {
      setErrorMessage(PoorMansError(error));
      setConfirmMessage('');
    }
  };

  const closeSetPasswordModal = () => {
    setIsSetPasswordOpen(false);
  };

  useEffect(() => {
    loadUser();
  }, [userid]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!initialValues && !errorMessage) {
    return <InfoText>{t('messages.loading')}</InfoText>;
  }

  return (
    <Container style={{ marginBottom: 0 }}>
      <EditFull>
        <H2>{t('account.userDetails')}</H2>
        {isLoaded && (
          <AdminBox style={{ backgroundColor: '#FDFDFD' }}>
            <TwoColumnsDiv>
              <Half>
                {isSetPasswordOpen && (
                  <SetPasswordModal
                    description={t('account.setPassword')}
                    userid={userid}
                    firstName={initialValues.firstName}
                    lastName={initialValues.lastName}
                    email={initialValues.email}
                    confirmButtonText={t('ok')}
                    cancelButtonText={t('cancel')}
                    onClickConfirm={() => {
                      closeSetPasswordModal();
                    }}
                    onClickCancel={() => closeSetPasswordModal()}
                  />
                )}
                <Formik
                  id={'formik1'}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSaveChanges}
                >
                  {({ isValid }) => (
                    <AdminGridForm id={'form1'}>
                      <LabelBox>
                        <FixedLabel required>{t('email')}</FixedLabel>
                      </LabelBox>
                      <AdminTextInput name="email" type="text" disabled={!isAdmin} />

                      <LabelBox>
                        <FixedLabel required>{t('account.firstname')}</FixedLabel>
                      </LabelBox>
                      <AdminTextInput name="firstName" type="text" />

                      <LabelBox>
                        <FixedLabel required>{t('account.lastname')}</FixedLabel>
                      </LabelBox>
                      <AdminTextInput name="lastName" type="text" />

                      <UserLicenseeMembership userId={userid} />

                      <LabelBox>
                        <FixedLabel>Email confirmed</FixedLabel>
                      </LabelBox>
                      <EmailValue>
                        <Checkbox name="emailConfirmed" />
                      </EmailValue>
                      {AuthService.isAdmin() && (
                        <>
                          <ArchivedLabel>
                            <FixedLabel>Archived</FixedLabel>
                          </ArchivedLabel>
                          <ArchivedValue>
                            <Checkbox name="archived" />
                          </ArchivedValue>
                        </>
                      )}

                      <ButtonsContainer>
                        <ButtonSmall
                          disabled={!isValid}
                          style={{ padding: '0.5rem 1rem', borderRadius: '3px' }}
                          type="submit"
                        >
                          {t('save')}
                        </ButtonSmall>
                        {AuthService.isAdmin() && (
                          <>
                            <A
                              style={{ display: 'inline-block', color: 'gray', marginLeft: '1rem' }}
                              href="#"
                              onClick={() => {
                                setIsSetPasswordOpen(true);
                                return false;
                              }}
                            >
                              {t('account.setPassword')}
                            </A>
                          </>
                        )}
                      </ButtonsContainer>
                    </AdminGridForm>
                  )}
                </Formik>
              </Half>
              <Half>
                <EditUserMembership userid={userid} />
              </Half>
            </TwoColumnsDiv>
          </AdminBox>
        )}

        {errorMessage && (
          <InfoBox style={{ borderLeft: 'solid 3px red', display: 'flex', alignItems: 'center' }}>
            <img src={warningIcon} alt="error" style={{ height: '35px', marginRight: '1rem' }} />
            <div style={{ color: 'red' }}>{errorMessage}</div>
          </InfoBox>
        )}
        {confirmMessage && (
          <div style={{ margin: '1rem 0', fontSize: '16px' }}>
            <div style={{ color: 'green' }}>{confirmMessage}</div>
          </div>
        )}
      </EditFull>
    </Container>
  );
};

export default EditUser;
