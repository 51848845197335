import React from 'react';
import styled from 'styled-components';

const TooltipInfoIcon: React.FunctionComponent<{ content: JSX.Element | string }> = ({ content }) => {
  return (
    <>
      <Image className="pi pi-info-circle"></Image>
      <Tooltip>{content}</Tooltip>
    </>
  );
};

const Image = styled.i`
  height: 1rem;
  vertical-align: top;
  margin: 0 0.5rem;
  cursor: pointer;
  &:hover {
    & + * {
      display: initial;
    }
  }
`;

const Tooltip = styled.div`
  display: none;
  ${(props) => props.theme.fonts.textSmall}
  text-transform: initial;
  position: absolute;
  background: ${(props) => props.theme.colors.darkGray};
  padding: 1rem;
  color: white;
  opacity: 0.85;
  z-index: 1;
`;

export default TooltipInfoIcon;
