import logo from 'assets/images/portal-logo.svg';
import Checkbox from 'components/form/Checkbox';
import PasswordInput from 'components/form/PasswordInput';
import TextInput from 'components/form/TextInput';
import { toaster } from 'components/v2/toast';
import { Formik } from 'formik';
import successIcon from 'images/icons/svg/fill/svg/success.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/AuthService';
import { RegisterModel } from 'services/EpdClient';
import styled from 'styled-components';
import {
  A,
  Button,
  CenterColumn,
  Container,
  ErrorText,
  Footer,
  H1,
  HeaderLogo,
  LoginFlowA,
  StyledForm,
  Text,
} from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

const initialValues: RegisterModel | { acceptTerms: boolean } = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
};

const RegisterUser: React.FunctionComponent = () => {
  const [success, setSuccess] = React.useState<boolean>();
  const { t } = useTranslation();

  const registerSchema = Yup.object({
    firstName: Yup.string().required(t('account.validation.firstNameRequired')),
    lastName: Yup.string().required(t('account.validation.lastNameRequired')),
    email: Yup.string().email('Invalid email address'),
    password: Yup.string()
      .required(t('account.validation.passwordRequired'))
      .nullable()
      .matches(/\w*[a-z]\w*/, t('account.validation.invalidPasswordSmallLetter'))
      .matches(/\w*[A-Z]\w*/, t('account.validation.invalidPasswordCapitalLetter'))
      .matches(/\d/, t('account.validation.invalidPasswordNumber'))
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, t('account.validation.invalidPasswordSpecial'))
      .min(6, ({ min }) => t('account.validation.passwordLength', { min })),
    confirmPassword: Yup.string()
      .nullable()
      .oneOf([Yup.ref('password')], t('account.validation.passwordsNotMatch'))
      .required(t('account.validation.confirmPasswordRequired')),
    acceptTerms: Yup.boolean()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.'),
  });

  const createUser = async (values: any) => {
    try {
      setSuccess(false);
      await AuthService.registerUser(values, localStorage.getItem('theme') || '');
      setSuccess(true);
    } catch (exception) {
      const errors = PoorMansError(exception);
      if (!success) {
        toaster({ severity: 'error', summary: 'Error', details: errors ? errors : 'Technical error, try again later' });
      }
    }
  };

  return (
    <RegisterContainer>
      <HeaderLogo src={logo} alt="EPD International" />
      <Container>
        {success ? (
          <CenterColumnSuccess>
            <GreenIcon src={successIcon} alt="information" />
            <H1>You have successfully created an account!</H1>
            <Text>
              We have sent you an email with a link to confirm your account. Follow the link to complete the registration.
            </Text>
          </CenterColumnSuccess>
        ) : (
          <CenterColumn>
            <Header>
              <H1>Register</H1>
              <Text style={{ marginTop: '10px' }}>
                Already have an account? <LoginFlowA href={'/login'}>Login</LoginFlowA>
              </Text>
            </Header>
            <Formik
              initialValues={initialValues}
              validationSchema={registerSchema}
              onSubmit={(values) => createUser(values)}
            >
              {({ isSubmitting }) => (
                <StyledForm>
                  <TextInput label="FIRST NAME" name="firstName" type="firstName" placeholder="Type first name" />
                  <TextInput label="LAST NAME" name="lastName" type="lastName" placeholder="Type last name" />
                  <TextInput label="EMAIL ADDRESS" name="email" type="email" placeholder="email@example.com" />
                  <PasswordInput label={t('account.password')} name="password" autoComplete="set-password" />
                  <p>
                    The password should contain a small letter, a capital letter, a number and a special symbol. The minimum
                    password length is 6 characters.
                  </p>
                  <PasswordInput label={t('account.confirmPassword')} name="confirmPassword" />
                  <Checkbox label={acceptLabel} name="acceptTerms" id="acceptTerms" />
                  <br />
                  <Button disabled={isSubmitting || success}>{isSubmitting ? 'Registration...' : 'Register'}</Button>
                  <br />
                </StyledForm>
              )}
            </Formik>
          </CenterColumn>
        )}
      </Container>
      <Footer>
        {t('footer.text')}
        <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>{'|'}</span>
        <A href={t('footer.link')}>{t('footer.linkText')}</A>
      </Footer>
    </RegisterContainer>
  );
};

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
  min-height: 100vh;
  ${Container} {
    margin: auto;
  }
  ${H1} {
    text-align: center;
    line-height: normal;
  }
  ${Text} {
    text-align: center;
  }
  ${StyledForm} {
    margin-top: 2.5rem;
  }
  ${LoginFlowA} {
    text-align: center;
  }
  p {
    ${(props) => props.theme.fonts.textSmallDescription}
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
  }
`;

const Header = styled.div`
  text-align: center;
  ${Text} {
    text-align: center;
  }
`;

const TermLink = styled.a`
  color: black;
  &::hover {
    text-decoration: underline;
  }
`;

const GreenIcon = styled.img`
  display: block;
  height: 64px;
  margin: auto auto 3rem;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(100%) contrast(100%);
`;

const CenterColumnSuccess = styled(CenterColumn)`
  flex-basis: 60%;
  ${Text} {
    width: 70%;
    margin: auto;
  }
`;

export const acceptLabel = (
  <>
    I have read and accept <TermLink href="https://www.environdec.com/general-terms">terms of use</TermLink> and{' '}
    <TermLink href="https://www.environdec.com/general-terms#privacyandcookies">privacy policy</TermLink>
  </>
);

export default RegisterUser;
