import { EPDModel } from 'services/EpdClient';
import styled from 'styled-components';

import EpdCollectionItem from './EpdCollectionItem';

type TProps = {
  epds: EPDModel[];
  isEditMode: boolean;
  onRemoveEpd: any;
};

const CollectionEpdList = ({ epds, isEditMode, onRemoveEpd }: TProps) => {
  return (
    <EpdCollectionListBox>
      {epds && epds.map((i) => EpdCollectionItem(i, isEditMode, () => onRemoveEpd(i.id)))}
    </EpdCollectionListBox>
  );
};

const EpdCollectionListBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default CollectionEpdList;
