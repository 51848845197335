import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const HTMLEditor = ({
  toolbar = undefined,
  toolbarHidden = true,
  editorStyle = {},
  value,
  onEdit,
  disabled = false,
  editorClassName,
  ...props
}: any) => {
  const [editorState, setEditorState] = useState<EditorState | null>(
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value ?? '') as any))
  );

  const onChange = async (newState: any) => {
    if (disabled) return;
    setEditorState(newState);
  };

  const newValue = draftToHtml(convertToRaw(editorState!.getCurrentContent()));

  return (
    <Editor
      editorStyle={editorStyle}
      toolbarHidden={toolbarHidden}
      editorState={editorState}
      editorClassName={editorClassName}
      onEditorStateChange={onChange}
      onBlur={() => onEdit(newValue)}
      toolbar={toolbar}
      {...props}
    />
  );
};

export default HTMLEditor;
