import CreatePcr from 'components/dashboard/CreatePcr';
import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import ReactSelect from 'components/form/ReactSelect';
import SortedTable from 'components/form/SortedTable';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { FixedLabel } from 'pages/wizard/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  ErrorMessage,
  H2,
  LabelBox,
  Message,
  SearchBox,
  ValueBox,
} from 'styles/Styles.styled';
import Spinner from 'styles/spinner-left.styled';
import { Theme } from 'styles/theme';
import { Option } from 'types/types';
import { PoorMansError, formatDate } from 'util/utils';
import * as Yup from 'yup';

import { PCRModel2, PCRStatus, PCRTypeSelection, ProductCategoryModel } from '../../../services/EpdClient';
import PcrService from '../../../services/PcrService';
import AdminPcrService from '../../../services/admin/AdminPcrService';
import { SortComparator } from '../utils';

const AdminPcrs: React.FunctionComponent = () => {
  const history = useHistory();
  const theme = useTheme() as Theme;

  const [initialValues, setInitialValues] = useState<object | null>({
    name: '',
    status: null,
    type: null,
    category: '',
  });

  const validationSchema = Yup.object({
    name: Yup.string(),
    status: Yup.object().nullable(),
    type: Yup.object().nullable(),
    category: Yup.string().nullable(),
  });

  const titles = [
    { label: 'Registration Number', field: 'registrationNumber' },
    { label: 'Name', field: 'name' },
    { label: 'Version', field: 'version' },
    { label: 'Validity Date', field: 'validityDate', sortComparator: SortComparator, fieldStyle: { minWidth: '120px' } },
    { label: 'Status', field: 'status' },
  ];
  const [pcr, setPcr] = useState<PCRModel2[]>([]);
  const statusKeys = Object.keys(PCRStatus);
  const status = statusKeys.map((key, index) => {
    return {
      value: key,
      label: PCRStatus[key as keyof typeof PCRStatus],
    };
  });
  const types = Object.values(PCRTypeSelection);
  const [categories, setCategories] = useState<ProductCategoryModel[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [pcrsNumber, setPcrsNumber] = useState<number | undefined>();
  const [underSearch, setUnderSearch] = useState<boolean>(false);

  const onSearch = (rowValues: any) => {
    const fetchPcr = async () => {
      try {
        const getPcr = await AdminPcrService.findPcrs(
          rowValues.name,
          rowValues.status?.value,
          rowValues.type?.value,
          rowValues.category?.value
        );
        let allPcrs = getPcr;
        allPcrs = allPcrs.map((pcr: any) => {
          const statusElementStyle = () => {
            switch (pcr.status) {
              case 'Under development':
                return {
                  margin: '0 10px 0 0',
                  padding: '5px 2px',
                  color: 'white',
                  backgroundColor: `${theme.colors.regionColor}`,
                  borderRadius: '3px',
                  textAlign: 'center',
                };
              case 'Registered':
                return {
                  margin: '0 10px 0 0',
                  padding: '5px 2px',
                  color: 'white',
                  backgroundColor: `${theme.colors.regionColorLight}`,
                  borderRadius: '3px',
                  textAlign: 'center',
                };
              case 'De-registered':
                return {
                  margin: '0 10px 0 0',
                  padding: '5px 2px',
                  color: 'white',
                  backgroundColor: `${theme.colors.darkGray}`,
                  borderRadius: '3px',
                  textAlign: 'center',
                };
              case 'None':
                return {
                  margin: '0 10px 0 0',
                  padding: '5px 2px',
                  color: 'white',
                  backgroundColor: `${theme.colors.lightOrange}`,
                  borderRadius: '3px',
                  textAlign: 'center',
                };
            }
          };
          const statusElement = () => {
            return <div style={statusElementStyle() as any}>{pcr.status}</div>;
          };
          return { ...pcr, validityDate: formatDate(pcr.validityDate), status: statusElement() };
        });
        setPcr(allPcrs);
        setPcrsNumber(allPcrs.length);
        setUnderSearch(true);
      } catch (error) {
        setErrorMessage(PoorMansError(error));
      }
    };
    fetchPcr();
  };

  const doSearch = (rowValues: any) => {
    onSearch(rowValues);
    localStorage.setItem('pcrValues', JSON.stringify(rowValues));
  };

  const getItemInfo = async (item: any) => {
    history.push(EpdLinks.editPcr(item.id));
    return;
  };

  useEffect(() => {
    const loadPcrsSummary = async () => {
      const result = await PcrService.getAllCategories();
      setCategories(result as any);
      const previousPcrValues = localStorage.getItem('pcrValues');
      if (previousPcrValues) {
        const values = JSON.parse(previousPcrValues as string);
        setInitialValues(values);
        doSearch(values);
        localStorage.removeItem('pcrValues');
      } else {
        setInitialValues({
          name: '',
          status: {},
          type: {},
          category: {},
        });
      }
    };
    loadPcrsSummary();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!initialValues) {
    return (
      <div style={{ marginTop: '8rem' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <EditFull>
        <H2>PCRs</H2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={doSearch}>
          <AdminBox>
            <AdminSearchGridForm>
              <LabelBox>
                <FixedLabel>Name</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput
                  style={{ boxSizing: 'border-box', width: '100%' }}
                  name="name"
                  type="text"
                  placeholder="Search by PCR name..."
                />
              </ValueBox>
              <LabelBox>
                <FixedLabel>Status</FixedLabel>
              </LabelBox>
              <ValueBox>
                <StyledReactSelect
                  isMulti={false}
                  name="status"
                  styles={editAdminSelectStyles}
                  options={status?.map((status) => {
                    return { value: status.value, label: status.label } as Option;
                  })}
                  isClearable={true}
                  defaultValue="Select..."
                ></StyledReactSelect>
              </ValueBox>
              <LabelBox>
                <FixedLabel>PCR type</FixedLabel>
              </LabelBox>
              <ValueBox>
                <StyledReactSelect
                  isMulti={false}
                  name="type"
                  styles={editAdminSelectStyles}
                  options={types?.map((type) => {
                    return { value: type, label: type } as Option;
                  })}
                  isClearable={true}
                  defaultValue="Select..."
                ></StyledReactSelect>
              </ValueBox>
              <LabelBox>
                <FixedLabel>Category</FixedLabel>
              </LabelBox>
              <ValueBox>
                <StyledReactSelect
                  isMulti={false}
                  name="category"
                  styles={editAdminSelectStyles}
                  options={categories?.map((category: any) => {
                    return { value: category.id, label: category.title } as Option;
                  })}
                  isClearable={true}
                  defaultValue="Select..."
                ></StyledReactSelect>
              </ValueBox>
              <SearchBox>
                <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                  Search
                </ButtonSmall>
              </SearchBox>
            </AdminSearchGridForm>
          </AdminBox>
        </Formik>
        {underSearch && !pcr && (
          <div>
            <Spinner />
          </div>
        )}
        <MessageBox>
          {errorMessage && isEmpty(pcr) && (
            <ErrorMessage style={{ marginLeft: 0, marginTop: '1rem', color: 'black', display: 'inline-block' }}>
              {' '}
              {errorMessage}
            </ErrorMessage>
          )}
          {underSearch &&
            (!isEmpty(pcr) ? (
              <>
                <ResultMessageBox>
                  {pcrsNumber === 1 ? (
                    <Message style={{ marginLeft: 0 }}>Found {pcrsNumber} match</Message>
                  ) : (
                    <Message>Found {pcrsNumber} matches</Message>
                  )}
                </ResultMessageBox>
                <div
                  style={{
                    display: 'inline-block',
                    gridColumnStart: '2',
                    gridColumnEnd: '3',
                    minWidth: '100%',
                    padding: '1px',
                    borderBottomLeftRadius: '3px',
                    borderBottom: `1px solid ${theme.colors.mediumGrey}`,
                    alignSelf: 'end',
                    justifySelf: 'end',
                  }}
                ></div>
                <CreatePcrBox>
                  <CreatePcr goToEditPcrPage={true} />
                </CreatePcrBox>
              </>
            ) : (
              <>
                <NoRecordsMessageBox>
                  <Message style={{ marginLeft: 0 }}>No records found.</Message>
                </NoRecordsMessageBox>
                <div
                  style={{
                    display: 'inline-block',
                    gridColumnStart: '2',
                    gridColumnEnd: '3',
                    minWidth: '100%',
                    padding: '1px',
                    borderBottom: `1px solid ${theme.colors.mediumGrey}`,
                    alignSelf: 'end',
                    justifySelf: 'end',
                  }}
                ></div>
                <CreatePcrBox>
                  <CreatePcr goToEditPcrPage={true} />
                </CreatePcrBox>
              </>
            ))}
        </MessageBox>
        {!underSearch && (!isEmpty(pcr) || pcr) && (
          <div style={{ marginLeft: '-1rem', marginTop: '-1rem' }}>
            <CreatePcr goToEditPcrPage={true} />
          </div>
        )}
        {!isEmpty(pcr) && (
          <div style={{ marginTop: '-1.2rem' }}>
            <SortedTable columns={titles} collection={pcr ?? []} onClick={getItemInfo} />
          </div>
        )}
      </EditFull>
    </Container>
  );
};

const StyledReactSelect = styled(ReactSelect)`
  box-sizing: border-box;
  width: 100%;
`;

const MessageBox = styled.div`
  display: grid;
  grid-template-columns: fit-content(25%) auto min-content;
  align-items: start;
  justify-items: start;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
`;

const ResultMessageBox = styled.div`
  align-self: end;
  padding: 0.8rem 5px 5px 5px;
  border-top-right-radius: 3px;
  border-top: 1px solid ${(props) => props.theme.colors.mediumGrey};
  border-right: 1px solid ${(props) => props.theme.colors.mediumGrey};
  margin-left: 0;
`;

const CreatePcrBox = styled.div`
  grid-column-start: 3;
  grid-column-end: 4;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumGrey};
  align-self: end;
  justify-self: end;
`;

const NoRecordsMessageBox = styled.div`
  align-self: end;
  padding: 0.8rem 5px 5px 5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumGrey};
  margin-left: 0;
`;

export default AdminPcrs;
