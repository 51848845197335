import React from 'react';
import { Container, CenterColumn, H2, StyledForm, Button, ErrorText, SuccessText } from 'styles/Styles.styled';
import { Formik } from 'formik';
import TextInput from 'components/form/TextInput';
import * as Yup from 'yup';
import { ForgotPasswordModel } from 'services/EpdClient';
import AuthService from 'services/AuthService';

const ForgotPassword: React.FunctionComponent = () => {
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const initialValues: ForgotPasswordModel = {
    email: '',
  };

  const forgotPasswordSchema = Yup.object({
    email: Yup.string().required('Required').email('Invalid email address'),
  });

  const forgotPasword = async (values: ForgotPasswordModel) => {
    try {
      await AuthService.forgotPassword(values);
    } catch (error) {
      setError(error as string);
      return;
    }

    setSuccess(true);
  };

  return (
    <Container>
      <CenterColumn>
        <H2>Forgot your password?</H2>
        <p>
          If you have forgotten your password, we can help you to reset it. We will send an email with a link and
          instructions to the email address you used when you signed up.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordSchema}
          onSubmit={(values) => forgotPasword(values)}
        >
          <StyledForm>
            <TextInput label="Email Address" name="email" type="email" placeholder="email@example.com" />
            <Button>Reset password</Button>
            {error && <ErrorText>{error}</ErrorText>}
            {success && (
              <SuccessText>
                If your account is registered, you will receive an email with instructions on how to reset your password.
              </SuccessText>
            )}
          </StyledForm>
        </Formik>
      </CenterColumn>
    </Container>
  );
};

export default ForgotPassword;
