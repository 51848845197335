import { useField } from 'formik';
import React from 'react';

import { ErrorMessage } from 'styles/Styles.styled';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useState } from 'react';

const HTMLEditor = ({ editorStyle = {}, toolbar = undefined, toolbarHidden = true, readOnly = false, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(field.value ?? '') as any))
  );

  const onChange = (newState: any) => {
    setEditorState(newState);
    const newValue = draftToHtml(convertToRaw(newState.getCurrentContent()));
    setValue(newValue);
  };

  return (
    <>
      <Editor
        editorStyle={editorStyle}
        editorState={editorState}
        toolbarHidden={toolbarHidden}
        toolbar={toolbar}
        editorClassName="html-editor"
        name={field.name}
        wrapperStyle={readOnly ? { backgroundColor: '#EEF1F4' } : null}
        onEditorStateChange={onChange}
        readOnly={readOnly}
        {...props}
      />
      {meta.touched && meta.error && !props.supressErrors ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  );
};

export default HTMLEditor;
