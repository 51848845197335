import { useState } from 'react';
import { EPDModel } from 'services/EpdClient';

import EditProductInformationDialog from '../../dialogs/EditProductInformationDialog';
import EpdDetailsGrid from '../EpdDetailsGrid';

type TProps = {
  epd: EPDModel;
  onChangeEpd: any;
  isReadOnly: boolean;
};

const ProductInformation = ({ epd, onChangeEpd, isReadOnly }: TProps) => {
  const [isProductEditOpened, setIsProductEditOpened] = useState<boolean>(false);

  return (
    <>
      <EpdDetailsGrid
        headerLabel={'EPD product information'}
        isReadOnly={isReadOnly}
        data={[
          { key: 'Product description', value: epd.text, isHtml: true, required: true },
          { key: 'UN CPC code', value: epd.uncpcCode?.description },
          { key: 'Products', value: epd.products, isHtml: true },
          { key: 'Geographical scope', value: epd.geographicalScopes?.map((x) => x.name).join('; '), required: true },
        ]}
        onEdit={() => setIsProductEditOpened(true)}
      />
      <EditProductInformationDialog
        isOpened={isProductEditOpened}
        epd={epd}
        onHide={() => setIsProductEditOpened(false)}
        onChangeEpd={onChangeEpd}
      />
    </>
  );
};

export default ProductInformation;
