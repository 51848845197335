import React from 'react';
import { CountryModel } from 'services/EpdClient';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { useMaterialPropertiesLookup, useUnCpcCodes } from 'services/api/queries';
import { EPDPDFModel, EPDProductInformationModel } from 'types/types';

import { PDF_H1, PDF_PAGE, PDF_Table } from './Styles.styled';
import FileService from 'services/FileService';

const ProductInformationPage: React.FunctionComponent<{
  productInformation: EPDProductInformationModel;
  countries: CountryModel[];
  getOptionsFromDictionary: any;
  hasDangerMaterials?: boolean;
}> = ({ productInformation, countries, getOptionsFromDictionary, hasDangerMaterials }) => {
  const unitTypeOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.UNIT_TYPE);
  const materialPropertiesLookup = useMaterialPropertiesLookup().data;
  const productClassificationOptions = getOptionsFromDictionary(EPD_DICTIONARY_CODES.CLASSIFICATION_CODE);
  const cpcCodes = useUnCpcCodes({ smartFilter: undefined, cpcLevel: undefined, codes: undefined }).data;
  const selectedCpc = cpcCodes?.find((cpc: any) => cpc?.id === productInformation?.uncpcId)?.description;
  const selectedUnitTypeOriginal = !isNaN(Number(productInformation?.unitType))
    ? unitTypeOptions.find((x: any) => x.value == productInformation?.unitType)
    : unitTypeOptions.find((x: any) => x.label == productInformation?.unitType);

  return (
    <PDF_PAGE>
      <PDF_H1>Product information</PDF_H1>

      <PDF_Table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Product name</td>
            <td>{productInformation?.productName}</td>
          </tr>
          <tr>
            <td>Product identification</td>
            <td>{productInformation?.productIdentification}</td>
          </tr>
          <tr>
            <td>Product description</td>
            <td>{productInformation.productDescription}</td>
          </tr>
          {productInformation?.productInformationExternal && (
            <tr>
              <td>Product information from external sources</td>
              <td>{productInformation?.productInformationExternal}</td>
            </tr>
          )}
          <tr>
            <td>Manufacturing or service provision description</td>
            <td>{productInformation?.manufacturingOrServiceProvisionDescription}</td>
          </tr>
          {productInformation?.productMaterialProperties?.map((item, index) => (
            <tr key={index}>
              <td>Material properties {index > 0 ? index + 1 : ''}</td>
              <td>
                {materialPropertiesLookup?.find((prop: any) => prop.id === item.materialPropertyId)?.name}
                <br />
                {item?.value}
              </td>
            </tr>
          ))}
          {productInformation?.productionSites?.map((productionSite, index) => (
            <tr key={index}>
              <td>Production site {index > 0 ? index + 1 : ''}</td>
              <td>
                {countries?.find((country: any) => country.id === productionSite.countryId)?.name}
                <br />
                {productionSite.city}
                <br />
                {productionSite.zipCode}
                <br />
                {productionSite.addressLine}
                <br />
                {productionSite.addressLineSecond}
              </td>
            </tr>
          ))}
          <tr>
            <td>UN CPC code</td>
            <td>{selectedCpc}</td>
          </tr>
          <tr>
            <td>GTIN</td>
            <td>{productInformation?.gtin}</td>
          </tr>
          {(productInformation?.productClassifications?.length as number) > 0 && (
            <tr>
              <td>Product classification system</td>
              <td>
                {productInformation.productClassifications?.map((produtClassification, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {
                      productClassificationOptions.find(
                        (x: any) => x.value == produtClassification?.productClassificationType
                      )?.label
                    }
                    <br />
                    {produtClassification?.code}
                    <br />
                  </React.Fragment>
                ))}
              </td>
            </tr>
          )}
          <tr>
            <td>Geographical scope(s)</td>
            <td>{productInformation.productGeographicalScopes?.map((g) => g.name).join(', ')}</td>
          </tr>
          <tr>
            <td>Geographical scope description</td>
            <td>{productInformation?.geographicalScopeDescription}</td>
          </tr>
          <tr>
            <td>Actual or technical lifespan</td>
            <td>
              {productInformation?.unitValue} {selectedUnitTypeOriginal?.label}
            </td>
          </tr>
          {!hasDangerMaterials && (
            <tr>
              <td>Hazardous and toxic substances</td>
              <td>
                The product does not contain any substances from the SVHC candidate list in concentrations exceeding 0.1% of
                its weight.
              </td>
            </tr>
          )}
        </tbody>
      </PDF_Table>
      {productInformation.productDetailsImages?.map((image, index) => (
        <div key={index}>
          <img style={{ maxWidth: '100%' }} src={FileService.getImageUrl(image?.id)} alt="Product image" />
        </div>
      ))}
    </PDF_PAGE>
  );
};

export default ProductInformationPage;
