import Impersonate from 'components/footer/Impersonate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EpdRoutes from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import styled from 'styled-components';
import { A, ButtonSmallSecondary } from 'styles/Styles.styled';

const Footer: React.FunctionComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isAuthed, setIsAuthed] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsAuthed(AuthService.isAuthenticated());
    history.listen(() => setIsAuthed(AuthService.isAuthenticated()));
  }, [history]);

  return (
    <MainFooter>
      <div>
        {t('footer.text')}{' '}
        <A href={t('footer.link')} style={{ color: 'white' }}>
          {t('footer.linkText')}
        </A>
        <Impersonate />
      </div>
      <div>
        {isAuthed && (
          <ButtonSmallSecondary
            onClick={() => {
              try {
                AuthService.logout(history);
              } catch {
                history.push(EpdRoutes.login);
              }
            }}
          >
            {t('logout.title')}
          </ButtonSmallSecondary>
        )}
      </div>
    </MainFooter>
  );
};

const MainFooter = styled.footer`
  width: 100%;
  margin-top: auto;
  background-color: ${(props) => props.theme.colors.regionColorLight};
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    padding: 2rem;
  }

  @media print {
    display: none;
  }
`;

export default Footer;
