import { ProcessStatus } from 'constants/constants';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminService from 'services/admin/AdminService';
import styled from 'styled-components';
import OverlaySpinner from 'components/spinners/OverlaySpinner';
import { ButtonSmall, Container, EditFull, H2 } from 'styles/Styles.styled';
import { FortnoxLogs, FortnoxSettingsModel } from 'services/EpdClient';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputSwitch } from 'primereact/inputswitch';
import { toast } from 'react-toastify';

const FortnoxIntegrationPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [fortnoxLogs, setFortnoxLogs] = React.useState<FortnoxLogs[]>();
  const [fortnoxSettingsModel, setFortnoxSettingsModel] = React.useState<FortnoxSettingsModel>();
  const [autoUpdateLogs, setAutoUpdateLogs] = React.useState<boolean>(false);
  const [url, setUrl] = React.useState<string>();
  const [timerCount, setTimerCount] = useState(0);
  const TIMER = 5 * 1000;

  useEffect(() => {
    setStatus(ProcessStatus.Fetching);
    if (code && state) {
      updateAuthCode(code, state);
    }
    loadSettings();
    getLogs();
    setStatus(ProcessStatus.None);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoUpdateLogs) {
        getLogs();
      }
      setTimerCount(timerCount + 1);
    }, TIMER);

    return () => clearInterval(interval);
  }, [timerCount]);

  const loadSettings = async () => {
    const result = await AdminService.GetFortnoxSettings();
    setFortnoxSettingsModel(result);
    setUrl(generateUrl(result?.clientId, result?.redirectURL, result?.state));
  };

  const getLogs = async () => {
    var logs = await AdminService.GetLastLogs();
    setFortnoxLogs(logs);
    showLogToast('Logs was updated');
  };

  const pushAllCusromers = async () => {
    setStatus(ProcessStatus.Fetching);
    await AdminService.PushAllCompaniesToFortnox();
    showSuccessToast('Started pushing companies to Fortnox');
    setStatus(ProcessStatus.None);
  };

  const updateAuthCode = async (code: string, state: string) => {
    showInfoToast('Start updating auth code');
    await AdminService.UpdateAuthCode(code, state).finally(() => {
      showSuccessToast('Auth code updated');
      showInfoToast('Start generating new tokens');
      AdminService.GenerateNewTokens(code).finally(() => {
        showSuccessToast('New token already generated');
        loadSettings();
      });
    });
  };

  const generateUrl = (clientId?: string, redirectUri?: string, state?: string) => {
    return `https://apps.fortnox.se/oauth-v1/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=currency%20price%20payment%20article%20customer%20print%20invoice%20connectfile%20archive%20costcenter%20settings&state=${state}&access_type=offline&response_type=code`;
  };

  const showInfoToast = (text: string) => {
    toast.info(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const showLogToast = (text: string) => {
    toast(text, {
      position: 'top-right',
      autoClose: 100,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    });
  };

  const showWarningToast = (text: string) => {
    toast.warning(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const showSuccessToast = (text: string) => {
    toast.success(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  return (
    <Container>
      <H2>Fortnox settings</H2>
      {status === ProcessStatus.Fetching && <OverlaySpinner></OverlaySpinner>}
      <Fieldset legend="Fortnox authorization">
        <p className="m-0" style={{ lineHeight: '35px', fontSize: '17px' }}>
          The authorization of access to fortnox is made using the OAuth2 Authorization Code Flow. You must approve the
          access and scope of access to their account during the activation process.
        </p>
        <p className="m-0" style={{ lineHeight: '35px', fontSize: '17px' }}>
          You must provide access to Fortnox at least once every 45 days.
        </p>
        <p className="m-0" style={{ lineHeight: '35px', fontSize: '17px' }}>
          Refresh token was generated - <b>{fortnoxSettingsModel?.refreshTokenDateTime}</b>
        </p>
        <p className="m-0" style={{ lineHeight: '35px', fontSize: '17px' }}>
          You could use this button to {'   '}
          <a href={url}>
            <Button label="Login" severity="info" text raised />
          </a>
        </p>
      </Fieldset>
      <Fieldset legend="Customers sync" style={{ margin: '40px 0', width: '500px', height: '200px', fontSize: '17px' }}>
        <p className="m-0" style={{ lineHeight: '35px', fontSize: '17px' }}>
          To push all clients that have one or more published EPD
        </p>
        <p>
          <center>
            <a href="#" onClick={pushAllCusromers}>
              <Button label="Synchronize" severity="info" text raised />
            </a>
          </center>
        </p>
      </Fieldset>
      <div>
        <Fieldset legend="Log table" style={{ margin: '40px 0', width: '500px', height: '200px', fontSize: '17px' }}>
          <p className="m-0" style={{ lineHeight: '35px', fontSize: '17px' }}>
            To turn on autoupdate logs in real time turn it on{' '}
          </p>
          <p>
            <center>
              <InputSwitch checked={autoUpdateLogs} onChange={(e) => setAutoUpdateLogs(e.value ? true : false)} />
            </center>
          </p>
        </Fieldset>
      </div>
      <EditFull>
        <H2>Fortnox settings</H2>

        <DataTable
          value={fortnoxLogs}
          showGridlines
          paginator
          rows={20}
          paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown "
          currentPageReportTemplate="{first}-{last} of {totalRecords}"
          rowsPerPageOptions={[20, 100, 500]}
          tableStyle={{ minWidth: '70rem' }}
        >
          <Column field="dateTimeUTC" header="Date" sortable style={{ maxWidth: '20%' }}></Column>
          <Column field="actionType" header="Type" style={{ maxWidth: '10%' }}></Column>
          <Column field="actionStage" header="Stage" style={{ maxWidth: '10%' }}></Column>
          <Column field="typeIdObject" header="Id" style={{ maxWidth: '10%' }}></Column>
          <Column field="text" header="Text" sortable style={{ maxWidth: '25%' }}></Column>
          <Column field="notes" header="Notes" style={{ maxWidth: '25%', overflow: 'auto' }}></Column>
        </DataTable>
      </EditFull>
    </Container>
  );
};

export const InputContainer = styled.div`
  min-height: 38px;
  grid-column-start: 1;
  grid-column-end: 1;
  margin-top: 10px;
`;

export const EnvirondecContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
`;

export const IlcdEpdContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  ${(props) => props.theme.media.tablet} {
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 20px;
  ${(props) => props.theme.media.desktop} {
    flex-direction: row;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const MappingButton = styled(ButtonSmall)`
  padding: 0.5rem 1rem;
  border-radius: 3px;
`;
export const FixedTextArea = styled.textarea`
  min-width: 100%;
  height: 100px;
  margin: 0.5rem 0 1rem 0;
`;

export default FortnoxIntegrationPage;
