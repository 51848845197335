import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAdminLicenseesModel } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';
import { InfoText, LabelBox } from 'styles/Styles.styled';
import { Option } from 'types/types';

import { FixedLabel, StyledReactSelectUserLicensees } from './style';

interface Props {
  userId: string;
}

const UserLicenseeMembership: React.FunctionComponent<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const [userAdminLicensees, setUserAdminLicensees] = useState<UserAdminLicenseesModel[]>([]);
  const [selectedValues, setSelectedValues] = useState<any>(null);

  const fetchUserAdminLicensees = async () => {
    const result = await MembershipService.getUserAdminLicensees(userId);
    setUserAdminLicensees(result);
    setSelectedValues(result);
  };

  useEffect(() => {
    fetchUserAdminLicensees();
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const initialValues = {
    ...selectedValues,
  };

  if (!initialValues) {
    return <InfoText>{t('messages.loading')}</InfoText>;
  }

  return (
    <>
      <LabelBox>
        <FixedLabel>{t('account.licensees')}</FixedLabel>
      </LabelBox>
      <StyledReactSelectUserLicensees
        key={'adminLicenseesSelect'}
        name="adminLicenseesSelect"
        value={userAdminLicensees.map((t) => {
          return { value: t.licenseeId, label: t.licenseeName } as Option;
        })}
        isMulti
        isDisabled
        placeholder=""
      />
    </>
  );
};

export default UserLicenseeMembership;
