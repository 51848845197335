import { CPCClient, CPCLevel } from './EpdClient';

const client = new CPCClient(process.env.REACT_APP_API_URL);

class CpcService {
  static getCpcs = async (smartFilter?: string, cPCLevel?: CPCLevel, codes?: string[]) => {
    return await client.get(smartFilter, cPCLevel, codes);
  };
  static getCpcTree = async () => {
    return await client.getTree();
  };
}
export default CpcService;
