import React from 'react';
import { VerifierModel } from 'services/EpdClient';
import { Option } from 'types/types';
import LcaCompanyCard from './LcaCompanyCard';

interface LcaCompaniesListProps {
  companies: any[];
  selectedCompany: any;
  selectedPractitioners?: Option[] | undefined;
  companyPractitioners?: Option[] | undefined;
  onSelectCompany: any;
  onRequestCollaboration: any;
  onAddPractitioner: any;
  buttonsAreDisabled?: boolean;
}

const LcaCompaniesList: React.FunctionComponent<LcaCompaniesListProps> = ({
  companies,
  selectedCompany,
  selectedPractitioners,
  companyPractitioners,
  onSelectCompany,
  onRequestCollaboration,
  onAddPractitioner,
  buttonsAreDisabled,
}) => (
  <div>
    {companies.map((company) => (
      <LcaCompanyCard
        key={company.id}
        data={company}
        selectedPractitioners={selectedPractitioners}
        companyPractitioners={companyPractitioners}
        onSelectVerifier={() =>
          onSelectCompany((prev: VerifierModel | undefined) => (prev?.id === company.id ? undefined : company))
        }
        onRequestCollaboration={onRequestCollaboration}
        onAddPractitioner={onAddPractitioner}
        expanded={selectedCompany?.id === company.id}
        buttonsAreDisabled={buttonsAreDisabled}
      />
    ))}
  </div>
);

export default LcaCompaniesList;
