import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import CpcService from 'services/CpcService';
import { InfoText } from 'styles/Styles.styled';
import '../../styles/react-checkbox-tree.css';

const Widget: React.FunctionComponent = () => {
  const [cpcTree, setCpcTree] = useState<any>(undefined);
  const [checked, setChecked] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<any[]>([]);

  const getNode = (item: any) => {
    let newNode = {
      value: Math.floor(Math.random() * 1000000), // item.id
      label: item.description,
    } as any;
    if (item.nodes) {
      newNode.children = getNodes(item.nodes);
    }
    return newNode;
  };
  const getNodes = (data: any) => {
    return data.map(getNode);
  };

  const onChecked = (checked: any) => {
    setChecked(checked);
  };

  const onExpanded = (expanded: any) => {
    setExpanded(expanded);
  };

  useEffect(() => {
    const fetchCpcTree = async () => {
      const result = await CpcService.getCpcTree();
      const data: any = result.nodes;

      const nodes = getNodes(data);
      setCpcTree(nodes);
    };
    fetchCpcTree();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!cpcTree) {
    return <InfoText>Loading...</InfoText>;
  }

  return <CheckboxTree nodes={cpcTree} checked={checked} expanded={expanded} onCheck={onChecked} onExpand={onExpanded} />;
};
export default Widget;
