import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useField } from 'formik';

const PrimeDropdownField = ({ label, options, ...props }: any) => {
  const [field, meta] = useField(props);

  const handleChange = (event: any) => {
    const { value } = event.target;
    const { name } = props;

    const fakeEvent = {
      target: {
        name,
        value,
      },
    };

    field.onChange(fakeEvent);
  };

  const handleBlur = () => {
    const { name } = props;

    const fakeEvent = {
      target: {
        name,
      },
    };

    field.onBlur(fakeEvent);
  };

  return (
    <div className="p-field">
      <label htmlFor={props.id || props.name}>{label}</label>
      <Dropdown {...field} {...props} options={options} onChange={handleChange} onBlur={handleBlur} />
      {meta.touched && meta.error && <span className="p-error">{meta.error}</span>}
    </div>
  );
};

export default PrimeDropdownField;
