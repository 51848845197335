import logo from 'assets/images/portal-logo.svg';
import errorIcon from 'images/icons/svg/fill/svg/error.svg';
import successIcon from 'images/icons/svg/fill/svg/success.svg';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import EpdRoutes from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { ConfirmEmailModel } from 'services/EpdClient';
import styled from 'styled-components';
import { A, CenterColumn, Container, Footer, H1, HeaderLogo, LoginFlowA, Text } from 'styles/Styles.styled';

const ConfirmEmail: React.FunctionComponent = () => {
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const location = useLocation();
  const { t } = useTranslation();
  const values = queryString.parse(location.search);
  const userId = values?.userId?.toString();
  const token = values?.token?.toString();

  React.useEffect(() => {
    const confirmEmail = async () => {
      if (!userId || !token) {
        setError('Something is not right. Try again later?');
        setSuccess(false);
        return;
      }
      const request: ConfirmEmailModel = {
        userId: userId,
        token: token,
      };
      try {
        await AuthService.confirmEmail(request);
      } catch (exception: any) {
        setError(exception.toString());
        setSuccess(false);
        return;
      }

      setSuccess(true);
    };

    confirmEmail();
  }, [token, userId]);

  return (
    <RegisterContainer>
      <HeaderLogo src={logo} alt="EPD International" />
      <Container>
        <CenterColumnConfirmEmail>
          {!success ? (
            <>
              <RedIcon src={errorIcon} alt="Error" />
              <H1>Something went wrong</H1>
              <Text>{error ? error : 'An unexpected error occurred. Please try again.'}</Text>
            </>
          ) : (
            success && (
              <>
                <GreenIcon src={successIcon} alt="information" />
                <H1>Your e-mail address has been confirmed!</H1>
                <Text>You can now start using the EPD portal.</Text>
              </>
            )
          )}
          <div style={{ textAlign: 'center' }}>
            <LoginFlowA href={EpdRoutes.login}>Back to login page</LoginFlowA>
          </div>
        </CenterColumnConfirmEmail>
      </Container>
      <Footer>
        {t('footer.text')}
        <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>{'|'}</span>
        <A href={t('footer.link')}>{t('footer.linkText')}</A>
      </Footer>
    </RegisterContainer>
  );
};

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
  min-height: 100vh;
  ${Container} {
    margin: auto;
  }
  ${H1} {
    text-align: center;
    line-height: normal;
  }
  ${Text} {
    text-align: center;
  }
  ${LoginFlowA} {
    text-align: center;
  }
  p {
    ${(props) => props.theme.fonts.textSmallDescription}
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
  }
`;

const GreenIcon = styled.img`
  display: block;
  height: 64px;
  margin: auto auto 3rem;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(100%) contrast(100%);
`;

const RedIcon = styled(GreenIcon)`
  filter: invert(12%) sepia(96%) saturate(6728%) hue-rotate(359deg) brightness(94%) contrast(115%);
`;

const CenterColumnConfirmEmail = styled(CenterColumn)`
  flex-basis: 60%;
  ${Text} {
    width: 70%;
    margin: 2rem auto;
  }
`;

export default ConfirmEmail;
