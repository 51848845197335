import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import SortedTable from 'components/form/SortedTable';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { FixedLabel } from 'pages/wizard/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import AdminDataSourcesService from 'services/admin/AdminDataSourcesService';
import styled from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  ErrorMessage,
  H2,
  LabelBox,
  Message,
  SearchBox,
  ValueBox,
} from 'styles/Styles.styled';
import Spinner from 'styles/spinner-left.styled';
import { PoorMansError, formatDate } from 'util/utils';
import * as Yup from 'yup';

import { DataSourceModel } from '../../../services/EpdClient';

const AdminDataSources: React.FunctionComponent = () => {
  const history = useHistory();

  const [initialValues, setInitialValues] = useState<object | null>({
    name: '',
    version: null,
  });

  const validationSchema = Yup.object({
    name: Yup.string(),
    version: Yup.string().nullable(),
  });

  const titles = [{ label: 'Name', field: 'name' }];

  const [dataSources, setDataSources] = useState<DataSourceModel[]>([]);
  const [dataSourcesNumber, setDataSourcesNumber] = useState<number | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [underSearch, setUnderSearch] = useState<boolean>(false);

  const onSearch = (rowValues: any) => {
    const fetchDataSources = async () => {
      try {
        const result = await AdminDataSourcesService.find(rowValues.name);
        setDataSources(
          result.map((x: any) => {
            return { ...x, validityDate: formatDate(x.validityDate) };
          })
        );
        setDataSourcesNumber(result.length);
        setUnderSearch(true);
      } catch (error) {
        setErrorMessage(PoorMansError(error));
      }
    };
    fetchDataSources();
  };

  const doSearch = (rowValues: any) => {
    onSearch(rowValues);
  };

  const getItemInfo = async (item: any) => {
    history.push(EpdLinks.editDataSource(item.id));
    return;
  };

  if (!initialValues) {
    return (
      <div style={{ marginTop: '8rem' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <EditFull>
        <H2>Data sources</H2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={doSearch}>
          <AdminBox>
            <AdminSearchGridForm>
              <LabelBox>
                <FixedLabel>Name</FixedLabel>
              </LabelBox>
              <ValueBox>
                <LargeRadiusTextInput
                  style={{ boxSizing: 'border-box', width: '100%' }}
                  name="name"
                  type="text"
                  placeholder="Search by name..."
                />
              </ValueBox>

              <SearchBox>
                <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                  Search
                </ButtonSmall>
              </SearchBox>
            </AdminSearchGridForm>
          </AdminBox>
        </Formik>

        {underSearch && !dataSources && (
          <div>
            <Spinner />
          </div>
        )}
        <MessageBox>
          {errorMessage && isEmpty(dataSources) && (
            <ErrorMessage style={{ marginLeft: 0, marginTop: '1rem', color: 'black', display: 'inline-block' }}>
              {' '}
              {errorMessage}
            </ErrorMessage>
          )}
          {underSearch &&
            (!isEmpty(dataSources) ? (
              <>
                <ResultMessageBox>
                  {dataSourcesNumber === 1 ? (
                    <Message style={{ marginLeft: 0 }}>Found {dataSourcesNumber} match</Message>
                  ) : (
                    <Message>Found {dataSourcesNumber} matches</Message>
                  )}
                </ResultMessageBox>
              </>
            ) : (
              <>
                <NoRecordsMessageBox>
                  <Message style={{ marginLeft: 0 }}>No records found.</Message>
                </NoRecordsMessageBox>
              </>
            ))}
        </MessageBox>
        {!underSearch && (!isEmpty(dataSources) || dataSources) && (
          <div style={{ marginLeft: '-1rem', marginTop: '-1rem' }}></div>
        )}
        {!isEmpty(dataSources) && (
          <div style={{ marginTop: '-1.2rem' }}>
            <SortedTable columns={titles} collection={dataSources ?? []} onClick={getItemInfo} />
          </div>
        )}
      </EditFull>
    </Container>
  );
};

const MessageBox = styled.div`
  display: grid;
  grid-template-columns: fit-content(25%) auto min-content;
  align-items: start;
  justify-items: start;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
`;

const ResultMessageBox = styled.div`
  align-self: end;
  padding: 0.8rem 5px 5px 5px;
  border-top-right-radius: 3px;
  border-top: 1px solid ${(props) => props.theme.colors.mediumGrey};
  border-right: 1px solid ${(props) => props.theme.colors.mediumGrey};
  margin-left: 0;
`;

const NoRecordsMessageBox = styled.div`
  align-self: end;
  padding: 0.8rem 5px 5px 5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumGrey};
  margin-left: 0;
`;

export default AdminDataSources;
