import { DataSourceVersionsClient } from './EpdClient';

const client = new DataSourceVersionsClient(process.env.REACT_APP_API_URL);

class DataSourceVersionsService {
  static getDataSourceVersions = async () => {
    return await client.getDataSourceVersions();
  };
}

export default DataSourceVersionsService;
