class PdfService {
  static async downloadImage(invoiceId: string) {
    const url = (process.env.REACT_APP_API_URL ?? 'http://localhost:44380') + `/api/v1/Invoices/${invoiceId}/Data`;
    const response = await fetch(url, {
      credentials: 'include',
    });
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = 'EPD_Invoice.pdf';
    a.href = blobUrl;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}

export default PdfService;
