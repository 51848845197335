import { toaster } from 'components/v2/toast';

import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';

interface LifecycleCallbackArgs<TArgs> {
  queryClient: QueryClient;
  args: TArgs;
}

export interface OptimisticMutationConfig<TArgs, TQueryData> {
  mutationFn(args: TArgs): Promise<any>;

  queryKey: string[];

  /**
   * Update data of the associated useQuery.
   * Return undefined to bail out of the update.
   *
   * @param queryData Old data of the query
   * @param args Arguments this mutation was called with
   */
  applyOptimisticChanges(queryData: TQueryData, args: TArgs): TQueryData | undefined;

  /**
   * Mutations with the same serial scope run one after another in serial.
   * Helps avoiding race conditions.
   */
  serialScopeId?: string;

  /**
   * Called when the mutation either succeeds or throws.
   * When multiple mutations run together, called when the last one settles down.
   * Helps to deduplicate queries invalidation calls.
   *
   * @param callbackArgs Convenience parameters
   */
  onAllSettled?(callbackArgs: LifecycleCallbackArgs<TArgs>): void;
}

export default function useOptimisticMutation<TArgs, TQueryData>(config: OptimisticMutationConfig<TArgs, TQueryData>) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: config.mutationFn,

    mutationKey: config.queryKey,

    async onMutate(args) {
      await queryClient.cancelQueries({ queryKey: config.queryKey, exact: true });

      const oldData = queryClient.getQueryData(config.queryKey) as TQueryData | undefined;
      let bailedOutOfUpdate = true;
      if (oldData !== undefined) {
        const newData = config.applyOptimisticChanges(oldData, args);
        if (newData !== undefined) {
          bailedOutOfUpdate = false;
          queryClient.setQueryData(config.queryKey, newData);
        }
      }

      const contextData = { bailedOutOfUpdate, oldData };
      return contextData;
    },

    onError(error, args, context) {
      if (context && !context.bailedOutOfUpdate) {
        queryClient.setQueryData(config.queryKey, context.oldData);
      }

      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Data update error',
      });
      console.error(error);
    },

    onSettled(data, error, args) {
      // The mutation which has just settled is still counted by isMutating
      const mutationsCount = queryClient.isMutating({ mutationKey: config.queryKey, exact: true });
      if (mutationsCount <= 1) {
        queryClient.invalidateQueries({ queryKey: config.queryKey, exact: true });
        config.onAllSettled?.({ queryClient, args });
      }
    },
    scope: config.serialScopeId ? { id: config.serialScopeId } : undefined,
  });
}
