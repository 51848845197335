import AccountRow from 'components/accounts/AccountRow';
import AddNewAccountRow from 'components/accounts/AddNewAccountRow';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import { AccountModel, CompanyModel, MembershipModel } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';
import AccountsService from 'services/admin/AccountsService';
import { ErrorText, InfoText } from 'styles/Styles.styled';

import { ProcessStatus, Role } from '../../constants/constants';

interface Props {
  companyId: string;
}

const ManageAccounts: React.FunctionComponent<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const [accounts, setAccounts] = React.useState<AccountModel[]>([]);
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [accountManagers, setAccountManagers] = React.useState<MembershipModel[]>([]);
  const [company, setCompany] = React.useState<CompanyModel>({} as CompanyModel);

  const fetchAccounts = useCallback(async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const resultAccounts = await AccountsService.getAccounts(companyId);
      setAccounts(resultAccounts);
      const resultCompany = await CompanyService.getCompany(companyId);
      setCompany(resultCompany);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  }, [companyId]);

  const fetchAccountManagers = useCallback(async () => {
    try {
      const result = await MembershipService.getMembershipsByCompanyAndRole(companyId ?? '', Role.EPDOwner);
      setAccountManagers(uniqBy(result, 'userId'));
    } catch {}
  }, [companyId]);

  React.useEffect(() => {
    fetchAccounts();
    fetchAccountManagers();
  }, [companyId, fetchAccounts, fetchAccountManagers]);

  const isAdmin = AuthService.isAdmin();
  return (
    <>
      {accounts.map((account) => (
        <AccountRow
          account={account}
          company={company}
          key={account.id}
          onCompleted={fetchAccounts}
          accountManagers={accountManagers}
          isAdmin={!!isAdmin}
        />
      ))}
      {status === ProcessStatus.Error && <ErrorText>{t('manageAccounts.errors.unFetched')}</ErrorText>}
      {status === ProcessStatus.Fetching && <InfoText>{t('manageAccounts.messages.fetching')}</InfoText>}
      {status === ProcessStatus.Success && !accounts && <InfoText>{t('manageAccounts.messages.noAccounts')}</InfoText>}
      {isAdmin && <AddNewAccountRow companyId={companyId} onCompleted={fetchAccounts} />}
    </>
  );
};

export default ManageAccounts;
