import React, { useEffect, useState } from 'react';
import AdminKpiService from 'services/admin/AdminKpiService';
import { KPIModel } from 'services/EpdClient';
import styled from 'styled-components';
import { Container, H2, H3 } from 'styles/Styles.styled';

const AdminKpis: React.FunctionComponent = () => {
  const [kpis, setKpis] = useState<KPIModel[]>([]);
  useEffect(() => {
    const fetchKpis = async () => {
      const getKpis = await AdminKpiService.getKpis();
      setKpis(getKpis);
    };
    fetchKpis();
  }, []);
  return (
    <Container style={{ justifyContent: 'center' }}>
      <KpiContainer>
        <H2 style={{ paddingLeft: '1rem', marginBottom: 0 }}>KPIs</H2>
        {kpis?.map((kpi) => (
          <>
            <Row>
              <H3 style={{ marginTop: '1rem', marginBottom: 0, textAlign: 'left' }}>{kpi.title}</H3>
            </Row>
            {kpi.description && <Row>{kpi.description}</Row>}
            {kpi.kpIs?.map((item) => (
              <>
                <ColumnA></ColumnA>
                <ColumnB>{item.key}</ColumnB>
                <ColumnC>{item.value}</ColumnC>
                <ColumnD></ColumnD>
              </>
            ))}
          </>
        ))}
      </KpiContainer>
    </Container>
  );
};

const Border = styled.div`
  padding: 1rem;
`;
const KpiContainer = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr 1fr 3rem;
  border: 1px solid rgb(217, 218, 219);
`;
const Row = styled(Border)`
  grid-column-start: 1;
  grid-column-end: 5;
  text-align: center;
`;

const ColumnA = styled(Border)`
  grid-column-start: 1;
  grid-column-end: 2;
`;
const ColumnB = styled(Border)`
  grid-column-start: 2;
  grid-column-end: 3;
`;
const ColumnC = styled(Border)`
  grid-column-start: 3;
  grid-column-end: 4;
  text-align: end;
`;
const ColumnD = styled(Border)`
  grid-column-start: 4;
  grid-column-end: 5;
`;

export default AdminKpis;
