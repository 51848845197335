import React from 'react';
import styled, { css } from 'styled-components';

const InformIcon: React.FunctionComponent<{ content: JSX.Element | string; top?: boolean; left?: boolean }> = ({
  content,
  top,
  left,
}) => {
  return (
    <Tooltip top={top} left={left}>
      {content}
    </Tooltip>
  );
};

const Tooltip = styled.div<{ top?: boolean; left?: boolean }>`
  display: none;
  ${(props) => props.theme.fonts.textSmall};
  text-transform: initial;
  position: absolute;
  background: ${(props) => props.theme.colors.darkGray};
  margin-left: 1rem;
  padding: 1rem;
  color: white;
  min-width: ${(props) => (props.top ? '15rem' : '12.5rem')};
  z-index: ${(props) => (props.top ? 1 : 2)};
  opacity: ${(props) => (props.top ? 1 : 0.9)};

  ${(props) =>
    props.left &&
    css`
      -webkit-transform: translate(-110%, 0%);
      transform: translate(-110%, 0%);
      -moz-transform: translate(-110%, 0%);
    `}
`;

export default InformIcon;
