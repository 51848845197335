import { LanguagesClient } from 'services/EpdClient';

const languagesClient = new LanguagesClient(process.env.REACT_APP_API_URL);

class LanguagesService {
  static getLanguages = async () => {
    return await languagesClient.getAll();
  };
}

export default LanguagesService;
