import { ActionAcceptButton, ActionDeclineButton } from 'components/v2/buttons';
import DeclineVerificationDialog from 'components/v2/copy/dialogs/DeclineVerificationDialog';
import UploadCertificationReportDialog from 'components/v2/copy/dialogs/UploadCertificationReportDialog';
import UploadVerificationReportDialog from 'components/v2/copy/dialogs/UploadVerificationReportDialog';
import { DeclinedLabel } from 'components/v2/copy/labels/DeclinedLabel';
import { SentForVerificationLabel } from 'components/v2/copy/labels/SentForVerificationLabel';
import { ButtonUploadIcon } from 'components/v2/icons';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useRef, useState } from 'react';
import { EPDModel, FileLoadingModel, VerificationType, VersionStateType } from 'services/EpdClient';
import { ActionState } from 'services/EpdClient';
import EpdStateService from 'services/EpdStateService';
import styled, { css } from 'styled-components';
import { EPDStatusBadge } from 'styles/v2/Styles.styled';

type TProps = {
  epd?: EPDModel;
  onChangeEpd?: any;
  refreshEPD: any;
  fileLoading?: FileLoadingModel[];
  canUpload: boolean;
  isReadOnly: boolean;
};

const HeaderActionPanelVerificationProcess = ({
  epd,
  onChangeEpd,
  refreshEPD,
  fileLoading,
  canUpload,
  isReadOnly,
}: TProps) => {
  const [isOpenedVerificationReportDialog, setIsOpenedVerificationReportDialog] = useState<boolean>(false);
  const [isOpenedCertificationReportDialog, setIsOpenedCertificationReportDialog] = useState<boolean>(false);
  const [isOpenedDeclineVerificationDialog, setIsOpenedDeclineVerificationDialog] = useState<boolean>(false);
  const [versionState, setVersionState] = useState<string>(epd?.versionState ?? VersionStateType.Draft);
  const menu = useRef<Menu>(null);
  useEffect(() => {
    if (epd === undefined) {
      return;
    }
    setVersionState(epd.versionState ?? VersionStateType.Draft);
  }, [epd, epd?.versionState]);

  const menuItemDeclined: MenuItem[] = [
    {
      label: 'Reset declined status',
      command: () => {
        EpdStateService.cancelDeclineVerification(epd?.id).then(() => {
          refreshEPD(epd?.id);
        });
      },
      visible: true,
    },
  ];

  const menuItemApproved: MenuItem[] = [
    {
      label: 'Reset approved status',
      command: () => {
        EpdStateService.cancelApproveVerification(epd?.id).then(() => {
          refreshEPD(epd?.id);
        });
      },
      visible: true,
    },
  ];

  const dotsMenu = (actionState?: ActionState, menuItems?: MenuItem[]) => {
    return actionState === ActionState.Enabled ? (
      <>
        <Divider layout="vertical" style={{ height: '1rem', alignSelf: 'center', padding: 0 }} />
        <Menu model={menuItems} popup ref={menu} />
        <Button className="border-none surface-section text-gray-900 px-0" onClick={(e) => menu.current?.toggle(e)}>
          <i className="pi pi-ellipsis-v font-bold" />
        </Button>
      </>
    ) : (
      <></>
    );
  };

  return (
    <div className="flex align-items-center">
      {(versionState === VersionStateType.Approved ||
        versionState === VersionStateType.RegisteredEditorial ||
        versionState === VersionStateType.LicenseeAdminVerification) && (
        <>
          {(!epd?.epdVerificationActionStates?.statusOfVerification ||
            epd?.epdVerificationActionStates?.statusOfVerification === ActionState.Enabled) && (
            <EPDStatusBadge status={versionState}>{VersionStateType.Approved}</EPDStatusBadge>
          )}

          {!isReadOnly && dotsMenu(epd?.epdVerificationActionStates?.additionalMenuInApproveStatus, menuItemApproved)}
        </>
      )}
      {versionState == VersionStateType.DeclinedVerification && (
        <>
          <DeclinedLabel actionState={epd?.epdVerificationActionStates?.statusOfVerification} />
          {!isReadOnly && dotsMenu(epd?.epdVerificationActionStates?.additionalMenuInDeclineStatus, menuItemDeclined)}
        </>
      )}
      {versionState == VersionStateType.UnderReview && (
        <>
          <SentForVerificationLabel actionState={epd?.epdVerificationActionStates?.statusOfVerification} />
          {epd?.verificationType === VerificationType.EPDVerification && canUpload && (
            <Button
              className="white-space-nowrap"
              label="Upload documents"
              icon={ButtonUploadIcon}
              text
              onClick={() => setIsOpenedVerificationReportDialog(true)}
            />
          )}
          {epd?.epdVerificationActionStates?.declineVerification !== ActionState.Hidden && (
            <Divider layout="vertical" style={{ height: '1rem', alignSelf: 'center', padding: 0 }} />
          )}
          <ActionDeclineButton
            label="Decline"
            style={{ marginLeft: '16px' }}
            onClick={() => setIsOpenedDeclineVerificationDialog(true)}
            actionState={epd?.epdVerificationActionStates?.declineVerification}
          />
          <ActionAcceptButton
            label="Approve"
            style={{ marginLeft: '16px' }}
            onClick={() =>
              EpdStateService.approveVerification(epd?.id, true).then(() => {
                refreshEPD(epd?.id);
              })
            }
            actionState={epd?.epdVerificationActionStates?.approveVerification}
          />
        </>
      )}
      {versionState == VersionStateType.Registered && <EPDStatusBadge status={versionState}>{versionState}</EPDStatusBadge>}
      {epd?.verificationType === VerificationType.EPDProcessCertification && canUpload && (
        <Button
          className="white-space-nowrap"
          label="Upload documents"
          icon={ButtonUploadIcon}
          text
          onClick={() => setIsOpenedCertificationReportDialog(true)}
        />
      )}
      <UploadVerificationReportDialog
        header="Upload documents"
        isOpened={isOpenedVerificationReportDialog}
        onHide={() => setIsOpenedVerificationReportDialog(false)}
        epd={epd}
        onChangeEpd={onChangeEpd}
        fileLoading={fileLoading}
      />
      <UploadCertificationReportDialog
        header="Upload documents"
        isOpened={isOpenedCertificationReportDialog}
        onHide={() => setIsOpenedCertificationReportDialog(false)}
        epd={epd}
        onChangeEpd={onChangeEpd}
        fileLoading={fileLoading}
      />
      <DeclineVerificationDialog
        header="Decline EPD publication"
        isOpened={isOpenedDeclineVerificationDialog}
        onHide={() => setIsOpenedDeclineVerificationDialog(false)}
        epd={epd}
        refreshEPD={refreshEPD}
      />
    </div>
  );
};

const buttonStylesApprove = css`
  font-size: 1rem;
  padding: 0.75rem 1rem;
  margin-left: 16;
  color: '#ffffff';
  background-color: '#757575';
  border-color: '#757575';
  border-radius: '2px';
  &:disabled {
    color: '#ffffff';
    background-color: '#757575';
    border-color: '#757575';
    border-radius: '2px';
  }
  /* &&&:hover {
    background-color: ${(props) => props.theme.colors.primaryWhite};
    border-color: ${(props) => props.theme.colors.elementsButtonHover};
    color: ${(props) => props.theme.colors.elementsButtonHover};
  } */
`;
const buttonStylesDecline = css`
  font-size: 1rem;
  padding: 0.75rem 1rem;
  margin: 0;
  color: '#ffffff';
  background-color: '#757575';
  border-color: '#757575';
  border-radius: '2px';
`;
export const ApproveButton = styled(Button)`
  ${buttonStylesApprove}
`;
export const DeclineButton = styled(Button)`
  ${buttonStylesDecline}
`;
export default HeaderActionPanelVerificationProcess;
