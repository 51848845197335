const CalendarIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4999 4H4.49999C3.94771 4 3.5 4.44771 3.5 4.99999V11.9999C3.5 12.5522 3.94771 12.9999 4.49999 12.9999H11.4999C12.0522 12.9999 12.4999 12.5522 12.4999 11.9999V4.99999C12.4999 4.44771 12.0522 4 11.4999 4Z"
        stroke="#151515"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.99976 3V4.99998" stroke="#151515" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 3V4.99998" stroke="#151515" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 7H12.4999" stroke="#151515" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CalendarIcon;
