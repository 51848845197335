import HelpIcon from 'components/icons/HelpIcon';
import MandatoryIcon from 'components/icons/MandatoryIcon';
import ConfirmModal from 'components/modals/ConfirmModal';
import OverlaySpinner from 'components/spinners/OverlaySpinner';
import { ProcessStatus } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { EpdStateButton } from 'pages/wizard/EpdStateButtons';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EpdLinks } from 'routes/EpdRoutes';
import { ActionState, EPDModel, EPDStateModel, EPDStatus, EPDVersionState, Subtype } from 'services/EpdClient';
import EpdStateService from 'services/EpdStateService';
import styled from 'styled-components';
import { ErrorMessage, FormMode, H2, StyledForm, StyledReactSelect } from 'styles/Styles.styled';
import { PoorMansError, formatDateTime } from 'util/utils';
import * as Yup from 'yup';

import AuthService from '../../services/AuthService';

type Option = {
  label: Date | string;
  value: string;
};

type TProps = {
  epd: EPDModel;
  companyId: string;
  updateEpd: (state: EPDStateModel | null) => void;
  epdVersions: EPDModel[];
  agreementConsent: boolean;
};

const ToolButtons = ({ epd, companyId, updateEpd, epdVersions, agreementConsent }: TProps) => {
  const { changeCompany } = useContext(CompanyContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeregisterModalOpen, setIsDeregisterModalOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);

  const publishedVersion = epdVersions?.find((version) => version.versionDate === epd.publishedVersionDate);

  useEffect(() => {
    var showToastRegisterEditorial = localStorage.getItem('showToastRegisterEditorial');
    if (showToastRegisterEditorial === 'true') {
      toast.success(toastTemplate, { autoClose: false, style: { minWidth: '30rem' } });
      localStorage.setItem('showToastRegisterEditorial', 'false');
    }
  }, []);

  const changeDeregisterModalState = () => {
    setIsDeregisterModalOpen(!isDeregisterModalOpen);
  };

  const changeDeleteModalState = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toastTemplate = (): ReactNode => {
    return (
      <div className="">
        <b>{'Thank you for registering your EPD with the International EPD System!'}</b>
        <p style={{ marginBottom: 0 }}>
          {
            'We will attend to it as soon as possible. We aim to publish the EPD within 1-3 days. If you have any questions feel free to contact us in the Secretariat at support@environdec.com.'
          }
        </p>
        <p style={{ marginBottom: 0 }}>
          {
            'If any information is missing or if any irregularities are recognized in the submitted material the support team will contact you.'
          }
        </p>
      </div>
    );
  };

  const onRegisterEditorial = async (epdId: string) => {
    try {
      await EpdStateService.registerEditorial(epdId, notes);
      localStorage.setItem('showToastRegisterEditorial', 'true');
      history.go(0);
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
  };

  const onRegister = async (epdId: string) => {
    try {
      await EpdStateService.register(epdId);
      toast.success(toastTemplate, { autoClose: false, style: { minWidth: '30rem' } });
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
  };

  const handleDeleteEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.delete(epd.id);
      setStatus(ProcessStatus.Success);
      toast.success(
        t('messages.epdDeleted', {
          epdNumber: publishedVersion?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
        }) as string,
        {
          position: 'top-center',
        }
      );
      changeDeleteModalState();
      changeCompany?.(companyId);
      history.push(EpdLinks.dashboard());
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const handleDeregister = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.deregister(epd.id);
      setStatus(ProcessStatus.Success);
      changeDeregisterModalState();
      history.go(0);
      toast.success(
        t('messages.epdDeregister', {
          epdNumber: publishedVersion?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
        }) as string,
        {
          position: 'top-center',
        }
      );
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const handleCancelUpdateEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.cancelUpdate(epd.id);
      setStatus(ProcessStatus.Success);
      toast.success(t('messages.epdCancelled') as string, {
        position: 'top-center',
      });
      changeDeleteModalState();
      changeCompany?.(companyId);
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const isSuperAdmin = AuthService.isAdmin();

  const now = new Date();

  const disableStart = new Date(now.getFullYear(), 11, 30, 0, 0, 0); // December 30, 00:00 local time
  const disableEnd = new Date(now.getFullYear(), 11, 31, 23, 59, 59); // December 31, 23:59 local time

  const registerEpdIsDisabledDueToYearEnd = now >= disableStart && now <= disableEnd;

  const registerEpdState =
    registerEpdIsDisabledDueToYearEnd || (epd.epdState?.register === ActionState.Enabled && !agreementConsent)
      ? ActionState.Disabled
      : epd.epdState?.register;

  const validationSchema = Yup.object({
    version: Yup.object().required('Required').nullable(),
  });

  return (
    <>
      {status === ProcessStatus.Fetching && <OverlaySpinner />}
      <ButtonGroup>
        <H2 style={{ margin: '1rem auto 1rem 1rem' }}>
          {epd?.name} - <span style={{ fontWeight: 'normal' }}>{epd?.status}</span>
        </H2>
        <EpdStateButton
          title={t('epdWizard.epdState.action.cancelUpdate')}
          actionState={epd.epdState?.cancelUpdate}
          epdId={epd.id}
          callFunction={changeDeleteModalState}
          buttonStyle={{ background: 'grey' }}
        />
        <EpdStateButton
          title={t('epdWizard.epdState.action.delete')}
          actionState={epd.epdState?.deleteDraft}
          epdId={epd.id}
          callFunction={changeDeleteModalState}
          buttonStyle={{ background: 'grey' }}
        />
        {epd.epdState?.cancelUpdate === ActionState.Enabled && isDeleteModalOpen && (
          <ConfirmModal
            description={t('confirmModal.cancelUpdateEPD')}
            confirmButtonText={t('Cancel update')}
            cancelButtonText={t('Close')}
            onClickConfirm={handleCancelUpdateEpd}
            onClickCancel={() => changeDeleteModalState()}
          />
        )}
        {epd.epdState?.deleteDraft === ActionState.Enabled && isDeleteModalOpen && (
          <ConfirmModal
            description={t('confirmModal.deleteEPD', { name: epd.name })}
            confirmButtonText={t('Delete')}
            cancelButtonText={t('Close')}
            onClickConfirm={handleDeleteEpd}
            onClickCancel={() => changeDeleteModalState()}
          />
        )}
        <EpdStateButton
          title={t('epdWizard.epdState.action.startVerification')}
          actionState={epd.epdState?.startVerification}
          epdId={epd.id}
          callFunction={EpdStateService.startVerification}
          toastMessage={t('messages.startVerification')}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
        <EpdStateButton
          title={t('epdWizard.epdState.action.cancelVerification')}
          actionState={epd.epdState?.cancelVerification}
          epdId={epd.id}
          callFunction={EpdStateService.cancelVerification}
          toastMessage={t('messages.cancelVerification')}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
        <EpdStateButton
          title={t('epdWizard.epdState.action.registerEpd')}
          actionState={registerEpdState}
          epdId={epd.id}
          callFunction={onRegister}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
        <EpdStateButton
          title={t('epdWizard.epdState.action.deregisterEpd')}
          actionState={epd.epdState?.deregister}
          epdId={epd.id}
          callFunction={changeDeregisterModalState}
          buttonStyle={{ background: 'grey' }}
        />
        {isDeregisterModalOpen && (
          <ConfirmModal
            description={t('confirmModal.deregisterEPD')}
            confirmButtonText={t('deregister')}
            cancelButtonText={t('cancel')}
            onClickConfirm={handleDeregister}
            onClickCancel={() => changeDeregisterModalState()}
          />
        )}
        <EpdStateButton
          title={t('epdWizard.epdState.action.cancelDeregistration')}
          actionState={epd.epdState?.cancelDeregistration}
          epdId={epd.id}
          callFunction={EpdStateService.cancelDeregisteration}
          toastMessage={t('messages.cancelDeregistration')}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
        <EpdStateButton
          title={t('epdWizard.epdState.action.startUpdatingEpd')}
          actionState={epd.epdState?.unlock}
          epdId={epd.id}
          callFunction={EpdStateService.unlock}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
        <EpdStateButton
          title={t('epdWizard.epdState.action.retractVerification')}
          actionState={epd.epdState?.retractVerification}
          epdId={epd.id}
          callFunction={EpdStateService.cancelApproveVerification}
          toastMessage={t('messages.retractVerification')}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
        <EpdStateButton
          title={t('epdWizard.epdState.action.approveEpd')}
          actionState={epd.epdState?.approve}
          epdId={epd.id}
          callFunction={EpdStateService.approve}
          toastMessage={t('messages.epdApproved', {
            epdNumber: publishedVersion?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
          })}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
        {isSuperAdmin && (
          <Formik
            initialValues={{
              version: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setStatus(ProcessStatus.Fetching);
              const versionId = values.version;
              EpdStateService.publish((versionId || ({} as Option)).value, epd?.id).then((result) => updateEpd(result));
            }}
          >
            {({ values: { version } }: any) => {
              const versionToPublish = epdVersions?.find((v) => v.versionId === version?.value);
              return (
                <StyledForm
                  mode={FormMode.Inline}
                  style={{ display: 'flex', alignItems: 'flex-start', paddingTop: '0.5rem' }}
                >
                  {epd.epdState?.publish === ActionState.Enabled && (
                    <SelectAsButtonStyles>
                      <StyledReactSelect
                        name="version"
                        styles={{
                          control: (p: any) => ({ ...p, minWidth: '10rem', minHeight: '44px' }),
                        }}
                        options={epdVersions?.map((version) => {
                          return {
                            value: version.versionId,
                            label: formatDateTime(version.versionDate),
                          } as Option;
                        })}
                        isClearable={true}
                      />
                    </SelectAsButtonStyles>
                  )}
                  <EpdStateButton
                    title={t('epdWizard.epdState.action.publishEpd')}
                    buttonStyle={{ marginTop: 0 }}
                    actionState={epd.epdState?.publish}
                    epdId={epd.id}
                    toastMessage={t('messages.epdPublished', {
                      epdNumber: versionToPublish?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
                    })}
                    callFunction={() => {}}
                  />
                </StyledForm>
              );
            }}
          </Formik>
        )}
        <EpdStateButton
          title={t('epdWizard.epdState.action.depublishEpd')}
          actionState={epd.epdState?.depublish}
          epdId={epd.id}
          callFunction={EpdStateService.depublish}
          toastMessage={t('messages.epdDepublished', { epdNumber: publishedVersion?.fullIdentificationNumber })}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
          buttonStyle={{ background: 'grey' }}
        />
        <EpdStateButton
          title={t('epdWizard.epdState.action.declineEpd')}
          actionState={epd.epdState?.needsVerification}
          epdId={epd.id}
          callFunction={EpdStateService.needsVerification}
          toastMessage={t('messages.declineEpd')}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
        {epd.epdState?.registerEditorial === ActionState.Enabled && (
          <ButtonGroup>
            <Text>
              {t('epdWizard.epdState.action.registerEditorial')}
              <HelpIcon content={t('epdWizard.tooltips.editorialChanges')} />
              {isEmpty(notes) && <MandatoryIcon />}
            </Text>
            <TextArea
              placeholder={t('epdWizard.placeholder.registerEditorial')}
              onChange={(e) => setNotes(e.target.value)}
            />
            <EpdStateButton
              buttonStyle={{ marginTop: '0' }}
              title={t('epdWizard.epdState.action.registerUpdatedEpd')}
              actionState={isEmpty(notes) ? ActionState.Disabled : ActionState.Enabled}
              epdId={epd.id}
              callFunction={onRegisterEditorial}
              toastMessage={t('messages.registerEditorialUpdate')}
            />
          </ButtonGroup>
        )}{' '}
        {epd.epdState?.licenseeAdminVerification === ActionState.Enabled && (
          <ButtonGroup>
            <EpdStateButton
              title={t('epdWizard.epdState.action.licenseeAdminVerificationApprove')}
              actionState={epd.epdState?.licenseeAdminVerification}
              epdId={epd.id}
              setState={(state: EPDStateModel | null) => updateEpd(state)}
              toastMessage={t('messages.approveEpdChanges')}
              callFunction={EpdStateService.licenseeAdminVerificationApprove}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.licenseeAdminVerificationDecline')}
              actionState={epd.epdState?.licenseeAdminVerification}
              epdId={epd.id}
              setState={(state: EPDStateModel | null) => updateEpd(state)}
              toastMessage={t('messages.declineEpd')}
              callFunction={EpdStateService.licenseeAdminVerificationDecline}
              buttonStyle={{ background: 'grey' }}
            />
          </ButtonGroup>
        )}
        {errorMessage && <ErrorMessage style={{ marginTop: '1rem' }}> {errorMessage}</ErrorMessage>}
        <EpdStateButton
          title={t('epdWizard.epdState.action.approveUpdatedEpd')}
          actionState={epd.epdState?.approveEditorial}
          epdId={epd.id}
          toastMessage={t('messages.approveEpdChanges')}
          callFunction={EpdStateService.approveEditorial}
          setState={(state: EPDStateModel | null) => updateEpd(state)}
        />
      </ButtonGroup>
    </>
  );
};

const SelectAsButtonStyles = styled.div`
  padding: 0 0.5rem;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
`;

const Text = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: left;
  ${(props) => props.theme.fonts.text};
  font-size: 0.96rem;
`;

const TextArea = styled.textarea`
  cursor: pointer;
  box-sizing: border-box;
  margin-left: 1rem;
  padding: 0.5rem;
  border: solid 1px gray;
  min-width: 28rem;
  background-color: #f4f4f4;
  height: 3rem;
  :focus {
    outline: none;
  }
`;

export default ToolButtons;
