import React from 'react';
import ReactDOM from 'react-dom';
import timeout from './timeout';

const animationInMs = 300;

interface IModalProps {
  children: any;
}

interface IModalState {}
export class Modal extends React.Component<IModalProps, IModalState> {
  modalRoot = document.getElementById('modal');
  el: HTMLDivElement;

  constructor(props: IModalProps) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.transition = `transform ${animationInMs}ms ease-out`;
    this.el.style.transform = 'translateX(100%)';
  }

  animateOut = async () => {
    this.el.style.transform = 'translateX(100%)';
    return timeout(animationInMs);
  };

  animateIn = async () => {
    // Empty delay in order for the element to first be rendered.
    await timeout(0);
    this.el.style.transform = 'translateX(0)';
    return timeout(animationInMs);
  };

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot?.appendChild(this.el);
    if (this.modalRoot) {
      this.modalRoot.style.display = 'flex';
    }

    this.animateIn();
  }

  componentWillUnmount() {
    this.modalRoot?.removeChild(this.el);
    if (this.modalRoot) {
      this.modalRoot.style.display = 'none';
    }
  }

  render() {
    return <>{ReactDOM.createPortal(this.props.children, this.el)}</>;
  }
}
