import { pcrSelectStyles } from 'components/form/PcrSelect';
import UploadFile from 'components/form/UploadFile';
import helpIcon from 'images/icons/svg/info.svg';
import React, { ChangeEvent } from 'react';
import Select from 'react-select';
import { A, ButtonSmall, InfoBox } from 'styles/Styles.styled';
import { FixedLabel, Row } from './PcrWizard';

const WizardStep4: React.FunctionComponent<{
  pcr?: any;
  onChangePcr: any;
  validation: any;
  goToStep: any;
}> = ({ pcr, onChangePcr, validation, goToStep }) => {
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !pcr) {
      return null;
    }

    onChangePcr && onChangePcr(propertyName, file);
  };

  return (
    <div>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Revised draft with incorporated comments from OC</FixedLabel>
        <UploadFile
          accept=".pdf"
          title="Please upload a revised draft (docx)."
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.draftOCDocuments}
          onChangeFile={onChangePcr}
          field="PCR-DRAFTOC"
        />
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Summary of open consultation</FixedLabel>
        <UploadFile
          accept=".pdf"
          title="Please upload a summary of the open consultation (docx)."
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.summaryOCDocuments}
          onChangeFile={onChangePcr}
          field="PCR-SUMMARYOC"
        />
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Draft with comments from secretariat</FixedLabel>
        <UploadFile
          accept=".pdf"
          title="Please upload comments from the secretariat (docs)."
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.draftSecretariatDocuments}
          onChangeFile={onChangePcr}
          field="PCR-DRAFTSECRETARIAT"
        />
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Preview by secretariat</FixedLabel>
        <UploadFile
          accept=".pdf"
          title="Please upload the preview by secretariat (docx)."
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.summarySecretariatDocuments}
          onChangeFile={onChangePcr}
          field="PCR-SUMMARYSECRETARIAT"
        />
      </Row>
      <Row style={{ marginTop: '1.5rem' }}>
        <FixedLabel>Invite members of TC to review the draft</FixedLabel>
        <Select onChange={(e) => {}} isMulti={false} styles={pcrSelectStyles} isClearable={true} />
      </Row>
      <Row style={{ marginTop: '1rem', justifyContent: 'left' }}>
        <FixedLabel></FixedLabel>
        <div style={{ justifySelf: 'end', marginLeft: '11rem' }}>
          <ButtonSmall>Invite Members</ButtonSmall>
        </div>
      </Row>
      <Row style={{ marginTop: '1.5rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Draft with comments from TC and secretariat</FixedLabel>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <InfoBox>
            <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
            <div>
              Template for review report.
              <br />
              <A href={''}>Download template</A>
            </div>
          </InfoBox>
          <UploadFile
            accept=".pdf"
            title="Please upload draft with comments from TC and secretariat (docx)."
            uploadFile={uploadFile}
            disabled={false}
            documents={pcr?.draftTCDocuments}
            onChangeFile={onChangePcr}
            field="PCR-DRAFTTC"
          />
        </div>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Review report with comments from TC and secretariat</FixedLabel>
        <UploadFile
          accept=".pdf"
          title="Please upload review report (docx)."
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.summaryTCDocuments}
          onChangeFile={onChangePcr}
          field="PCR-SUMMARYTC"
        />
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Final draft from PCR moderator</FixedLabel>
        <UploadFile
          accept=".pdf"
          title="Please upload final draft (docx)."
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.draftFinalDocuments}
          onChangeFile={onChangePcr}
          field="PCR-DRAFTFINAL"
        />
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Separate response to review</FixedLabel>
        <UploadFile
          accept=".pdf"
          title="Please upload separate response to review (docs)."
          uploadFile={uploadFile}
          disabled={false}
          documents={pcr?.summaryFinalDocuments}
          onChangeFile={onChangePcr}
          field="PCR-SUMMARYFINAL"
        />
      </Row>
    </div>
  );
};
export default WizardStep4;
