import { HelpBox } from 'components/v2/help-boxes';
import React from 'react';
import { FormPanel, HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

const OwnerWillInitiate: React.FunctionComponent = () => {
  return (
    <FormPanel style={{ display: 'block' }}>
      <HeaderCaptionSemiBold className="white-space-nowrap">Verification report</HeaderCaptionSemiBold>
      <HelpBox style={{ marginTop: '30px' }}>
        When EPD Owner initiates the verification process, the EPD verifier will be able to check that the uploaded EPD and
        filled information in the
        <br />
        Step 2 &gt; Product information corresponds with the verified information in the LCA report and EPD document.
      </HelpBox>
    </FormPanel>
  );
};

export default OwnerWillInitiate;
