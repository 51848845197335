import React from 'react';
import { ButtonSmall } from 'styles/Styles.styled';
import { formatDate } from 'util/utils';

import { FixedLabel, Row } from './PcrWizard';

const WizardStep5: React.FunctionComponent<{
  pcr: any;
  onChangePcr: any;
  validation: any;
  goToStep: any;
}> = ({ pcr, onChangePcr, validation, goToStep }) => {
  return (
    <div>
      <Row style={{ marginTop: '1rem' }}>
        <FixedLabel style={{ alignSelf: 'start' }}>Validity date</FixedLabel>
        <em>{formatDate(pcr.validityDate)}</em>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <ButtonSmall style={{ width: '8rem' }} type="button">
          Publish PCR
        </ButtonSmall>
      </Row>
    </div>
  );
};

export default WizardStep5;
