import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { ConfirmEmailModel } from 'services/EpdClient';
import { CenterColumn, Container, ErrorText, H2, StyledLink } from 'styles/Styles.styled';

const ConfirmEmail: React.FunctionComponent = () => {
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const location = useLocation();
  const values = queryString.parse(location.search);
  const userId = values?.userId?.toString();
  const token = values?.token?.toString();

  React.useEffect(() => {
    const confirmEmail = async () => {
      if (!userId || !token) {
        setError('Something is not right. Try again later?');
        return;
      }
      const request: ConfirmEmailModel = {
        userId: userId,
        token: token,
      };
      try {
        await AuthService.confirmEmail(request);
      } catch (exception: any) {
        setError(JSON.stringify(exception));
        return;
      }

      setSuccess(true);
    };

    confirmEmail();
  }, [token, userId]);

  return (
    <Container>
      <CenterColumn>
        <H2>Email confirmation</H2>
        {!success ? (
          <p>Confirming your email...</p>
        ) : (
          success && (
            <p>
              Your email address has been confirmed. Welcome!
              <br />
              <div style={{ marginTop: '1rem' }}>
                {AuthService.isAuthenticated() ? (
                  <StyledLink to={EpdLinks.dashboard()}>Go to the dashboard</StyledLink>
                ) : (
                  <StyledLink to={EpdRoutes.login}>Log in</StyledLink>
                )}
              </div>
            </p>
          )
        )}
        {error && <ErrorText>{error}</ErrorText>}
      </CenterColumn>
    </Container>
  );
};

export default ConfirmEmail;
