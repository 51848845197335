import { Subtype } from 'services/EpdClient';

export default function getSubtypeLabel(subtype: Subtype) {
  switch (subtype) {
    case Subtype.Specific:
      return 'Specific (single product)';
    case Subtype.Average:
      return 'Average (multiple products)';
    case Subtype.Representative:
      return 'Representative (Sector EPD)';
    case Subtype.Template:
      return 'Template (product not yet on the market)';
    default:
      throw new Error('Invalid subtype has been passed.');
  }
}
