import { DictionaryValue, Option } from 'types/types';

export const PoorMansError = (errors: any): string => {
  //Server errors are inconsistent. Try parse response as errors

  if (typeof errors === 'string') {
    try {
      const object = JSON.parse(errors);
      return PoorMansError(object);
    } catch {
      return errors;
    }
  }

  if (!errors) {
    return '';
  }

  if (errors.detail) {
    return errors.detail;
  }

  if (errors.title) {
    return errors.title;
  }

  if (errors.errorMessage) {
    return errors.errorMessage;
  }

  if (errors.ErrorMessage) {
    return errors.ErrorMessage;
  }

  if (errors.response) {
    return PoorMansError(errors.response);
  }

  const getErrorFromArray = (errorsList: any): string => {
    if (errorsList.length > 0) {
      if (Array.isArray(errorsList[0])) {
        return PoorMansError(errorsList[0][0]);
      }

      return PoorMansError(errorsList[0]);
    }

    return '';
  };

  if (errors.Errors) {
    const errorsList = Object.values(errors.Errors);
    return getErrorFromArray(errorsList);
  }

  const values = errors.errors ? Object.values(errors.errors) : Object.values(errors);
  return getErrorFromArray(values);
};

export const generateExpireTime = () => {
  let date = new Date();
  date.setMinutes(date.getMinutes() + 600);
  date = new Date(date);
  return date.getTime();
};

export function formatDate(field?: Date | string | null) {
  if (field == null) return undefined;
  // The date picker expects the format yyyy-mm-dd according to the HTML specifications
  // and the swedish format happens to be just yyyy-mm-dd.
  return Intl.DateTimeFormat('sv-SE').format(field instanceof Date ? field : new Date(field));
}

export function formatAsUTCDate(field?: Date | string) {
  if (field == null) return undefined;
  const d = field instanceof Date ? field : new Date(field);
  const utcDate = new Date(
    Date.UTC(
      d.getFullYear(), // Year
      d.getMonth(), // Month (0-11)
      d.getDate(), // Day of the month (1-31)
      d.getHours(), // Hours (0-23)
      d.getMinutes(), // Minutes (0-59)
      d.getSeconds(), // Seconds (0-59)
      d.getMilliseconds() // Milliseconds (0-999)
    )
  );
  return utcDate;
}

export function formatDateTime(field?: Date | string) {
  const options: {
    year: string;
    month: string;
    day: string;
    hour: string;
    minute: string;
    hour12: boolean;
  } = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  if (field == null) return undefined;
  // The date picker expects the format yyyy-mm-dd according to the HTML specifications
  // and the swedish format happens to be just yyyy-mm-dd.
  return Intl.DateTimeFormat('sv-SE', options as any).format(field instanceof Date ? field : new Date(field));
}

export const isWithin24Hours = (checkedDate: Date | undefined) => {
  if (!checkedDate) return false;

  const now = new Date();
  const nowInUTC = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  );
  const msIn24Hours = 24 * 60 * 60 * 1000;
  const timeDifference = nowInUTC.getTime() - checkedDate.getTime();

  return timeDifference >= 0 && timeDifference <= msIn24Hours;
};

export function isValidImageFile(file: File, supportedExtensions: string[]): boolean {
  const extensionIndex = file.name.lastIndexOf('.');
  if (extensionIndex === -1) {
    return false;
  }
  const fileExtension = file.name.substring(extensionIndex);
  return supportedExtensions.includes(fileExtension);
}

export const isPositiveDecimal = (val: any) => {
  let str = String(val);
  str = str.trim();

  if (!str || str === 'null') {
    return true;
  }

  let n = Number(str);

  return n !== Infinity && String(n) === str && n >= 0;
};

export const getOptionsFromDictionary = (data: DictionaryValue[] | undefined, code: string) =>
  Array.isArray(data)
    ? data
        .filter((x) => x.dictionaryCode === code)
        .map(
          (x: DictionaryValue) =>
            ({
              value: x.key.toString(),
              label: x.value,
              code: x.code,
            } as Option)
        )
    : [];
