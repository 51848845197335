import 'primeflex/primeflex.css';

import { ProcessStatus } from 'constants/constants';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import CollectionServiceV2 from 'services/CollectionServiceV2';
import { CollectionDashboardModel, CollectionModel } from 'services/EpdClient';
import styled from 'styled-components';
import { ButtonSmall, ErrorText, InfoText } from 'styles/Styles.styled';

import CollectionCard from './CollectionCard';
import CollectionListItem from './CollectionListItem';
import WelcomeCollections from './WelcomeCollections';

interface SortOption {
  label: string;
  value: string;
}

const ManageCollections: React.FunctionComponent<{ companyId: string }> = ({ companyId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [collections, setCollections] = useState<CollectionModel[]>([]);
  const [layout, setLayout] = useState('grid');

  const [sortKey, setSortKey] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(0);
  const [sortField, setSortField] = useState<string>('');
  const sortOptions: SortOption[] = [
    { label: 'Name High to Low', value: '!name' },
    { label: 'Name Low to High', value: 'name' },
    // { label: 'Created High to Low', value: '!created' },
    // { label: 'Created Low to High', value: 'created' },
  ];

  const onSortChange = (event: DropdownChangeEvent) => {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      setSortOrder(-1);
      setSortField(value.substring(1, value.length));
      setSortKey(value);
    } else {
      setSortOrder(1);
      setSortField(value);
      setSortKey(value);
    }
  };

  const createNewCollection = async () => {
    try {
      openCollection('new');
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCollections = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const result = await CollectionServiceV2.getCollections(companyId);
      setCollections(result);
      setStatus(ProcessStatus.Success);
    } catch (error) {
      console.error(error);
      setStatus(ProcessStatus.Error);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, [companyId]);

  if (status === ProcessStatus.Fetching) {
    return <InfoText>{t('manageSettings.messages.fetching')}</InfoText>;
  }

  if (!companyId) {
    return <ErrorText>{t('manageSettings.errors.technical')}</ErrorText>;
  }

  const openCollection = async (collectionId: string) => {
    try {
      setStatus(ProcessStatus.Fetching);
      const route = EpdLinks.editCollection(collectionId);
      history.push(route);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  const itemTemplate = (collection: CollectionDashboardModel, layout: string) => {
    if (!collection) {
      return;
    }

    if (layout === 'list') return <CollectionListItem collection={collection} fetchCollections={fetchCollections} />;
    else if (layout === 'grid') return <CollectionCard collection={collection} fetchCollections={fetchCollections} />;
  };

  return collections.length > 0 ? (
    <>
      <ConfirmDialog />
      <div className="flex justify-content-between w-full p-2">
        <PageTitle>{t('manageCollections.collections')}</PageTitle>
        <ButtonSmall
          style={{ paddingLeft: '1.4rem', paddingRight: '1.4rem', alignSelf: 'center' }}
          onClick={() => createNewCollection()}
        >
          {t('createCollection')}
        </ButtonSmall>
      </div>

      <div className="card" style={{ width: '100%' }}>
        <DataView
          value={collections}
          itemTemplate={itemTemplate}
          layout={layout as 'grid' | 'list' | (string & Record<string, unknown>) | undefined}
          // header={header()}
          paginator={collections.length > 6}
          paginatorTemplate={{ layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink' }}
          paginatorClassName="paginator-right"
          rows={6}
          sortField={sortField}
          sortOrder={sortOrder}
        />
      </div>
    </>
  ) : (
    <WelcomeCollections companyId={companyId} />
  );
};

const PageTitle = styled.div`
  //font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  /* Primary/Black */
  color: #151515;
`;

export default ManageCollections;
