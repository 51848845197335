import logo from 'assets/images/portal-logo.svg';
import TextInput from 'components/form/TextInput';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EpdRoutes from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { ForgotPasswordModel } from 'services/EpdClient';
import styled from 'styled-components';
import {
  A,
  Button,
  CenterColumn,
  Container,
  ErrorText,
  Footer,
  H1,
  HeaderLogo,
  LoginFlowA,
  StyledForm,
  Text,
} from 'styles/Styles.styled';
import * as Yup from 'yup';

const ForgotPassword: React.FunctionComponent = () => {
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const initialValues: ForgotPasswordModel = {
    email: '',
  };

  const forgotPasswordSchema = Yup.object({
    email: Yup.string().required('Required').email('Invalid email address'),
  });

  const forgotPasword = async (values: ForgotPasswordModel) => {
    try {
      await AuthService.forgotPassword(values);
    } catch (error) {
      setError(error as string);
      return;
    }

    setSuccess(true);
  };

  return (
    <RegisterContainer>
      <HeaderLogo src={logo} alt="EPD International" />
      <Container>
        <CenterColumn>
          <H1>Forgot password?</H1>
          {success ? (
            <Text>
              Instructions to reset your password have been sent to the e-mail that you submitted. Please follow the
              instructions in the e-mail.
            </Text>
          ) : (
            <>
              <Text>
                Enter the e-mail address that you used when registering. Instructions to reset your password will be sent to
                you.
              </Text>
              <Formik
                initialValues={initialValues}
                validationSchema={forgotPasswordSchema}
                onSubmit={(values) => forgotPasword(values)}
              >
                <StyledForm>
                  <TextInput label="Email Address" name="email" type="email" placeholder="email@example.com" />
                  <Button>Reset password</Button>
                  {error && <ErrorText>{error}</ErrorText>}
                </StyledForm>
              </Formik>
            </>
          )}
          <div style={{ textAlign: 'center' }}>
            <LoginFlowA href={EpdRoutes.login}>Back to login page</LoginFlowA>
          </div>
        </CenterColumn>
      </Container>
      <Footer>
        {t('footer.text')}
        <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>{'|'}</span>
        <A href={t('footer.link')}>{t('footer.linkText')}</A>
      </Footer>
    </RegisterContainer>
  );
};

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
  min-height: 100vh;
  ${Container} {
    margin: auto;
  }
  ${H1} {
    text-align: center;
  }
  ${Text} {
    text-align: center;
    margin-bottom: 2rem;
  }
  ${LoginFlowA} {
    margin: 3rem auto 0;
    text-align: center;
  }
`;

export default ForgotPassword;
