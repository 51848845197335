import { Button } from 'primereact/button';
import { useRef } from 'react';
import { FileModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';
import { ButtonUploadIcon } from '../icons';
import { toast } from 'react-toastify';

interface ImageUploadProps {
  name: string;
  image?: string | ArrayBuffer | null | FileModel;
  onRemoveImage: (e: any) => any;
  onChange: any;
  disabled?: boolean;
}

const CollectionImageUpload = ({ name, image, onRemoveImage, onChange, disabled = false, ...props }: ImageUploadProps) => {
  const inputFileRef = useRef(null);

  const handleRemove = (e: any) => {
    // @ts-ignore
    inputFileRef.current.value = '';
    onRemoveImage(e);
  };

  const onChangeInternal = (e: any, validExtensions: string[]) => {
    if (validExtensions?.length > 0) {
      for (const file of e.target.files) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!validExtensions?.includes(fileExtension)) {
          toast.error(`Please upload file having extensions ${validExtensions.join('/')} only.`, { position: 'top-center' });
          return false;
        }
      }
    }

    onChange(e);
  };

  const getImageBox = (img: any) => {
    return (
      <ImageBox key={img?.id}>
        <ImageBoxImage>
          {/* @ts-ignore */}
          {image && !image.id && <img alt={'Collection image'} src={image} />}
          {image && (image as FileModel).id && (
            <img alt={'Collection image'} src={FileService.getImageUrl((image as FileModel).id)} />
          )}
        </ImageBoxImage>
        <ImageRemove>{!disabled && <ImageIconButton icon="pi pi-times" onClick={handleRemove} />}</ImageRemove>
      </ImageBox>
    );
  };

  const noImage = () => {
    return (
      <div className="flex w-full h-full justify-content-center align-content-center">
        <div
          className="flex flex-column justify-content-center align-items-center"
          style={{
            color: 'rgba(21, 21, 21, 0.5)',
          }}
        >
          <div>
            <i className="pi pi-image" />
          </div>
          <span>Uploaded cover image preview will appear in this box</span>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <PreviewImageBox>{image ? getImageBox(image) : noImage()}</PreviewImageBox>
      <UploadButton>
        {!disabled && (
          <div>
            <Button label="Upload cover" icon={ButtonUploadIcon} text />
            <input
              type="file"
              accept=".png,.jpg,.jpeg"
              name={name}
              onChange={(e) => onChangeInternal(e, ['png', 'jpg', 'jpeg'])}
              ref={inputFileRef}
              disabled={!!image}
            />
          </div>
        )}
      </UploadButton>
    </div>
  );
};

export default CollectionImageUpload;

const UploadButton = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

const PreviewImageBox = styled.div`
  /* Primary/White */
  background: #ffffff;
  /* Shadow/Block */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  /* Elements/Input filled */
  border: 1px solid #eef1f4;
  display: flex;
  width: 100%;
  min-height: 106px;
  padding: 13px;
  align-items: center;
`;

const ImageRemove = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
`;

const ImageBox = styled.div`
  position: relative;
  display: inline-block;
`;

const ImageBoxImage = styled.div`
  position: relative;
  flex: 0 0 auto;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: scale-down;
    height: 80px;
  }
`;

const ImageIconButton = styled(Button)`
  background-color: rgba(21, 21, 21, 0.5);
  border-color: rgba(21, 21, 21, 0.5);
  border-radius: 100%;
  color: white;
  padding: 0 !important;
  margin: 0 !important;
  width: 12px !important;
  height: 12px !important;
  span {
    font-size: 8px;
    font-weight: 900;
  }
`;
