import useHelpDialog from 'components/v2/copy/dialogs/help-dialog/useHelpDialog';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import styled from 'styled-components';

import { HelpButton, PrimaryButton } from '../../../buttons';

type TProps = React.PropsWithChildren<{
  title: string;
  helpButtonRef: any;
  backLabel?: string | undefined;
}>;

const HelpButtonDialog = ({ title, helpButtonRef, backLabel, children }: TProps) => {
  const { isHelpDialogOpen, closeHelpDialog, openHelpDialog, helpDialogTitle } = useHelpDialog();
  const helpDialogFooter = (
    <div>
      <PrimaryButton label={backLabel ?? 'Back'} onClick={closeHelpDialog} autoFocus />
    </div>
  );
  return (
    <>
      <HelpButton ref={helpButtonRef} icon={'pi pi-question-circle'} onClick={() => openHelpDialog(title)} />
      <Dialog
        header={helpDialogTitle}
        visible={isHelpDialogOpen}
        style={{ width: '50vw' }}
        onHide={closeHelpDialog}
        footer={helpDialogFooter}
        closable={false}
      >
        <div style={{ padding: '1rem 2rem' }}>
          <ContentWrapper>{children}</ContentWrapper>
        </div>
      </Dialog>
    </>
  );
};

const ContentWrapper = styled.div`
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  ${(props) => props.theme.typography.bodyBody14Regular}
`;
export default HelpButtonDialog;
