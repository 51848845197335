import { useCompany } from 'hooks/useCompany';
import React, { ReactNode, createContext } from 'react';
import { CompanyModel, LicenseeModel } from 'services/EpdClient';

interface CompanyContextType {
  companyId?: string;
  company?: CompanyModel;
  companyAccountId?: string;
  licensee?: LicenseeModel;
  changeCompany: (newCompanyId?: string) => void;
  setDefaultOrFirstCompany: () => Promise<void>;
  setDefaultCompany: () => void;
  fetchCompanyAndLicensees: (newCompanyId?: string) => Promise<void>;
  changeCompanyAccount: (id?: string) => void;
  refreshCompany: () => void;
}

const defaultContextValue: CompanyContextType = {
  companyId: undefined,
  company: undefined,
  companyAccountId: undefined,
  licensee: undefined,
  changeCompany: () => {},
  setDefaultOrFirstCompany: async () => {},
  setDefaultCompany: () => {},
  fetchCompanyAndLicensees: async () => {},
  changeCompanyAccount: async () => {},
  refreshCompany: () => {},
};

export const CompanyContext = createContext<CompanyContextType>(defaultContextValue);

export const CompanyContextProvider = ({ children }: { children: ReactNode }) => {
  const company = useCompany();

  return <CompanyContext.Provider value={company}>{children}</CompanyContext.Provider>;
};
