import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import EpdRoutes from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';

interface OwnProps {}

type Props = RouteProps & OwnProps;

const PrivateRoute: React.FunctionComponent<Props> = ({ component: Component, ...rest }: Props) => {
  return AuthService.isAuthenticated() ? (
    <Route {...rest} component={Component} />
  ) : 
  (
    window.sessionStorage.setItem('redirectUrl', window.location.pathname),
    <Route {...rest} render={(props) => <Redirect to={EpdRoutes.login} />} />
  );
};

export default PrivateRoute;
