import HelpButtonDialog from 'components/v2/copy/dialogs/help-dialog/HelpButtonDialog';

type TProps = {
  helpButtonRef?: any;
  backLabel?: string | undefined;
};

const Help = ({ helpButtonRef, backLabel }: TProps) => {
  return (
    <HelpButtonDialog title={'Copy function FAQ'} helpButtonRef={helpButtonRef} backLabel={backLabel}>
      <span>
        Copy EPD functionality allows you to easily create similar EPDs copies based on one selected EPD template.
        <br />
        During the creation of EPD copies, you will be able to edit all created copies in a list view before the actual
        copying is done.
      </span>
      <p style={{ marginBottom: 0, fontWeight: 700 }}>How to create EPD copies</p>
      1. Step 1 - Select source EPD to copy the data and number of copies.
      <br />
      2. Step 2 - Fill the required data and upload documents to EPDs.
      <br />
      3. Step 3 - Verify EPDs.
      <br />
      4. Step 4 - Send to publish EPDs.
      <p style={{ marginBottom: 0, fontWeight: 700 }}>How to work with EPD copies</p>
      <span>
        Once you select source EPD and fill the data, choose number of copies, and submit the data - the EPD copies will be
        created.
      </span>
      <p>Please note, some data like EPD documents, EPD name, GTIN can not be copy pasted from selected source EPD.</p>
      <p style={{ marginBottom: 0, fontWeight: 700 }}>Can I verify EPDs in copy function?</p>
      Yes, you can assign one EPD verifier for all EPD copies in your copy group and send them all at the same time to the
      verifier.
      <p style={{ marginBottom: 0 }}>
        When the EPDs are published, the group ends and you see your EPDs one by one on the dashboard. If you want to
        continue saving and presenting them as a group in the EPD library, then use the collection function.
      </p>
      <p style={{ marginBottom: 0, fontWeight: 700 }}>EPD copies management</p>
      You can either unlink or delete the created EPD copy from the group.
      <br /> These actions are available under “3 dots” menu icon in the right corner on the EPD copy card in Step 2 &gt;
      Product information.
    </HelpButtonDialog>
  );
};

export default Help;
