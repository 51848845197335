import styled, { keyframes } from 'styled-components';

type SpinnerProps = {
  inline?: boolean;
  backgroundColor?: string;
};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<SpinnerProps>`
  font-size: 10px;
  position: ${(props) => (props.inline ? 'initial' : 'fixed')};
  right: 1rem;
  margin: 0;
  text-indent: -9999em;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  animation: ${rotate360} 0.9s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: ${(props) => props.theme.colors.regionColorLight};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export default Spinner;
