import styled from 'styled-components';
import { ErrorMessage, Input, Label } from 'styles/Styles.styled';

const ConsentCheckBox = ({ label, onCheck, checked, ...props} : any) => {
  
  return (
    <>
      <CheckboxLabel htmlFor={props.id || props.name} style={props.labelStyle}>
        <Input
          id={props.id || props.name}
          style={props.inputStyle || inputStyle}
          {...props}
          type={'checkbox'}
          checked={checked}
          onChange={onCheck}
        />
        {label ?? null}
      </CheckboxLabel>
    </>
  );
};

const inputStyle = {
  marginRight: '0.75rem'
}

const CheckboxLabel = styled(Label)`
  ${(props) => props.theme.fonts.text};
  display:flex;
  align-items:center;
`;

export default ConsentCheckBox;
