import FilesList from 'components/v2/file-row/FilesList';
import FileUpload from 'components/v2/file-upload/FileUpload';
import { HelpBox } from 'components/v2/help-boxes';
import { ButtonUploadIcon } from 'components/v2/icons';
import { Dialog } from 'primereact/dialog';
import { ChangeEvent } from 'react';
import { EPDModel, FileLoadingModel } from 'services/EpdClient';
import styled from 'styled-components';
import { HyperLink } from 'styles/Styles.styled';

type TProps = {
  epd?: EPDModel;
  isOpened: boolean;
  header: string;
  onHide: any;
  onChangeEpd: any;
  fileLoading?: FileLoadingModel[];
};

const UploadCertificationReportDialog = ({ isOpened, onHide, epd, onChangeEpd, fileLoading }: TProps) => {
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];
    if (!file || !epd) {
      return null;
    }

    onChangeEpd && onChangeEpd(propertyName, file);
  };
  return (
    <Dialog header="Upload documents" visible={isOpened} style={{ width: '50vw' }} onHide={onHide} footer={null}>
      <DialogContentContainer>
        <HelpBox>
          <span>
            Please upload the verification report in either DOCX or PDF format using the{' '}
            <HyperLink
              href="https://environdec.com/resources/documentation#epddevelopmentandverificationtemplates"
              target="_blank"
            >
              template
            </HyperLink>
            . Several documents can be uploaded.
          </span>
        </HelpBox>
        <FileUpload
          label="Upload document"
          name="VerificationReport"
          icon={ButtonUploadIcon}
          accept=".pdf"
          validExtensions={['pdf']}
          onFileUpload={uploadFile}
        />
        <FilesList
          files={epd?.verificationReports}
          onRemoveFile={onChangeEpd}
          fileLoading={
            fileLoading?.find((loading) => loading.fileType === 'VerificationReport' && loading.loadingsCount > 0) !==
            undefined
          }
        />
        <HelpBox>
          You can upload additional Process Certificate here for the EPD. The process certificate should be in PDF format.
        </HelpBox>
        <FileUpload
          label="Upload document"
          name="processCertificate"
          icon={ButtonUploadIcon}
          accept=".pdf"
          validExtensions={['pdf']}
          onFileUpload={uploadFile}
        />
        <FilesList
          files={epd?.processCertificates}
          onRemoveFile={onChangeEpd}
          fileLoading={
            fileLoading?.find((loading) => loading.fileType === 'processCertificate' && loading.loadingsCount > 0) !==
            undefined
          }
        />{' '}
      </DialogContentContainer>
    </Dialog>
  );
};

const FieldPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: start;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

const DialogContentContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
  overflow-y: auto;
`;
export default UploadCertificationReportDialog;
