import 'primeflex/primeflex.css';

import { CSSProperties, ReactNode } from 'react';
import { HeaderDialog } from 'styles/Styles.styled';

import { QuestionIcon } from '../icons';
import { confirmGeneric } from './ConfirmGeneric';
import { Description } from './styled';

type TProps = {
  caption?: string | undefined;
  description?: string | undefined | ReactNode;
  acceptLabel?: string | undefined;
  rejectLabel?: string | undefined;
  onConfirm: any;
  onReject?: any;
  closable?: boolean | undefined;
  contentStyle?: CSSProperties | undefined;
};

export const confirmQuestion = ({
  caption,
  description,
  acceptLabel,
  rejectLabel,
  onConfirm,
  onReject,
  closable,
  contentStyle,
}: TProps) => {
  return confirmGeneric({
    contents: (
      <>
        <QuestionIcon />
        <HeaderDialog>{caption || 'Are you sure?'}</HeaderDialog>
        <Description>{description}</Description>
      </>
    ),
    acceptLabel: acceptLabel || 'Yes',
    rejectLabel: rejectLabel || 'No',
    onConfirm,
    onReject,
    closable,
    contentStyle,
  });
};
