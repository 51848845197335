import React from 'react';
import { Container } from 'styles/Styles.styled';
import ManageLicensees from 'components/v2/admin/licensees/ManageLicensees';
import { withGreyBackground } from '../../WithGreyBackground';

const AdminLicensees: React.FunctionComponent = () => {
  return (
    <Container>
      <ManageLicensees key={'licensees'} />
    </Container>
  );
};

export default withGreyBackground(AdminLicensees);
