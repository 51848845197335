import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { InvoiceSummaryModel } from 'services/EpdClient';
import InvoiceService from 'services/InvoiceService';
import { H2, Text } from 'styles/Styles.styled';

import { QuickFactBox, QuickFactValue } from './styles';

const InvoiceQuickFacts: FunctionComponent<{ accountId?: string; companyid: string }> = ({ accountId, companyid }) => {
  const [invoiceSummary, setInvoiceSummary] = useState<InvoiceSummaryModel>();
  const history = useHistory();

  useEffect(() => {
    const getInvoiceSummary = async () => {
      if (accountId) {
        const summary = await InvoiceService.getInvoiceSummary(accountId);
        setInvoiceSummary(summary);
      }
    };

    getInvoiceSummary();
  }, [accountId]);

  const newInvoices = invoiceSummary?.drafts?.length ?? 0;
  const allInvoices = invoiceSummary?.invoices?.length ?? 0;

  if (accountId == null || newInvoices + allInvoices === 0 || companyid == null) {
    return null;
  }

  const openInvoices = () => {
    if (newInvoices === 1 && invoiceSummary?.drafts?.[0].invoiceId) {
      return history.push(EpdLinks.invoice(companyid, accountId, invoiceSummary.drafts[0].invoiceId));
    } else if (allInvoices === 1 && newInvoices === 0 && invoiceSummary?.invoices?.[0].invoiceId) {
      return history.push(EpdLinks.invoice(companyid, accountId, invoiceSummary.invoices[0].invoiceId));
    }
    return history.push(EpdLinks.invoices(companyid, accountId));
  };

  return (
    <>
      <H2>Invoices</H2>
      <Text style={{ marginBottom: '3rem' }}>
        <QuickFactBox onClick={openInvoices} style={{ cursor: 'pointer' }}>
          <span>New invoices:</span>
          <strong>
            <QuickFactValue highlight={newInvoices > 0}>
              <div>{newInvoices}</div>
            </QuickFactValue>
          </strong>

          <span>Invoice history:</span>
          <strong>
            <QuickFactValue>
              <div>{allInvoices}</div>
            </QuickFactValue>
          </strong>
        </QuickFactBox>
      </Text>
    </>
  );
};

export default InvoiceQuickFacts;
