import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EPDStateModel } from 'services/EpdClient';
import styled from 'styled-components';

type TProps = {
  epdState: EPDStateModel;
};

const StatusBox = ({ epdState }: TProps) => {
  const { t } = useTranslation();
  const [statusMessage, setStatusMessage] = useState<string | undefined>();
  const [actionMessage, setActionMessage] = useState<string | undefined>();

  useEffect(() => {
    const getEpdStateStatusMessage = (epdState: EPDStateModel) => {
      setStatusMessage(t(`epdWizard.epdState.statusMessage.${epdState.statusMessage || 'status0'}`));
      setActionMessage(t(`epdWizard.epdState.actionMessage.${epdState.requiredActionMessage || 'requiredAction0'}`));
    };
    getEpdStateStatusMessage(epdState || {});
  }, [epdState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EpdStateContainer>
      <EpdStateMessage>
        <b>
          <label>{t('epdWizard.epdState.title')}</label>
        </b>
        {statusMessage}
      </EpdStateMessage>
      <EpdStateMessage>
        <b>
          <label>{t('epdWizard.epdState.action.title')}</label>
        </b>
        {actionMessage}
      </EpdStateMessage>
    </EpdStateContainer>
  );
};

const EpdStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.lightGreen};
  padding: 1rem;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 1rem;
`;

const EpdStateMessage = styled.div`
  padding-right: 1rem;
  display: flex;
  flex-direction: column;

  label {
    font-style: normal;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    ${(props) => props.theme.fonts.textSmall}
  }
`;

export default StatusBox;
