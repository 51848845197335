import React from 'react';
import { FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { useGetEpdCO2UptakeAssociation } from 'services/api/queries';
import { YES_NO } from '../../constants';
import { useCreateEpdCO2UptakeAssociation, useUpdateEpdCO2UptakeAssociation } from 'services/api/mutations';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import DeclarationOfCO2Grid from '../../grids/declaration-of-co2/DeclarationOfCo2Grid';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';

const DeclarationOfCO2Panel: React.FunctionComponent<{
  epdVersionId: string;
  cO2CarbonationError: string | undefined;
  declarationOfCO2AssumptionsError: string | undefined;
}> = ({ epdVersionId, cO2CarbonationError, declarationOfCO2AssumptionsError }) => {
  const cO2UptakeAssociation = useGetEpdCO2UptakeAssociation(epdVersionId).data;
  const createCO2UptakeAssociation = useCreateEpdCO2UptakeAssociation(epdVersionId);
  const updateCO2UptakeAssociation = useUpdateEpdCO2UptakeAssociation(epdVersionId);

  const handleCO2UptakeAssociationUpdate = (field: string, value: string) => {
    if (!cO2UptakeAssociation?.id) {
      createCO2UptakeAssociation.mutate({ epdVersionId, association: value });
      return;
    }
    updateCO2UptakeAssociation.mutate({ id: cO2UptakeAssociation.id, epdVersionId, association: value });
  };

  const getWizardSelectValue = () => {
    switch (cO2UptakeAssociation?.association) {
      case YES_NO.YES:
        return {
          value: YES_NO.YES,
          label: 'Yes, CO2 is uptaking associated with carbonation included in the modules',
        };
      case YES_NO.NO:
        return {
          value: YES_NO.NO,
          label: 'No, there is no CO2 uptaking associated with carbonation in any of the modules',
        };
      default:
        return null;
    }
  };

  return (
    <FieldPanelFullWidth>
      <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>
        CO2 Carbonation
        <TooltipHelpIcon content={'Assumptions on CO2 uptake associated with carbonation shall be specified if included.'} />
        {cO2CarbonationError && <TooltipErrorIcon content={cO2CarbonationError} />}
      </FieldPanelHeader>
      <CompilerSelectControlledComponent
        label="CO2 uptake associated with carbonation"
        name="association"
        options={[
          { value: YES_NO.YES, label: 'Yes, CO2 is uptaking associated with carbonation included in the modules' },
          {
            value: YES_NO.NO,
            label: 'No, there is no CO2 uptaking associated with carbonation in any of the modules',
          },
        ]}
        value={getWizardSelectValue()}
        onChanged={handleCO2UptakeAssociationUpdate}
        required={false}
        placeholder="Select..."
        isClearable={false}
      />
      <DeclarationOfCO2Grid
        epdVersionId={epdVersionId}
        disabled={cO2UptakeAssociation?.association !== YES_NO.YES}
        error={declarationOfCO2AssumptionsError}
      />
    </FieldPanelFullWidth>
  );
};

export default DeclarationOfCO2Panel;
