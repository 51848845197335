import { CompanyContext } from 'contexts/CompanyContextProvider';
import filterIcon from 'images/icons/svg/funnel.svg';
import networkIcon from 'images/icons/svg/network.svg';
import createIcon from 'images/icons/svg/plus.svg';
import cogSettings from 'images/icons/svg/settings-4.svg';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import { CompanyModel } from 'services/EpdClient';
import styled from 'styled-components';
import { InputSmallIcon } from 'styles/Styles.styled';

import InformIcon from '../icons/InformIcon';

type TProps = {
  companies: CompanyModel[];
};

export const OrgDropDown = ({ companies }: TProps) => {
  const { changeCompany } = useContext(CompanyContext);
  const [filter, setFilter] = useState('');
  const { t } = useTranslation();

  return (
    <DropDown
      heading={t('epdDashboard.organizations.title')}
      icon={networkIcon}
      content={t('epdDashboard.tooltips.switchOrganization')}
    >
      <InputSmallIcon
        style={{ backgroundImage: `url(${filterIcon})` }}
        type="text"
        placeholder={t('epdDashboard.filter.title')}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <Scrollable>
        {companies
          ?.filter((c) => c.name?.toLocaleLowerCase().includes(filter.toLowerCase()))
          .map((company) => (
            <ChangeOrgRow key={company.id} style={{ minWidth: '300px' }}>
              <ChangeOrg onClick={() => changeCompany?.(company.id)} to={EpdLinks.dashboard()}>
                {company.name}
              </ChangeOrg>
              {company.settingsEnabled && (
                <Link
                  to={EpdRoutes.param(EpdRoutes.manageCompany, ':companyid', company.id || '')} // TODO: id is not required on model?
                  style={{ padding: '1rem' }}
                >
                  <SettingsCog icon={cogSettings} content={t('epdDashboard.tooltips.editSettings')} />
                </Link>
              )}
            </ChangeOrgRow>
          ))}
      </Scrollable>
      <CreateNewOrgContainer to={EpdRoutes.account.register.company}>
        <DropDownIcon src={createIcon} style={{ paddingRight: '.25rem' }} alt={'Create'} />{' '}
        {t('epdDashboard.organizations.create')}
      </CreateNewOrgContainer>
    </DropDown>
  );
};

type Props = React.PropsWithChildren<{
  heading?: string;
  icon: any;
  content: string;
}>;

function DropDown({ heading, icon, children, content }: Props) {
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false);
  const node = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleClickOutside = (e: any) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setShowDropDown(false);
  };

  React.useEffect(() => {
    if (showDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Profile ref={node} style={{ cursor: 'pointer' }} onClick={() => setShowDropDown(true)}>
      <Image alt="" src={icon} />
      {content && <InformIcon content={content} top={true} />}
      {showDropDown && (
        <ProfileDrop>
          <DropDownHeading>{heading}</DropDownHeading>
          {children}
        </ProfileDrop>
      )}
    </Profile>
  );
}

const Image = styled.img`
  height: 1rem;
  vertical-align: top;
  cursor: pointer;
  &:hover {
    & + * {
      display: initial;
    }
  }
`;

const Profile = styled.div`
  float: right;
  display: flex;
  border-radius: 50%;
  padding: 0.5rem;
  aspect-ratio: 1 / 1;
  margin: 0 0 0 1rem;
  background-color: ${(props) => props.theme.colors.gray};
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.darkGray};
  }
`;

const DropDownIcon = styled.img`
  height: 15px;
  cursor: pointer;
`;

export const ProfileDrop = styled.div`
  position: absolute;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.3);
  top: 2rem;
  z-index: 1;
  cursor: default;
`;

const DropDownHeading = styled.h4`
  padding: 1rem;
  margin: 0px;
  border-bottom: solid 2px ${(props) => props.theme.colors.regionColorLight};
`;

const ChangeOrgRow = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
`;

const ChangeOrg = styled(Link)`
  padding: 1rem;
  color: black;
  text-decoration: none;
  flex-grow: 1;
  &:hover {
    color: ${(props) => props.theme.colors.regionColorLight};
  }
`;

const CreateNewOrgContainer = styled(ChangeOrg)`
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-top: 1px solid gainsboro;
`;

const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
`;

function SettingsCog({ icon, content }: Props) {
  return (
    <CogContainer>
      <Image alt="cog" src={icon} />
      {content && <InformIcon content={content} top={false} />}
    </CogContainer>
  );
}

const CogContainer = styled.div`
  float: right;
  border-radius: 50%;
  padding: 0.5rem;
  height: 15px;
  width: 15px;
  margin: 0 0 0 1rem;
  background-color: ${(props) => props.theme.colors.gray};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.darkGray};
  }
`;

export default DropDown;
