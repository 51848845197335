import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import { HelpBox } from 'components/v2/help-boxes';
import { toaster } from 'components/v2/toast';
import { Field, FieldArray, Formik } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminDataSourceVersionsService from 'services/admin/AdminDataSourceVersionsService';
import AdminDataSourcesService from 'services/admin/AdminDataSourcesService';
import styled from 'styled-components';
import { AdminBox, ButtonSmall, Container, EditFull, H2, InfoText, Label, LabelBox } from 'styles/Styles.styled';

import { AdminGridForm, ButtonsContainer } from '../users/style';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const EditDataSource: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [dataSource, setDataSource] = React.useState<any>(null);
  const [dataSourceVersions, setDataSourceVersions] = React.useState<any>(null);

  useEffect(() => {
    const loadDataSource = async () => {
      const dataSourceResult = await AdminDataSourcesService.get(id);
      const dataSourceVersionsResult = await AdminDataSourceVersionsService.getByDataSource(id);
      setDataSource(dataSourceResult);
      setDataSourceVersions(dataSourceVersionsResult);
    };

    loadDataSource();
  }, [id]);

  if (!dataSource) {
    return <InfoText>{t('messages.loading')}</InfoText>;
  }

  const onSaveChanges = async (values: any) => {
    try {
      await AdminDataSourcesService.update(id, values);
      toaster({
        severity: 'success',
        summary: t('dataSources.messages.saved.summary'),
        details: t('dataSources.messages.saved.details'),
      });
    } catch (error: any) {
      const errorObject = JSON.parse(JSON.stringify(error));
      const detailValue = errorObject?.response;
      var errorDetails = JSON.parse(detailValue)?.detail;
      toaster({ severity: 'error', summary: 'Error', details: errorDetails ?? JSON.stringify(error) });
    }
  };

  const MyInput = ({ field, form, index, fieldName, ...rest }: any) => {
    const errors = form.errors.dataSourceVersions || [];
    const error = errors[index] ? errors[index][fieldName] : undefined;

    const touches = form.touched.dataSourceVersions || [];
    const touch = touches[index] ? touches[index][fieldName] : undefined;

    return (
      <div>
        <InputText {...field} {...rest} />
        {touch && error && <div className="p-error">{error}</div>}
      </div>
    );
  };

  const emptyVersion = {
    id: null,
    name: '',
    externalId: '',
  };

  const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  const validationSchema = Yup.object({
    dataSource: Yup.object().shape({
      name: Yup.string()
        .required(t('dataSources.dataSource.errorMessages.nameRequired'))
        .max(500, t('dataSources.dataSource.errorMessages.nameMaxLength')),
      externalId: Yup.string().nullable().matches(guidRegex, t('dataSources.dataSource.errorMessages.externalIdIsNotGuid')),
    }),
    dataSourceVersions: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required(t('dataSources.dataSourceVersions.errorMessages.nameRequired'))
          .max(500, t('dataSources.dataSourceVersions.errorMessages.nameMaxLength')),
        externalId: Yup.string()
          .nullable()
          .matches(guidRegex, t('dataSources.dataSourceVersions.errorMessages.externalIdIsNotGuid')),
      })
    ),
  });

  return (
    <Container>
      <EditFull>
        <H2>{t('dataSources.header')}</H2>
        <HelpBox>{t('dataSources.helpText')}</HelpBox>
        <AdminBox style={{ backgroundColor: '#FDFDFD' }}>
          <Formik
            initialValues={{ dataSource, dataSourceVersions }}
            onSubmit={onSaveChanges}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <AdminGridForm id="editDatasetsForm" autoComplete="off">
                <LabelBox>
                  <FixedLabel>{t('dataSources.dataSource.name')}</FixedLabel>
                </LabelBox>
                <ValueBox>
                  <AdminTextInput name="dataSource.name" type="text" />
                </ValueBox>

                <LabelBox>
                  <FixedLabel>{t('dataSources.dataSource.externalId')}</FixedLabel>
                </LabelBox>
                <ValueBox>
                  <AdminTextInput name="dataSource.externalId" type="text" />
                </ValueBox>

                <FieldArray name="dataSourceVersions">
                  {(arrayHelpers) => (
                    <div className="w-full" style={{ gridColumn: '1/6' }}>
                      <hr />
                      <VersionsHeader>
                        <h2>{t('dataSources.dataSourceVersions.header')}</h2>
                        <ButtonSmall
                          type="button"
                          onClick={() => {
                            arrayHelpers.insert(0, emptyVersion);
                          }}
                        >
                          {t('dataSources.dataSource.addVersion')}
                        </ButtonSmall>
                      </VersionsHeader>
                      {!(values?.dataSourceVersions?.length > 0) && t('dataSources.dataSourceVersions.noVersion')}
                      {values?.dataSourceVersions?.map((_: any, index: any) => (
                        <div
                          className="w-full"
                          key={index}
                          style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}
                        >
                          <Field name={`dataSourceVersions[${index}].id`} style={{ display: 'none' }} />

                          <FieldGroup style={{ width: '100%', marginRight: '2rem' }}>
                            {index === 0 && <Label>{t('dataSources.dataSourceVersions.name')}</Label>}
                            <Field
                              style={{ width: '100%' }}
                              name={`dataSourceVersions[${index}].name`}
                              render={(props: any) => (
                                <MyInput {...props} fieldName="name" index={index} style={{ width: '100%' }} />
                              )}
                            />
                          </FieldGroup>

                          <FieldGroup style={{ width: '100%', marginRight: '2rem' }}>
                            {index === 0 && <Label>{t('dataSources.dataSourceVersions.externalId')}</Label>}
                            <Field
                              style={{ width: '100%' }}
                              name={`dataSourceVersions[${index}].externalId`}
                              render={(props: any) => (
                                <MyInput {...props} fieldName="externalId" index={index} style={{ width: '100%' }} />
                              )}
                            />
                          </FieldGroup>

                          <FieldGroup>
                            {index === 0 && <Label>&nbsp;</Label>}
                            <ButtonSmall
                              style={{ alignSelf: 'center', padding: '5px' }}
                              type="button"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                toaster({
                                  severity: 'warning',
                                  summary: t('dataSources.messages.notification.summary'),
                                  details: t('dataSources.messages.notification.details'),
                                });
                              }}
                            >
                              Remove
                            </ButtonSmall>
                          </FieldGroup>
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
                <ButtonsContainer>
                  <ButtonSmall type="submit">{t('save')}</ButtonSmall>
                </ButtonsContainer>
              </AdminGridForm>
            )}
          </Formik>
        </AdminBox>
      </EditFull>
    </Container>
  );
};

const AdminTextInput = styled(LargeRadiusTextInput)`
  box-sizing: border-box;
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 3;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 5;
  }
`;

const FixedLabel = styled(Label)`
  flex: 0 0 200px;
  align-self: start;
  margin: 0.5rem 1rem 0.5rem 0; /*risky change? */
  padding-top: 10%;

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

const ValueBox = styled.div`
  min-height: 38px;
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: center;
  ${(props) => props.theme.media.desktop} {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`;

const VersionsHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export default EditDataSource;
