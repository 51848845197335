import 'primeflex/primeflex.css';

import { ReactNode } from 'react';
import { HeaderDialog } from 'styles/Styles.styled';

import { DeleteIcon } from '../icons';
import { confirmGeneric } from './ConfirmGeneric';
import { Description } from './styled';

type TProps = {
  caption?: string | undefined;
  description?: string | undefined | ReactNode;
  acceptLabel?: string | undefined;
  rejectLabel?: string | undefined;
  onConfirm: any;
  onReject?: any | undefined;
};

export const confirmDelete = ({ caption, description, acceptLabel, rejectLabel, onConfirm, onReject }: TProps) => {
  return confirmGeneric({
    contents: (
      <>
        <DeleteIcon />
        <HeaderDialog>{caption || 'Are you sure you want to delete?'}</HeaderDialog>
        <Description>{description}</Description>
      </>
    ),
    acceptLabel: acceptLabel || 'Delete',
    rejectLabel: rejectLabel || 'Cancel',
    onConfirm,
    onReject,
  });
};
