import React from 'react';
import styled from 'styled-components';

const MandatoryIcon: React.FunctionComponent<{ message?: string }> = ({ message }) => {
  return (
    <>
      <IconContainer>
        <svg viewBox="0 0 52 52" width="1rem" height="1rem">
          <path
            fill="#e75113"
            d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M28,41c0,1.104-0.896,2-2,2s-2-0.896-2-2v-2
   c0-1.104,0.896-2,2-2s2,0.896,2,2V41z M28,33c0,1.104-0.896,2-2,2s-2-0.896-2-2V11c0-1.104,0.896-2,2-2s2,0.896,2,2V33z"
          />
        </svg>
      </IconContainer>
      {message && <Tooltip>{message}</Tooltip>}
    </>
  );
};

const IconContainer = styled.div`
  display: inline-block;
  &:hover {
    & + * {
      display: initial;
    }
  }
`;

const Tooltip = styled.div`
  display: none;
  ${(props) => props.theme.fonts.textSmall};
  text-transform: initial;
  position: absolute;
  background: ${(props) => props.theme.colors.darkGray};
  padding: 1rem;
  color: white;
  opacity: 0.85;
  z-index: 1;
`;

export default MandatoryIcon;
