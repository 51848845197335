import 'primeflex/primeflex.css';

import { ProcessStatus } from 'constants/constants';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { CollectionDashboardModel, EPDCollectionStatus } from 'services/EpdClient';
import FileService from 'services/FileService';
import styled from 'styled-components';
import { formatDate } from 'util/utils';

import CollectionStatusBadge from './CollectionStatusTag';
import CollectionTitleRow from './CollectionTitleRow';

type TProps = {
  collection: CollectionDashboardModel;
  fetchCollections: () => {};
};

const CollectionCard = ({ collection, fetchCollections }: TProps) => {
  const history = useHistory();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);

  const openCollection = async (collectionId: string) => {
    try {
      setStatus(ProcessStatus.Fetching);
      const route = EpdLinks.editCollection(collectionId);
      history.push(route);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  return (
    <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
      <Box>
        <div
          className="border-0 shadow-6 surface-card flex flex-column justify-content-between"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <>
            <ImageContainer
              src={FileService.getImageUrl(collection?.image?.id)}
              onClick={() => openCollection(collection.id || '')}
            >
              <img src={FileService.getImageUrl(collection?.image?.id)} alt={collection.name} />
            </ImageContainer>
            <CollectionTitleRow collection={collection} fetchCollections={fetchCollections} />
          </>

          <div className="px-4 pb-4  flex flex-column justify-content-end h-full">
            <div className="flex flex-wrap  justify-content-between gap-2 align-items-end">
              <div className="flex align-items-end gap-2">
                <span className="font-semibold" style={{ color: 'rgba(21, 21, 21, 0.5)' }}>
                  {collection.createdAt === collection.modifiedAt
                    ? `Created: ${formatDate(collection?.createdAt)}`
                    : `Last edit: ${formatDate(collection?.modifiedAt)}`}
                </span>
              </div>
              <CollectionStatusBadge status={collection.status || EPDCollectionStatus.None} />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

const Box = styled.div`
  position: relative;
  width: 100%;
  padding-top: 80%; /* set the height to be 80% of the width */
  border-radius: 4px;
`;

const ImageContainer = styled.div<{ src: any }>`
  width: 100%;
  max-height: 57%;
  min-height: 57%;
  object-fit: scale-down;
  cursor: pointer;
  margin-bottom: 0.5rem;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    //object-fit: cover;
  }
`;

export default CollectionCard;
