import DropDown from 'components/company/DropDown';
import CreateEpd from 'components/dashboard/CreateEpd';
import InviteConfirmationDialog from 'components/dialogs/InviteConfirmationDialog';
import { ProcessStatus } from 'constants/constants';
import { defaultLicensee } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import controlIcon from 'images/icons/svg/controls.svg';
import filterIcon from 'images/icons/svg/funnel.svg';
import helpIcon from 'images/icons/svg/warning.svg';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import {
  AccountModel,
  CompanyModel,
  EPDModel,
  EPDStatus,
  EPDStatusToEPDCopyGroupStatusMapping,
  EpdGroupDashboardModel,
  InvitationModel,
  InvoiceSummaryModel,
} from 'services/EpdClient';
import EpdService from 'services/EpdService';
import FileService from 'services/FileService';
import InvoiceService from 'services/InvoiceService';
import MembershipService from 'services/MembershipService';
import { Container, InputSmallIcon, MainView } from 'styles/Styles.styled';

import { AccountSwitcher } from './AccountSwitcher';
import EpdCard from './EpdCard';
import EpdGroupCard from './EpdGroupCard';
import NoCards from './NoCards';
import Sidebox from './Sidebox';
import Welcome from './Welcome';
import { CardContainer, CardsEmpty, FilterContainer, HeadingArea, InfoBox, InputLabel } from './styles';

const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [account, setAccount] = useState<AccountModel | undefined>({});
  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [allEpds, setAllEpds] = useState<EPDModel[]>([]);
  const [epds, setEpds] = useState<EPDModel[]>([]);
  const [allEpdGroups, setAllEpdGroups] = useState<EpdGroupDashboardModel[]>([]);
  const [epdGroups, setEpdGroups] = useState<EpdGroupDashboardModel[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<EPDStatus[]>([]);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.Fetching);
  const [companyStatus, setCompanyStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [isAdminLicenseeCompanies, setIsAdminLicenseeCompaies] = useState<boolean>(false);
  const { companyId, company, companyAccountId, licensee } = useContext(CompanyContext);
  const [invitations, setInvitations] = useState<InvitationModel[]>();

  const handleFilter = (e: React.FormEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.value);
  };

  const [invoiceSummary, setInvoiceSummary] = useState<InvoiceSummaryModel>();
  const newInvoices = invoiceSummary?.drafts?.length ?? 0;

  const fetchInvitations = async () => {
    const result = await MembershipService.getInvitationsByUser();
    setInvitations(result);
  };

  useEffect(() => {
    fetchInvitations();
  }, []);

  useEffect(() => {
    if (!companyId) {
      setStatus(ProcessStatus.Success);
      return;
    }

    const fetchEpds = async () => {
      if (companyAccountId === '') {
        setStatus(ProcessStatus.Success);
      }
      if (!companyAccountId) {
        setAllEpds([]);
        setAllEpdGroups([]);
        return;
      }

      try {
        setStatus(ProcessStatus.Fetching);
        const result = await EpdService.getEpds(companyAccountId, companyId);
        const resultGroups = await EpdService.getEpdGroups(companyAccountId, companyId);
        setAllEpds(result);
        setAllEpdGroups(resultGroups);
        setStatus(ProcessStatus.Success);
      } catch {
        setStatus(ProcessStatus.Error);
      }
    };

    const getInvoiceSummary = async () => {
      if (companyAccountId) {
        const summary = await InvoiceService.getInvoiceSummary(companyAccountId);
        setInvoiceSummary(summary);
      }
    };

    fetchEpds();
    getInvoiceSummary();
  }, [companyAccountId, companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const feachIsAdminLicenseeInCompany = async () => {
      var result = await AuthService.isAdminLicenseeInCompany(company?.id || '');
      setIsAdminLicenseeCompaies(result);
    };

    feachIsAdminLicenseeInCompany();
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchCompanies = async () => {
      setCompanyStatus(ProcessStatus.Fetching);
      const companiesResult = await CompanyService.getCompanies();
      setCompanies(companiesResult);
    };

    fetchCompanies();
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let filteredEpds = allEpds.filter((x) => x.name?.toLowerCase().includes(filter.toLocaleLowerCase()));
    if (typeFilter?.length) {
      filteredEpds = filteredEpds.filter((x) => x.status && typeFilter.includes(x.status));
    }
    let filteredEpdGroups = allEpdGroups.filter((x) => x.name?.toLowerCase().includes(filter.toLocaleLowerCase()));
    if (typeFilter?.length) {
      const copyGroupFilter = typeFilter.map((filter) => EPDStatusToEPDCopyGroupStatusMapping[filter]);
      filteredEpdGroups = filteredEpdGroups.filter((x) => x.status && copyGroupFilter.includes(x.status));
    }
    setEpds(filteredEpds);
    setEpdGroups(filteredEpdGroups);
  }, [allEpds, allEpdGroups, filter, typeFilter]);

  const toggleTypeFilter = (value: EPDStatus) => {
    if (typeFilter.includes(value)) {
      setTypeFilter(typeFilter.filter((x) => x !== value));
    } else {
      setTypeFilter(typeFilter.concat(value));
    }
  };

  const hasCompanies = companies && companies.length > 0 && companyStatus !== ProcessStatus.Error;
  const hasAccounts = hasCompanies && company?.accounts && company?.accounts.length > 1;
  const canCreateEpd =
    AuthService.isAdmin() ||
    AuthService.isCompanyOwner(company?.id || '') ||
    (AuthService.isLicenseeAdmin() && isAdminLicenseeCompanies);
  const getCoverImage = (epd: EPDModel) => {
    const coverId = epd?.covers && epd?.covers?.length > 0 && epd.covers[0].id;
    return coverId ? FileService.getImageUrl(coverId) : undefined;
  };

  return (
    <Container style={{ flexWrap: 'nowrap', position: 'relative' }}>
      <MainView>
        {invitations?.map((invitation) => (
          <InviteConfirmationDialog closable={false} visible={true} invitation={invitation} />
        ))}
        {hasAccounts && <AccountSwitcher company={company} account={account} />}
        <HeadingArea>
          <FilterContainer>
            {allEpds?.length > 0 && (
              <>
                <div>
                  <InputSmallIcon
                    style={{ backgroundImage: `url(${filterIcon})` }}
                    type="text"
                    placeholder={t('epdDashboard.filter.title')}
                    value={filter}
                    onChange={handleFilter}
                  />
                </div>
                <div style={{ position: 'relative' }}>
                  <DropDown
                    icon={controlIcon}
                    heading={t('epdDashboard.status.title')}
                    content={t('epdDashboard.tooltips.EPDStatus')}
                  >
                    <div style={{ padding: '1rem 0' }}>
                      <InputLabel>
                        {t('epdDashboard.status.list.registered')}
                        <input
                          type="checkbox"
                          value={EPDStatus.Registered}
                          checked={typeFilter.includes(EPDStatus.Registered)}
                          onChange={() => toggleTypeFilter(EPDStatus.Registered)}
                        />
                      </InputLabel>
                      <InputLabel>
                        <span style={{ paddingRight: '1rem' }}>{t('epdDashboard.status.list.deregistered')}</span>
                        <input
                          type="checkbox"
                          value={EPDStatus.Deregistered}
                          checked={typeFilter.includes(EPDStatus.Deregistered)}
                          onChange={() => toggleTypeFilter(EPDStatus.Deregistered)}
                        />
                      </InputLabel>
                      <InputLabel>
                        {t('epdDashboard.status.list.reserved')}
                        <input
                          type="checkbox"
                          value={EPDStatus.Reserved}
                          checked={typeFilter.includes(EPDStatus.Reserved)}
                          onChange={() => toggleTypeFilter(EPDStatus.Reserved)}
                        />
                      </InputLabel>
                    </div>
                  </DropDown>
                </div>
              </>
            )}
          </FilterContainer>
          {hasCompanies && canCreateEpd && <CreateEpd anyEpd={epds?.length > 0} />}
        </HeadingArea>

        {newInvoices > 0 ? (
          <InfoBox>
            <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
            <div>
              {t('epdDashboard.hasNewInvoices.info_1')}
              <br />
              {t('epdDashboard.hasNewInvoices.info_2')}
            </div>
          </InfoBox>
        ) : null}
        <CardContainer>
          {!hasCompanies && !company?.id && status !== ProcessStatus.Fetching && <Welcome />}
          {/* {status === ProcessStatus.Fetching && <div>{t('epdDashboard.onFetchEpds.fetching')}</div>} */}
          {status === ProcessStatus.Fetching && (
            <>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3"></Skeleton>
            </>
          )}
          {status === ProcessStatus.Error && (
            <CardsEmpty>
              <h3>{t('epdDashboard.onFetchEpds.error')}</h3>
            </CardsEmpty>
          )}
          {status === ProcessStatus.Success &&
            company?.id &&
            (epds?.length > 0 || epdGroups?.length > 0 ? (
              <>
                {epdGroups?.map((epdGroup) => (
                  <EpdGroupCard key={epdGroup.id} epdGroup={epdGroup} background={null} />
                ))}
                {epds?.map((epd) => (
                  <EpdCard key={epd.id} companyid={company?.id ?? ''} epd={epd} background={getCoverImage(epd)} />
                ))}
              </>
            ) : (
              <NoCards anyExist={allEpds.length > 0} />
            ))}
        </CardContainer>
      </MainView>

      <Sidebox
        allEpds={allEpds}
        allCopyGroups={allEpdGroups}
        accountId={companyAccountId}
        companyId={company?.id || ''}
        licenseeSupportEmail={licensee?.supportEmail ?? defaultLicensee.supportEmail}
      />
    </Container>
  );
};

export default Dashboard;
