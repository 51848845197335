import React from 'react';
import { UploadButton } from 'pages/wizard/WizardStep2';
import styled from 'styled-components';

interface INoImages {
  inputElement: React.ReactElement;
  disabled?: boolean;
}

export const NoImages: React.FunctionComponent<INoImages> = ({ inputElement, disabled }) => {
  return (
    <NoImagesPanel disabled={disabled}>
      <ImagesIcon className="pi pi-images" disabled={disabled} />
      {disabled ? (
        <p>EPD images will be displayed here</p>
      ) : (
        <>
          <MainText>
            Drag and drop or
            <UploadButton>
              &nbsp;<u>choose files</u>&nbsp;
              {inputElement}
            </UploadButton>
            to upload.
          </MainText>
          <HelpText>
            Image formats: <SmallHelpText>.png, .jpg.</SmallHelpText>
          </HelpText>
          <HelpText>
            Image size: <SmallHelpText>up to 1MB.</SmallHelpText>
          </HelpText>
        </>
      )}
    </NoImagesPanel>
  );
};

const ImagesIcon = styled.i<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack50 : props.theme.colors.secondaryLightGreen)};
  font-size: 20px;
`;

const MainText = styled.span`
  padding: 8px;
  display: flex;
  align-items: start;
`;

const HelpText = styled.span`
  color: ${(props) => props.theme.colors.primaryBlack50};
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  letter-spacing: -0.2px;
`;

const SmallHelpText = styled(HelpText)`
  font-weight: 400;
`;

const NoImagesPanel = styled.div<{ disabled?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  color: ${(props) => (props.disabled ? props.theme.colors.primaryBlack50 : props.theme.colors.primaryGreen)};
  font-weight: ${(props) => (props.disabled ? 400 : 500)};
`;

export default INoImages;
