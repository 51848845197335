import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardsEmpty } from './styles';

type TProps = {
  anyExist: boolean;
};

const NoCards = ({ anyExist }: TProps) => {
  const { t } = useTranslation();
  return (
    <CardsEmpty>
      <h3>{t('epdDashboard.companyHasNoEpds.header')}</h3>
      <div>{anyExist ? t('epdDashboard.companyHasNoEpds.info_1') : t('epdDashboard.companyHasNoEpds.info_2')}</div>
    </CardsEmpty>
  );
};

export default NoCards;
