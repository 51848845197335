import { Column, ColumnBodyOptions, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React from 'react';
import { useEpdSystemBoundaryData, useEpdSystemBoundaryGrid, useGeographicalScopeLookup } from 'services/api/queries';
import styled from 'styled-components';
import { EPDProductType, LCAModules } from 'types/types';

import { DataTableStyled } from '../StyledGrid';
import { SystemBoundaryGridHeader } from './SystemBoundaryGridHeader';

interface Props {
  epdVersionId: string;
  productType: EPDProductType | undefined;
  dontApplyPrintStyles?: boolean;
}

const SystemBoundaryGridPdf: React.FunctionComponent<Props> = ({ epdVersionId, productType, dontApplyPrintStyles }) => {
  const geographicalScopeLookup = useGeographicalScopeLookup().data;
  const data = useEpdSystemBoundaryGrid(epdVersionId).data;
  const boundaryData = useEpdSystemBoundaryData(epdVersionId).data;

  const rows = [
    { ord: 0, epdVersionId: data?.epdVersionId, module: 'Modules declared', ...data?.declaredModules },
    { ord: 1, epdVersionId: data?.epdVersionId, module: 'Geography', ...data?.declaredModulesGeography },
  ];

  const modulesTemplate = (row: any, options: ColumnBodyOptions) => {
    if (options.rowIndex == 0) {
      if (row[options.field] == null) {
        return null;
      }
      return row[options.field] === true ? 'X' : 'ND';
    } else {
      if (rows[0][options.field as keyof LCAModules<boolean | null>] === false) {
        return 'XXX';
      }
      return geographicalScopeLookup?.find((x) => x.id === row[options.field])?.name;
    }
  };

  const moduleColumnStyle: React.CSSProperties = dontApplyPrintStyles
    ? { minWidth: '3rem' }
    : { minWidth: '3rem', fontSize: '8px' };
  const SystemBoundaryGridColumns: ColumnProps[] = [
    {
      field: 'module',
      header: 'Module',
      style: { minWidth: '10rem' },
    },
    {
      field: 'a1',
      header: 'A1',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'a2',
      header: 'A2',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'a3',
      header: 'A4',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'a4',
      header: 'A4',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'a5',
      header: 'A5',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'b1',
      header: 'B1',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'b2',
      header: 'B2',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'b3',
      header: 'B3',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'b4',
      header: 'B4',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'b5',
      header: 'B5',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'b6',
      header: 'B6',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'b7',
      header: 'B7',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'c1',
      header: 'C1',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'c2',
      header: 'C2',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'c3',
      header: 'C3',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'c4',
      header: 'C4',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
    {
      field: 'd',
      header: 'D',
      style: moduleColumnStyle,
      body: (row, options) => modulesTemplate(row, options),
    },
  ];

  const spanColsCount = productType === 'Goods' ? 3 : 5;
  const disabledColsCount = SystemBoundaryGridColumns.length - 1 - spanColsCount;
  const formatBoundaryPercentData = (value: number | undefined) => {
    if (typeof value !== 'number') {
      return '';
    }
    return `${value}%`;
  };
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Specific data used" />
        <Column
          colSpan={spanColsCount}
          style={{ textAlign: 'center' }}
          footer={formatBoundaryPercentData(boundaryData?.specificDataUsed)}
        />
        {Array.from({ length: disabledColsCount - 1 }, (_, index) => (
          <Column key={index} footer="-" style={{ backgroundColor: '#FCFCFC' }} />
        ))}
        <Column footer="-" style={{ borderBottom: 0 }} />
      </Row>
      <Row>
        <Column footer="Variation - products" />
        <Column
          colSpan={spanColsCount}
          style={{ textAlign: 'center' }}
          footer={formatBoundaryPercentData(boundaryData?.variationProducts)}
        />
        {Array.from({ length: disabledColsCount - 1 }, (_, index) => (
          <Column key={index} footer="-" style={{ backgroundColor: '#FCFCFC' }} />
        ))}
        <Column footer="-" style={{ borderBottom: 0 }} />
      </Row>
      <Row>
        <Column footer="Variation - sites" />
        <Column
          colSpan={spanColsCount}
          style={{ textAlign: 'center' }}
          footer={formatBoundaryPercentData(boundaryData?.variationSites)}
        />
        {Array.from({ length: disabledColsCount - 1 }, (_, index) => (
          <Column key={index} footer="-" style={{ backgroundColor: '#FCFCFC' }} />
        ))}
        <Column footer="-" />
      </Row>
    </ColumnGroup>
  );

  return (
    <BoundaryDataTableStyled
      headerColumnGroup={SystemBoundaryGridHeader}
      footerColumnGroup={footerGroup}
      value={rows}
      dataKey="ord"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      {SystemBoundaryGridColumns?.map((x) => (
        <Column key={x.field} {...x} />
      ))}
    </BoundaryDataTableStyled>
  );
};

const BoundaryDataTableStyled = styled(DataTableStyled)`
  display: grid;
  width: 100%;
  .p-datatable-thead > tr > th:last-child,
  .p-datatable-tbody > tr > td:last-child,
  .p-datatable-tfoot > tr > td:last-child {
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
  }

  .p-datatable-thead > tr:first-child > th {
    ${(props) => props.theme.typography.contentTableTitleXs}
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    padding: 0.5rem 1rem;
  }

  .p-datatable-thead > tr > th {
    ${(props) => props.theme.typography.contentTableBodyXs}
    background-color: ${(props) => props.theme.colors.primaryWhite};
    padding: 0.5rem 1rem;
  }
`;

export default SystemBoundaryGridPdf;
