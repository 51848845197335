import { StyledInput } from 'styles/v2/Styles.styled';

import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';
import useIsReadOnlyMode from '../compiler/hooks/useIsReadOnlyMode';

type TProps = Partial<TSmartLabelProps> & {
  value: any;
  name: string;
  onChanged?: any;
  disabled?: boolean;
  style?: any;
  placeholder?: string;
  type?: string;
  containerClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;
const WizardTextInputComponent: React.FunctionComponent<TProps> = ({
  label,
  tooltip,
  tooltipPosition,
  labelStyle,
  name,
  value,
  onChanged,
  disabled,
  style,
  placeholder,
  type,
  required,
  error,
  containerClassName,
  ...props
}) => {
  const isReadOnly = useIsReadOnlyMode() || disabled;
  return (
    <div className={containerClassName} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={!!value}
          error={error}
        />
      )}
      <StyledInput
        {...props}
        style={style}
        type={type}
        defaultValue={value}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.currentTarget.value !== value?.toString() && onChanged(name, e.currentTarget.value);
        }}
        disabled={isReadOnly}
        placeholder={isReadOnly ? undefined : placeholder}
      />
    </div>
  );
};

export default WizardTextInputComponent;
