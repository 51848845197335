import React from 'react';
import { useEpdVersionHistoryData } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySummaryGrid, { SummaryGridRow } from '../VersionHistorySummaryGrid';
import { LcaCustomResultModel } from 'types/types';
import { CUSTOM_RESULT_KEYS } from 'constants/constants';

const NOT_ASSIGNED = 'N/A';

type Props = {
  customResult: LcaCustomResultModel;
};

const VersionHistoryLcaCustomResultGrid: React.FC<Props> = ({ customResult }) => {
  const { scenarios } = useEpdVersionHistoryData();

  const possibleRows: (SummaryGridRow | false)[] = [
    customResult.resultType === CUSTOM_RESULT_KEYS.A4C4 && {
      name: 'Additional scenario',
      value: (() => {
        if (!customResult.scenarioId) {
          return NOT_ASSIGNED;
        }
        const scenario = scenarios.find((s) => s.id === customResult.scenarioId);
        if (!scenario) {
          return NOT_ASSIGNED;
        }
        return scenario.name ?? NOT_ASSIGNED;
      })(),
    },
    customResult.resultType === CUSTOM_RESULT_KEYS.BEST && {
      name: 'Best-case product',
      value: customResult.bestCaseProduct ?? NOT_ASSIGNED,
    },
    customResult.resultType === CUSTOM_RESULT_KEYS.WORST && {
      name: 'Worst-case product',
      value: customResult.worstCaseProduct ?? NOT_ASSIGNED,
    },
    {
      name: 'Description of the scenario/method',
      value: customResult.scenarioDescription || NOT_ASSIGNED,
    },
  ];

  const rows = possibleRows.filter((r) => r) as SummaryGridRow[];

  return <VersionHistorySummaryGrid rows={rows} />;
};

export default VersionHistoryLcaCustomResultGrid;
