import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';

const EpdCompilerPdfPreview: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams<any>();
  useEffect(() => {
    const checkAuthentication = async () => {
      const route = EpdLinks.epdCompilerPdfPreviewAuth(id);
      const isAuthenticated = AuthService.isLoggedin();

      // It seems that here is the problem with pdf generation, sometimes the browser has auth token from local storage, while BE responds 401
      // TODO: check on env
      if (isAuthenticated && false) {
        history.push(route);
      } else {
        const query = new URLSearchParams(location.search);
        const userName = query.get('user');
        if (!userName) {
          throw new Error("Missing 'user' query parameter.");
        }
        const password = query.get('password');
        if (!password) {
          throw new Error("Missing 'password' query parameter.");
        }
        await AuthService.login({ userName, password });
        history.push(route);
      }
    };

    checkAuthentication();
  }, [id]);

  return <div>Loading...</div>;
};

export default EpdCompilerPdfPreview;
