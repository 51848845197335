import { Calendar } from 'primereact/calendar';
import React from 'react';
import styled from 'styled-components';
import { Option } from 'types/types';
import { formatAsUTCDate } from 'util/utils';

import CalendarIcon from '../copy/CalendarIcon';
import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';

type TProps = Partial<TSmartLabelProps> & {
  name: string;
  value: any;
  options: any[];
  isMulti?: boolean;
  style?: any;
  onChanged: any;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
  dateFormat?: string;
} & any;

const WizardCalendarControlled: React.FunctionComponent<TProps> = ({
  label,
  options,
  tooltip,
  labelStyle,
  name,
  value,
  onChanged,
  disabled,
  style,
  placeholder,
  required,
  isClearable = true,
  dateFormat,
  error,
  ...props
}) => {
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          hasValue={!!value}
          error={error}
        />
      )}
      <StyledCalendar
        className="customCalendar"
        name={name}
        dateFormat={dateFormat ?? 'dd-mm-yy'}
        value={value}
        disabled={disabled}
        placeholder="Select..."
        onChange={(e: any) => {
          e !== value && onChanged(name, formatAsUTCDate(e.value));
        }}
        icon={CalendarIcon}
        showIcon
        {...props}
      />
    </div>
  );
};

const StyledCalendar = styled(Calendar)`
  & > .p-button {
    background-color: transparent;
    color: ${(props) => props.theme.colors.primaryBlack};
    border: none;
  }

  & > .p-button:hover {
    background-color: transparent;
    color: ${(props) => props.theme.colors.primaryBlack};
  }

  .p-inputtext {
    border: none;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none;
    outline: none;
    border-radius: 2px;
  }

  &.p-inputwrapper {
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
    border-radius: 2px;
    &:hover {
      border-color: ${(props) => props.theme.colors.primaryOrange};
    }
  }

  &.p-inputwrapper-focus:focus-within {
    border: 1px solid ${(props) => props.theme.colors.primaryOrange};
    outline: 2px solid ${(props) => props.theme.colors.primaryOrange};
    border-radius: 2px;
  }
`;
export default WizardCalendarControlled;
