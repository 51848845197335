import { PDFImagesView } from 'components/v2/images-upload/PDFImagesView';
import React from 'react';
import { CountryModel } from 'services/EpdClient';
import { EPDOrganizationInformationModel, EPDPDFOrganizationInformationAddress } from 'types/types';

import { PDF_H1, PDF_H2, PDF_P, PDF_PAGE, PDF_Table } from './Styles.styled';

const ManufacturerInformationPage: React.FunctionComponent<{
  organizationInformation?: EPDOrganizationInformationModel;
  organizationInformationAddress?: EPDPDFOrganizationInformationAddress | any;
  countries?: CountryModel[];
}> = ({ organizationInformation, organizationInformationAddress, countries }) => {
  return (
    <PDF_PAGE>
      <PDF_H1>INFORMATION ABOUT EPD OWNER</PDF_H1>
      <PDF_Table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>EPD Owner</td>
            <td>{organizationInformation?.companyName}</td>
          </tr>
          <tr>
            <td>Contact person name</td>
            <td>{organizationInformation?.contactPersonName}</td>
          </tr>
          <tr>
            <td>Contact person e-mail</td>
            <td>{organizationInformation?.contactPersonEmail}</td>
          </tr>
          <tr>
            <td>Organization address</td>
            <td>
              {countries?.find((country: any) => country.id === organizationInformationAddress?.countryId)?.name}
              <br />
              {organizationInformationAddress?.city}
              <br />
              {organizationInformationAddress?.zipCode}
              <br />
              {organizationInformationAddress?.addressLine}
              {organizationInformationAddress?.addressLineSecond && (
                <>
                  <br /> {organizationInformationAddress?.addressLineSecond}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </PDF_Table>

      <PDF_H2>Description of the organisation of the EPD owner</PDF_H2>
      <PDF_P>{organizationInformation?.description}</PDF_P>
      <PDFImagesView images={organizationInformation?.organizationImages} />
    </PDF_PAGE>
  );
};

export default ManufacturerInformationPage;
