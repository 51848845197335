import Checkbox from 'components/form/Checkbox';
import { collectionSelectStyles } from 'components/form/CollectionSelect';
import { ProcessStatus } from 'constants/constants';
import { Form, Formik } from 'formik';
import infoIcon from 'images/icons/svg/info.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { EpdLinks } from 'routes/EpdRoutes';
import { CopyEpdModel, EPDModel } from 'services/EpdClient';
import EpdService from 'services/EpdService';
import styled from 'styled-components';
import { ButtonSmall, FormMode, H2, H3, InfoBox, ProcessMode } from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';
import { Option } from 'types/types';
import * as Yup from 'yup';

import LargeTextInput from '../form/LargeTextInput';

enum Sections {
  EpdDeveloper = 'EpdDeveloper',
  EpdVerifier = 'EpdVerifier',
  Pcr = 'Pcr',
  Collections = 'Collections',
  ProductDescription = 'ProductDescription',
  Products = 'Products',
  Images = 'Images',
  GeographicalScope = 'GeographicalScope',
  Gtin = 'Gtin',
  LcaResults = 'LcaResults',
  ReferenceFlows = 'ReferenceFlows',
  MaterialProperties = 'MaterialProperties',
  MachineReadableGeographicalScope = 'MachineReadableGeographicalScope',
  Description = 'Description',
  TechnologyDescription = 'TechnologyDescription',
  TechnicalPurposeOfProduct = 'TechnicalPurposeOfProduct',
  Subtype = 'Subtype',
  DataSources = 'DataSources',
  UseAdvice = 'UseAdvice',
}

const CopyEpd: React.FunctionComponent<{
  companyId: string;
  accountId: string;
  onClose: () => void;
}> = ({ companyId, accountId, onClose }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [submitStatus, setSubmitStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [selectedEpd, setSelectedEpd] = useState<Option | undefined | null>(null);
  const [allEpds, setAllEpds] = useState<EPDModel[]>([]);

  const initialValues = { epdId: null };
  const cbLblStyle = { textTransform: 'uppercase', width: 'fit-content' };
  const RenderCheckBox = (name: string, disabled: boolean = false) => {
    return (
      <Checkbox
        disabled={disabled}
        key={name}
        labelStyle={cbLblStyle}
        label={t(`copyEpd.${name}`)}
        style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.75rem', verticalAlign: 'middle' }}
        name={name}
      />
    );
  };
  const disabledCheckboxes = [Sections.Gtin, Sections.LcaResults];
  const handleSubmit = async (values: any) => {
    try {
      setSubmitStatus(ProcessStatus.Fetching);
      const newEpd = await EpdService.copyEpd(
        values.epdId,
        accountId,
        values.newEpdName,
        Object.values(Sections).filter((o) => values[o] === true)
      );
      const route = EpdLinks.epd(newEpd.id);
      history.push(route);
      setSubmitStatus(ProcessStatus.Success);
    } catch (e) {
      toast.error(t('copyEpd.onFetchEpds.error') as string, {
        position: 'top-center',
      });
      setSubmitStatus(ProcessStatus.Error);
    }
  };

  useEffect(() => {
    const fetchEpds = async () => {
      if (!accountId) {
        setAllEpds([]);
        return;
      }

      try {
        setStatus(ProcessStatus.Fetching);
        const result = await EpdService.getEpds(accountId, companyId, undefined, undefined);
        setAllEpds(result);
        setStatus(ProcessStatus.Success);
      } catch (e) {
        setStatus(ProcessStatus.Error);
      }
    };
    fetchEpds();
  }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

  const copyEpdSchema = Yup.object<CopyEpdModel>({
    epdId: Yup.string().required(t('messages.required')),
    newEpdName: Yup.string().required(t('messages.required')),
  });

  return (
    <Formik initialValues={initialValues as any} validationSchema={copyEpdSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, dirty, isValid, setFieldValue }) => (
        <PopupForm>
          <div style={{ overflowY: 'auto' }}>
            <H2>{t('copyEpd.header')}</H2>
            <InfoBox>
              <img src={infoIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
              <div>{t('copyEpd.info')}</div>
            </InfoBox>
            <fieldset style={{ border: 'none' }} disabled={isSubmitting}>
              <FixedRow>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginRight: '2rem' }}>
                  <LargeTextInput label={t('copyEpd.newName')} name="newEpdName" />
                  {status === ProcessStatus.Fetching && <div>{t('copyEpd.onFetchEpds.fetching')}</div>}
                  {status === ProcessStatus.Error && (
                    <CardsEmpty>
                      <h3>{t('copyEpd.onFetchEpds.error')}</h3>
                    </CardsEmpty>
                  )}

                  {status === ProcessStatus.Success && (
                    <Select
                      value={selectedEpd}
                      placeholder={t('copyEpd.tooltips.epd')}
                      styles={collectionSelectStyles}
                      onChange={(option) => {
                        setSelectedEpd(option);
                        setFieldValue('epdId', option?.value);
                      }}
                      options={allEpds.map((epd: EPDModel) => {
                        return {
                          value: epd.id,
                          label: epd.name,
                        } as Option;
                      })}
                    />
                  )}
                </div>
              </FixedRow>

              <H3>{t('copyEpd.subHeader')}</H3>
              <Checkbox
                disable={false}
                key={'selectAll'}
                labelStyle={cbLblStyle}
                label={'Select all'}
                style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.75rem', verticalAlign: 'middle' }}
                name={'selectAll'}
                onClick={(event: { target: { checked: boolean } }) => {
                  Object.values(Sections).forEach((item) => {
                    if (!disabledCheckboxes.includes(item)) setFieldValue(item, event.target.checked);
                  });
                }}
              />
              <Title>{t('copyEpd.team')}</Title>
              <CheckboxGroupContainer>
                {[Sections.EpdDeveloper, Sections.EpdVerifier, Sections.Pcr].map((i) => RenderCheckBox(i))}
              </CheckboxGroupContainer>

              <Title>{t('copyEpd.informationEnvirondec')}</Title>
              <CheckboxGroupContainer>
                {[
                  Sections.Collections,
                  Sections.ProductDescription,
                  Sections.Products,
                  Sections.Images,
                  Sections.GeographicalScope,
                ].map((i) => RenderCheckBox(i, false))}
              </CheckboxGroupContainer>

              <Title>{t('copyEpd.informationMachineReadable')}</Title>
              <CheckboxGroupContainer>
                {[Sections.Gtin, Sections.LcaResults].map((i) => RenderCheckBox(i, true))}
                {[
                  Sections.ReferenceFlows,
                  Sections.MaterialProperties,
                  Sections.MachineReadableGeographicalScope,
                  Sections.Description,
                  Sections.TechnologyDescription,
                  Sections.TechnicalPurposeOfProduct,
                  Sections.Subtype,
                  Sections.DataSources,
                  Sections.UseAdvice,
                ].map((i) => RenderCheckBox(i))}
              </CheckboxGroupContainer>
            </fieldset>
          </div>
          {isSubmitting && (
            <div>
              <Spinner />
            </div>
          )}
          <ButtonsContainer>
            <ButtonSmall disabled={isSubmitting || !isValid || !dirty} type={'submit'}>
              {t('copyEpd.copyButton')}
            </ButtonSmall>
            <CloseTab>
              <InfoButton
                disabled={isSubmitting && submitStatus !== ProcessStatus.Error}
                type="button"
                onClick={() => onClose()}
              >
                {t('copyEpd.closeButton')}
              </InfoButton>
            </CloseTab>
          </ButtonsContainer>
        </PopupForm>
      )}
    </Formik>
  );
};

export default CopyEpd;

const PopupForm = styled(Form)<{ mode?: FormMode; process?: ProcessMode }>`
  position: absolute;
  height: 80%;
  top: 10%;
  left: 10%;
  right: 10%;
  max-width: 40rem;
  bottom: 0;
  margin: auto;
  background-color: ${(props) => props.theme.colors.lightGray};
  line-height: 1.7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;

  flex-direction: ${(props) => (props.mode === FormMode.Inline ? 'row' : 'column')};
  opacity: ${(props) => (props.process === ProcessMode.Submitting ? 0.5 : 1)};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
`;

const CloseTab = styled.div`
  align-items: end;
  text-align: end;
  grid-column-start: 3;
  grid-column-end: -1;
`;

export const FixedRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${(props) => props.theme.media.tablet} {
    flex-wrap: nowrap;
    margin: 1rem 0;
  }
`;

export const FixedLabel = styled.label`
  font-weight: normal;
  text-transform: uppercase;
  ${(props) => props.theme.fonts.textSmall}
  flex: 0 0 5rem;
  align-self: auto;
  margin: 0.5rem 1rem 0.5rem 0;

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
`;

const Title = styled.h4`
  font-family: ${(props) => props.theme.fontFamilies.heading};
  font-weight: bold;
  margin: 0;
`;

const InfoButton = styled(ButtonSmall)`
  background-color: ${(props) => props.theme.colors.regionColor};
  &:hover {
    background-color: ${(props) => props.theme.colors.regionColorLight};
  }
`;

const CardsEmpty = styled.div`
  flex: 1 0 auto;
  text-align: center;
  margin: 2rem;
`;

const CheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6rem;
  margin-bottom: 1rem;
`;
