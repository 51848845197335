import { AdminInvoicesClient, InvoiceStatus, UpdateInvoiceModel } from '../EpdClient';

const client = new AdminInvoicesClient(process.env.REACT_APP_API_URL);

class AdminInvoicesService {
  static getInvoices = async (
    invoiceNumber?: string,
    smartFilter?: string,
    companyId?: string,
    periods?: string[],
    invoiceStatuses?: InvoiceStatus[]
  ) => {
    return await client.get(invoiceNumber, smartFilter, companyId, periods, invoiceStatuses);
  };
  static updateInvoice = async (invoiceId: string, updateInvoiceModel: UpdateInvoiceModel) => {
    return await client.put(invoiceId, updateInvoiceModel);
  };
  static sendReminderApproval = async (invoiceId: string) => {
    return await client.sendApprovalReminder(invoiceId);
  };
  static sendReminderPayment = async (invoiceId: string) => {
    return await client.sendPaymentReminder(invoiceId);
  };
  static updateInvoiceStatuses = async () => {
    return await client.updateInvoiceStatuses();
  };
  static restoreInvoice = async (invoiceId?: string) => {
    return await client.restoreInvoice(invoiceId as string);
  };
}

export default AdminInvoicesService;
