import React from 'react';
import VersionHistorySection from '../VersionHistorySection';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import { VersionHistorySummaryGridSkeleton } from '../VersionHistorySummaryGrid';
import SystemBoundaryGridPdf from '../../grids/system-boundary/SystemBoundaryGridPdf';
import VersionHistorySystemBoundaryGrid from './VersionHistorySystemBoundaryGrid';
import SmartLabel from 'components/v2/labels/SmartLabel';
import { ImagesUpload } from 'components/v2/images-upload';

const VersionHistorySystemBoundarySection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();

  return (
    <VersionHistorySection title="System boundary">
      {data ? (
        <>
          <SystemBoundaryGridPdf
            epdVersionId={data.epdVersionId}
            productType={data.epdDefinitionInformation.productType}
            dontApplyPrintStyles
          />

          <VersionHistorySystemBoundaryGrid />

          <div>
            <SmartLabel label="Process flow related images" />
            <ImagesUpload
              name="ProcessFlowImages"
              images={data.systemBoundary?.processFlowImages ?? []}
              onUpload={() => {}}
              onReorder={() => {}}
              onRemove={() => {}}
              disabled
              preferEnabledAppearanceEvenWhenDisabled
            />
          </div>
        </>
      ) : (
        <>
          <VersionHistorySummaryGridSkeleton skeletonRowsCount={8} />
          <VersionHistorySummaryGridSkeleton skeletonRowsCount={2} />
        </>
      )}
    </VersionHistorySection>
  );
};

export default VersionHistorySystemBoundarySection;
