import { useField } from 'formik';
import { FieldPanelInput } from 'styles/v2/Styles.styled';

import SmartLabel from '../labels/SmartLabel';
import SelectFormField from './SelectFormField';

const SelectDataField = ({
  label,
  labelStyle,
  name,
  tooltip,
  required,
  options,
  disabled,
  isMulti,
  tooltipPosition,
  ...props
}: any) => {
  const [field] = useField({ ...props, name });
  return (
    <FieldPanelInput>
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={isMulti ? (field?.value?.length || 0) > 0 : field?.value}
        />
      )}
      <SelectFormField name={name} options={options} isDisabled={disabled} isMulti={isMulti} {...props} />
    </FieldPanelInput>
  );
};

export default SelectDataField;
