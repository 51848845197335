import linkIcon from 'images/icons/svg/link-1.svg';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import styled from 'styled-components';
import { VersionHistoryModel } from 'types/types';

import VersionItem from './VersionItem';

const VersionHistoryDialog: React.FunctionComponent<{ epdId: string; versions: VersionHistoryModel[] }> = ({
  epdId,
  versions,
}) => {
  const [isVersionHistoryOpen, setIsVersionHistoryOpen] = useState(false);

  const handleVersionClick = () => {
    // Note: Don't prevent event's default behaviour and let it open the page in a new tab
    setIsVersionHistoryOpen(false);
  };

  return (
    <>
      <VersionHistoryButtonBox>
        <Button
          icon={<ShowDetailsIcon src={linkIcon} alt="link" />}
          style={{ fontSize: 15, fontWeight: 500 }}
          type="button"
          label="Version history"
          text
          onClick={() => setIsVersionHistoryOpen(true)}
          disabled={false}
        />
      </VersionHistoryButtonBox>
      <Dialog
        header={'EPD version history'}
        visible={isVersionHistoryOpen}
        style={{ width: 560 }}
        onHide={() => setIsVersionHistoryOpen(false)}
      >
        <DialogContentContainer>
          <InformationContainer>
            Here you can find the version history for this EPD.
            <br />
            The previous EPD version will open in a new tab.
          </InformationContainer>
          {versions.map((version) => (
            <VersionItem key={version.id} epdId={epdId} data={version} onClick={handleVersionClick} />
          ))}
        </DialogContentContainer>
      </Dialog>
    </>
  );
};

const VersionHistoryButtonBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 70px;
  margin-top: 10px;
  cursor: pointer;

  .p-button.p-button-text {
    padding: 0;
    background-color: transparent;
    border: none;
    ${(props) => props.theme.fonts.standard};
  }
  .p-button.p-button-text:hover {
    background-color: transparent;
  }
  .p-button.p-button-text:disabled {
    color: #757575;
  }
`;

const DialogContentContainer = styled.div`
  padding: 0.25rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InformationContainer = styled.div`
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  text-align: justify;
  padding: 1rem;
  border-radius: 4px;
  margin-top: 27px;
`;

const ShowDetailsIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  cursor: pointer;
`;

export default VersionHistoryDialog;
