import { useState } from 'react';
import { EPDModel } from 'services/EpdClient';

import EditDefinitionDialog from '../../dialogs/EditDefinitionDialog';
import EpdDetailsGrid from '../EpdDetailsGrid';

type TProps = {
  epd: EPDModel;
  onChangeEpd: any;
  isReadOnly: boolean;
  title: string | undefined;
  setTitle: any;
};

const EpdDefinition = ({ epd, onChangeEpd, isReadOnly, title, setTitle }: TProps) => {
  const [isDefinitionEditOpened, setIsDefinitionEditOpened] = useState<boolean>(false);

  return (
    <>
      <EpdDetailsGrid
        headerLabel={'EPD definition'}
        isReadOnly={isReadOnly}
        data={[
          { key: 'UUID of data set', value: epd.id }, // TODO: headId
          { key: 'LCA Practitioner(s)', value: epd.epdDevelopers?.map((x) => x.contactName).join('; ') },
          { key: 'EPD Verifier', value: epd.epdVerifier?.displayName },
          { key: 'PCR', value: epd.pcr?.fullName },
          { key: 'Sector EPD', value: epd.isSectorEPD ? 'Yes' : 'No' },
        ]}
        onEdit={() => setIsDefinitionEditOpened(true)}
      />
      <EditDefinitionDialog
        isOpened={isDefinitionEditOpened}
        epd={epd}
        onHide={() => setIsDefinitionEditOpened(false)}
        onChangeEpd={onChangeEpd}
        title={title}
        setTitle={setTitle}
      />
    </>
  );
};

export default EpdDefinition;
