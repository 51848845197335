/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestModel } from 'services/EpdClient';
import RequestsService from 'services/RequestService';
import { ButtonSmall, ErrorText, InfoText } from 'styles/Styles.styled';

import { ProcessStatus } from '../../constants/constants';
import { TableRow } from './ManageMembers';

const MemberRequests: React.FunctionComponent<{ companyId: string }> = ({ companyId }) => {
  const [requests, setRequests] = React.useState<RequestModel[]>([]);
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.Success);
  const [updated, setUpdated] = React.useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setStatus(ProcessStatus.Fetching);
        const result = await RequestsService.getRequests(companyId);
        setRequests(result);
        setStatus(ProcessStatus.Success);
      } catch {
        setStatus(ProcessStatus.Error);
      }
    };

    fetchRequests();
  }, [companyId, updated]);

  const reject = async (requestId: string | undefined) => {
    try {
      await RequestsService.rejectRequest(requestId || '');
      setUpdated(!updated);
    } catch {}
  };

  const accept = async (requestId: string | undefined) => {
    try {
      await RequestsService.acceptRequest(requestId || '');
      setUpdated(!updated);
    } catch {}
  };

  return (
    <>
      <h3>{t('manageMembers.requests')}</h3>
      {requests?.map((req) => (
        <TableRow key={req.id}>
          <div style={{ flexGrow: 1 }}>{req.requestingUserName}</div>
          <div style={{ flexGrow: 1 }}>{req.roleName}</div>
          <a style={{ margin: '0 1rem', cursor: 'pointer' }} onClick={() => reject(req.id)}>
            {t('reject')}
          </a>
          <ButtonSmall onClick={() => accept(req.id)}>{t('accept')}</ButtonSmall>
        </TableRow>
      ))}
      {status === ProcessStatus.Fetching && <InfoText>{t('manageMembers.messages.fetchRequests')}</InfoText>}
      {status === ProcessStatus.Error && <ErrorText>{t('manageMembers.errors.unFetchedRequests')}</ErrorText>}
      {status === ProcessStatus.Success && requests.length === 0 && (
        <InfoText>{t('manageMembers.messages.noRequests')}</InfoText>
      )}
    </>
  );
};

export default MemberRequests;
