import styled from 'styled-components';

export const DialogContainer = styled.div`
  width: 525px;
  min-height: 214;
  text-align: center;
  padding: 10px 2rem 1rem 2rem;
  margin: 0;
  && h3 {
    font-weight: 600;
  }
`;

export const Description = styled.span`
  ${(props) => props.theme.typography.bodyBody14Regular}
  color: ${(props) => props.theme.colors.primaryBlack70};
`;

export const InformationContainer = styled.div`
  background-color: ${(props) => props.theme.colors.elementsFilledInput};
  text-align: justify;
  padding: 1rem;
  border-radius: 4px;
`;
