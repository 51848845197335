import 'primeflex/primeflex.css';

import { ProcessStatus } from 'constants/constants';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import { CollectionDashboardModel, CollectionModel } from 'services/EpdClient';
import FileService from 'services/FileService';

import CollectionTitleRow from './CollectionTitleRow';

type TProps = {
  collection: CollectionDashboardModel;
  fetchCollections: () => {};
};

const CollectionListItem = ({ collection, fetchCollections }: TProps) => {
  const toast = useRef<Toast>(null);
  const menu = useRef<Menu>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);

  const accept = () => {
    toast.current!.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  };

  const openCollection = async (collectionId: string) => {
    try {
      setStatus(ProcessStatus.Fetching);
      const route = EpdLinks.editCollection(collectionId);
      history.push(route);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  const getStatus = (collection: CollectionModel) => {
    switch (collection.description) {
      case 'INSTOCK':
        return 'success';

      case 'LOWSTOCK':
        return 'warning';

      case 'OUTOFSTOCK':
        return 'danger';

      default:
        return null;
    }
  };

  return (
    <div className="col-12">
      <Toast ref={toast} />
      <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4 pr-0">
        <img
          className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
          src={FileService.getImageUrl(collection?.image?.id)}
          alt={collection.name}
        />
        <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
          <div className="flex flex-column gap-3 flex-1">
            <CollectionTitleRow collection={collection} fetchCollections={fetchCollections} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionListItem;
