import React, { useState } from 'react';
import MaximizedDialog from '../panels/expandable/MaximizedDialog';
import GridPanelHeader from '../panels/expandable/GridPanelHeader';
import { Panel } from 'primereact/panel';
import styled from 'styled-components';

type Props = {
  title: string;
  children: React.ReactNode;
};

const VersionHistoryExpandableGridWrapper: React.FC<Props> = ({ title, children }) => {
  const [visible, setVisible] = useState(false);
  const onMaximize = () => {
    setVisible(true);
  };

  return (
    <>
      <MaximizedDialog title={title} onHide={() => setVisible(false)} visible={visible}>
        {children}
      </MaximizedDialog>

      <PaddinglessPanel
        headerTemplate={(options) => (
          <GridPanelHeader
            options={options}
            title={title}
            onMaximize={onMaximize}
          />
        )}
        toggleable
        collapsed
      >
        <div style={{ overflowX: 'hidden' }}>{children}</div>
      </PaddinglessPanel>
    </>
  );
};

export default VersionHistoryExpandableGridWrapper;

const PaddinglessPanel = styled(Panel)`
  && .p-panel-header {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: none;
  }
  && .p-panel-content {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
`;
