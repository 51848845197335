import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvitationModel } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';
import { ButtonSmall, ErrorText, InfoText } from 'styles/Styles.styled';

import { ProcessStatus } from '../../constants/constants';
import { TableRow } from './ManageMembers';

const MemberInvites: React.FunctionComponent<{ companyId: string; triggerToUpdateInvites: number }> = ({
  companyId,
  triggerToUpdateInvites,
}) => {
  const [invites, setInvites] = React.useState<InvitationModel[]>([]);
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  // @ts-ignore
  const { t } = useTranslation();

  const fetchInvites = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const result = await MembershipService.getInvitations(companyId);
      setInvites(result);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  React.useEffect(() => {
    fetchInvites();
  }, [companyId, triggerToUpdateInvites]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = (inviteId: string | undefined) => {
    MembershipService.deleteInvitation(inviteId).then(() => {
      fetchInvites();
    });
  };

  return (
    <>
      {invites.map((invite) => (
        <TableRow key={invite.id}>
          <div>{invite.invitedUserEmail}</div>
          <div>{invite.roleName}</div>
          <ButtonSmall
            onClick={() => {
              onDelete(invite.id);
            }}
          >
            {t('delete')}
          </ButtonSmall>
        </TableRow>
      ))}
      {status === ProcessStatus.Error && <ErrorText>{t('manageMembers.errors.unFetchedPendingInvites')}</ErrorText>}
      {status === ProcessStatus.Fetching && <InfoText>{t('manageMembers.messages.fetchPendingInvites')}</InfoText>}
      {status === ProcessStatus.Success && !invites && <InfoText>{t('manageMembers.messages.noPendingInvites')}</InfoText>}
    </>
  );
};

export default MemberInvites;
