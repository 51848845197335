import FileRow from 'components/v2/file-row/FileRow';
import { HelpBox } from 'components/v2/help-boxes';
import { FileModel } from 'services/EpdClient';
import styled from 'styled-components';

type TProps = {
  processCertificates: FileModel[] | undefined;
};

const ProcessCertificateDescription = ({ processCertificates }: TProps) => {
  return processCertificates && processCertificates.length > 0 ? (
    <>
      {processCertificates?.map((file: any) => (
        <FileRow key={file.id} file={file} disabled onRemoveFile={null} />
      ))}
    </>
  ) : (
    <PendingReport>Please upload the process certificate.</PendingReport>
  );
};

const PendingReport = styled(HelpBox)`
  background-color: #f3f4fb;
  color: #151515;
  flex-direction: row;
  padding: 14px 16px;
  align-items: center;
  margin: 10px 10px 10px 0px;
`;
export default ProcessCertificateDescription;
