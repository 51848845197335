import {
  MembershipsClient,
  InvitationsClient,
  CreateInvitationModel,
  MembershipModel,
  InquireCollaborationModel,
  EPDModel,
  MemberModel,
  CreateMembershipModel,
} from 'services/EpdClient';

const client = new MembershipsClient(process.env.REACT_APP_API_URL);
const invitationsClient = new InvitationsClient(process.env.REACT_APP_API_URL);

class MembershipService {
  static deleteInvitation = async (inviteId: string | undefined) => {
    if (inviteId) {
      return await invitationsClient.delete(inviteId);
    }
    return;
  };
  static getMembershipsByCompany = async (companyId: string, accountId: string | undefined) => {
    return await client.getAll(companyId, undefined, undefined, accountId);
  };

  static getMembershipsByUser = async (userId: string) => {
    return await client.getAll(undefined, undefined, userId, undefined);
  };
  static getMembershipsByUserAndCompany = async (companyId: string, userId: string) => {
    return await client.getAll(companyId, undefined, userId, undefined);
  };
  static getMembershipsByUserAndCompanyAndRole = async (companyId: string, roleId: string, userId: string) => {
    return await client.getAll(companyId, roleId, userId, undefined);
  };

  static getMembershipsByCompanyAndRole = async (companyId: string, roleId: string, accountId?: string) => {
    return await client.getAll(companyId, roleId, undefined, accountId);
  };

  static getVerifiers = async (companyId: string) => {
    return await client.getVerifierMemberships(companyId);
  };

  static getUserAdminLicensees = async (userId: string) => {
    return await client.getUserAdminLicensees(userId);
  };  

  static createInvitation = async (values: CreateInvitationModel) => {
    return await invitationsClient.createInvitation(values);
  };

  static inquireCollaboration = async (values: InquireCollaborationModel) => {
    return await invitationsClient.inquireCollaboration(values);
  };

  static getInvitations = async (companyId: string) => {
    return await invitationsClient.getAll(companyId); //getInvitations(companyId);
  };

  static getInvitationsByUser = async () => {
    return await invitationsClient.getAllByUser();
  }

  static approveInvitation = async (id: string) => {
    return await invitationsClient.accept(id);
  };

  static rejectInvitation = async (id: string) => {
    return await invitationsClient.reject(id);
  };

  static deleteMembership = async (id: string) => {
    return await client.delete(id);
  };

  static notify = async (id: string) => {
    return await client.invite(id);
  };

  static updateMembership = async (member: MembershipModel) => {
    return await client.put(member.id || '', member); // TODO: Remove empty string thingy when id== required
  };

  static connect = async (member: MemberModel, epd: EPDModel) => {
    return await client.connect(member.membershipId, epd.id);
  };

  static create = async (membership: CreateMembershipModel) => {
    return await client.create(membership);
  };
}

export default MembershipService;
