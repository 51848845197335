import { Link } from 'react-router-dom';
import { EPDCopyGroupStatus, EPDStatus } from 'services/EpdClient';
import styled, { css } from 'styled-components';
import { H2 } from 'styles/Styles.styled';

export const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;
  margin-right: 1vw;
`;

export const QuickFactBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem;
  background-color: white;
  box-shadow: 5px 5px 5px ${(props) => props.theme.colors.darkGray};
  > span {
    flex: 0 1 60%;
    padding: 0.25rem;
  }
  > strong {
    flex: 0 1 30%;
    text-align: right;
    padding: 0.25rem;
  }
`;

export const AccountDropDownItem = styled.div`
  padding: 1rem;
  min-width: 250px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.lightGreen};
  }
`;

export const InputLabel = styled.label`
  margin: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
`;

export const CardsEmpty = styled.div`
  flex: 1 0 auto;
  text-align: center;
  margin: 2rem;
`;

export const CardStatus = styled.div<{ type?: EPDStatus }>`
  ${(props) => props.theme.fonts.textSmall};
  font-style: italic;
  flex: 1 0 auto;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-weight: normal;
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  > span {
    background-color: ${(props) =>
      props.type === EPDStatus.Registered ? props.theme.colors.lightGreen : props.theme.colors.darkGray};
    padding: 0.25rem 0.75rem;
  }
`;

export const CardName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const CardHeading = styled.h4`
  margin: 0;
  ${(props) => props.theme.fonts.textSmall};
  color: ${(props) => props.theme.colors.gray};
`;

export const OrgHeading = styled(H2)`
  margin: 0;
`;

export const OrganisationContainer = styled.div`
  margin: 1rem;
  margin-left: 0rem;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled(Link)<{ background?: string }>`
  flex: 0 0 23%;
  height: 150px;
  position: relative;
  overflow: hidden;
  border: solid 1px ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11);
  transition: transform 0.15s;
  margin: 1%;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(0.375turn, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)),
    url(${(props) => props.background});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-weight: bold;

  &:hover {
    transform: scale(1.05);
  }
`;
export const CopyGroupCard = styled(Card)`
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.11), 2px 2px 1px rgba(255, 255, 255, 1), 4px 4px 1px rgba(0, 0, 0, 0.11),
    6px 6px 4px rgba(0, 0, 0, 0.11);
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  & ${Card}:nth-child(4n+1) {
    margin-left: 0;
  }
`;

export const HeadingArea = styled.div`
  display: flex;
  margin-right: 2%;
  margin-bottom: 1%;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: flex-start;
  margin-right: 1.5rem;
`;

export const QuickFactValue = styled.div.attrs((props: { highlight: boolean }) => ({ highlight: props.highlight ?? false }))`
  display: flex;
  justify-content: flex-end;

  div {
    ${(props) =>
      props.highlight
        ? css`
            background: ${(props) => props.theme.colors.darkOrange};
            color: white;

            :hover {
              border: 1px solid ${(props) => props.theme.colors.darkOrange};
              background: white;
              color: ${(props) => props.theme.colors.darkOrange};
            }
          `
        : ''}
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    padding: 0 0.2rem;
  }
`;
