import React from 'react';
import { Column, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useEpdLcaResultsAdditional } from 'services/api/queries';
import { DataTableStyledPdf } from './LcaResultsGridPdf';

type TProps = {
  epdVersionId: string;
  columns?: ColumnProps[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
};
const LcaResultsGridExtendedPdf = ({ epdVersionId, columns, notes }: TProps) => {
  const rows = useEpdLcaResultsAdditional(epdVersionId).data;

  const footerGroup = notes ? (
    <ColumnGroup>
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} colSpan={2} className="p-row-note" />
          <Column style={{ padding: '2px' }} footer={x.description} colSpan={columns?.length} className="p-row-note" />
        </Row>
      ))}
    </ColumnGroup>
  ) : null;

  return (
    <DataTableStyledPdf
      value={rows}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      footerColumnGroup={footerGroup}
    >
      {columns?.map((x) => (
        <Column key={x.field} {...x} />
      ))}
    </DataTableStyledPdf>
  );
};

export default LcaResultsGridExtendedPdf;
