import { MaterialPropertiesClient } from './EpdClient';

const materialPropertiesClient = new MaterialPropertiesClient(process.env.REACT_APP_API_URL);

class MaterialPropertiesService {
  static getMaterialProperties = async () => {
    return materialPropertiesClient.get();
  };
}

export default MaterialPropertiesService;
