import { FixedLabel } from 'components/dashboard/CopyEpd';
import { TextButton } from 'components/v2/buttons';
import { EditDataIcon } from 'components/v2/icons';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

type TProps = {
  headerLabel: string;
  isReadOnly?: boolean;
  onEdit?: any;
};

const EpdDetailsGridHeader = ({ headerLabel, isReadOnly, onEdit }: TProps) => {
  return (
    <div className="flex flex-row align-items-center justify-content-between">
      <HeaderCaptionSemiBold className="white-space-nowrap">{headerLabel}</HeaderCaptionSemiBold>
      {!isReadOnly && (
        <TextButton
          className="white-space-nowrap"
          style={{ cursor: 'pointer' }}
          label="Edit data"
          icon={EditDataIcon}
          text
          onClick={onEdit}
        />
      )}
    </div>
  );
};

export default EpdDetailsGridHeader;
