import { editAdminSelectStyles } from 'components/form/EditAdminSelectStyles';
import LargeRadiusTextInput from 'components/form/LargeRadiusTextInput';
import ReactSelect from 'components/form/ReactSelect';
import { Form, Formik } from 'formik';
import { FixedLabel } from 'pages/wizard/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddressModel, CountryModel3, InvoiceModel, InvoiceStatus, UpdateInvoiceModel } from 'services/EpdClient';
import InvoiceService from 'services/InvoiceService';
import AdminInvoicesService from 'services/admin/AdminInvoicesService';
import AdminService from 'services/admin/AdminService';
import styled from 'styled-components';
import { AdminBox, ButtonSmall, Container, EditFull, H2, InfoText } from 'styles/Styles.styled';
import { Option } from 'types/types';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

const EditInvoice: React.FunctionComponent = () => {
  const { invoiceid } = useParams<{ invoiceid: string }>();
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceModel>();
  const [status, setStatus] = useState<[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [savedUpdates, setSavedUpdates] = useState('');
  const [actionConfirmationMessage, setActionConfirmationMessage] = useState('');
  const [actionErrorMessage, setActionErrorMessage] = useState('');

  const [countries, setCountries] = useState<CountryModel3[]>([]);
  const swedenId = '6f65eb76-cb39-ea11-a248-dcfb48d05344';
  const lastIndex = (selectedInvoice?.invoiceItems?.length ?? 0) - 1;
  const formatAmount = (number: number) => {
    return new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      const invoice = await InvoiceService.getInvoice(invoiceid);
      setSelectedInvoice(invoice as any);
      const items = Object.values(InvoiceStatus as any);
      setStatus(items as []);
    };
    const fetchCountries = async () => {
      const result = await AdminService.getCountries(undefined);
      setCountries(result);
    };
    fetchInvoice();
    fetchCountries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validationSchema = Yup.object({
    vismaId: Yup.number(),
    companyName: Yup.string().required(),
    accountName: Yup.string().nullable(),
    reference: Yup.string().nullable(),
    customerNumber: Yup.string().nullable(),
    vat: Yup.string().nullable(),
    invoicingAddress: Yup.mixed<AddressModel>(),
    invoiceStatus: Yup.mixed<InvoiceStatus>(),
    invoicingEmail: Yup.string().email('Invalid email address').nullable(),
    totalAmount: Yup.number().nullable(),
  });

  if (!selectedInvoice) {
    return <InfoText>Loading...</InfoText>;
  }

  const initialStatus = { label: selectedInvoice?.invoiceStatus, value: selectedInvoice?.invoiceStatus };
  const initialCountry = {
    label: selectedInvoice?.invoicingAddress?.country,
    value: selectedInvoice?.invoicingAddress?.countryId,
  };

  const initialValues: any = {
    ...selectedInvoice,
    invoiceStatus: initialStatus,
    invoicingAddress: {
      ...selectedInvoice.invoicingAddress,
      country: initialCountry,
    },
  };

  delete initialValues.invoicingAddress.countryId;

  const onSaveChanges = async (rawValues: any) => {
    let values = {
      ...rawValues,
    };

    let changedValues: UpdateInvoiceModel = {};

    values.invoiceStatus = values?.invoiceStatus.value as any;

    changedValues = {
      id: invoiceid,
      companyName: values.companyName,
      accountName: values.accountName,
      customerNumber: values.customerNumber,
      reference: values.reference,
      vat: values.vat,
      invoicingAddress: {
        ...values.invoicingAddress,
      },
      invoiceStatus: values.invoiceStatus,
    };

    if (selectedInvoice != null) {
      try {
        await AdminInvoicesService.updateInvoice(invoiceid, changedValues);
        setSavedUpdates('Your updates have been saved.');
      } catch (error) {
        setErrorMessage(PoorMansError(error));
      }
      setSelectedInvoice(await InvoiceService.getInvoice(invoiceid));
    }
  };

  const onRestoreInvoice = async () => {
    setActionConfirmationMessage('');
    setActionErrorMessage('');
    try {
      await AdminInvoicesService.restoreInvoice(invoiceid);
      setActionConfirmationMessage('Your invoice have been restored.');
    } catch (error) {
      setActionErrorMessage(PoorMansError(error));
    }
  };

  const onReminderApproval = async () => {
    setActionConfirmationMessage('');
    setActionErrorMessage('');
    try {
      await AdminInvoicesService.sendReminderApproval(invoiceid);
      setActionConfirmationMessage('An email reminder for approval has been sent.');
    } catch (error) {
      setActionErrorMessage(PoorMansError(error));
    }
  };

  const onReminderPayment = async () => {
    setActionConfirmationMessage('');
    setActionErrorMessage('');
    try {
      await AdminInvoicesService.sendReminderPayment(invoiceid);
      setActionConfirmationMessage('An email reminder for payment has been sent.');
    } catch (error) {
      setActionErrorMessage(PoorMansError(error));
    }
  };

  return (
    <Container>
      <EditFull>
        <H2>Invoice details</H2>
        <Formik initialValues={initialValues as any} validationSchema={validationSchema} onSubmit={onSaveChanges}>
          <>
            <Tabs>
              {selectedInvoice?.invoiceStatus === InvoiceStatus.Draft && (
                <ButtonSmall type="button" onClick={onReminderApproval}>
                  Send reminder for approval
                </ButtonSmall>
              )}

              {selectedInvoice?.invoiceStatus === InvoiceStatus.Approved && (
                <ButtonSmall type="button" onClick={onReminderPayment}>
                  Send reminder for payment
                </ButtonSmall>
              )}

              {selectedInvoice?.invoiceStatus === InvoiceStatus.Declined && (
                <ButtonSmall type="button" onClick={onRestoreInvoice}>
                  Restore quotation
                </ButtonSmall>
              )}
            </Tabs>

            {actionErrorMessage ? (
              <div style={{ color: 'red', marginTop: '1rem' }}>{actionErrorMessage}</div>
            ) : (
              <div style={{ color: 'green', marginTop: '1rem' }}>{actionConfirmationMessage}</div>
            )}

            <AdminBox style={{ backgroundColor: '#FDFDFD' }}>
              <InvoiceGrid>
                <TopAreaRight>
                  <div>
                    <FixedLabel>Status</FixedLabel>
                  </div>
                  <div>
                    <StyledReactSelect
                      name="invoiceStatus"
                      isMulti={false}
                      styles={editAdminSelectStyles}
                      options={status?.map((s) => {
                        return { value: s, label: s } as Option;
                      })}
                      isClearable={true}
                    ></StyledReactSelect>
                  </div>
                </TopAreaRight>
                <Company>
                  <div>
                    <FixedLabel>Invoice Number</FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="vismaId" type="text" value={selectedInvoice.vismaId} disabled={true} />
                  </div>
                  <div>
                    <FixedLabel>Organisation Name</FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="companyName" type="text" />
                  </div>
                  <div>
                    <FixedLabel>Email</FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="invoicingEmail" type="text" disabled={true} />
                  </div>
                </Company>
                <Account>
                  <div>
                    <FixedLabel>Account Name</FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="accountName" type="text" />
                  </div>
                  <div>
                    <FixedLabel>Reference</FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="reference" type="text" />
                  </div>
                  <div>
                    <FixedLabel>Purchase Order</FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="customerNumber" type="text" />
                  </div>
                  <div>
                    <FixedLabel>VAT</FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="vat" type="text" />
                  </div>
                </Account>
                <Address>
                  <div>
                    <FixedLabel>Address</FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="invoicingAddress.line1" type="text" placeholder="Address line 1" />
                  </div>
                  <div>
                    <FixedLabel></FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="invoicingAddress.line2" type="text" placeholder="Address line 2" />
                  </div>
                  <div>
                    <FixedLabel></FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="invoicingAddress.line3" type="text" placeholder="Address line 3" />
                  </div>
                  <div>
                    <FixedLabel></FixedLabel>
                  </div>
                  <div>
                    <LargeRadiusTextInput name="invoicingAddress.line4" type="text" placeholder="Address line 4" />
                  </div>
                  <div>
                    <FixedLabel></FixedLabel>
                  </div>
                  <div>
                    <StyledReactSelect
                      isDisabled={true}
                      defaultValue={initialValues.invoicingAddress?.country}
                      name="invoicingAddress.country"
                      isMulti={false}
                      styles={editAdminSelectStyles}
                      options={countries?.map((country) => {
                        return { value: country.id, label: country.name } as Option;
                      })}
                      isClearable={true}
                    ></StyledReactSelect>
                  </div>
                </Address>
                <InvoiceItems>
                  <ItemHeader>Item(s)</ItemHeader>
                  <ItemHeader>Description</ItemHeader>
                  {selectedInvoice.invoicingAddress?.countryId === swedenId ? (
                    <ItemHeader style={{ textAlign: 'right' }}>
                      <ItemHeader style={{ textAlign: 'right' }}>Amount ( {selectedInvoice.currency} )</ItemHeader>
                    </ItemHeader>
                  ) : (
                    <ItemHeader style={{ textAlign: 'right' }}>Amount ( EUR )</ItemHeader>
                  )}
                  <GridLine />
                  {selectedInvoice?.invoiceItems?.map((item, index) => (
                    <React.Fragment key={index}>
                      <ItemName>{item.code}</ItemName>
                      <span>{item.description}</span>
                      {selectedInvoice.totalVAT && item.amount ? (
                        <Amount>{formatAmount(item.amount)}</Amount>
                      ) : (
                        <Amount>{item.amount?.toLocaleString()}</Amount>
                      )}
                      <GridLine style={{ opacity: index === lastIndex ? 1 : 0.25 }} />
                    </React.Fragment>
                  ))}
                  <Surcharge>Subtotal</Surcharge>
                  {selectedInvoice.totalVAT && selectedInvoice.subTotal ? (
                    <Amount>{formatAmount(selectedInvoice.subTotal)}</Amount>
                  ) : (
                    <Amount>{selectedInvoice.subTotal?.toLocaleString()}</Amount>
                  )}

                  {selectedInvoice.totalVAT ? (
                    <Fragment>
                      <Surcharge>VAT</Surcharge>
                      <Amount>{formatAmount(selectedInvoice.totalVAT)}</Amount>

                      {selectedInvoice.roundOff ? (
                        <Fragment>
                          <Surcharge>Round off</Surcharge>
                          <Amount>{formatAmount(selectedInvoice.roundOff)}</Amount>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : null}
                  <GridLine />
                  <TotalLabel>
                    Total (<span>{selectedInvoice.currency}</span>)
                  </TotalLabel>
                  {selectedInvoice.totalVAT && selectedInvoice?.totalAmount ? (
                    <TotalSum>{formatAmount(selectedInvoice?.totalAmount)}</TotalSum>
                  ) : (
                    <TotalSum>{selectedInvoice.totalAmount?.toLocaleString()}</TotalSum>
                  )}
                </InvoiceItems>

                <SaveBox>
                  <ButtonSmall type="submit">Save</ButtonSmall>
                </SaveBox>
              </InvoiceGrid>
            </AdminBox>
          </>
        </Formik>

        {errorMessage ? (
          <div style={{ color: 'red', marginTop: '1rem' }}>{errorMessage}</div>
        ) : (
          <div style={{ color: 'green', marginTop: '1rem' }}>{savedUpdates}</div>
        )}
      </EditFull>
    </Container>
  );
};

const Tabs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
`;

const StyledReactSelect = styled(ReactSelect)`
  box-sizing: border-box;
  width: 100%;
`;

const InvoiceGrid = styled(Form)`
  width: 100%;
  display: grid;
  grid-template-columns: 35% 30% 10% 20%;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: start;
`;

const TopAreaRight = styled.div`
  grid-area: 1 / 4 / 5 / 5;
  display: grid;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  margin-right: 20px;
`;

const SaveBox = styled.div`
  grid-area: 16 / 1 / 17 / 2;
  text-align: start;
  margin-left: 20px;
`;

const Address = styled.div`
  grid-area: 5 / 3 / 10 / 5;
  display: grid;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  margin-right: 20px;
`;

const Account = styled.div`
  grid-area: 5 / 2 / 10 / 3;
  display: grid;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
`;

const Company = styled.div`
  grid-area: 5 / 1 / 10 / 2;
  display: grid;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  margin-left: 20px;
`;

const InvoiceItems = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 3px;
  padding: 1rem;
  background-color: hsl(0, 0%, 95%);
  grid-area: 11 / 1 / 16 / -1;
  font-size: 14px;
  flex: 0;
  display: grid;
  grid-gap: 0.5rem 2rem;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-auto-rows: min-content;
  margin: 0 20px;
  @media print {
    flex-grow: 1;
  }
`;

const ItemHeader = styled.span`
  ${(props) => props.theme.fonts.heading4};
`;

const GridLine = styled.div`
  grid-column: 1 / span 3;
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGray};
`;

const ItemName = styled.span`
  font-family: monospace;
  font-size: 14px;
  padding: 0.5rem 1rem;
  background: #f4f4f4;

  color-adjust: exact;
  -webkit-print-color-adjust: exact;
`;

const Amount = styled.div`
  text-align: right;
`;

const Surcharge = styled(Amount)`
  grid-column: 1 / span 2;
  padding: 0.1rem 0;
  font-family: ${(props) => props.theme.fontFamilies.heading};
`;

const TotalSum = styled(Amount)`
  font-size: 14px;
`;

const TotalLabel = styled(TotalSum)`
  grid-column: 1 / span 2;
  ${(props) => props.theme.fonts.heading4};

  span {
    padding: 0 0.5rem;
  }
`;

export default EditInvoice;
