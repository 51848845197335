import { QUERY_KEYS } from './constants';
import useOptimisticMutation, { OptimisticMutationConfig } from './useOptimisticMutation';

export default function useCompilerOptimisticMutation<TArgs, TQueryData>(
  config: OptimisticMutationConfig<TArgs, TQueryData>
) {
  return useOptimisticMutation<TArgs, TQueryData>({
    ...config,
    onAllSettled(callbackArgs) {
      const { queryClient } = callbackArgs;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SERVER_VALIDATION] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });

      config.onAllSettled?.(callbackArgs);
    },
  });
}
