import LcaCustomBestWorstResultsGridPdf from 'components/v2/compiler/grids/lca-results/custom/LcaCustomBestWorstResultsGridPdf';
import LcaCustomBiogenicResultsGridPdf from 'components/v2/compiler/grids/lca-results/custom/LcaCustomBiogenicResultsGridPdf';
import LcaCustomResultsGridPdf from 'components/v2/compiler/grids/lca-results/custom/LcaCustomResultsGridPdf';
import { CUSTOM_RESULT_KEYS } from 'constants/constants';
import React from 'react';
import { LcaCustomResultModel, Option } from 'types/types';

import { PDF_H2, PDF_PAGE, PDF_Table } from './Styles.styled';

const LCACustomResultsPage: React.FunctionComponent<{
  lcaCustomResults: LcaCustomResultModel[];
  additionalPerformanceResultsTypeLookup: Option[];
  scenariosLookup: Option[];
}> = ({ lcaCustomResults, additionalPerformanceResultsTypeLookup, scenariosLookup }) => {
  return (
    <PDF_PAGE className="landscape">
      {lcaCustomResults?.map((customResult: LcaCustomResultModel) => (
        <React.Fragment key={customResult.id}>
          <PDF_H2>
            {additionalPerformanceResultsTypeLookup.labelByValue(customResult.resultType) ??
              'Additional environmental performance results'}
          </PDF_H2>
          <PDF_Table>
            <thead>
              <tr>
                <th style={{ width: '20%' }}></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customResult.resultType === CUSTOM_RESULT_KEYS.A4C4 && (
                <tr>
                  <td>Additional scenario</td>
                  <td>{scenariosLookup.labelByValue(customResult.scenarioId)}</td>
                </tr>
              )}

              {customResult.resultType === CUSTOM_RESULT_KEYS.BEST && (
                <tr>
                  <td>Best-case product</td>
                  <td>{customResult.bestCaseProduct}</td>
                </tr>
              )}

              {customResult.resultType === CUSTOM_RESULT_KEYS.WORST && (
                <tr>
                  <td>Worst-case product</td>
                  <td>{customResult.worstCaseProduct}</td>
                </tr>
              )}

              {customResult.resultType && (
                <tr>
                  <td>Description of the scenario/method</td>
                  <td>{customResult.scenarioDescription}</td>
                </tr>
              )}
            </tbody>
          </PDF_Table>
          <br />
          {[CUSTOM_RESULT_KEYS.A4C4, CUSTOM_RESULT_KEYS.MODELING, CUSTOM_RESULT_KEYS.ELECTRICITY].includes(
            customResult.resultType
          ) && <LcaCustomResultsGridPdf customResultId={customResult.id} />}

          {customResult.resultType === CUSTOM_RESULT_KEYS.BIOGENIC && (
            <LcaCustomBiogenicResultsGridPdf customResultId={customResult.id} />
          )}
          {[CUSTOM_RESULT_KEYS.BEST, CUSTOM_RESULT_KEYS.WORST].includes(customResult.resultType) && (
            <LcaCustomBestWorstResultsGridPdf customResultId={customResult.id} />
          )}
          <br />
          <br />
        </React.Fragment>
      ))}
    </PDF_PAGE>
  );
};

export default LCACustomResultsPage;
