import styled from 'styled-components';
import { ActionState } from 'services/EpdClient';

export const DeclinedLabel = ({ actionState }: any) => {
  return !actionState || actionState === ActionState.Enabled ? <LabelStyle>DECLINED</LabelStyle> : <></>;
};

export const LabelStyle = styled.div`
  width: 60px;
  height: 16px;
  display: table;
  margin: 10px 5px 5px 5px;
  padding: 0.25rem 0.75rem;
  background-color: #f6e5e4;
  color: #cc302b;
  font-size: 12px;
  border-radius: 2px;
`;
export default DeclinedLabel;
