import HTMLEditor from 'components/form/HTMLEditor';
import { customToolbarEpd } from 'components/form/customToolbar';
import TitleAndBack from 'components/v2/TitleAndBack';
import { PrimaryButton, SecondaryButton, ToolButton } from 'components/v2/buttons';
import CollectionEpdList from 'components/v2/collections/CollectionEpdList';
import CollectionGuide from 'components/v2/collections/CollectionGuide';
import CollectionImageUpload from 'components/v2/collections/CollectionImageUpload';
import EpdItem from 'components/v2/collections/EpdItem';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import { confirmQuestion } from 'components/v2/confirm-dialog/ConfirmQuestion';
import { HelpBox } from 'components/v2/help-boxes';
import InfoIcon from 'components/v2/icons/TooltipInfoIcon';
import { toaster } from 'components/v2/toast';
import { ProcessStatus } from 'constants/constants';
import { Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Field, Form, Formik, FormikValues } from 'formik';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AuthService from 'services/AuthService';
import CollectionServiceV2 from 'services/CollectionServiceV2';
import CompanyService from 'services/CompanyService';
import {
  AccountModel,
  CollectionModel,
  EPDCollectionStatus,
  EPDModel,
  FileParameter,
  SaveCollectionModel,
} from 'services/EpdClient';
import EpdService from 'services/EpdService';
import MembershipService from 'services/MembershipService';
import styled, { css } from 'styled-components';
import { Container, Label } from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';
import { MainViewFull, WizardHeaderGrid } from 'styles/v2/Styles.styled';
import * as Yup from 'yup';

import { withGreyBackground } from '../WithGreyBackground';
import { InfoErrorContainer } from 'components/v2/help-boxes/InfoErrorContainer';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  description: Yup.string().test('is-not-empty', ' ', (value: any) => value && value.replace(/<[^>]*>/g, '').trim() !== ''),
});

const initialValues: CollectionModel = {
  id: null,
  name: '',
  description: '',
  status: EPDCollectionStatus.Created,
};

const EditCollection: React.FunctionComponent = () => {
  const [collection, setCollection] = React.useState<CollectionModel | undefined>(undefined);
  const [account, setAccount] = useState<AccountModel>({});
  const { changeCompany } = React.useContext(CompanyContext);
  const [allEpds, setAllEpds] = useState<EPDModel[]>([]);
  const [currentEpds, setCurrentEpds] = useState<EPDModel[]>([]);
  const [currentImage, setCurrentImage] = useState<any>();
  const [currentImageFile, setCurrentImageFile] = useState<any>();
  const [popupAddEpdList, setPopupAddEpdList] = useState<EPDModel[]>([]);
  const [filterText, setFilterText] = useState('');
  const history = useHistory();
  const { t } = useTranslation();
  const { companyId } = React.useContext(CompanyContext);
  const visibleEditorStyle = {
    border: '1px solid #F1F1F1',
    padding: '0 1rem',
    fontSize: '14px',
    height: 'fit-content',
    minHeight: '100px',
  };
  const [openedEpdChooser, setOpenedEpdChooser] = useState<boolean>(false);
  const formRef = useRef<FormikValues | any>();
  const [processStatus, setProcessStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleAddEpd = async () => {
    setCurrentEpds([
      ...currentEpds,
      ...popupAddEpdList.map((x: EPDModel) => {
        return { id: x.id, epdStatus: x.status, fullNumber: x.registrationNumber, name: x.name };
      }),
    ] as EPDModel[]);

    setPopupAddEpdList([]);
    setOpenedEpdChooser(false);
  };

  const handleCancelAddEpd = async () => {
    setOpenedEpdChooser(false);
    setPopupAddEpdList([]);
  };

  let { collectionId } = useParams<{
    collectionId: string;
  }>();

  const onRemoveFile = async (fileId: string) => {
    setCurrentImage(null);
    setCurrentImageFile(null);
  };

  const onChangeCollection = async (propertyName: string, val: FileParameter) => {
    if (!collectionId) {
      return;
    }
    await CollectionServiceV2.addCollectionFile(collectionId, propertyName, val);
  };

  const onRemoveEpd = async (epdId: string) => {
    setCurrentEpds(currentEpds.filter((item) => item.id !== epdId));
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1000000) {
        toaster({ severity: 'error', summary: 'Image too large', details: 'Image size is more than 1MB.' });
      } else {
        setCurrentImageFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setCurrentImage(reader.result);
        };
      }
    }
  };

  const uploadImage = async (propertyName: string) => {
    if (!currentImageFile || !collectionId) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const fileBlob = reader.result as ArrayBuffer;
      if (fileBlob) {
        const blob = new Blob([new Uint8Array(fileBlob)], { type: currentImageFile.type });
        await onChangeCollection(propertyName, {
          fileName: currentImageFile.name,
          data: blob,
        });
        await fetchCollection();
      }
    };
    await reader.readAsArrayBuffer(currentImageFile);
  };

  const fetchCollection = async () => {
    try {
      const result: any = await CollectionServiceV2.getCollection(collectionId);
      setCollection(result);
      setCurrentEpds(result?.epDs);
      setCurrentImage(result?.image);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEpds = async () => {
    if (!account?.id) {
      setAllEpds([]);
      return;
    }

    try {
      const result = await EpdService.getEpds(account.id, companyId, undefined, undefined);
      setAllEpds(result);
    } catch {}
  };

  const fetchAccount = async () => {
    if (companyId) {
      try {
        const res = await CompanyService.getCompany(companyId);
        changeCompany?.(companyId);
        res?.accounts && setAccount(res?.accounts[0]);
      } catch {}
    } else {
    }
  };

  useEffect(() => {
    fetchAccount();
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (collectionId != 'new') {
      fetchCollection();
      fetchEpds();
      setIsEditMode(false);
    } else {
      setCollection(initialValues);
      fetchEpds();
      setIsEditMode(true);
    }
  }, [collectionId, account]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!collection) {
    return <div>${t('messages.loading')}</div>;
  }

  const onSave = async (values: any) => {
    try {
      if (!currentImage) {
        toaster({ severity: 'error', summary: 'No Image', details: 'Collection image is mandatory' });
        return;
      }
      setProcessStatus(ProcessStatus.Fetching);

      const { ...data }: SaveCollectionModel = {
        ...values,
        companyId,
        epdIds: currentEpds.map((i) => i.id),
        image: currentImageFile,
      };
      const result = await CollectionServiceV2.saveCollection(data);
      if (result && result.id) {
        collectionId = result.id;
      }
      await uploadImage('ProductImage');

      toaster({ severity: 'success', summary: 'Success', details: 'Collection has been saved' });
      setCollection(result);
      setIsEditMode(false);
      setProcessStatus(ProcessStatus.Success);
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: JSON.stringify(error) });
      setProcessStatus(ProcessStatus.Error);
    }
  };

  const handlePublish = async (id: string) => {
    try {
      const isOwner =
        (
          await MembershipService.getMembershipsByUserAndCompanyAndRole(
            companyId || '',
            Role.EPDOwner,
            AuthService.getUser()?.id!
          )
        ).length > 0;

      if (isOwner || AuthService.isAdmin()) {
        setProcessStatus(ProcessStatus.Fetching);
        const result = await CollectionServiceV2.publishCollection(id);
        toaster({
          severity: 'info',
          summary: 'The collection has been published!',
          details: 'Now it is available on EPD website in EPD library.',
        });
        setCollection(result);
        setProcessStatus(ProcessStatus.Success);
      } else {
        toaster({
          severity: 'error',
          summary: 'Collection not published!',
          details:
            'Unfortunately, you are not authorized to publish this collection. Please contact the EPD owner who is authorized to publish this.',
        });
      }
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: JSON.stringify(error) });
      setProcessStatus(ProcessStatus.Error);
    }
  };

  const handleDepublish = async (id: string) => {
    try {
      const isOwner =
        (
          await MembershipService.getMembershipsByUserAndCompanyAndRole(
            companyId || '',
            Role.EPDOwner,
            AuthService.getUser()?.id!
          )
        ).length > 0;
      if (isOwner || AuthService.isAdmin()) {
        setProcessStatus(ProcessStatus.Fetching);
        const result = await CollectionServiceV2.depublishCollection(id);
        toaster({
          severity: 'info',
          summary: 'Success',
          details: 'The collection has been depublished.',
        });
        setCollection(result);
        setProcessStatus(ProcessStatus.Success);
      } else {
        toaster({
          severity: 'error',
          summary: 'Collection not depublished!',
          details:
            'Unfortunately, you are not authorized to depublish this collection. Please contact the EPD owner who is authorized to depublish this.',
        });
      }
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: JSON.stringify(error) });
      setProcessStatus(ProcessStatus.Error);
    }
  };

  const handleDelete = async (values: any) => {
    try {
      setProcessStatus(ProcessStatus.Fetching);
      const c: SaveCollectionModel = {
        ...values,
        companyId,
        status: EPDCollectionStatus.Created,
      };

      const result = await CollectionServiceV2.saveCollection(c);
      toaster({
        severity: 'info',
        summary: 'Success',
        details: 'The collection has been depublished.',
      });
      setCollection(result);
      setProcessStatus(ProcessStatus.Success);
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: JSON.stringify(error) });
      setProcessStatus(ProcessStatus.Error);
    }
  };

  const acceptCollectionDelete = async () => {
    try {
      await CollectionServiceV2.deleteCollection(collection.id!);
      toaster({
        severity: 'info',
        summary: 'Success',
        details: 'The collection has been deleted.',
      });
      // TODO refresh collection list
      history.goBack();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: JSON.stringify(error) });
      console.error(error);
    }
  };

  const isFormFieldInvalid = (name: string) =>
    formRef.current && !!(formRef.current.touched[name] && formRef.current.errors[name]);

  const epdChooserFooterContent = (
    <div>
      <Button label="Cancel" onClick={handleCancelAddEpd} className="p-button-text" />
      <Button label="Add" onClick={handleAddEpd} autoFocus />
    </div>
  );
  return (
    <Container>
      <ConfirmDialog />
      {processStatus == ProcessStatus.Fetching && <Spinner />}
      <MainViewFull>
        <WizardHeaderGrid>
          <TitleAndBack
            title={collection.id == null ? 'Create collection' : collection.name!}
            backTo="Back to collections"
            onBack={() => history.goBack()}
          />
          <div className="flex align-items-center gap-3">
            {isEditMode && (
              <>
                <SecondaryButton
                  label="Cancel"
                  outlined
                  disabled={processStatus == ProcessStatus.Fetching}
                  onClick={() => {
                    confirmQuestion({
                      caption: collection.id ? 'Do you want to cancel edit' : 'Do you want to cancel collection creation?',
                      description: collection.id ? (
                        <div>
                          The collection edits will not be saved.<p>All edits will be lost.</p>
                        </div>
                      ) : (
                        <div>
                          The collection creation will be cancelled.<p>All the data will be lost.</p>
                        </div>
                      ),
                      acceptLabel: 'Cancel',
                      rejectLabel: 'Discard',
                      onConfirm: history.goBack,
                    });
                  }}
                />
                <PrimaryButton
                  label="Save collection"
                  disabled={processStatus == ProcessStatus.Fetching}
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.handleSubmit();
                    }
                  }}
                />
              </>
            )}

            {!isEditMode && collection.status === EPDCollectionStatus.Published && (
              <>
                <SecondaryButton
                  label="Depublish"
                  outlined
                  onClick={() => {
                    handleDepublish(formRef.current!.values.id);
                  }}
                />
              </>
            )}

            {!isEditMode && collection.status !== EPDCollectionStatus.Published && (
              <>
                <PrimaryButton
                  label="Publish"
                  onClick={() => {
                    handlePublish(formRef.current!.values.id);
                  }}
                  disabled={!collection.hasPublishedEPDs}
                />
              </>
            )}
            {!isEditMode && (
              <>
                <ToolButton
                  icon="pi pi-file-edit text-2xl"
                  aria-label="Edit"
                  onClick={() => {
                    setIsEditMode(true);
                  }}
                />
                <ToolButton
                  icon="pi pi-trash text-2xl"
                  aria-label="Delete"
                  onClick={() => {
                    confirmDelete({
                      caption: 'Are you sure you want to delete the collection?',
                      description: 'The collection will be deleted and we will not be able to restore it.',
                      onConfirm: () => {
                        acceptCollectionDelete();
                      },
                    });
                  }}
                />
              </>
            )}
          </div>
        </WizardHeaderGrid>

        {isEditMode && <CollectionGuide />}

        {(!collection.hasPublishedEPDs && !isEditMode) && (
          <InfoErrorContainer
            infoText={'This collection cannot be published yet, as none of the selected EPDs are published.'}
          />
        )}

        <CollectionForm>
          <Formik
            innerRef={formRef!}
            enableReinitialize
            initialValues={collection}
            onSubmit={onSave}
            validationSchema={validationSchema}
          >
            {({ values, handleChange }) => (
              <Form>
                <Column>
                  <FixedRow>
                    <FixedLabel>
                      <Label required>EPD COLLECTION NAME</Label>
                      <InfoIcon content={t('manageCollections.tooltips.title')} />
                    </FixedLabel>
                    <Field
                      as={InputText}
                      maxLength={300}
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      className={isFormFieldInvalid('name') && 'p-invalid'}
                      disabled={!isEditMode}
                    />
                  </FixedRow>
                </Column>

                <Column>
                  <FixedRow>
                    <FixedLabel>
                      <Label required> EPD Collection description</Label>
                      <InfoIcon content={t('manageCollections.tooltips.profile')} />
                    </FixedLabel>
                    <HTMLEditor
                      name="description"
                      editorStyle={visibleEditorStyle}
                      toolbarHidden={!isEditMode}
                      toolbar={customToolbarEpd}
                      toolbarClassName={'p-test'}
                      readOnly={!isEditMode}
                      stripPastedStyles={true}
                      editorClassName={isFormFieldInvalid('description') && 'p-invalid'}
                    />
                  </FixedRow>
                </Column>

                <Column>
                  <FixedRow>
                    <FixedLabel>
                      <Label required>Collection cover image</Label>
                      <InfoIcon content={t('manageCollections.tooltips.image')} />
                    </FixedLabel>

                    {isEditMode && (
                      <HelpBox>
                        <span>
                          The added image will be used as a cover to present your collection in the EPD Library at{' '}
                          <a target="_blank" rel="noopener noreferrer" href="http://www.environdec.com">
                            www.environdec.com.
                          </a>
                        </span>
                        File formats: PNG, JPG. File sizes: the recommend file size is less than 1 MB per file uploaded.
                      </HelpBox>
                    )}

                    <UploadBox>
                      <CollectionImageUpload
                        name={t('manageCollections.image')}
                        image={currentImage}
                        onRemoveImage={onRemoveFile}
                        disabled={!isEditMode}
                        onChange={handleImageChange}
                      />
                    </UploadBox>
                  </FixedRow>
                </Column>
              </Form>
            )}
          </Formik>

          <Column>
            <FixedRow>
              <FixedLabel>
                <Label>Add EPD to the collection</Label>
                <InfoIcon content={t('...')} />
              </FixedLabel>

              {isEditMode && <HelpBox>Please select the EPD from your library.</HelpBox>}

              {isEditMode && (
                <div>
                  <Button label="+ Add EPD" text onClick={() => setOpenedEpdChooser(true)} />
                </div>
              )}
              <Dialog
                header="Add EPD"
                visible={openedEpdChooser}
                style={{ width: '50vw' }}
                onHide={() => setOpenedEpdChooser(false)}
                footer={epdChooserFooterContent}
              >
                <div>
                  <SelectAllPanel>
                    <Button
                      label="Select all"
                      text
                      onClick={() =>
                        setPopupAddEpdList(
                          allEpds.filter(
                            (item) =>
                              !currentEpds.find((i) => i.id === item.id) &&
                              item.status?.toLocaleLowerCase() !== 'deregistered' &&
                              item.name?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
                          )
                        )
                      }
                    />
                  </SelectAllPanel>
                  <ListBox
                    filter
                    multiple
                    filterValue={filterText}
                    onFilterValueChange={(e) => setFilterText(e.value)}
                    value={popupAddEpdList}
                    onChange={(e) => setPopupAddEpdList(e.value)}
                    options={allEpds.filter(
                      (item) =>
                        !currentEpds.find((i) => i.id === item.id) && item.status?.toLocaleLowerCase() !== 'deregistered'
                    )}
                    optionLabel="name"
                    className="w-full"
                    itemTemplate={(x) => EpdItem(x, popupAddEpdList.includes(x))}
                    listStyle={{ maxHeight: '50vh' }}
                  />
                </div>
              </Dialog>
              <CollectionEpdList epds={currentEpds} isEditMode={isEditMode} onRemoveEpd={onRemoveEpd} />
            </FixedRow>
          </Column>
        </CollectionForm>
      </MainViewFull>
    </Container>
  );
};

const SelectAllPanel = styled.div`
  margin-top: 0.75rem;
  float: right;
  margin-right: 1rem;
`;

const CollectionForm = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 1rem 2rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  box-sizing: border-box;
  margin: 1rem 0;
`;

export const FixedRow = styled.div`
  grid-column-gap: 10px;
  display: grid;
  grid-template-columns: 100%;
`;

export const FixedLabel = styled.label<{ required?: boolean }>`
  font-weight: normal;
  text-transform: uppercase;
  ${(props) => props.theme.fonts.textSmall}
  flex: 0 0 200px;
  align-self: auto;
  margin: 0.5rem 1rem 0.5rem 0; /*risky change? */

  > svg {
    margin: 0 0.5rem;
  }

  > div + svg {
    margin: 0;
  }
  ${(props) =>
    props.required &&
    css`
      &:after {
        content: ' *';
      }
    `}
`;

const UploadBox = styled.div`
  display: flex;
`;

export default withGreyBackground(EditCollection);

const Preview = ({ src, onDelete }: any) => {
  return (
    <div className="p-mt-3">
      <img src={src} alt="Preview" className="p-d-block p-mb-2" style={{ maxWidth: '100%' }} />
      <Button label="Remove" icon="pi pi-times" onClick={onDelete} />
    </div>
  );
};
