import { useField } from 'formik';
import React from 'react';
import Select from 'react-select';
import { ErrorMessage, Label } from 'styles/Styles.styled';

const ReactSelect = ({ label, options, isMulti, onChange, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const innerOnChange = (value: any) => {
    let newValue = onChange ? onChange(value) : value;
    if (isMulti) {
      setValue(newValue ?? []);
    } else {
      setValue(newValue);
    }
  };

  return (
    <>
      {label && <Label htmlFor={props.id || props.name}>{label}</Label>}
      <Select
        options={options}
        name={field.name}
        value={field.value}
        onChange={innerOnChange}
        onBlur={field.onBlur}
        isMulti={isMulti}
        {...props}
      />
      {meta.touched && meta.error && !props.supressErrors ? (
        <ErrorMessage style={{ marginTop: 0, marginBottom: '0.75rem' }}>{meta.error}</ErrorMessage>
      ) : null}
    </>
  );
};

export default ReactSelect;
