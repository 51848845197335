import { AdminUsersClient, UpdateUserModel, SetUserPasswordModel } from '../EpdClient';
const client = new AdminUsersClient(process.env.REACT_APP_API_URL);

class AdminUserService {
  static getUsers = async (filter?: string, includeArchived?: boolean) => {
    return await client.getAll(filter, includeArchived);
  };
  static getUser = async (id?: string) => {
    return await client.get(id as any);
  };
  static updateUser = async (id: string, data?: UpdateUserModel) => {
    return await client.update(id, data as UpdateUserModel);
  };
  static setUserPassword = async (id: string, data?: SetUserPasswordModel) => {
    return await client.setPassword(id, data as SetUserPasswordModel);
  };
}

export default AdminUserService;
