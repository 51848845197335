import { PDFImagesView } from 'components/v2/images-upload/PDFImagesView';
import React from 'react';
import { SystemBoundaryModel } from 'types/types';

import { PDF_H1, PDF_P, PDF_PAGE } from './Styles.styled';

const ProcessFlowDescriptionPage: React.FunctionComponent<{
  systemBoundaryData?: SystemBoundaryModel;
}> = ({ systemBoundaryData }) => {
  return (
    <PDF_PAGE>
      <PDF_H1>Description of the process flow </PDF_H1>
      <PDF_P>{systemBoundaryData?.processFlowDescription}</PDF_P>
      {systemBoundaryData?.processFlowImages && systemBoundaryData?.processFlowImages.length > 0 && (
        <PDFImagesView images={systemBoundaryData.processFlowImages} />
      )}
    </PDF_PAGE>
  );
};

export default ProcessFlowDescriptionPage;
