export const SortComparator = (a: any, b: any, direction: number) => {
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  if (a > b) {
    return direction;
  }
  if (a < b) {
    return -direction;
  }
  return 0;
};
