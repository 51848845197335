import styled from 'styled-components';

export const PanelTitle = styled.div`
  ${(props) => props.theme.typography.headerCaptionSemiBold}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.75rem 0rem;
  margin: 0;
`;

export const ErrorAlertPanel = styled.div`
  background: #f6e5e4;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  color: #cc302b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400px;
  line-height: 20px;
`;
