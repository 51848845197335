import { AdminEPDsClient, MoveEPDModel } from '../EpdClient';
const client = new AdminEPDsClient(process.env.REACT_APP_API_URL);

class AdminEpdService {
  static findEpds = async (companyId?: string, companyName?: string, title?: string, registrationNumber?: number) => {
    return await client.findEPDs(companyId as string, companyName as string, title as string, registrationNumber as number);
  };
  static moveEpd = async (epdId: string, toAccountId: string) => {
    const move: MoveEPDModel = { epdId, toAccountId };
    return await client.move(epdId, move);
  };
}

export default AdminEpdService;
