import React from 'react';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { PanelHeaderTemplateOptions } from 'primereact/panel';
import styled from 'styled-components';
import { PanelTitle } from '../Styles.styled';
import ToggleButton from './ToggleButton';

interface Props {
  options: PanelHeaderTemplateOptions;
  title: string | undefined;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  error?: any;
  headerActionsNode: React.ReactNode;
}

const ToggleablePanelHeader: React.FC<Props> = ({ options, title, tooltip, tooltipPosition, error, headerActionsNode }) => {
  return (
    <div className={`${options.className} justify-content-start w-full`}>
      <ElementsContainer>
        <PanelTitle>{title}</PanelTitle>
        <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'center' }}>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {error && <TooltipErrorIcon content={error} containerStyle={{ position: 'relative' }} />}
          <ToggleButton options={options} />
        </div>
        {headerActionsNode && <div>{headerActionsNode}</div>}
      </ElementsContainer>
    </div>
  );
};

const ElementsContainer = styled.div`
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 10px;
`;

export default ToggleablePanelHeader;
