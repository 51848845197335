import { PanelHeaderTemplateOptions } from 'primereact/panel';

const ToggleButton: React.FunctionComponent<{ options: PanelHeaderTemplateOptions; withMargins?: boolean }> = ({
  options,
  withMargins,
}) => {
  return (
    <button
      className={options.togglerClassName}
      onClick={options.onTogglerClick}
      style={withMargins ? { margin: '0 0.5rem' } : undefined}
    >
      <span className={options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'} />
    </button>
  );
};

export default ToggleButton;
