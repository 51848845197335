import React from 'react';
import { useEpdVersionHistoryData } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySummaryGrid, { SummaryGridRow } from '../VersionHistorySummaryGrid';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';

const NOT_ASSIGNED = 'N/A';

const VersionHistorySystemBoundaryGrid: React.FC<{}> = () => {
  const { dictionaries, systemBoundary } = useEpdVersionHistoryData();

  const possibleRows: (SummaryGridRow | false)[] = [
    {
      name: 'Description of the system boundary',
      value: (() => {
        if (!systemBoundary?.description) {
          return NOT_ASSIGNED;
        }
        const description = dictionaries.find(
          (d) =>
            d.dictionaryCode === EPD_DICTIONARY_CODES.SYSTEM_BOUNDARY_DESCRIPTION && d.key === systemBoundary.description
        );
        if (!description) {
          return NOT_ASSIGNED;
        }
        return description.value;
      })(),
    },
    {
      name: 'Excluded modules',
      value: (() => {
        if (typeof systemBoundary?.hasExcludedModules !== 'boolean') {
          return NOT_ASSIGNED;
        }
        return systemBoundary.hasExcludedModules
          ? 'Yes, there is the excluded module(s)'
          : 'No, there is no excluded module(s)';
      })(),
    },
    !!systemBoundary?.hasExcludedModules && {
      name: 'Justification for the omission of modules',
      value: systemBoundary.excludedModulesJustification ?? NOT_ASSIGNED,
    },
    {
      name: 'Description of the process flow',
      value: systemBoundary?.processFlowDescription ?? '',
    },
  ];

  const rows = possibleRows.filter((r) => r) as SummaryGridRow[];

  return <VersionHistorySummaryGrid rows={rows} />;
};

export default VersionHistorySystemBoundaryGrid;
