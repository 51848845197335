import React from 'react';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';

type Props = {
  visible: boolean;
  children: React.ReactNode;
  title: React.ReactNode;
  onHide(): void;
};

const MaximizedDialog: React.FC<Props> = ({ visible, children, title, onHide }) => {
  if (!visible) {
    // This is done intentionally despite Dialog having its own visibility prop.
    // Otherwise, there's an issue with page scrollbar or full screen size of the dialog
    return null;
  }
  return (
    <StyledDialog header={title} visible={visible} onHide={onHide} maximizable maximized>
      {children}
    </StyledDialog>
  );
};

export default MaximizedDialog;

const StyledDialog = styled(Dialog)`
  & .p-dialog-header-maximize {
    display: none;
  }
  & .p-dialog-content {
    padding: 10px 30px 30px 30px;
  }
`;
