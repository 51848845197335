import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MembershipModel } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';
import styled from 'styled-components';
import { ErrorText, InfoText } from 'styles/Styles.styled';

import { ProcessStatus, Role } from '../../constants/constants';
import AddNewMemberRow from './AddNewMemberRow';
import MemberInvites from './MemberInvites';
import MemberRequests from './MemberRequests';
import MemberRow from './MemberRow';

const ManageMembers: React.FunctionComponent = () => {
  const { companyId } = useContext(CompanyContext);
  const [members, setMembers] = React.useState<MembershipModel[]>([]);
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [triggerToUpdateInvites, setTriggerToUpdateInvites] = React.useState(0);
  // @ts-ignore
  const { t } = useTranslation();
  const handlerInvitedMembers = async () => {
    setTriggerToUpdateInvites((triggerToUpdateInvites) => triggerToUpdateInvites + 1);
  };

  useEffect(() => {
    const fetchMembers = async () => {
      setStatus(ProcessStatus.Fetching);
      try {
        const members = await MembershipService.getMembershipsByCompany(companyId as string, undefined);
        setMembers(members);
        setStatus(ProcessStatus.Success);
      } catch {
        setStatus(ProcessStatus.Error);
      }
    };

    fetchMembers();
  }, [companyId]);

  return (
    <div>
      {members.map((member: any) => (
        <MemberRow member={member} key={member.id} />
      ))}
      {status === ProcessStatus.Success && members.length === 0 && (
        <InfoText>{t('manageMembers.messages.noMembers')}</InfoText>
      )}
      {status === ProcessStatus.Error && <ErrorText>{t('manageMembers.errors.unFetchedMembers')}</ErrorText>}
      {status === ProcessStatus.Fetching && <InfoText>{t('manageMembers.messages.fetchMembers')}</InfoText>}
      <br />
      <AddNewMemberRow companyId={companyId as string} heading={t('invite')} handlerInvitedMembers={handlerInvitedMembers} />
      <MemberInvites companyId={companyId as string} triggerToUpdateInvites={triggerToUpdateInvites} />
      <br />
      <MemberRequests companyId={companyId as string} />
    </div>
  );
};

export const RoleOptions: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const roleOptions = [
    <option value={Role.EPDOwner} key={0}>
      {t('roleOptions.epdOwner')}
    </option>,
    <option value={Role.EPDDeveloper} key={1}>
      {t('roleOptions.epdDeveloper')}
    </option>,
    <option disabled value={Role.EPDVerifier} key={2}>
      {t('roleOptions.epdVerifier')}
    </option>,
    //   <option value="6362962c-5d80-e911-ad05-000d3ab8d68e" key={3}>
    //     LCA Author
    //   </option>,
  ];
  return <>{roleOptions}</>;
};

export const TableRow = styled.div<{ isSubmitting?: boolean }>`
  padding: 1rem;
  margin: 1px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.regionColorLight};
  display: flex;
  justify-content: space-between;
  opacity: ${(props) => (props.isSubmitting ? 0.5 : 1)};
  align-items: center;
`;

export default ManageMembers;
