import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required(),
  approvalDate: Yup.date().required(),
  validityDate: Yup.date().nullable(),
  verifierName: Yup.string().nullable(),
  preverifiedToolVersions: Yup.array(
    Yup.object({
      name: Yup.string().required('Version name is required'),
      verifierName: Yup.string().required('Verifier name is required'),
    })
  ).max(100),
});
