import { useGetEpdDataQualityAssessment } from 'services/api/queries';
import { PDF_H2, PDF_P } from 'pages/v2/compiler/PDF/Styles.styled';
import DataQualityAssessmentAndReferenceYearsGridPdf from './DataQualityAssessmentAndReferenceYearsGridPdf';

type TProps = {
  epdVersionId: string;
};

const DataQualityAssessmentAndReferenceYearsPdf = ({ epdVersionId }: TProps) => {
  const dataQualityAssessmentAndReferenceYears = useGetEpdDataQualityAssessment(epdVersionId).data;

  return (
    <div style={{ marginBottom: 20 }}>
      <PDF_H2>Description of data quality assessment and reference years</PDF_H2>
      <PDF_P>{dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentSummary}</PDF_P>
      <DataQualityAssessmentAndReferenceYearsGridPdf
        dataQualityAssessmentItems={dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentItems}
      />
      <PDF_P>
        The share of primary data is calculated based on GWP-GHG results. It is a simplified indicator for data quality that
        do not capture all relevant aspects of data quality. The indicator is not comparable across product categories.
      </PDF_P>
    </div>
  );
};

export default DataQualityAssessmentAndReferenceYearsPdf;
