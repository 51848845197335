import FindCompany from 'components/company/FindCompany';
import InformationalDialog from 'components/dialogs/InformationalDialog';
import RadioButton from 'components/form/RadioButton';
import SelectInput from 'components/form/SelectInput';
import TextInput from 'components/form/TextInput';
import { EUCountries, REG_PHONE, REG_URL, Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Formik } from 'formik';
import helpIcon from 'images/icons/svg/info.svg';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import CountriesService from 'services/CountriesService';
import { CompanySize, CountryModel, CreateCompanyModel, LoginModel } from 'services/EpdClient';
import styled from 'styled-components';
import {
  A,
  Button,
  Container,
  ErrorText,
  H2,
  Half,
  InfoBox,
  StyledForm,
  StyledLink,
  SuccessText,
  Text,
} from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

const RegisterCompany: FunctionComponent = () => {
  const { company, licensee, changeCompany } = useContext(CompanyContext);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [showCreatedLicenseeCompanyDialog, setShowCreatedLicenseeCompanyDialog] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>();
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const { t } = useTranslation();
  const createInitialValues: CreateCompanyModel = {
    name: '',
    roleId: Role.EPDOwner,
    website: '',
    countryId: '',
    vat: '',
    companySize: undefined,
  };

  const createSchema = Yup.object({
    name: Yup.string().required(t('messages.required')),
    roleId: Yup.string(),
    website: Yup.string().matches(REG_URL, t('messages.invalidUrl')),
    phone: (Yup.string() as any).matches(REG_PHONE, t('messages.invalidPhone')).nullable(),
    countryId: (Yup.string() as any).when('roleId', {
      is: (roleId: string) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    vat: (Yup.string() as any).when(['countryId', 'roleId'], {
      is: (countryId: string, roleId: string) =>
        roleId === Role.EPDOwner &&
        countryId &&
        EUCountries.includes(countries.find((x) => x.id === countryId)?.alpha2Code || ''),
      then: Yup.string().required(t('messages.requiredVatInEU')),
    }),
    companySize: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
  });

  const createCompany = async (values: CreateCompanyModel) => {
    setServerError('');
    if (values.roleId === Role.EPDDeveloper) {
      values.companySize = undefined;
      values.countryId = undefined;
      values.vat = undefined;
    }
    try {
      const result = await CompanyService.createCompany(values);
      setSuccess(true);

      const currentUser = AuthService.getUser().email;
      if (currentUser) {
        changeCompany?.(result.id || '');
        await AuthService.relogin({ userName: currentUser, password: '' } as LoginModel);
      }
    } catch (e) {
      const error = PoorMansError(e);
      setServerError(error ? error : t('errors.wrong'));
    }
  };

  useEffect(() => {
    async function fetch() {
      const result = await CountriesService.getCountries();
      const emptyCountry: CountryModel = { id: undefined, name: '', alpha2Code: '' };
      setCountries([emptyCountry].concat(result));
    }
    fetch();
  }, []);

  useEffect(() => {
    if (success && licensee) {
      setShowCreatedLicenseeCompanyDialog(true);
    }
  }, [licensee]);

  return (
    <>
      <Container>
        <Half>
          <H2>{t('organization.register.title')}</H2>
          <Text style={{ marginBottom: '1.5rem' }}>{t('organization.register.description')}</Text>
          <Formik
            initialValues={createInitialValues}
            validationSchema={createSchema}
            onSubmit={(values) => createCompany(values)}
          >
            {({ isSubmitting, values }) => (
              <StyledForm>
                <TextInput required label={t('organization.name')} name="name" placeholder={t('organization.name')} />
                <Title>{t('organization.type')}</Title>
                <RadioButton
                  label={t('roleOptions.epdOwner')}
                  name="roleId"
                  id="epdOwner"
                  defaultChecked={true}
                  value={Role.EPDOwner}
                  tooltip={t('organization.tooltips.epdOwner')}
                />
                <RadioButton
                  label={t('roleOptions.epdDeveloper')}
                  name="roleId"
                  id="epdDeveloper"
                  value={Role.EPDDeveloper}
                  tooltip={t('organization.tooltips.epdDeveloper')}
                />
                <StyledInfoBox>
                  <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
                  <div dangerouslySetInnerHTML={{ __html: t('organization.register.moreInfo') }} />
                </StyledInfoBox>
                <Grid>
                  <TextInput label={t('website')} name="website" placeholder="http://www.environdec.com" />
                </Grid>
                {values.roleId === Role.EPDOwner && (
                  <>
                    <Grid>
                      <SelectInput required label={t('country')} name="countryId">
                        {countries.map((c) => (
                          <option value={c.id} key={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </SelectInput>
                    </Grid>
                    <Grid>
                      <TextInput label={t('vat')} name="vat" />
                    </Grid>
                    <Grid>
                      <SelectInput required label={t('organizationSize')} name="companySize">
                        {[''].concat(Object.values(CompanySize)).map((x) => (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        ))}
                      </SelectInput>
                    </Grid>
                  </>
                )}
                <Button disabled={isSubmitting || success}>
                  {isSubmitting ? t('organization.register.creating') : t('organization.register.create')}
                </Button>
              </StyledForm>
            )}
          </Formik>
          <br />
          {success && (
            <SuccessText>
              {t('organization.register.success')}
              <br />
              <br />
              <StyledLink to={EpdLinks.dashboard()}>{t('organization.register.goToDashboard')}</StyledLink>
            </SuccessText>
          )}
          {serverError && <ErrorText>{serverError}</ErrorText>}
        </Half>
        <NarrowHalf>
          <FindCompany />
        </NarrowHalf>
        <InformationalDialog
          isOpened={showCreatedLicenseeCompanyDialog && !!licensee?.localHomepage}
          header="Regional licensee country is detected"
          onHide={() => setShowCreatedLicenseeCompanyDialog(false)}
        >
          Thank you for registering with EPD International. We have various licensees around the world. You have selected a
          country located in the regional license holder {company?.address?.country}. The regional administrators will help
          you with all support regarding your EPD. To find the local support, please visit{' '}
          <LicenseeSiteLink href={licensee?.localHomepage}>{licensee?.localHomepage}</LicenseeSiteLink>
        </InformationalDialog>
      </Container>
    </>
  );
};

const NarrowHalf = styled(Half)`
  margin-top: 3rem;
  padding: 0 7rem 3rem;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.gray};
  ${(props) => props.theme.media.desktop} {
    margin-top: 0;
  }
`;

const Grid = styled.div`
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 40% auto;
  align-items: center;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 20% auto;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 28% auto;
  }
`;

const StyledInfoBox = styled(InfoBox)`
  margin-bottom: 2rem;
  a {
    text-decoration: none;
    color: black;
    :hover {
      text-decoration: underline;
    }
  }
  ${(props) => props.theme.media.desktop} {
    margin-bottom: 1rem;
  }
`;

const LicenseeSiteLink = styled(A)`
  color: ${(props) => props.theme.colors.primaryOrange};
`;

const Title = styled.text`
  font-weight: normal;
  margin: 1rem 0 0.5rem 0;
  text-transform: uppercase;
  ${(props) => props.theme.fonts.textSmall}
`;

export default RegisterCompany;
