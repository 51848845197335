import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import React, { useEffect, useMemo, useState } from 'react';
import { PCRModel } from 'services/EpdClient';
import PcrService from 'services/PcrService';
import { useUpdateEpdPCRInformation } from 'services/api/mutations';
import { useGetEpdPCRInformation } from 'services/api/queries';
import { FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { FieldPanelInput } from 'styles/v2/Styles.styled';

import CPcrSelector from './c-pcr-selector/CPcrSelector';
import PcrSelector from './pcr-selector/PcrSelector';

const PcrInformationPanel: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState?: any;
}> = ({ epdId, epdVersionId, validationState }) => {
  const pcrInformation = useGetEpdPCRInformation(epdVersionId).data;
  const updateMutation = useUpdateEpdPCRInformation(epdVersionId);
  const { errors, fieldsState } = validationState || {};

  const onChangeEpdPCRInformation = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId, versionId: epdVersionId, propertyName, newValue: val });
  };

  const [cpcrList, setCPcrList] = useState<PCRModel[]>([]);

  const getCPcrList = async () => {
    if (!pcrInformation?.pcr?.productCategories?.length) return;
    const selectedCategory = pcrInformation.pcr.productCategories[0];
    const res = await PcrService.findPcr('c-pcr', undefined, selectedCategory.id, false);
    setCPcrList(res);
  };

  useEffect(() => {
    if (!pcrInformation?.pcr) {
      setCPcrList([]);
      return;
    }
    getCPcrList();
  }, [pcrInformation?.pcr]);

  const handleChangePcr = async (pcr: PCRModel | undefined) => {
    if (!pcr?.id) return;
    onChangeEpdPCRInformation('PCR', pcr.id);
  };

  const handleRemovePcr = async () => {
    await updateMutation.mutateAsync({ epdId, versionId: epdVersionId, propertyName: 'PCR', newValue: undefined });
    await updateMutation.mutateAsync({ epdId, versionId: epdVersionId, propertyName: 'CPCR', newValue: undefined });
  };

  const handleChangeCPcr = async (cpcr: PCRModel | undefined) => {
    if (!cpcr?.id) return;
    onChangeEpdPCRInformation('CPCR', cpcr.id);
  };

  const handleRemoveCPcr = async () => {
    onChangeEpdPCRInformation('CPCR', undefined);
  };

  const selectedCpcrCategory = {
    value: pcrInformation?.cpcrProductCategory?.id,
    label: pcrInformation?.cpcrProductCategory?.title,
  };

  const cpcrCategoryOptions = useMemo(
    () =>
      pcrInformation?.cpcrProductCategoriesLookup?.map((x) => {
        return {
          value: x.id,
          label: x.title,
        };
      }) ?? [],
    [pcrInformation?.cpcrProductCategoriesLookup]
  );

  const showCpcrSelector = !!pcrInformation?.cpcr || !!cpcrList.length;

  const isAllUndefined = (obj: any) => {
    return Object.values(obj).every((value) => value === undefined);
  };

  return (
    <FieldPanelFullWidth>
      <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>Pcr Information</FieldPanelHeader>
      <FieldPanelInput>
        <PcrSelector
          label={'PCR'}
          tooltip={'Product category rules.'}
          tooltipPosition={'top | right'}
          pcr={pcrInformation?.pcr}
          onChangePcr={handleChangePcr}
          onRemovePcr={handleRemovePcr}
          error={errors?.pcr}
          required
          disabled={false}
        />
      </FieldPanelInput>
      {showCpcrSelector && (
        <FieldPanelInput>
          <CPcrSelector
            label={'C-PCR'}
            tooltip={'Complementary product category rules.'}
            tooltipPosition={'top | right'}
            cpcr={pcrInformation?.cpcr}
            cpcrList={cpcrList}
            onChangeCPcr={handleChangeCPcr}
            onRemoveCPcr={handleRemoveCPcr}
            required={false}
            disabled={false}
          />
        </FieldPanelInput>
      )}
      {!!pcrInformation?.cpcr && (
        <CompilerSelectControlledComponent
          label="Product category covered in the c-PCR"
          tooltip="If possible, choose a fitting product category for your product(s)."
          name="cpcrProductCategory"
          singleValueColor={isAllUndefined(selectedCpcrCategory) ? '#757575' : 'hsl(0, 0%, 20%)'}
          options={cpcrCategoryOptions}
          required
          value={isAllUndefined(selectedCpcrCategory) ? { label: 'Select' } : selectedCpcrCategory}
          onChanged={onChangeEpdPCRInformation}
          placeholder="Select"
          isClearable={!isAllUndefined(selectedCpcrCategory)}
          state={fieldsState}
          error={errors?.cpcrProductCategory}
        />
      )}
    </FieldPanelFullWidth>
  );
};

export default PcrInformationPanel;
