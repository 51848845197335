//Todo: Create a custom react-select-contorl?

export const customSelectStyles = {
  menu: (styles: any) => ({ ...styles }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 0,
    borderColor: 'gray',
    color: 'black',
    fontSize: '14px',
    minHeight: '49px',
    margin: '0.5rem 0 ',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: '100%',
  }),
};
