import React from 'react';
import VersionHistorySection from '../VersionHistorySection';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import SmartLabel from 'components/v2/labels/SmartLabel';
import DynamicGridPdf from '../../grids/lca-results/DynamicGridPdf';
import FixedGridPdf from '../../grids/lca-results/FixedGridPdf';

const VersionHistoryTechnicalPerformanceSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();
  const cpcrSpecificData = data?.cpcrSpecificData;
  const isThereAnythingToDisplay = !!cpcrSpecificData && cpcrSpecificData.data.length > 0;

  if (!isThereAnythingToDisplay) {
    return null;
  }

  return (
    <VersionHistorySection title="Technical characteristics and performance">
      {cpcrSpecificData.tables.map((t) => (
        <React.Fragment key={t.tableCode}>
          {t.isFixed ? (
            <div>
              <SmartLabel label={t.tableTitle} />
              <FixedGridPdf
                columns={cpcrSpecificData.columns.filter((x) => x.tableCode == t.tableCode)}
                value={cpcrSpecificData.data.filter((x) => x.tableCode == t.tableCode) || []}
              />
            </div>
          ) : (
            (cpcrSpecificData.data?.filter((x) => x.tableCode == t.tableCode) || []).length > 0 && (
              <div>
                <SmartLabel label={t.tableTitle} />
                <DynamicGridPdf
                  columns={cpcrSpecificData.columns.filter((x) => x.tableCode == t.tableCode)}
                  value={cpcrSpecificData.data.filter((x) => x.tableCode == t.tableCode) || []}
                />
              </div>
            )
          )}
        </React.Fragment>
      ))}
    </VersionHistorySection>
  );
};

export default VersionHistoryTechnicalPerformanceSection;
