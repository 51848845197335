import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import 'flag-icons/css/flag-icons.min.css';

const Languages: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [isClicked, setIsClicked] = React.useState<boolean>(false);

  const languages: any[] = [
    {
      code: t('header.languages.english.code'),
      name: t('header.languages.english.name'),
      countryCode: t('header.languages.english.countryCode'),
    },
    {
      code: t('header.languages.swedish.code'),
      name: t('header.languages.swedish.name'),
      countryCode: t('header.languages.swedish.countryCode'),
    },
  ];

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setIsClicked(true);
  };

  return (
    <ul style={{ paddingLeft: '1rem' }}>
      {languages.map((item: any) => (
        <LanguageItem key={item.code} isClicked={isClicked} onClick={() => changeLanguage(item.code)}>
          <div style={{ display: 'flex', cursor: 'pointer' }}>
            <span className={'fi fi-' + item.countryCode}></span>
            <span className="language-name" style={{ marginLeft: '1rem' }}>
              {item.name}
            </span>
          </div>
        </LanguageItem>
      ))}
    </ul>
  );
};

const LanguageItem = styled.li<{ isClicked: any }>`
  list-style: none;
  text-decoration: none;
  &:hover {
    color: ${(props) => (props.isClicked ? 'black' : props.theme.colors.regionColorLight)};
  }
`;

export default Languages;
