import React from 'react';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import PcrService from 'services/PcrService';
import { ButtonSmall } from 'styles/Styles.styled';

import { ProcessStatus } from '../../constants/constants';

const CreatePcr: React.FunctionComponent<{ goToEditPcrPage: boolean }> = ({ goToEditPcrPage }) => {
  const history = useHistory();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);

  const createPcr = async () => {
    if (goToEditPcrPage) {
      try {
        setStatus(ProcessStatus.Fetching);
        const newPcr = await PcrService.createPcr();
        const route = EpdLinks.editPcr(newPcr.id as string);
        history.push(route);
        setStatus(ProcessStatus.Success);
      } catch {
        setStatus(ProcessStatus.Error);
      }
    } else {
      try {
        setStatus(ProcessStatus.Fetching);
        const newPcr = await PcrService.createPcr();
        const route = EpdLinks.createPcr(newPcr.id as string);
        history.push(route);
        setStatus(ProcessStatus.Success);
      } catch {
        setStatus(ProcessStatus.Error);
      }
    }
  };

  return (
    <div style={{ marginLeft: '1rem' }}>
      <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} onClick={createPcr}>
        {status === ProcessStatus.Fetching ? 'Creating...' : '＋ New PCR'}
      </ButtonSmall>
    </div>
  );
};
export default CreatePcr;
