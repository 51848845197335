import { Column, ColumnEvent, ColumnProps } from 'primereact/column';
import React, { useState } from 'react';
import { useCreateDictionaryValue, useDeleteDictionaryValue, useUpdateDictionaryValue } from 'services/api/mutations';
import { useDictionaryValues } from 'services/api/queries';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { DictionaryValue, LcaResultModel } from 'types/types';

import { DataTableStyled, GridButton } from '../compiler/grids/StyledGrid';

type TProps = {
  dictionaryCode: string;
  columns?: ColumnProps[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
};
const DictionaryDetailsGrid = ({ dictionaryCode, columns }: TProps) => {
  const [selectedRows, setSelectedRows] = useState<LcaResultModel[] | null>(null);
  const rows = useDictionaryValues(dictionaryCode).data;
  const createMutation = useCreateDictionaryValue();
  const updateMutation = useUpdateDictionaryValue();
  const deleteMutation = useDeleteDictionaryValue();

  const handleAddRow = () => {
    createMutation.mutate({ dictionaryCode: dictionaryCode } as DictionaryValue);
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows(null);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Dictionary details</HeaderCaptionSemiBold>
      <span>
        <GridButton title="Add" icon="pi pi-plus" onClick={handleAddRow} disabled={!dictionaryCode} />{' '}
        <GridButton
          title="Delete"
          icon="pi pi-trash"
          onClick={handleDeleteSelected}
          disabled={!selectedRows || selectedRows.length === 0 || deleteMutation.isPending}
        />
      </span>
    </div>
  );

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  return (
    <DataTableStyled
      value={rows}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      header={header}
      selectionMode="checkbox"
      selection={selectedRows!}
      onSelectionChange={(e) => setSelectedRows(e.value as any[])}
      editMode="cell"
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />

      {columns?.map((x) => (
        <Column key={x.field} {...x} onCellEditComplete={x.editor ? onCellEditComplete : undefined} />
      ))}
    </DataTableStyled>
  );
};

export default DictionaryDetailsGrid;
