import styled from 'styled-components';

export const EpdLine = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.isOdd ? props.theme.colors.lightGray : 'white')};
  padding: 5px;
`;

export const Col = styled.div<any>`
  margin-right: 1rem;
  width: ${(props) => props?.width || '100%'};
`;
