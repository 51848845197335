export const pcrSelectStyles = {
  container: (styles: any) => ({
    ...styles,
    minWidth: '20rem',
  }),
  menu: (styles: any) => ({ ...styles }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 0,
    borderColor: 'gray',
    color: 'black',
    fontSize: '14px',
    minHeight: '49px',
    margin: '0.5rem 0 ',
    maxWidth: '60rem',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: '100%',
  }),
};
