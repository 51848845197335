import React from 'react';
import styled from 'styled-components';

const ConfirmModal = (props: any) => {
  return (
    <Overlay>
      <Modal>
        <Content>
           <div dangerouslySetInnerHTML={{ __html: props.description }} />
          <ButtonContainer>
            <CancelBox>
              <button onClick={props.onClickCancel}>{props.cancelButtonText}</button>
            </CancelBox>
            <ConfirmBox>
              <button onClick={props.onClickConfirm}>{props.confirmButtonText}</button>
            </ConfirmBox>
          </ButtonContainer>
        </Content>
      </Modal>
    </Overlay>
  );
};

export default ConfirmModal;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: rgb(128, 128, 128, 0.7);
  z-index: 1;
`;

const Modal = styled.div`
  display: block;
  z-index: 10;
  overflow: hidden;
  background-color: transparent;
  position: fixed;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 16px;
  text-align: center;
  border: 1px solid #888;
  width: 50vw;
  > p {
    font-size: 16px;
  }
  ${(props) => props.theme.media.tablet} {
    width: 25vw;
  }
`;

const ConfirmBox = styled.div`
  > button {
    font-size: 1rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    background: #cc0000;
    border-radius: 5px;
    opacity: 0.7;
    color: white;
    :hover {
      background: #b30000;
      cursor: pointer;
    }
  }
`;
const CancelBox = styled.div`
  padding-right: 1rem;
  > button {
    font-size: 1rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    background: rgb(128, 128, 128);
    border-radius: 5px;
    color: white;
    :hover {
      background: rgb(105, 105, 105);
      cursor: pointer;
    }
  }
`;
