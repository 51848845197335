import logo from 'assets/images/portal-logo.svg';
import Checkbox from 'components/form/Checkbox';
import PasswordInput from 'components/form/PasswordInput';
import { ProcessStatus } from 'constants/constants';
import { Formik } from 'formik';
import successIcon from 'images/icons/svg/fill/svg/success.svg';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import EpdRoutes from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { ResetPasswordModel } from 'services/EpdClient';
import styled from 'styled-components';
import {
  A,
  Button,
  CenterColumn,
  Container,
  ErrorText,
  Footer,
  H1,
  HeaderLogo,
  LoginFlowA,
  StyledForm,
  Text,
} from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import { acceptLabel } from './RegisterUser';

const ResetPassword: React.FunctionComponent = () => {
  const [error, setError] = React.useState<string>('');
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.Success);
  const location = useLocation();
  const values = queryString.parse(location.search);
  const userName = values?.userName?.toString();
  const token = values?.token?.toString();
  const { t } = useTranslation();

  type ExtendedResetPasswordModel = ResetPasswordModel | { acceptTerms: boolean };

  const initialValues: ExtendedResetPasswordModel = {
    userName: userName || '',
    password: '',
    confirmPassword: '',
    token: token || '',
    acceptTerms: false,
  };

  const resetPasswordSchema = Yup.object({
    password: Yup.string()
      .required(t('account.validation.passwordRequired'))
      .nullable()
      .matches(/\w*[a-z]\w*/, t('account.validation.invalidPasswordSmallLetter'))
      .matches(/\w*[A-Z]\w*/, t('account.validation.invalidPasswordCapitalLetter'))
      .matches(/\d/, t('account.validation.invalidPasswordNumber'))
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, t('account.validation.invalidPasswordSpecial'))
      .min(6, ({ min }) => t('account.validation.passwordLength', { min })),
    confirmPassword: Yup.string()
      .nullable()
      .oneOf([Yup.ref('password')], t('account.validation.passwordsNotMatch'))
      .required(t('account.validation.confirmPasswordRequired')),
    acceptTerms: Yup.boolean()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.'),
  });

  const resetPassword = async (values: ResetPasswordModel) => {
    try {
      setStatus(ProcessStatus.Fetching);
      await AuthService.resetPassword(values);
      setStatus(ProcessStatus.Success);
    } catch (e) {
      const error = PoorMansError(e);
      setStatus(ProcessStatus.Error);
      setError(error ? error : 'Something went wrong. Try again later.');
    }
  };

  return (
    <RegisterContainer>
      <HeaderLogo src={logo} alt="EPD International" />
      <Container>
        {!token ? (
          <CenterColumn>
            <H1>Reset your password</H1>
            <Text>Oops. It appears the link is you used to reset your password is faulty.</Text>
          </CenterColumn>
        ) : status === ProcessStatus.Success ? (
          <CenterColumn>
            <GreenIcon src={successIcon} alt="information" />
            <H1>Your password has been reset!</H1>
            <Text>
              An e-mail has been sent to you with a confirmation link. Follow the link to complete the registration.
            </Text>
            <div style={{ textAlign: 'center' }}>
              <LoginFlowA href={EpdRoutes.login}>Back to login page</LoginFlowA>
            </div>
          </CenterColumn>
        ) : (
          <CenterColumn>
            <H1>Reset your password</H1>
            <Text>Please select a new password and confirm it below.</Text>
            <Formik
              initialValues={initialValues}
              validationSchema={resetPasswordSchema}
              onSubmit={(values) => resetPassword(values as ResetPasswordModel)}
            >
              <StyledForm>
                <PasswordInput label={t('account.password')} name="password" />
                <p>
                  The password should contain a small letter, a capital letter, a number and a special symbol. The minimum
                  password length is 6 characters.
                </p>
                <PasswordInput label={t('account.confirmPassword')} name="confirmPassword" />
                <Checkbox label={acceptLabel} name="acceptTerms" id="acceptTerms" />
                <Button disabled={status === ProcessStatus.Fetching}>
                  {status === ProcessStatus.Fetching ? 'Resetting password...' : 'Set password'}
                </Button>

                {status === ProcessStatus.Error && <ErrorText>{error}</ErrorText>}
              </StyledForm>
            </Formik>
          </CenterColumn>
        )}
      </Container>
      <Footer>
        {t('footer.text')}
        <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>{'|'}</span>
        <A href={t('footer.link')}>{t('footer.linkText')}</A>
      </Footer>
    </RegisterContainer>
  );
};

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
  min-height: 100vh;
  ${Container} {
    margin: auto;
    width: 1000px;
  }
  ${H1} {
    text-align: center;
  }
  ${Text} {
    text-align: center;
  }
  ${StyledForm} {
    margin-top: 3rem;
  }
  ${LoginFlowA} {
    margin: 3rem auto 0;
    text-align: center;
  }
  p {
    ${(props) => props.theme.fonts.textSmallDescription}
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
  }
`;

const GreenIcon = styled.img`
  display: block;
  height: 64px;
  margin: auto auto 3rem;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(100%) contrast(100%);
`;

export default ResetPassword;
