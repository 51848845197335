import { Checkbox, CheckboxChangeEvent, CheckboxProps } from 'primereact/checkbox';
import { ColumnEditorOptions, ColumnFooterOptions, ColumnProps } from 'primereact/column';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { CSSProperties } from 'react';
import { Option } from 'types/types';

export const cellNumberEditor = (options: ColumnEditorOptions) => {
  return (
    <InputNumber
      style={{ minWidth: '3rem', height: '32px', border: 0, width: '100%' }}
      inputStyle={{ minWidth: '3rem', width: '100%', height: '32px' }}
      value={options.value}
      onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback?.(e.value)}
      mode="decimal"
      step={0.01}
      maxFractionDigits={2}
    />
  );
};

/// TODO: E-notation editing if appliable
// export const cellDecimalEditor = (options: ColumnEditorOptions) => {
//   return (
//     <InputNumber
//       style={{ minWidth: '3rem', height: '32px', border: 0, width: '100%' }}
//       inputStyle={{ minWidth: '3rem', width: '100%', height: '32px' }}
//       value={options.value}
//       onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback?.(e.value)}
//       mode="decimal"
//       step={0.01}
//       maxFractionDigits={100}
//     />
//   );
// };

export const cellDecimalEditor = (options: ColumnEditorOptions) => {
  return (
    <InputText
      style={{ minWidth: '3rem', height: '28px', border: 0, width: '98%' }}
      value={options.value || ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback?.(e.target.value)}
    />
  );
};

export const cellTextEditor = (options: ColumnEditorOptions, placeholder?: string) => {
  return (
    <InputText
      style={{ width: '100%', height: '32px', pointerEvents: 'none' }}
      placeholder={placeholder}
      type="text"
      value={options.value || ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback?.(e.target.value)}
    />
  );
};

export const cellSelectEditor = (options: ColumnEditorOptions, selectOptions: Option[]) => {
  return (
    <Dropdown
      style={{ width: '100%', height: '28px', minWidth: '1rem' }}
      value={options.rowData[options.field]}
      optionLabel="label"
      optionValue="value"
      options={selectOptions}
    />
  );
};

export const cellCheckboxEditor = (options: ColumnEditorOptions) => {
  return (
    <Checkbox
      style={{
        minWidth: '1rem',
      }}
      value={options.value}
      checked={options.value}
      onChange={(e: CheckboxChangeEvent) => {
        options.editorCallback?.(e.checked);
      }}
    />
  );
};

export const cellDateRangeEditor = (options: ColumnEditorOptions) => {
  return (
    <Calendar
      style={{ minWidth: '3rem', height: '32px', border: 0, width: '100%' }}
      inputStyle={{ minWidth: '3rem', width: '100%', height: '32px' }}
      value={options.value}
      onChange={(e: any) => options.editorCallback?.(e.value)}
      selectionMode="range"
      readOnlyInput
    />
  );
};
