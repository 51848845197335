import React from 'react';
import { FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { useGetEpdLcaSupport } from 'services/api/queries';
import { useUpdateEpdLcaSupport } from 'services/api/mutations';
import LcaPractitionersSelector from './lca-practitioner-selector/LcaPractitionersSelector';

const LcaSupportPanel: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState?: any;
}> = ({ epdId, epdVersionId, validationState }) => {
  const lcaSupportInformation = useGetEpdLcaSupport(epdVersionId).data;
  const updateMutation = useUpdateEpdLcaSupport(epdVersionId);

  const onChangeLcaSupport = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  return (
    <FieldPanelFullWidth>
      <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>LCA support</FieldPanelHeader>
      <LcaPractitionersSelector
        epdId={epdId}
        epdVersionId={epdVersionId}
        practitioners={lcaSupportInformation?.epdDevelopers ?? []}
        onChanged={onChangeLcaSupport}
        errors={validationState?.errors}
      />
    </FieldPanelFullWidth>
  );
};

export default LcaSupportPanel;
