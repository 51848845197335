import { size } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type TProps = PropsWithChildren<{
  isOpened: boolean;
  header: string;
  onHide: any;
  maximizable?: boolean;
}>;

const EditDialog = ({ isOpened, header, onHide, children, maximizable, ...props }: TProps) => {
  return (
    <Dialog
      header={header}
      visible={isOpened}
      style={{ width: '50vw' }}
      onHide={onHide}
      footer={null}
      maximizable={maximizable}
      {...props}
    >
      <DialogContentContainer>{children}</DialogContentContainer>
    </Dialog>
  );
};

const DialogContentContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
  overflow-y: auto;
`;

export default EditDialog;
