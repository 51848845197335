import { FlowPropertiesClient } from './EpdClient';

const flowPropertiesClient = new FlowPropertiesClient(process.env.REACT_APP_API_URL);

class FlowPropertiesService {
  static getFlowProperties = async () => {
    return await flowPropertiesClient.get();
  };
}

export default FlowPropertiesService;
