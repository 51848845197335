import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FieldPanelHeader, FormPanel } from 'styles/v2/Styles.styled';

type Props = {
  title: string;
  children: ReactNode;
};

const VersionHistorySection: React.FC<Props> = ({ title, children }) => {
  return (
    <FormPanel>
      <FormPanelContent>
        <FieldPanelHeader>{title}</FieldPanelHeader>
        <ContentWrapper>{children}</ContentWrapper>
      </FormPanelContent>
    </FormPanel>
  );
};

export default VersionHistorySection;

const FormPanelContent = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
