import filterIcon from 'images/icons/svg/funnel.svg';
import React, { useState } from 'react';
import styled from 'styled-components';
import { InputSmallIcon } from 'styles/Styles.styled';

import { ProductCategoryModel } from '../../services/EpdClient';
import InformIcon from '../icons/InformIcon';

export const DropDownCategory: React.FunctionComponent<{
  categories?: ProductCategoryModel[];
  onChangeCategory: (category?: string) => void;
}> = ({ categories, onChangeCategory }) => {
  const [filter, setFilter] = useState<string>('');
  const [previousCategory, setPreviousCategory] = useState<string | undefined>(undefined);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };
  const handleChange = (categoryId: any) => {
    if (categoryId && categoryId !== previousCategory) {
      onChangeCategory(categoryId as any);
      setPreviousCategory(categoryId);
    } else {
      onChangeCategory(undefined);
      setPreviousCategory(undefined);
    }
  };

  return (
    <DropDownCat heading={'Product Categories'} icon={filterIcon} content={Tooltips.filterByCategory as any}>
      <InputSmallIcon
        style={{ backgroundImage: `url(${filterIcon})` }}
        type="text"
        placeholder="Filter by category"
        onChange={onChange}
      />
      <Scrollable>
        {categories
          ?.filter((c) => c.title?.toLocaleLowerCase().includes(filter?.toLowerCase()))
          .map((category) => (
            <ChangeCategoryRow
              key={category.id}
              style={{ minWidth: '300px' }}
              previousCategory={previousCategory}
              currentCategory={category.id}
              onClick={() => handleChange(category?.id)}
            >
              <ChangeCategory>{category.title}</ChangeCategory>
            </ChangeCategoryRow>
          ))}
      </Scrollable>
    </DropDownCat>
  );
};

type Props = React.PropsWithChildren<{
  heading?: string;
  icon: any;
  content: string;
}>;

function DropDownCat({ heading, icon, children, content }: Props) {
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false);
  const node = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleClickOutside = (e: any) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setShowDropDown(false);
  };

  React.useEffect(() => {
    if (showDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Profile ref={node} style={{ cursor: 'pointer' }} onClick={() => setShowDropDown(true)}>
      <Image alt="" src={icon} />
      {content && <InformIcon content={content} top={true} />}
      {showDropDown && (
        <ProfileDrop>
          <DropDownHeading>{heading}</DropDownHeading>
          {children}
        </ProfileDrop>
      )}
    </Profile>
  );
}

const Image = styled.img`
  height: 1rem;
  vertical-align: top;
  cursor: pointer;
  &:hover {
    & + * {
      display: initial;
    }
  }
`;

const Tooltips = {
  filterByCategory: 'Filter by product category.',
};

const Profile = styled.div`
  float: right;
  border-radius: 50%;
  padding: 0.5rem;
  height: 15px;
  width: 15px;
  margin: 0 0 0 1rem;
  background-color: ${(props) => props.theme.colors.gray};
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.darkGray};
  }
`;

export const ProfileDrop = styled.div`
  position: absolute;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.3);
  top: 2rem;
  z-index: 1;
  cursor: default;
`;

const DropDownHeading = styled.h4`
  padding: 1rem;
  margin: 0px;
  border-bottom: solid 2px ${(props) => props.theme.colors.regionColorLight};
`;

const ChangeCategoryRow = styled.div<{ previousCategory?: string; currentCategory?: string }>`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  color: ${(props) => (props.previousCategory === props.currentCategory ? props.theme.colors.regionColorLight : 'black')};
  &:hover {
    color: ${(props) => props.theme.colors.regionColorLight};
  }
`;

const ChangeCategory = styled.div`
  padding: 1rem;
  text-decoration: none;
  flex-grow: 1;
`;

const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
`;

export default DropDownCat;
