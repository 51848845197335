import { StatusAttentionIcon } from 'components/v2/icons';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useCreateEpdDeclarationOfCO2Assumption, useUpdateEpdDeclarationOfCO2Assumption } from 'services/api/mutations';
import { useGetEpdDeclarationsOfCO2 } from 'services/api/queries';
import { defaultThemeColors } from 'styles/theme';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { DeclarationOfCO2Model } from 'types/types';

import { cellTextEditor } from '../GridCellEditors';
import { DataTableStyled, Placeholder } from '../StyledGrid';
import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';

const mainRowBodyStyle = {
  minHeight: 32,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 14,
};

const selectedRowStyle: CSSProperties = {
  ...mainRowBodyStyle,
  background: defaultThemeColors.primaryWhite,
};

const unselectedRowStyle: CSSProperties = {
  ...mainRowBodyStyle,
  background: defaultThemeColors.elementsFilledInput,
};

const DeclarationOfCO2Grid: React.FunctionComponent<{
  epdVersionId: string;
  disabled: boolean;
  error: string | undefined;
}> = ({ epdVersionId, disabled, error }) => {
  const declarationsOfCO2 = useGetEpdDeclarationsOfCO2(epdVersionId).data;
  const createDeclarationOfCO2Assumption = useCreateEpdDeclarationOfCO2Assumption(epdVersionId);
  const updateDeclarationOfCO2Assumption = useUpdateEpdDeclarationOfCO2Assumption(epdVersionId);

  const [selectedRows, setSelectedRows] = useState<DeclarationOfCO2Model[]>([]);
  const isReadOnly = useIsReadOnlyMode() || disabled;

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  useEffect(() => {
    if (!declarationsOfCO2) return;
    setSelectedRows(declarationsOfCO2.filter((d) => d.isSelected));
  }, [declarationsOfCO2]);

  const createOrUpdateDeclarationOfCO2Assumption = (rowData: any) => {
    const { nameId, assumptions, isSelected } = rowData;
    if (assumptions === null) {
      createDeclarationOfCO2Assumption.mutate({
        nameId,
        epdVersionId,
        isSelected: true,
      });
      return;
    }
    updateDeclarationOfCO2Assumption.mutate({
      nameId,
      epdVersionId,
      assumptions: assumptions,
      isSelected: !isSelected,
    });
  };

  const handleRowClick = (event: any) => {
    const checkboxColumnIndex = 0;
    const target = event.originalEvent.target;
    const cell = target.closest('td');

    if (!cell || cell.cellIndex !== checkboxColumnIndex) return;
    const rowData = event.data;

    if (selectedRows.includes(rowData)) {
      createOrUpdateDeclarationOfCO2Assumption(rowData);
      setSelectedRows(selectedRows.filter((item) => item !== rowData));
      return;
    }

    createOrUpdateDeclarationOfCO2Assumption(rowData);
    setSelectedRows([...selectedRows, rowData]);
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    rowData[field] = newValue;
    const data = {
      epdVersionId,
      nameId: rowData.nameId,
      assumptions: newValue,
      isSelected:
        selectedRows.find((r) => r.nameId === rowData.nameId && r.epdVersionId === epdVersionId)?.isSelected ?? false,
    };
    if (!rowData.epdVersionId) {
      createDeclarationOfCO2Assumption.mutate(data);
      return;
    }
    updateDeclarationOfCO2Assumption.mutate(data);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-start gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">
        CO<sub>2</sub> uptake associated with carbonation and its assumptions
      </HeaderCaptionSemiBold>
      {error && <TooltipErrorIcon content={error} />}
    </div>
  );

  const renderNameColumnBody = (rowData: DeclarationOfCO2Model) => {
    const { name, assumptions } = rowData;
    if (!assumptions && isRowSelected(rowData)) {
      return (
        <div style={getRowBodyStyle(rowData)}>
          <span>{name}</span>
          <StatusAttentionIcon />
        </div>
      );
    }
    return <div style={getRowBodyStyle(rowData)}>{name}</div>;
  };

  const renderSelectedRowAssumptionCell = (assumptions: string | undefined) => {
    if (!assumptions) {
      return <Placeholder>Type here assumptions</Placeholder>;
    }
    return <span>{assumptions}</span>;
  };

  const renderAssumptionsColumnBody = (rowData: DeclarationOfCO2Model) => {
    const { assumptions } = rowData;
    return (
      <div style={getRowBodyStyle(rowData)}>
        {isRowSelected(rowData) ? renderSelectedRowAssumptionCell(assumptions) : 'Excluded'}
      </div>
    );
  };

  const assumptionsColumnCellEditor = (options: ColumnEditorOptions) => {
    if (isRowSelected(options.rowData)) {
      return cellTextEditor(options, 'Type here assumptions');
    }
    return <div style={unselectedRowStyle}>Excluded</div>;
  };

  const isRowSelected = (rowData: DeclarationOfCO2Model) => {
    return (
      selectedRows.some((row) => row.epdVersionId === rowData.epdVersionId && row.nameId === rowData.nameId) && !isReadOnly
    );
  };

  const getRowBodyStyle = (rowData: DeclarationOfCO2Model) => {
    return isRowSelected(rowData) ? selectedRowStyle : unselectedRowStyle;
  };

  const handleRowSelect = (event: any) => {
    const { data } = event;
    const selectData = {
      epdVersionId,
      nameId: data.nameId,
      assumptions: data.assumptions,
      isSelected: true,
    };
    if (data.assumptions === null) {
      createDeclarationOfCO2Assumption.mutate(selectData);
      return;
    }
    updateDeclarationOfCO2Assumption.mutate(selectData);
  };

  const handleRowUnselect = (event: any) => {
    const { data } = event;
    const unselectData = {
      epdVersionId,
      nameId: data.nameId,
      assumptions: data.assumptions,
      isSelected: false,
    };
    if (data.assumptions === null) {
      createDeclarationOfCO2Assumption.mutate(unselectData);
      return;
    }
    updateDeclarationOfCO2Assumption.mutate(unselectData);
  };

  return (
    <DataTableStyled
      dataKey="nameId"
      header={header}
      value={declarationsOfCO2}
      editMode="cell"
      selectionMode="checkbox"
      selection={selectedRows}
      onRowSelect={handleRowSelect}
      onRowUnselect={handleRowUnselect}
      onSelectionChange={(event: any) => setSelectedRows(event.value as DeclarationOfCO2Model[])}
      rowClassName={rowClassName}
      {...(!isReadOnly && { onRowClick: handleRowClick })}
      showGridlines
    >
      <Column
        selectionMode={isReadOnly ? undefined : 'multiple'}
        headerStyle={{ display: 'none' }}
        style={{ width: '5%' }}
        align="center"
      />
      <Column
        field="name"
        headerStyle={{ display: 'none', overflow: 'hidden', position: 'relative' }}
        bodyStyle={{ padding: 0 }}
        body={renderNameColumnBody}
      />
      <Column
        field="assumptions"
        headerStyle={{ display: 'none' }}
        style={{ width: '40%' }}
        bodyStyle={{ padding: 0 }}
        body={renderAssumptionsColumnBody}
        editor={isReadOnly ? undefined : assumptionsColumnCellEditor}
        onCellEditComplete={onCellEditComplete}
      />
    </DataTableStyled>
  );
};

export default DeclarationOfCO2Grid;
